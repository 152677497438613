import React from "react";
import { Switch } from "react-router-dom";
import { StylesProvider } from "@material-ui/core/styles";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dashboard from "pages/dashboard/Page";
import Module from "pages/module/page";
import Businessrules from "pages/businessrules/page";
import Preview from "pages/preview/page";
import NotFound from "pages/NotFound";

import "App.scss";

//key cloak related imports
import { ReactKeycloakProvider } from "@react-keycloak/web";
import KeyCloakPrivateRoute from "keycloak/KeyCloakPrivateRoute";

import { BrowserRouter, useHistory, useLocation } from "react-router-dom";
import { eventLogger, keycloak, tokenLogger } from "keycloak/keyCloakConfig";
import { routes } from "Uitls/Util";
import { ModuleContextProvider } from "contexts/ModuleContext";

declare function cwr(operation: string, payload: any): void;

function App() {
  const location = useLocation();

  React.useEffect(() => {
    // console.log(useLocation());
    if (location) {
      cwr("recordPageView", location.pathname);
    }
  }, [location]);
  return (
    <div className="container-fluid">
      <StylesProvider injectFirst>
        <ToastContainer limit={1} />
        <ReactKeycloakProvider
          initOptions={{ onLoad: "login-required" }}
          authClient={keycloak}
          onEvent={eventLogger}
          onTokens={tokenLogger}
          keycloak={keycloak}
        >
          <Switch>
            <KeyCloakPrivateRoute
              path={routes.dashboard}
              component={Dashboard}
              exact
              // canAccessedBy={[
              //   "offline_access",
              //   "users",
              //   "translator",
              //   "agency",
              // ]}
            />
            <ModuleContextProvider>
              <KeyCloakPrivateRoute
                path={routes.module}
                component={Module}
                exact
                // canAccessedBy={[
                //   "offline_access",
                //   "users",
                //   "translator",
                //   "agency",
                // ]}
              />
              <KeyCloakPrivateRoute
                path={routes.businessRules}
                component={Businessrules}
                exact
                // canAccessedBy={[
                //   "offline_access",
                //   "users",
                //   "translator",
                //   "agency",
                // ]}
              />
              <KeyCloakPrivateRoute
                path="/preview"
                component={Preview}
                exact
                // canAccessedBy={[
                //   "offline_access",
                //   "users",
                //   "translator",
                //   "agency",
                // ]}
              />
            </ModuleContextProvider>

            <KeyCloakPrivateRoute component={NotFound} />
          </Switch>
          {/* </BrowserRouter> */}
        </ReactKeycloakProvider>
      </StylesProvider>
    </div>
  );
}
export default App;
