import React from "react";
import styled from "styled-components";
import SingleLinekeyValue from "components/SingleLineKeyValue";
import LinkButton from "components/LinkButton";
import { BoldText } from "./Texts";
import completeImg from "img/complete.svg";

const OtherDetailsDiv = styled.div`
  margin-top: 16px;
  margin-bottom: 18px;
  margin-left: 22px;
`;
const SuccessfulToastMessage = ({ message, otherDetails, handleClose }) => {
  return (
    <div style={{ color: "#000" }}>
      <div className="d-flex">
        <img
          src={completeImg}
          alt=""
          height={16}
          style={{ marginRight: "6px" }}
        />
        <BoldText>{message}</BoldText>
      </div>

      <OtherDetailsDiv>
        {Object.keys(otherDetails).map((el) => {
          return (
            <div className="d-flex" style={{ margin: "6px 0px" }}>
              <SingleLinekeyValue
                label={el}
                value={otherDetails[el]}
                inline={true}
              />
            </div>
          );
        })}
      </OtherDetailsDiv>
      <div style={{ float: "right" }}>
        <LinkButton title="OK, GOT IT" handleClick={handleClose} />
      </div>
    </div>
  );
};

export default SuccessfulToastMessage;
