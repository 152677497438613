import { BoldText } from "components/Texts";
import React from "react";

const ImportComponentsInfoHoverText = () => {
  return (
    <div>
      <BoldText>Add Component :</BoldText>

      <ol style={{ paddingLeft: "12px", marginBottom: "5px" }}>
        <li>To add a component from VVPM, please click the add CTA.</li>
        <li>
          Use the search screen to apply filters or directly search for a
          component.
        </li>
        <li>
          Doc type, Sub-doc Type, Topic & Sub-Topic are the additional filter
          types provided for the search.
        </li>
      </ol>
      <BoldText>Note :</BoldText>
      <ol style={{ paddingLeft: "12px", marginBottom: "0px" }}>
        <li>
          Components can be added only when either one claim or one reusable
          text has been added.
        </li>
        <li>
          You can now select one component at a time from the search and
          selection screen.
        </li>
        <li>You can delete one or more Component from the Component Box.</li>
      </ol>
    </div>
  );
};

export default ImportComponentsInfoHoverText;
