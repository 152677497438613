import React from "react";
import "styles/components/DashboardBlueTile.scss";
import ModulesIcon from "img/ModulesTilesIcon.svg";
// import ChannelMatrixIcon from "img/ChannelMatrixScatterIcon.svg";
// import MlrSubmissionIcon from "img/MlrSubmissionIcon.svg";
import BussinessRulesIcon from "img/BussinessRules.svg";
import { useTranslation } from "react-i18next";

const DashboardBlueTile = ({ selectedTab, handleTabSelection }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="row align-items-center align-items-stretch topNavigationRow">
        <div className="topRowColum1">
          <div className="topTitleText">
            <span className="choose-title">
              {selectedTab === 0
                ? "Module Dashboard"
                : t("generalTexts.businessRulesDashboard")}
            </span>
            <span className="proceed-title">
              {selectedTab === 0
                ? t("generalTexts.browseAndCreateModules")
                : t("generalTexts.applyBusinessRules")}
            </span>
          </div>
        </div>
        <div
          className={`link-block ${selectedTab === 0 ? "active" : ""}`}
          onClick={() => handleTabSelection(0)}
        >
          <div>
            <span>
              <img src={ModulesIcon} alt="" className="img-fluid" />
            </span>
            <span>Modules</span>
          </div>
        </div>
        <div
          className={`link-block ${selectedTab === 1 ? "active" : ""}`}
          onClick={() => handleTabSelection(1)}
        >
          <div>
            <span>
              <img src={BussinessRulesIcon} alt="" className="img-fluid" />
            </span>
            <span>Business Rules</span>
          </div>
        </div>
        {/* <div className={`link-block ${selectedTab === 2 ? 'active' :""}`} onClick={()=>handleTabSelection(2)}>
          <div>
            <span>
              <img src={ChannelMatrixIcon} alt="" className="img-fluid" />
            </span>
            <span>Channel Matrix</span>
          </div>
        </div> 
        <div className={`link-block ${selectedTab === 3 ? 'active' :""}`} onClick={()=>handleTabSelection(3)}>
          <div>
            <span>
              <img src={MlrSubmissionIcon} alt="" className="img-fluid" />
            </span>
            <span>MLR Submission</span>
            <span className="mt6">Document</span>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default DashboardBlueTile;
