import React from "react";
import variables from "../styles/base/_universal.scss";
import styled from "styled-components";
import { TransparentButton } from "./Buttons";

const LinkButton = ({
  title,
  handleClick,
  customClass,
  disabled = false,
  height,
}) => {
  return (
    <TransparentButton
      title={title}
      className={`${customClass} link-button`}
      variant="light"
      disabled={disabled}
      onClick={handleClick}
      height={height}
    >
      {title}
    </TransparentButton>
  );
};

export default LinkButton;
