import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  createRef,
} from "react";
import "styles/modules/modulePreview.scss";
import modulePreviewArrow from "img/modulePreviewArrow.svg";
import mustUseRedDot from "img/mustUseRedDot.svg";
import optionalGreenDot from "img/mustUseGreenDot.svg";
import sampleScreenShot from "images/sampleScreenShot.jpg";
import BusinessRulesPreview from "components/businessrules/BusinessRlsPreview";
import SingleLinekeyValue from "components/SingleLineKeyValue";
import { getMlrStatusIcon } from "Uitls/Util";
import OverlayPopOver from "Uitls/OverlayPopOver";
import { ToolTipText } from "components/TooltipText";
const popoverRef = createRef();

function ModulePreviewData(props) {
  const {
    modulePreviewData,
    isBussinessRulesView,
    activePreviwData,
    generatePdf,
    openedFromModal = false,
  } = props;
  const reusableTextTypes = [
    {
      name: "copy__v",
      label: "Copy",
    },
    {
      name: "headline__v",
      label: "Headline",
    },
    {
      name: "objective__v",
      label: "Objective",
    },
    {
      name: "strapline__v",
      label: "Strapline",
    },
    {
      name: "summary__v",
      label: "Summary",
    },
    {
      name: "tagline__v",
      label: "Tagline",
    },
    {
      name: "teaser__v",
      label: "Teaser",
    },
    {
      name: "title__v",
      label: "Title",
    },
  ];
  function getLabelByName(name) {
    const item = reusableTextTypes.find((item) => item.name === name);
    return item ? item.label : null;
  }
  const getSvgCDNLinks = (fileName) => {
    return modulePreviewData?.logos?.find((item) => item.includes(fileName));
  };
  const getMlrStatusIcon = (mlrStatus) => {
    if (!mlrStatus) return getSvgCDNLinks("infoIcon.svg");

    const trimmedStatus = mlrStatus.trim().toLowerCase();

    if (trimmedStatus.includes("draft")) {
      return getSvgCDNLinks("statusDraft.svg");
    } else if (trimmedStatus.includes("available")) {
      return getSvgCDNLinks("statusApproved.svg");
    } else if (trimmedStatus.includes("expired")) {
      return getSvgCDNLinks("final-status.svg");
    } else {
      return getSvgCDNLinks("Intermediate.svg");
    }
  };

  return (
    <>
      <div
        id="modulePreviewContainer"
        className="pdfPreviewWidth mt-4"
        ref={popoverRef}
      >
        <div
          className={`col-12 p-0 ${
            !activePreviwData ? "cmsdTrackContainer" : null
          }`}
        >
          <div className="modulePreviewContainer">
            {generatePdf ? null : (
              <div className="modulePreviewHeader">
                <p>
                  {modulePreviewData?.countryName && (
                    <>
                      <span className="ml-0">Primary Product:</span>{" "}
                      {modulePreviewData?.productName}
                    </>
                  )}
                  {modulePreviewData?.productName && (
                    <>
                      <span>Primary Country:</span>{" "}
                      {modulePreviewData?.countryName}
                    </>
                  )}
                  {modulePreviewData?.indications?.length > 0 && (
                    <>
                      <span>Indication:</span>{" "}
                      {modulePreviewData?.indications?.map(
                        (indication, index, array) => (
                          <>
                            {indication.indicationName}
                            {index < array.length - 1 ? ", " : ""}
                          </>
                        )
                      )}
                    </>
                  )}

                  {modulePreviewData?.languageName && (
                    <>
                      <span>Language:</span> {modulePreviewData?.languageName}
                    </>
                  )}
                  {modulePreviewData?.topicName && (
                    <>
                      <span>Topic:</span> {modulePreviewData?.topicName}
                    </>
                  )}
                  {modulePreviewData?.subtopicName && (
                    <>
                      <span>Sub-Topic:</span> {modulePreviewData?.subtopicName}
                    </>
                  )}
                </p>

                {modulePreviewData?.audienceName ||
                modulePreviewData?.segments?.length > 0 ||
                modulePreviewData?.strategicObjectives?.length > 0 ||
                modulePreviewData?.languageName ? (
                  <>
                    <p>
                      {modulePreviewData?.audienceName && (
                        <>
                          <span className="ml-0">Audience:</span>{" "}
                          {modulePreviewData?.audienceName}
                        </>
                      )}
                      {modulePreviewData?.therapeuticAreaName && (
                        <>
                          <span>Therapeutic Area:</span>{" "}
                          {modulePreviewData?.therapeuticAreaName}
                        </>
                      )}
                      {modulePreviewData.segments.length > 0 && (
                        <>
                          <span>Segment:</span>{" "}
                          {modulePreviewData.segments.map(
                            (indication, index, array) => (
                              <>
                                {indication.segmentName}
                                {index < array.length - 1 ? ", " : ""}
                              </>
                            )
                          )}
                        </>
                      )}

                      {modulePreviewData?.strategicObjectives?.length > 0 && (
                        <>
                          <span>Strategic Objective:</span>{" "}
                          {modulePreviewData?.strategicObjectives?.map(
                            (indication, index, array) => (
                              <>
                                {indication.strategicObjName}
                                {index < array.length - 1 ? ", " : ""}
                              </>
                            )
                          )}
                        </>
                      )}
                    </p>
                    <p className="mb-0">
                      {modulePreviewData?.communicationObjectives && (
                        <>
                          <span className="ml-0">
                            {" "}
                            Communication Objectives:
                          </span>{" "}
                          {modulePreviewData?.communicationObjectives}
                        </>
                      )}
                    </p>
                  </>
                ) : null}
              </div>
            )}
            {modulePreviewData?.claims &&
              modulePreviewData?.claims?.map((claim) => (
                <>
                  {claim.trackChangeValue && (
                    <div>
                      <span
                        className={
                          claim.trackChangeValue === "new"
                            ? "newData"
                            : claim.trackChangeValue === "old"
                            ? "newData oldData"
                            : ""
                        }
                      >
                        {claim.trackChangeValue}
                      </span>
                    </div>
                  )}
                  <div
                    className={`d-flex moduleClaimSection ${
                      claim.trackChangeValue === "new"
                        ? "trackChangeValue"
                        : claim.trackChangeValue === "old"
                        ? "trackChangeValue old"
                        : ""
                    }`}
                    key={claim.claimId}
                  >
                    <div>
                      <div className={`d-flex align-items-center`}>
                        <div className="claimBoxBrdr">
                          <div className="claimType">CLAIM</div>
                          <div className="damStatus">
                            {claim.mustUse ? (
                              <>
                                <span>
                                  <img
                                    src={getSvgCDNLinks("mustUseRedDot.svg")}
                                    // src="https://d22ddreqdqjerl.cloudfront.net/LOGO/mustUseRedDot.svg"
                                    alt=""
                                  />
                                </span>{" "}
                                <span>Must Use</span>
                              </>
                            ) : (
                              <>
                                <span>
                                  <img
                                    src={getSvgCDNLinks("mustUseGreenDot.svg")}
                                    //src="https://d22ddreqdqjerl.cloudfront.net/LOGO/mustUseGreenDot.svg"
                                    alt=""
                                  />
                                </span>{" "}
                                <span>Optional</span>
                              </>
                            )}
                          </div>
                          <div className="claimId">
                            PromoMats ID:{" "}
                            {/* <span>
                              <img
                                style={{ verticalAlign: "unset" }}
                                src={getMlrStatusIcon(claim?.mlrStatus)}
                                alt={modulePreviewData?.mlrStatus}
                              />

                              {claim?.claimName}
                            </span> */}
                            <span>
                              <OverlayPopOver
                                element={
                                  <img
                                    src={getMlrStatusIcon(claim?.mlrStatus)}
                                    alt={claim?.mlrStatus}
                                  />
                                }
                                overlayData={
                                  <ToolTipText>
                                    {claim?.mlrStatus
                                      ? claim?.mlrStatus
                                      : "Module not Published"}
                                  </ToolTipText>
                                }
                                trigger={["hover", "focus"]}
                                placement="bottom"
                                classId="tooltip"
                                containerRef={popoverRef}
                              />
                              {claim?.claimName}
                            </span>
                          </div>
                        </div>
                        <div>
                          <img
                            src={getSvgCDNLinks("modulePreviewArrow.svg")}
                            //src="https://d22ddreqdqjerl.cloudfront.net/LOGO/modulePreviewArrow.svg"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="claimDetailsBox">
                      <p className="mb-0 claimText">{claim.matchText}</p>

                      {claim.matchTextVariants &&
                        claim.matchTextVariants.map((variant, index) => (
                          <>
                            <p className="captionText">Variation {index + 1}</p>
                            <p className="mb-0 claimText">
                              {" "}
                              {variant.matchText}
                            </p>
                          </>
                        ))}

                      {claim.footnotes && (
                        <p className="captionText">Footnote</p>
                      )}
                      <p className="relatedText">{claim.footnotes}</p>
                      {claim.references.length > 0 && (
                        <p className="captionText">Reference</p>
                      )}
                      <div className="relatedText">
                        {claim.references.map((reference, refIndex) => (
                          <p
                            className="d-block"
                            style={{ marginBottom: "5px" }}
                          >
                            ({refIndex + 1}){" "}
                            {/* <a href={reference.documentUrl}> */}
                            {reference.documentName}
                            {/* </a> */}
                          </p>
                        ))}
                      </div>
                    </div>
                  </div>
                </>
              ))}
            {modulePreviewData?.claims?.length > 0 && (
              <div className="sectionDevider"></div>
            )}
            {modulePreviewData?.reusableTexts?.length > 0 && (
              <>
                {modulePreviewData?.reusableTexts?.map(
                  (reusableText, index) => (
                    <div key={index}>
                      {reusableText.trackChangeValue && (
                        <div>
                          <span
                            className={
                              reusableText.trackChangeValue === "new"
                                ? "newData"
                                : reusableText.trackChangeValue === "old"
                                ? "newData oldData"
                                : ""
                            }
                          >
                            {reusableText.trackChangeValue}
                          </span>
                        </div>
                      )}
                      <div
                        className={`d-flex align-items-center  moduleClaimSection ${
                          reusableText.trackChangeValue === "new"
                            ? "trackChangeValue"
                            : reusableText.trackChangeValue === "old"
                            ? "trackChangeValue old"
                            : ""
                        }`}
                      >
                        <div className={`d-flex align-items-center`}>
                          <div className="claimBoxBrdr">
                            <div className="claimType">Reusable text</div>
                            {reusableText.mustUse ? (
                              <>
                                <div className="damStatus">
                                  <span>
                                    <img
                                      src={getSvgCDNLinks("mustUseRedDot.svg")}
                                      //src="https://d22ddreqdqjerl.cloudfront.net/LOGO/mustUseRedDot.svg"
                                      alt=""
                                    />
                                  </span>{" "}
                                  <span>Must Use</span>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="damStatus">
                                  <span>
                                    <img
                                      src={getSvgCDNLinks(
                                        "mustUseGreenDot.svg"
                                      )}
                                      //src="https://d22ddreqdqjerl.cloudfront.net/LOGO/mustUseRedDot.svg"
                                      alt=""
                                    />
                                  </span>{" "}
                                  <span>Optional</span>
                                </div>
                              </>
                            )}
                            <div className="claimTypeText">
                              Type: <span>{reusableText.textType}</span>
                            </div>
                            <div className="claimId">
                              PromoMats ID:{" "}
                              {/* <span>
                                <img
                                  style={{ verticalAlign: "unset" }}
                                  src={getMlrStatusIcon(
                                    reusableText?.mlrStatus
                                  )}
                                  alt={reusableText?.mlrStatus}
                                />
                                {reusableText.reusableTextName}
                              </span> */}
                              <span>
                                <OverlayPopOver
                                  element={
                                    <img
                                      src={getMlrStatusIcon(
                                        reusableText?.mlrStatus
                                      )}
                                      alt={reusableText?.mlrStatus}
                                    />
                                  }
                                  overlayData={
                                    <ToolTipText>
                                      {reusableText?.mlrStatus
                                        ? reusableText?.mlrStatus
                                        : "Module not Published"}
                                    </ToolTipText>
                                  }
                                  trigger={["hover", "focus"]}
                                  placement="bottom"
                                  classId="tooltip"
                                  containerRef={popoverRef}
                                />
                                {reusableText.reusableTextName}
                              </span>
                            </div>
                          </div>
                          <div>
                            <img
                              src={getSvgCDNLinks("modulePreviewArrow.svg")}
                              //src="https://d22ddreqdqjerl.cloudfront.net/LOGO/mustUseRedDot.svg"
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="claimDetailsBox">
                          <div>
                            {reusableText.textType !== "call_to_action__c" ? (
                              <p className="mb-0 claimText">
                                {reusableText.matchText}
                              </p>
                            ) : (
                              <>
                                <p className="mb-0 claimText">
                                  {reusableText.matchText}
                                </p>
                                <p className="captionText">Link</p>
                                <p className="relatedText"> </p>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                )}
                <div className="sectionDevider"></div>
              </>
            )}
            {modulePreviewData?.components?.length > 0 &&
              modulePreviewData?.components.map((component, index) => (
                <div className="d-flex moduleClaimSection" key={index}>
                  <div>
                    <div className={`d-flex align-items-center`}>
                      <div className="claimBoxBrdr">
                        <div className="claimType">Component</div>
                        <div className="damStatus">
                          {component.mustUse ? (
                            <>
                              <div className="damStatus">
                                <span>
                                  <img
                                    src={getSvgCDNLinks("mustUseRedDot.svg")}
                                    //src="https://d22ddreqdqjerl.cloudfront.net/LOGO/mustUseRedDot.svg"
                                    alt=""
                                  />
                                </span>{" "}
                                <span>Must Use</span>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="damStatus">
                                <span>
                                  <img
                                    src={getSvgCDNLinks("mustUseGreenDot.svg")}
                                    //src="https://d22ddreqdqjerl.cloudfront.net/LOGO/mustUseGreenDot.svg"
                                    alt=""
                                  />
                                </span>{" "}
                                <span>Optional</span>
                              </div>
                            </>
                          )}
                        </div>
                        <div className="claimTypeText">
                          Sub-Type: <span>{component.subType}</span>
                        </div>

                        <div className="claimId">
                          PromoMats ID:{" "}
                          {/* <span>
                            <img
                              style={{ verticalAlign: "unset" }}
                              src={getMlrStatusIcon(component?.mlrStatus)}
                              alt={component?.mlrStatus}
                            />
                            {component.componentDocNumber}
                          </span> */}
                          <span>
                            <OverlayPopOver
                              element={
                                <img
                                  src={getMlrStatusIcon(component?.mlrStatus)}
                                  alt={component?.mlrStatus}
                                />
                              }
                              overlayData={
                                <ToolTipText>
                                  {component?.mlrStatus
                                    ? component?.mlrStatus
                                    : "Module not Published"}
                                </ToolTipText>
                              }
                              trigger={["hover", "focus"]}
                              placement="bottom"
                              classId="tooltip"
                              containerRef={popoverRef}
                            />
                            {component.componentDocNumber}
                          </span>
                        </div>
                      </div>
                      <div>
                        <img
                          src={getSvgCDNLinks("modulePreviewArrow.svg")}
                          //src="https://d22ddreqdqjerl.cloudfront.net/LOGO/modulePreviewArrow.svg"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="claimDetailsBox">
                    {component.trackChangeValue && (
                      <div>
                        <span
                          className={
                            component.trackChangeValue === "new"
                              ? "newData"
                              : component.trackChangeValue === "old"
                              ? "newData oldData"
                              : ""
                          }
                        >
                          {component.trackChangeValue}
                        </span>
                      </div>
                    )}
                    <img
                      src={component.componentUrl}
                      alt=""
                      className="mb-2 img-fluid"
                    />
                    {(component?.title || component?.matchText) &&
                    component.subType !== "Data Graphic" ? (
                      <>
                        <p className="captionText">TITLE</p>
                        <p className="mb-0 relatedText">
                          {component.matchText
                            ? component.matchText
                            : component.title}
                        </p>
                      </>
                    ) : (
                      <>
                        {component?.relatedClaims?.length > 0 && (
                          <p className="captionText">
                            RELATED CLAIMS ({component?.relatedClaims?.length})
                          </p>
                        )}
                        {component.relatedClaims?.map((item, index) => (
                          <div style={{ marginBottom: "20px" }} key={index}>
                            <SingleLinekeyValue
                              label="ID"
                              value={
                                <>
                                  <div
                                    style={{
                                      display: "inline-flex",
                                      marginRight: "4px",
                                      marginLeft: "3px",
                                    }}
                                  >
                                    <span className="mr-1">
                                      <OverlayPopOver
                                        element={
                                          <img
                                            src={getMlrStatusIcon(
                                              item.mlrStatus
                                            )}
                                            alt={item.mlrStatus}
                                          />
                                        }
                                        overlayData={
                                          <ToolTipText>
                                            {item.mlrStatus
                                              ? item.mlrStatus
                                              : ""}
                                          </ToolTipText>
                                        }
                                        trigger={["hover", "focus"]}
                                        placement="bottom"
                                        classId="tooltip"
                                        containerRef={popoverRef}
                                      />
                                    </span>
                                  </div>
                                  <span
                                    style={{
                                      display: "inline-flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    {item?.claimName}
                                  </span>
                                  {item?.mlrStatus
                                    ?.trim()
                                    .toLowerCase()
                                    .includes("expired") && (
                                    <span
                                      className="Expired"
                                      style={{
                                        verticalAlign: "middle",
                                        marginBottom: "-2px",
                                      }}
                                    >
                                      Expired
                                    </span>
                                  )}
                                </>
                              }
                              inline={true}
                            />
                            <p
                              className="mb-0 claimText"
                              style={{ marginTop: "12px" }}
                            >
                              {item.matchText}
                            </p>
                            {item.footnotes && (
                              <>
                                <p className="captionText">FOOTNOTE</p>
                                <p className="mb-0 relatedText">
                                  {item.footnotes ? item.footnotes : ""}
                                </p>
                              </>
                            )}
                            {item.references?.length > 0 && (
                              <>
                                <p className="captionText">Reference</p>
                                <p className="relatedText">
                                  {item.references?.map(
                                    (reference, refIndex) => (
                                      <span className="d-block" key={refIndex}>
                                        ({refIndex + 1}){" "}
                                        {reference.documentName}
                                      </span>
                                    )
                                  )}
                                </p>
                              </>
                            )}
                          </div>
                        ))}
                        {component?.relatedReusableTexts?.length > 0 && (
                          <p className="captionText">
                            Related Reusable Texts (
                            {component?.relatedReusableTexts?.length})
                          </p>
                        )}
                        {component.relatedReusableTexts?.map(
                          (item, refIndex) => (
                            <div
                              style={{ marginBottom: "20px" }}
                              key={refIndex}
                            >
                              <SingleLinekeyValue
                                label="ID"
                                // value={item.reusableTextName}
                                value={
                                  <>
                                    <div
                                      style={{
                                        display: "inline-flex",
                                        marginRight: "4px",
                                        marginLeft: "3px",
                                      }}
                                    >
                                      <span className="mr-1">
                                        <OverlayPopOver
                                          element={
                                            <img
                                              src={getMlrStatusIcon(
                                                item.mlrStatus
                                              )}
                                              alt={item.mlrStatus}
                                            />
                                          }
                                          overlayData={
                                            <ToolTipText>
                                              {item.mlrStatus
                                                ? item.mlrStatus
                                                : ""}
                                            </ToolTipText>
                                          }
                                          trigger={["hover", "focus"]}
                                          placement="bottom"
                                          classId="tooltip"
                                          containerRef={popoverRef}
                                        />
                                      </span>
                                    </div>
                                    <span
                                      style={{
                                        display: "inline-flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      {item.reusableTextName}
                                    </span>
                                    {item?.mlrStatus
                                      ?.trim()
                                      .toLowerCase()
                                      .includes("expired") && (
                                      <span
                                        className="Expired"
                                        style={{
                                          verticalAlign: "middle",
                                          marginBottom: "-2px",
                                        }}
                                      >
                                        Expired
                                      </span>
                                    )}
                                  </>
                                }
                                inline={true}
                              />
                              <p
                                className="mb-0 claimText"
                                style={{ marginTop: "12px" }}
                              >
                                {item.matchText}
                              </p>
                            </div>
                          )
                        )}
                      </>
                    )}
                    {component.footnotes && (
                      <>
                        <p className="captionText">FOOTNOTE</p>
                        <p className="mb-0 relatedText">
                          {component.footnotes ? component.footnotes : ""}
                        </p>
                      </>
                    )}

                    {component.references?.length > 0 && (
                      <p className="captionText">Reference</p>
                    )}
                    <p className="relatedText">
                      {component.references?.map((reference, refIndex) => (
                        <span className="d-block">
                          ({refIndex + 1}){" "}
                          {/* <a href={reference.documentUrl}> */}
                          {reference.documentName}
                          {/* </a> */}
                        </span>
                      ))}
                    </p>
                  </div>
                </div>
              ))}
          </div>
          {modulePreviewData?.businessRulesTrackChanges && (
            <>
              <div>
                <span className="bsOld">Old</span>
              </div>
              <BusinessRulesPreview
                businessRules={
                  modulePreviewData?.businessRulesTrackChanges?.old
                }
                usageGuidelines={
                  modulePreviewData?.businessRulesTrackChanges
                    ?.usageGuidelines || ""
                }
                linksNotClickable={openedFromModal}
              />
            </>
          )}
          {(modulePreviewData?.businessRules?.length > 0 ||
            modulePreviewData?.usageGuidelines ||
            modulePreviewData?.businessRulesTrackChanges) && (
            <>
              {modulePreviewData?.businessRulesTrackChanges && (
                <div>
                  <span className="newData">New</span>
                </div>
              )}
              <BusinessRulesPreview
                businessRules={modulePreviewData?.businessRules}
                usageGuidelines={modulePreviewData?.usageGuidelines || ""}
                linksNotClickable={openedFromModal}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default ModulePreviewData;
