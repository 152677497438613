import React, { useState, useEffect, useRef, useContext } from "react";
import BrowseModules from "./BrowseModules";
import ApplyBusinessRules from "./ApplyBusinessRules";
import styles from "styles/components/businessRules/businesrules.module.scss";
import { getModuleBusinessRules, saveBusinessRules } from "services/apis";
import { routes, showError, emitter } from "Uitls/Util";
import { toast } from "react-toastify";
import LinkButton from "components/LinkButton";
import PrimaryButton from "components/PrimaryButton";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import FooterButtons from "components/FooterButtons";
import { brulesScreens } from "pages/businessrules/page";
import CircularProgressBar from "Uitls/CircularProgressBar";
import { ModuleContext, moduleActionTypes } from "contexts/ModuleContext";

const Businessrules = (props) => {
  const {
    sourceModule,
    modulesList,
    goToNext,
    goBack,
    setAlreadyaddedModules,
    defaultScreen,
    setSelectedModules,
  } = props;
  const history = useHistory();
  const guidelinesRef = useRef();
  const [brulesSaved, setBrulesSaved] = useState(false);
  const { updateModule, module } = useContext(ModuleContext);
  // const [selectedAssociatedModules, setSelectedAssociatedModules] =
  //   useState(undefined);
  const [autoSyncData, setAutoSyncData] = useState(undefined);
  const [sourceModuleDetails, setSourceModuleDetails] = useState(undefined);

  useEffect(() => {
    if (autoSyncData) {
      let newData = [...modulesList];
      let index = newData?.findIndex((item) => item.id === autoSyncData?.id);
      if (index > -1) {
        newData[index].mlrStatus = autoSyncData.mlrStatus;
        setSelectedModules(newData);
      }
      if (sourceModule && sourceModule.id === autoSyncData?.id) {
        let moduleDetails = JSON.parse(JSON.stringify(sourceModuleDetails));
        moduleDetails.mlrStatus = autoSyncData?.mlrStatus;
        setSourceModuleDetails(moduleDetails);
        updateModule(moduleActionTypes.SET_MODULE, moduleDetails);
        localStorage.setItem("bsrulesPageData", JSON.stringify(moduleDetails));
      }
    }
  }, [autoSyncData]);

  useEffect(() => {
    const handleFooEvent = (e) => {
      try {
        const parsedData = JSON.parse(e);
        if (parsedData.type === "Module") {
          setAutoSyncData(parsedData);
        }
      } catch (error) {
        console.error("Received non-JSON message:", e);
      }
    };

    emitter.on("autoSync", handleFooEvent);

    return () => {
      emitter.off("autoSync", handleFooEvent);
    };
  }, []);
  const defaultRules = [
    {
      id: 1,
      ruleCondition: "MustExistWith",
      title: "Must exist with",
      rules: [],
    },
    {
      id: 2,
      ruleCondition: "CannotExistWith",
      title: "Cannot exist with",
      rules: [],
    },
    {
      id: 3,
      ruleCondition: "Optional",
      title: "",
      rules: [],
    },
  ];
  const [bsRules, setBsRules] = useState(defaultRules);
  const [isloading, setIsloading] = useState(false);
  const [businessRulesList, setBusinessRulesList] = useState([]);

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("bsrulesPageData"))) {
      let data = JSON.parse(localStorage.getItem("bsrulesPageData"));
      setSourceModuleDetails(data);
    }
  }, [localStorage.getItem("bsrulesPageData")]);
  useEffect(() => {
    getAllBusinessRules();
  }, []);

  const getAllBusinessRules = (defaultRules = bsRules) => {
    setIsloading(true);
    let payload = sourceModule?.id;
    getModuleBusinessRules(payload, (res, err) => {
      setIsloading(false);
      setBrulesSaved(res?.data?.businessRuleSaved || false);
      if (res) {
        setBusinessRulesList(res.data);
        loadAddedBusinessRules(res.data, defaultRules);
      } else {
        showError(err);
      }
    });
  };

  const loadAddedBusinessRules = (data, rules) => {
    let modules = [];
    if (data) {
      rules.forEach((rule) => {
        data.businessRules.forEach((businessRule) => {
          if (rule.ruleCondition === businessRule.ruleCondition) {
            const rulesObj = [];
            businessRule.targetModules.map((item) => {
              const module = {
                id: item.targetModuleId,
                nextUniqueModuleCode: item.targetNextUniqueModuleCode,
                moduleDamId: item.targetModuleDamId,
                moduleDamName: item.targetModuleDamName,
                productName: item.productName,
                moduleName: item.targetModuleName,
                componentThumbnailUrl: item.targetModuleComponentThumbnailUrl,
                mlrStatus: item.targetModuleMlrStatus,
              };
              rulesObj.push(module);
              modules = modules.concat(module);
            });
            rule.rules = [...rulesObj];
          }
        });
      });
      setBsRules([...rules]);
      guidelinesRef.current.value = data.usageGuidelines;
    }
    setAlreadyaddedModules(modules);
  };
  function hasEmptyRules() {
    if (defaultScreen === brulesScreens.applyRules) {
      return false;
    } else if (
      defaultScreen === brulesScreens.modulesSelection &&
      brulesSaved
    ) {
      return false;
    }
    let result = false;
    for (let i = 0; i < bsRules.length; i++) {
      if (bsRules[i].rules.length === 0) {
        result = true;
      } else {
        result = false;
        break;
      }
    }
    return result;
  }

  const showPublishunsuccessful = (errorCode) => {
    // toast.error(errCoponent);
    toast.error(
      <div style={{ color: "#000" }}>
        Business Rules could not be saved. Please try again.
      </div>,
      {
        autoClose: 200,
        position: "top-right",
        closeButton: false,
      }
    );
  };

  const generateBusinessRulesObj = (
    shouldGoNext = true,
    businessRules = bsRules,
    callback
  ) => {
    let payload = {
      moduleId: businessRulesList.moduleId,
      nextUniqueModuleCode: businessRulesList.nextUniqueModuleCode,
      moduleDamId: businessRulesList.moduleDamId,
      moduleDamName: businessRulesList.moduleDamName,
      productName: businessRulesList.productName,
      countryName: businessRulesList.countryName,
      usageGuidelines: guidelinesRef?.current.value,
      // createdBy: "MG",
      businessRules: generateBusinessRulesFromObj(businessRules),
    };
    saveBusinessRules(businessRulesList.moduleId, payload, (res, err) => {
      setIsloading(false);
      if (res) {
        //console.log(res);
        // showPublishSuccess();
        //getModuleDetailsForPreview(businessRulesList.moduleId);
        if (shouldGoNext) {
          goToNext();
        } else {
          getAllBusinessRules(defaultRules);
        }

        //history.push("/");
      } else {
        showPublishunsuccessful();
        //showError(err);
      }
    });
  };

  const generateBusinessRulesFromObj = (bsRules) => {
    const rules = [];
    bsRules.map((rule) => {
      rule.rules.map((module) => {
        rules.push({
          targetModuleId: module.id,
          targetNextUniqueModuleCode: module.nextUniqueModuleCode,
          targetModuleDamId: module.moduleDamId,
          targetModuleDamName: module.moduleDamName,
          ruleCondition: rule.ruleCondition,
          targetModuleName: module.moduleName,
        });
      });
    });
    return rules;
  };

  const backButton = (
    <LinkButton
      title="BACK"
      handleClick={() => {
        goBack();
      }}
      // disabled={!selectedData || selectedData.length === 0}
    />
  );

  const cancelButton = (
    <LinkButton
      title="CANCEL"
      handleClick={() => {
        history.push({
          pathname: routes.dashboard,
          state: {
            selectedTab: 1,
          },
        });
      }}
      // disabled={!selectedData || selectedData.length === 0}
    />
  );
  const nextButton = (
    <PrimaryButton
      title="NEXT"
      handleClick={() => {
        generateBusinessRulesObj();
        //  goBack();
      }}
      minWidth={110}
      marginLeft={20}
      disabled={hasEmptyRules()}
    />
  );

  return (
    <>
      <CircularProgressBar isloading={isloading} />
      <div class="d-flex bd-highlight">
        <div className={`pdlpdr15 pl-0 flex-shrink-1 ${styles.bsLeftColumn}`}>
          <BrowseModules
            bsRules={bsRules}
            modulesList={modulesList}
            sourceModule={
              sourceModuleDetails ? sourceModuleDetails : sourceModule
            }
            handleEditSelection={() => {
              goBack();
            }}
          />
        </div>
        <div class="w-100" style={{ padding: "40px" }}>
          <ApplyBusinessRules
            bsRules={bsRules}
            setBsRules={setBsRules}
            modulesList={modulesList}
            guidelinesRef={guidelinesRef}
            saveBusinessRules={generateBusinessRulesObj}
            businessRulesList={businessRulesList}
          />
        </div>
      </div>

      <div className="row">
        {" "}
        <FooterButtons
          primaryButton={nextButton}
          intermediateButtons={[cancelButton]}
          secondaryButton={
            defaultScreen === brulesScreens.applyRules ? undefined : backButton
          }
        />
      </div>
    </>
  );
};

export default Businessrules;
