import React, { useState, useEffect, useRef } from "react";

import { useHistory, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import UserInfo from "components/userDetails";
import {
  isContentStudioLinkEnabled,
  isTranslateFeatureEnabled,
  removeModuleInfo,
  removeTranslateModuleInfo,
} from "../Uitls/Util";

import dashboardIcon from "../images/dashboard.svg";
import dashboardIconwhite from "../images/dashboard_white.svg";
import moduleManagerIcon from "../images/moduleManager.svg";
import moduleManagerIconWhite from "../images/moduleManager_white.svg";
import editorIcon from "../images/editor.svg";
import editorIconWhite from "../images/editor_white.svg";
import leftArrowIcon from "../images/leftArrowIcon.svg";
import logoutIcon from "../images/logoutIcon.svg";
import logoutIconWhite from "../images/logoutIcon_white.svg";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import translateIconwhite from "../images/translate_icon_white.svg";
import translateIcon from "../images/translate_icon.svg";
import "../styles/pages/Dashboard.scss";
import ListItemText from "@material-ui/core/ListItemText";
import { logoutUser } from "keycloak/keyCloakConfig";

function LogoutCTA() {
  const history = useHistory();
  const onLogout = () => {
    logoutUser();
    history.push("/");
  };
  const [navColor, setNavColor] = useState(false);
  const [navColorMm, setNavColorMm] = useState(false);
  const [navColorDash, setNavColorDash] = useState(false);
  const [navColorCs, setNavColorCs] = useState(false);
  const [navColorTranslate, setNavColortranslate] = useState(false);
  const { t } = useTranslation();
  const ref = useRef();
  const [isMenu, setIsMenu] = useState(false);
  const [userRole, setUserRole] = useState([]);
  const handleRightArrowClick = () => {
    setIsMenu(!isMenu);
  };
  const handleLeftArrowClick = () => {
    //alert("hello");
    setIsMenu(!isMenu);
  };
  const [open, setOpen] = React.useState(false);
  const handleClickSub = () => {
    setOpen(!open);
    setNavColorMm(!navColorMm);
  };
  // function to call on outside click
  useOnClickOutside(ref, () => setIsMenu(false));
  const handleChangeColorDashBoard = () => {
    setNavColorDash(!navColorDash);
  };
  const handleChangeColor = () => {
    setNavColor(!navColor);
  };
  const handleChangeColorMm = () => {
    setNavColorMm(!navColorMm);
  };
  const handleChangeColorCs = () => {
    setNavColorCs(!navColorCs);
  };
  const handleChangeColorTranslate = () => {
    setNavColortranslate(!navColorTranslate);
  };

  const getUserRole = () => {
    const currentUserRole = localStorage.getItem("userRole")?.split(",");
    if (currentUserRole && currentUserRole.length) {
      setUserRole(currentUserRole);
    }
  };

  useEffect(() => {
    getUserRole();
  }, []);

  const removeModuleFromLocal = () => {
    removeModuleInfo();
    removeTranslateModuleInfo();
  };

  return (
    <>
      {isMenu === true ? (
        <div className="dashboardLinks" ref={ref}>
          <ul>
            {userRole.includes("users") ? (
              <>
                <li
                  onMouseOver={handleChangeColorDashBoard}
                  onMouseOut={handleChangeColorDashBoard}
                >
                  <Link to="/dashboard">
                    {navColorDash === false ? (
                      <img
                        className="iconCtaColor"
                        src={dashboardIcon}
                        alt={t("dashBoard.navLinksLabel.dashboard")}
                      />
                    ) : (
                      <img
                        className="iconCtaColor"
                        src={dashboardIconwhite}
                        alt={t("dashBoard.navLinksLabel.dashboard")}
                      />
                    )}
                    {t("dashBoard.navLinksLabel.dashboard")}
                  </Link>
                </li>

                <li
                  onClick={handleClickSub}
                  onMouseOver={handleChangeColorMm}
                  onMouseOut={handleChangeColorMm}
                >
                  <ListItemText />
                  <Link
                    to="/upload-master-asset"
                    onClick={removeModuleFromLocal}
                  >
                    {navColorMm === false ? (
                      <img
                        src={moduleManagerIcon}
                        alt={t("dashBoard.navLinksLabel.moduleManager")}
                      />
                    ) : (
                      <img
                        src={moduleManagerIconWhite}
                        alt={t("dashBoard.navLinksLabel.moduleManager")}
                      />
                    )}

                    <span style={{ color: navColorMm ? "#FFFFFF" : "" }}>
                      {t("dashBoard.navLinksLabel.moduleManager")}
                    </span>
                  </Link>
                </li>

                {isContentStudioLinkEnabled() && (
                  <li
                    onMouseOver={handleChangeColorCs}
                    onMouseOut={handleChangeColorCs}
                  >
                    <Link to="/dashboard" onClick={removeModuleFromLocal}>
                      {navColorCs === false ? (
                        <img
                          className="iconCtaColor"
                          src={editorIcon}
                          alt={t("dashBoard.navLinksLabel.editor")}
                        />
                      ) : (
                        <img
                          src={editorIconWhite}
                          alt={t("dashBoard.navLinksLabel.moduleManager")}
                        />
                      )}
                      {i18next.t("dashBoard.navLinksLabel.contentStudio")}
                    </Link>
                  </li>
                )}
                {isTranslateFeatureEnabled() && (
                  <li
                    onMouseOver={handleChangeColorTranslate}
                    onMouseOut={handleChangeColorTranslate}
                  >
                    <Link
                      to="/dashboard-reviewer"
                      onClick={removeModuleFromLocal}
                    >
                      {navColorTranslate === false ? (
                        <img
                          className="iconCtaColor"
                          src={translateIcon}
                          alt={t("dashBoard.navLinksLabel.translate")}
                        />
                      ) : (
                        <img
                          src={translateIconwhite}
                          alt={t("dashBoard.navLinksLabel.translate")}
                        />
                      )}
                      {i18next.t("dashBoard.navLinksLabel.reviewer")}
                    </Link>
                  </li>
                )}
              </>
            ) : (
              ""
            )}

            {userRole.includes("translator") || userRole.includes("agency") ? (
              <li
                onMouseOver={handleChangeColorTranslate}
                onMouseOut={handleChangeColorTranslate}
              >
                <Link
                  to="/dashboard-translate"
                  onClick={removeTranslateModuleInfo}
                >
                  {navColorTranslate === false ? (
                    <img
                      className="iconCtaColor"
                      src={translateIcon}
                      alt={t("dashBoard.navLinksLabel.translate")}
                    />
                  ) : (
                    <img
                      src={translateIconwhite}
                      alt={t("dashBoard.navLinksLabel.translate")}
                    />
                  )}
                  {i18next.t("dashBoard.navLinksLabel.translate")}
                </Link>
              </li>
            ) : (
              ""
            )}

            <li>
              <div className="d-flex align-items-center">
                <div
                  className="d-inline"
                  onMouseOver={handleChangeColor}
                  onMouseOut={handleChangeColor}
                >
                  {navColor === false ? (
                    <img src={logoutIcon} alt="Log Out" />
                  ) : (
                    <img src={logoutIconWhite} alt="Log Out" />
                  )}
                </div>
                <div className="d-inline">
                  <button onClick={onLogout} className="btn btn-link">
                    <span className="userNameInfo">
                      <UserInfo />
                    </span>{" "}
                    <span
                      className="userDesignation"
                      onMouseOver={handleChangeColor}
                      onMouseOut={handleChangeColor}
                    >
                      {i18next.t("dashBoard.navLinksLabel.logout")}
                    </span>
                  </button>
                </div>
              </div>
            </li>
          </ul>
          <div className="left-arrow">
            <img
              src={leftArrowIcon}
              className="leftArrowIcon"
              onClick={handleLeftArrowClick}
              alt="Hide Navigation Menu"
            />
          </div>
        </div>
      ) : (
        <div className="floatingMenu">
          <ArrowForwardIosIcon
            className="right-arrow"
            onClick={handleRightArrowClick}
          ></ArrowForwardIosIcon>
        </div>
      )}
    </>
  );
}

function useOnClickOutside(ref, handler) {
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
}
export default LogoutCTA;
