import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import RectangularSearchTextField from "components/RectangularSearchTextField";
import DropDownWithLabel from "components/DropDownWithLabel";
import { getTopicsFilterData } from "dashboard/filters/TopicsFilter";
import BorderedOutLineButton from "components/BorderOutLinedButton";
import DropDownWithSearch from "components/DropDownWithSearch";
import MultiSelectDropDownWithInlineSearch from "components/MultiSelectDropDownWithInlineSearch";
import { fecthSubTopicsFilter } from "dashboard/filters/SubTopicsFilter";
const FilterComponent = ({
  dropDownData,
  resUsableTextTypes,
  handleDropdownSelection,
  topicsSubtopics,
  messageTypes,
  setFilteredReusableTexts,
  resusableTexts,
  setSearchTrgiggered,
  searchTriggered,
  setDropDownData,
  toggle,
  subTopicsData,
  setCurrentPage,
  setHasMore,
}) => {
  const { t } = useTranslation();
  const [searchInput, setSearchInput] = useState("");
  const [isResetClicked, setIsResetClicked] = useState(false);
  const charLimit = 250;
  // useEffect(() => {
  //   if (dropDownData && Object.keys(dropDownData).length > 0) {
  //     setSearchTrgiggered(true);
  //   }
  // }, [dropDownData]);

  useEffect(() => {
    if (toggle) {
      setIsResetClicked(true);
      setDropDownData({});
      setSearchInput("");
      setSearchTrgiggered(false);
      setFilteredReusableTexts([]);
      setCurrentPage(1);
      setTimeout(() => {
        setIsResetClicked(false);
      }, [0]);
    } else {
      // setCurrentPage(1);
      setHasMore(true);
      // setFilteredReusableTexts([]);
      //resetAll();
    }
  }, [toggle]);

  const handleSearch = (type) => {
    if (type === "search") {
      setDropDownData((prevState) => {
        return { ...prevState, searchText: searchInput };
      });
      setSearchTrgiggered(true);
    } else {
      setDropDownData((prevState) => {
        return { ...prevState, searchText: undefined };
      });
    }
    setCurrentPage(1);
  };
  const resetAll = () => {
    setIsResetClicked(true);
    setDropDownData({});
    setHasMore(true);
    setSearchInput("");
    setSearchTrgiggered(false);
    setFilteredReusableTexts([]);
    setCurrentPage(1);
    setTimeout(() => {
      setIsResetClicked(false);
    }, [0]);
  };
  return (
    <div className="d-flex">
      <div style={{ flex: "0 0 38%", paddingRight: "16px" }}>
        <RectangularSearchTextField
          placeholder="Search by ‘ID / Match Text’"
          onSearchInputChange={(value) => {
            if (value.trim() === 0 || value.trim() === "") {
              setSearchInput(undefined);
              handleSearch("searchBarEmpty");
            } else {
              setSearchInput(value);
            }
          }}
          handleSearch={handleSearch}
          isResetClicked={isResetClicked}
          charLimit={charLimit}
        />
      </div>
      <div style={{ flex: "0 0 19%", paddingRight: "16px" }}>
        <DropDownWithSearch
          options={{
            value: dropDownData?.reusableTexts, //metaData.brand, //t("uploadAssetLabel.campaignItWasUsed"),
            name: t("importReusableTexts.reusable"),
            label: t("importReusableTexts.reusable"),
            items: resUsableTextTypes,
            isDataTypeObject: true,
            placeholder: "Search and Select",
            handleChange: (selectedValue) => {
              handleDropdownSelection(selectedValue, "reusableTexts");
            },
          }}
        />
      </div>
      <div style={{ flex: "0 0 18%", paddingRight: "16px" }}>
        <DropDownWithSearch
          options={{
            value: dropDownData?.topic, //metaData.brand, //t("uploadAssetLabel.campaignItWasUsed"),
            name: t("importClaims.topic"),
            label: t("importClaims.topic"),
            isDataTypeObject: true,
            placeholder: "Search and Select",
            items: getTopicsFilterData(topicsSubtopics),
            handleChange: (selectedValue) => {
              handleDropdownSelection(selectedValue, "topic");
            },
          }}
        />
      </div>
      <div style={{ flex: "0 0 16%", paddingRight: "16px" }}>
        <MultiSelectDropDownWithInlineSearch
          options={{
            value: dropDownData?.subtopics,
            items: fecthSubTopicsFilter(subTopicsData),
            name: t("metadata.subtopic"),
            label: t("metadata.subtopic"),
            isDataTypeObject: true,
            placeholder: "Search and Select",
            handleChange: (selectedValue) => {
              handleDropdownSelection(selectedValue, "subtopics");
            },
            disabled: !dropDownData?.topic,
          }}
        />
      </div>
      {/* <div style={{ flex: "0 0 16%", paddingRight: "16px" }}>
        <DropDownWithSearch
          options={{
            value: dropDownData?.messageType, //metaData.brand, //t("uploadAssetLabel.campaignItWasUsed"),
            name: t("importClaims.messageType"),
            label: t("importClaims.messageType"),
            isDataTypeObject: true,
            handleChange: (selectedValue) => {
              handleDropdownSelection(selectedValue, "messageType");
            },
            items: messageTypes,
          }}
        />
      </div> */}
      <div style={{ flex: "0 0 8%" }}>
        <BorderedOutLineButton
          btnLable="Reset All"
          disabled={!searchTriggered}
          handleClick={resetAll}
        />
      </div>
    </div>
  );
};
export default FilterComponent;
