import React, { useState, useEffect, createRef } from "react";
import styled from "styled-components";
import FooterButtons from "components/FooterButtons";
import PrimaryButton from "components/PrimaryButton";
import LeftRightScrollButton from "components/LeftRightScrollButton";
import { getMlrStatusIcon } from "Uitls/Util";
import OverlayPopOver from "Uitls/OverlayPopOver";
import { ToolTipText } from "components/TooltipText";
const popoverRef = createRef();
const overLayRef = createRef();

const PopupContainer = styled.div`
  margin: 0 auto;
  background: #fff;
  border-radius: 8px;
  position: relative;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
  padding-right: 20px;
  border-bottom: 1px solid #ddd;
`;

const HeaderTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: #231f20;
  margin: 0;
  padding-left: 20px;

  & > span:first-child {
    margin-right: 12px;
    font-weight: bold;
  }

  & > div {
    border-left: 1px solid #dddfdd;
    padding-left: 12px;
    font-size: 12px;
    color: #1e1e1e;

    & > span {
      font-weight: bold;
    }
  }
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 14px 0px;
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px;
`;

const ColumnLabel = styled.p`
  font-size: 12px;
  font-weight: bold;
  margin: 0 0 8px;
  align-items: left;
  color: #252529;
`;

const Column = styled.div`
  width: 267px;
  height: 260px;
  padding: 12px;
  border-radius: 12px;
  background-color: #f8f9f9;
  overflow-y: auto;
`;

const Column2 = styled.div`
  width: 660px;
  height: 260px;
  padding: 12px;
  border-radius: 12px;
  background-color: #f8f9f9;
  overflow-y: auto;
`;

const MatchText = styled.div`
  font-size: 12px;
  color: #1e1e1e;
`;

const Footnotes = styled.div`
  font-size: 12px;
  color: #1e1e1e;
`;

const References = styled.div`
  font-size: 12px;
  color: #1e1e1e;
`;

const ViewTextElement = ({
  toggleComponentView,
  viewTextualElementType,
  data,
  selectectedtextElement,
}) => {
  const [currentComponentIndex, setCurrentComponentIndex] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  useEffect(() => {
    const initialPage = data.findIndex(
      (item) =>
        (viewTextualElementType === 1
          ? item.claimName
          : item.reusableTextName) === selectectedtextElement
    );
    setCurrentPage(initialPage !== -1 ? initialPage : 0);
  }, [selectectedtextElement, data, viewTextualElementType]);

  if (!data || data.length === 0) {
    return null;
  }

  const totalPages = data.length;

  const handleNext = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevious = () => {
    if (currentPage > 0) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const CancelButton = (
    <PrimaryButton
      title={"Close".toUpperCase()}
      handleClick={() => toggleComponentView(false)}
    />
  );

  const currentItem = data[currentPage] || {};

  return (
    <PopupContainer ref={popoverRef}>
      <Header>
        <HeaderTitle>
          <span>
            {viewTextualElementType === 1
              ? "RELATED CLAIM"
              : "RELATED REUSABLE TEXT"}
          </span>
          <div>
            ID:{" "}
            <span className="mr-1">
              <OverlayPopOver
                element={
                  <img
                    src={getMlrStatusIcon(currentItem.mlrStatus)}
                    alt={currentItem.mlrStatus}
                    style={{ marginTop: "-2px" }}
                  />
                }
                overlayData={
                  <ToolTipText>
                    {currentItem.mlrStatus
                      ? currentItem.mlrStatus
                      : "Module not Published"}
                  </ToolTipText>
                }
                trigger={["hover", "focus"]}
                placement="bottom"
                classId="tooltip"
                containerRef={popoverRef}
              />
            </span>
            {viewTextualElementType === 1
              ? currentItem.claimName
              : currentItem.reusableTextName}
          </div>
          {currentItem?.mlrStatus?.trim().toLowerCase().includes("expired") && (
            <span
              className="Expired"
              style={{
                verticalAlign: "middle",
                marginBottom: "-2px",
              }}
            >
              Expired
            </span>
          )}
        </HeaderTitle>

        <LeftRightScrollButton
          handleClick={(direction) => {
            if (direction === "forward") {
              handleNext();
            } else {
              handlePrevious();
            }
          }}
          shouldDisableForward={currentPage >= totalPages - 1}
          shouldDisableBackward={currentPage <= 0}
          count={totalPages}
          currentIndex={currentPage + 1}
          goToSlide={(value) => setCurrentPage(value - 1)}
          customMargin={true}
          leadingZeros={true}
        />
      </Header>
      {viewTextualElementType === 1 && (
        <ContentContainer>
          <ColumnWrapper>
            <ColumnLabel>Match Text</ColumnLabel>
            <Column>
              <MatchText>
                <p>{currentItem.matchText ?? ""}</p>
              </MatchText>
            </Column>
          </ColumnWrapper>

          <ColumnWrapper>
            <ColumnLabel>Footnotes</ColumnLabel>
            <Column>
              <Footnotes>
                {currentItem.footnotes ? <p>{currentItem.footnotes}</p> : ""}
              </Footnotes>
            </Column>
          </ColumnWrapper>

          <ColumnWrapper>
            <ColumnLabel>References</ColumnLabel>
            <Column>
              <References>
                {Array.isArray(currentItem.references) &&
                currentItem.references.length > 0
                  ? currentItem.references.map((reference, index) => (
                      <p key={reference.id}>
                        {index + 1}. {reference.documentName ?? ""}
                      </p>
                    ))
                  : ""}
              </References>
            </Column>
          </ColumnWrapper>
        </ContentContainer>
      )}

      {viewTextualElementType === 2 && (
        <ContentContainer>
          <ColumnWrapper>
            <ColumnLabel>Match Text</ColumnLabel>
            <Column2>
              <MatchText>
                <p>{currentItem.matchText ?? ""}</p>
              </MatchText>
            </Column2>
          </ColumnWrapper>
        </ContentContainer>
      )}
      <FooterButtons primaryButton={CancelButton} />
    </PopupContainer>
  );
};

export default ViewTextElement;
