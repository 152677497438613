import React, { useState, useEffect } from "react";
import variables from "styles/base/_universal.scss";
import styled from "styled-components";
import "styles/components/NotificationsSidebar.scss";
import expiredIcon from "img/final-status.svg";
import BellIcon from "img/bell-icon.svg";
import CrosIcon from "img/path-29792.svg";
import SwicthButton from "components/SwitchButton";
import { Dropdown } from "react-bootstrap";
import IconAdvanceFilter from "img/advance-filter.svg";
import { markAsReadNotification } from "services/apis";
import { showError } from "Uitls/Util";
import NoDataFound from "components/NoDataFound";

const FilterButtonDiv = styled.div`
  position: relative;
  padding: 8px 15px 8px;
  font-size: ${variables.fontSmall};
  border-radius: 16px;
  font-weight: bold;
  text-wrap: nowrap;
  cursor: pointer;
  height: 32px;
  border: solid 1px ${variables.borderColor};
  margin: 0px 0px 0px 20px;
  &::before,
  &::after {
    content: none !important;
  }
`;

const NotificationCount = styled.span`
  content: "";
  position: absolute;
  top: -5px;
  right: -1px;
  width: 0.9rem;
  height: 0.9rem;
  background-color: #203662;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 10px;
`;

const BlurrOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(5.7px);
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
`;
const NotificationTitle = styled.span`
  font-size: 14px;
  font-weight: bold;
  color: #222;
`;
const FilterTypesLable = styled.div`
  font-size: 12px;
  font-weight: bold;
  color: #1e1e1e;
`;
const UnreadNotificationLable = styled.span`
  font-size: 12px;
  font-weight: bold;
  color: #1e1e1e;
`;
const NotificationsSidebar = ({
  isOpen,
  toggleSidebar,
  expiryNotificationData,
  getExpiryDataOfMCRT,
}) => {
  const [notificationCount, setNotificationCount] = useState(undefined);
  const [checked, setChecked] = useState(false);
  const [filterType, setFilterType] = useState("All");
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    if (expiryNotificationData && expiryNotificationData) {
      setNotifications(expiryNotificationData.mlrStatusNotificationDtoList);
    }
  }, [expiryNotificationData]);

  useEffect(() => {
    if (
      checked &&
      expiryNotificationData &&
      expiryNotificationData?.mlrStatusNotificationDtoList?.length > 0
    ) {
      let data = [...expiryNotificationData.mlrStatusNotificationDtoList];
      data = data.filter((notification) => notification.readStatus === false);
      setNotifications(data);
    } else {
      setNotifications(
        expiryNotificationData?.mlrStatusNotificationDtoList || []
      );
    }
  }, [expiryNotificationData, checked]);

  const handleToggle = (toggleValue) => {
    setChecked(toggleValue);
  };

  const handleFilterSelect = (eventKey) => {
    setFilterType(eventKey);
    let type = eventKey === "All" ? "" : eventKey;
    let count = eventKey === "All" ? 0 : 1;
    setNotificationCount(count);
    getExpiryDataOfMCRT(type);
  };

  const isSelected = (eventKey) => filterType === eventKey;

  const unreadCount = expiryNotificationData?.unReadCount || 0;

  const formatUTCDate = (utcDateString) => {
    const date = new Date(utcDateString);

    // Extract day, month, and year
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const year = date.getUTCFullYear();

    // Extract hours and minutes
    const hours = String(date.getUTCHours()).padStart(2, "0");
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");

    return `${day}/${month}/${year}, ${hours}:${minutes}`;
  };

  const markAllAsRead = (ids) => {
    let payLoad = {
      mlrNotificationIds: ids,
    };
    markAsReadNotification(payLoad, (res, err) => {
      if (res) {
        let type = filterType === "All" ? "" : filterType;
        getExpiryDataOfMCRT(type);
        return;
      }
      showError(err);
    });
  };
  const handleReset = () => {
    setFilterType("All");
    getExpiryDataOfMCRT("");
    setChecked(false);
    setNotificationCount(0);
  };

  const markAllReadBtnEnable = () => {
    return notifications?.length === 0 ||
      notifications?.every((notification) => notification.readStatus === true)
      ? true
      : false;
  };

  return (
    <div>
      {isOpen && <BlurrOverlay></BlurrOverlay>}
      {isOpen && (
        <div className="notifications-container">
          <div className="sidebar">
            <div className="sidebar-header">
              <span className="flex">
                <img src={BellIcon} alt="" />
                <NotificationTitle>NOTIFICATIONS</NotificationTitle>
              </span>
              <button
                className="close-btn"
                onClick={() => {
                  handleReset();
                  toggleSidebar();
                }}
              >
                <img src={CrosIcon} alt="" />
              </button>
            </div>
            <div className="sidebar-content">
              <div className="sidebar-filters">
                <span className="flex">
                  <UnreadNotificationLable style={{ marginTop: "5px" }}>
                    Unread Notifications ({unreadCount})
                  </UnreadNotificationLable>
                  <SwicthButton handleToggle={handleToggle} checked={checked} />
                </span>
                <div className="flex">
                  <Dropdown onSelect={handleFilterSelect}>
                    <Dropdown.Toggle as={FilterButtonDiv}>
                      {"Filters"}
                      <img
                        src={IconAdvanceFilter}
                        alt=""
                        style={{ marginLeft: "6px" }}
                      />
                      {notificationCount > 0 && (
                        <NotificationCount>
                          {notificationCount}
                        </NotificationCount>
                      )}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        className={`custom-dropdown-item ${
                          isSelected("All") ? "selected" : ""
                        }`}
                        eventKey="All"
                      >
                        All
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={`custom-dropdown-item ${
                          isSelected("Claim") ? "selected" : ""
                        }`}
                        eventKey="Claim"
                      >
                        Claims
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={`custom-dropdown-item ${
                          isSelected("Reusable Text") ? "selected" : ""
                        }`}
                        eventKey="Reusable Text"
                      >
                        Reusable Texts
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={`custom-dropdown-item ${
                          isSelected("Component") ? "selected" : ""
                        }`}
                        eventKey="Component"
                      >
                        Components
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={`custom-dropdown-item ${
                          isSelected("Module") ? "selected" : ""
                        }`}
                        eventKey="Module"
                      >
                        Modules
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <button
                    className="reset-btn"
                    onClick={handleReset}
                    disabled={filterType === "All"}
                  >
                    Reset
                  </button>
                </div>
              </div>
              <div className="d-flex justify-content-between markas">
                <FilterTypesLable>
                  {filterType !== "All" ? filterType + "s" : filterType} (
                  {notifications?.length || 0})
                </FilterTypesLable>
                <button
                  onClick={() => {
                    if (notifications?.length > 0 && unreadCount > 0) {
                      let data = notifications
                        ?.filter(
                          (notification) => notification.readStatus === false
                        ) // Filter only unread notifications
                        .map((notification) => notification.mlrNotificationId);
                      markAllAsRead(data);
                    }
                  }}
                  disabled={markAllReadBtnEnable()}
                >
                  Mark all as Read
                </button>
              </div>
              <div className="notifications-list customScrollBar">
                {notifications && notifications.length > 0 ? (
                  notifications.map((notification, index) => (
                    <div
                      key={index}
                      className="notification-item"
                      style={{
                        backgroundColor: notification.readStatus
                          ? "#ffff"
                          : "#f2f6f9",
                      }}
                      onClick={() => {
                        window.open(notification?.objectDamUrl, "_blank");
                        if (!notification.readStatus) {
                          markAllAsRead([notification.mlrNotificationId]);
                        }
                      }}
                    >
                      <div className="d-flex justify-content-between">
                        <div className="notification-type">
                          {notification.message}
                        </div>
                        <div className="notification-date">
                          {notification?.objDamExpiredTime}
                          {/* {formatUTCDate(notification.expiredOn)} */}
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="notification-id d-flex">
                          <div>
                            ID:&nbsp;
                            <img src={expiredIcon} alt="" />{" "}
                          </div>
                          <div
                            style={{ cursor: "pointer" }}
                            // onClick={() => {
                            //   window.open(notification?.objectDamUrl, "_blank");
                            //   if (!notification.readStatus) {
                            //     markAllAsRead([notification.mlrNotificationId]);
                            //   }
                            // }}
                          >
                            {notification.objectDamName}
                          </div>
                        </div>
                        <div className="Expired">{notification.mlrStatus}</div>
                      </div>
                    </div>
                  ))
                ) : (
                  <NoDataFound
                    customClassforNotifications={true}
                    customClassNoResult={true}
                    Description={
                      <>
                        {"You’ve reviewed all your notifications from "}
                        <br />
                        {"the past 30 days. Try searching "}
                        <br />
                        {"with different filter settings."}
                      </>
                    }
                  />
                )}
              </div>
            </div >
          </div >
        </div >
      )}
    </div >
  );
};

export default NotificationsSidebar;
