import React, { useState } from "react";
import styled from "styled-components";
import { Dropdown } from "react-bootstrap";
import variables from "styles/base/_universal.scss";
import reload from "img/reload.svg";
import timeIcon from "img/time.svg";

import { useHistory } from "react-router-dom";
// import stylized from "img/stylized.svg";
import OverlayPopOver from "Uitls/OverlayPopOver";
import OverlayPopOverToolTip from "Uitls/OverlayPopOverToolTip";
import placeholder from "img/Placeholder.svg";
import { useTranslation } from "react-i18next";
import { ToolTipText } from "components/TooltipText";
import { getDaysAgo, routes, showError } from "Uitls/Util";
import { fetchModuleDetails, deleteModule } from "services/apis";
import CircularProgressBar from "Uitls/CircularProgressBar";
import LinkButton from "components/LinkButton";
import ImageSelectTick from "img/ImageSelectTickIcon.svg";
import Preview from "components/PreviewButton";
import CustomModal from "components/customModal";
import ModuleView from "components/businessrules/ModuleView";
import { getMlrStatusIcon } from "Uitls/Util";
import ConfirmationPopup from "components/ConfirmationPopup";
import { ThreeDotMenu } from "./ThreeDotMenu";

const TruncatedText = styled.span`
  font-size: 12px !important;
  color: ${(props) =>
    props.disabled ? variables.subtitleColor : variables.blackColor} !important;
  font-weight: ${(props) =>
    props.disabled ? "normal" : variables.fontWeight600}!important;
`;

const ModuleDamName = styled.span`
  color: ${variables.blackColor} !important;
  font-weight: ${variables.fontWeight700} !important;
  cursor: ${(props) =>
    props.isCursorPointer ? "pointer" : "default"} !important;
`;

const ModulePublish = styled.span`
  color: ${variables.themeColor} !important;
  font-weight: ${variables.fontWeight700} !important;
  cursor: pointer !important;
`;

const ApplyBusinessRules = styled.div`
  color: ${({ isEnabled }) => (isEnabled ? "#203662" : "#b2b2b2")};
  font-size: 12px;
  font-weight: 600;
  cursor: ${({ isEnabled }) => (isEnabled ? "" : "not-allowed")};
`;

const ModuleCard = ({
  module,
  selectedTab,
  isSelectable,
  cardActions,
  executeModuleAction,
  isSelected = false,
  selectedTickIcon = ImageSelectTick,
}) => {
  const [value, setValue] = useState("");
  const { t } = useTranslation();
  const history = useHistory();
  const [isHovered, setIsHovered] = useState(false);
  const [modulePreviewOpen, setModulePreviewOpen] = useState(false);
  const [modulePreviewData, setModulePreviewData] = useState([]);
  const [isloading, setIsloading] = useState(false);

  const truncateText = (text, maxLength) => {
    if (text?.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
    return text;
  };

  const getModuleDetailsForPreview = (moduleId) => {
    setIsloading(true);
    fetchModuleDetails(moduleId, (res, err) => {
      if (res) {
        setIsloading(false);
        setModulePreviewData(res.data);
        setModulePreviewOpen(true);
      } else {
        setIsloading(false);
        showError(err);
      }
    });
  };
  const getOverlayStyle = () => {
    const baseStyle = {
      position: "absolute",
      width: "100%",
      height: "100%",
      zIndex: 1,
      background: "rgba(0, 0, 0, 0.5)",
      // display: isHovered ? "flex" : "none",
      alignItems: "center",
      justifyContent: "center",
      backdropFilter: "blur(2px)",
    };

    if (selectedTab === 1 && !module?.mlrStatus) {
      return {
        background: "rgba(0, 0, 0, 0.5)",
        backdropFilter: "blur(2px)",
        position: "absolute",
        width: "100%",
        height: "100%",
      };
    }

    if (!cardActions.select) {
      return {
        ...baseStyle,
        // top: "50%",
        // left: "50%",
        // transform: "translate(-50%, -50%)",
      };
    }
    if (cardActions.select && isSelected && !isSelectable) {
      return {
        ...baseStyle,
        // top: "50%",
        // left: "50%",
        // transform: "translate(-50%, -50%)",
      };
    }

    return null;
  };

  const getBackgroundStyle = (module) => ({
    position: "relative",
    backgroundColor: module.componentThumbnailUrl ? "transparent" : "#fff7ed",
    backgroundImage: module.componentThumbnailUrl
      ? `url(${module.componentThumbnailUrl})`
      : "none",
    cursor: "pointer",
  });

  const moduleCardMenuOptions = [
    module?.mlrStatus?.toLowerCase() === "expired" ? null : (
      <li className="pl-0 pr-0">
        <button
          className="btn btn-link p-0 m-0 w-100 text-left pr12 pl12 lh32"
          onClick={() => {
            executeModuleAction(cardActions.edit, module);
          }}
          disabled={
            module?.mlrStatus !== null &&
            !["draft", "revise and resubmit"].includes(
              module?.mlrStatus?.toLowerCase()
            )
          }
        >
          Edit Module
        </button>
      </li>
    ),
    <li className="pl-0 pr-0">
      <button
        className="btn btn-link p-0 m-0 w-100 text-left pr12 pl12 lh32"
        onClick={() => {
          executeModuleAction(cardActions.clone, module);
        }}
      >
        {t("module.cloneModule")}
      </button>
    </li>,
    module?.mlrStatus ? null : (
      <li className="pl-0 pr-0">
        <button
          className="btn btn-link p-0 m-0 w-100 text-left pr12 pl12 lh32"
          onClick={() => {
            executeModuleAction(cardActions.delete, module);
          }}
        >
          Delete Module
        </button>
      </li>
    ),
  ];

  const renderModuleCard = () => {
    return (
      <div
        className="dasboardSearchCard"
        style={{
          boxShadow: isSelected ? " 0 0 8px 0 rgba(0, 0, 0, 0.16" : "",
        }}
      >
        <div
          className="cardHover"
          onClick={() => {
            if (cardActions.select && module.mlrStatus) {
              executeModuleAction(cardActions.select, module);
            }
          }}
          selected={isSelected}
          style={{
            cursor:
              isSelectable && cardActions.select && module.mlrStatus
                ? "pointer"
                : "",
          }}
        >
          {isSelected ? (
            <TickIcon
              src={selectedTickIcon}
              alt=""
              //className={`${styles.imageSelectionTick}`}
            />
          ) : null}
          <div
            className={`imgBlock ${
              cardActions.select
                ? isSelected && !isSelectable
                  ? "imgBlockWithHover"
                  : ""
                : "imgBlockWithHover"
            }`}
            style={getBackgroundStyle(module)}
          >
            {module?.componentThumbnailUrl ? null : (
              <img
                src={placeholder}
                alt="Placeholder"
                className="btnVhCenter"
              />
            )}

            <div className="cardOverLay" style={getOverlayStyle()}>
              <Preview
                message={
                  selectedTab === 1 && !module.mlrStatus
                    ? t("businessRules.publishThenApply")
                    : isSelected && !isSelectable
                    ? t("businessRules.alreadyAddedAsBusinessRule")
                    : undefined
                }
                onPreviewClick={() => {
                  getModuleDetailsForPreview(module?.id);
                }}
                shouldHavePreviewCTA={!cardActions.select}
              />
            </div>
          </div>

          <div
            style={{
              backgroundColor: isSelected
                ? variables.paleBlueColor
                : cardActions.select && !module?.mlrStatus
                ? "rgba(230, 230, 235, 0.3)"
                : "",
              borderBottomLeftRadius: "5px",
              borderBottomRightRadius: "5px",
            }}
          >
            <div className="contentBlock">
              <p className="mtitle">
                <>
                  {!module.mlrStatus ? (
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <img
                          src={getMlrStatusIcon(null)}
                          alt={"Module not Published"}
                        />
                        Module not Published
                      </div>
                      {selectedTab === 1 && cardActions.publish && (
                        <>
                          <ModulePublish
                            onClick={() => {
                              history.push({
                                pathname: "/preview",
                                state: { moduleId: module.id },
                              });
                              // executeModuleAction(cardActions.publish, module);
                            }}
                          >
                            Publish
                          </ModulePublish>
                          {/* <CircularProgressBar isloading={isLoading} /> */}
                        </>
                      )}
                    </div>
                  ) : (
                    <>
                      <OverlayPopOverToolTip
                        element={
                          <img
                            src={getMlrStatusIcon(module.mlrStatus)}
                            alt={module.mlrStatus}
                          />
                        }
                        overlayData={
                          <ToolTipText>{module.mlrStatus}</ToolTipText>
                        }
                        trigger={["hover", "focus"]}
                        placement="bottom"
                        classId="tooltip"
                      />
                      <ModuleDamName
                        onClick={() => {
                          executeModuleAction(cardActions.open, module);
                        }}
                        isCursorPointer={module?.cmsdDocUrl ? true : false}
                      >
                        {module.moduleDamName}
                      </ModuleDamName>
                    </>
                  )}
                </>

                {!module.mlrStatus ? null : cardActions.syncMlr ? (
                  <button
                    className="btn btn-link ml-1 p-0 "
                    onClick={() => {
                      executeModuleAction(cardActions.syncMlr, module);
                    }}
                  >
                    <img
                      style={{ verticalAlign: "unset" }}
                      src={reload}
                      alt="Reload"
                    />
                  </button>
                ) : null}
              </p>
              <p>
                <span>Primary Product:</span>{" "}
                {cardActions.select && !module?.mlrStatus ? (
                  <span>{module.productName}</span>
                ) : (
                  module.productName
                )}
              </p>
              <p>
                <span>Name: </span>
                <OverlayPopOverToolTip
                  element={
                    <TruncatedText
                      disabled={
                        cardActions.select && !module?.mlrStatus ? true : false
                      }
                    >
                      {truncateText(module.moduleName, 20)}
                    </TruncatedText>
                  }
                  overlayData={
                    <ToolTipText className="scollable-tooltip customScrollBar">
                      {module.moduleName}
                    </ToolTipText>
                  }
                  trigger={["hover", "focus"]}
                  placement="auto"
                  classId="tooltip"
                />
              </p>
            </div>
            <div className="cardFooter">
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <img src={timeIcon} alt="Created/update Date" />{" "}
                  <span>{getDaysAgo(module.createdDateTime)}</span>
                </div>
                <div>
                  {selectedTab === 0 ? (
                    <>
                      <div>
                        <ThreeDotMenu menuOptions={moduleCardMenuOptions} />
                      </div>
                    </>
                  ) : selectedTab === 1 ? (
                    module.moduleDamId === null ? (
                      <OverlayPopOverToolTip
                        element={
                          <ApplyBusinessRules isEnabled={true}>
                            <button disabled className="btn btn-link p-0 m-0">
                              Apply Business Rules
                            </button>
                          </ApplyBusinessRules>
                        }
                        overlayData={
                          <ToolTipText>
                            {t(
                              "generalTexts.publishBeforeApplyingBusinessRules"
                            )}
                          </ToolTipText>
                        }
                        trigger={["hover", "focus"]}
                        placement="auto"
                        classId="tooltip"
                      />
                    ) : (
                      <>
                        {module?.moduleBusinessRules?.length > 0 ? (
                          <button
                            onClick={() => {
                              let cardAction =
                                module?.moduleBusinessRules?.length > 0
                                  ? cardActions.view
                                  : cardActions.applyBRule;
                              executeModuleAction(cardAction, module);
                            }}
                            className="btn btn-link p-0 m-0 mun"
                          >
                            View Business Rules
                          </button>
                        ) : (
                          <button
                            onClick={() => {
                              let cardAction =
                                module?.moduleBusinessRules?.length > 0
                                  ? cardActions.view
                                  : cardActions.applyBRule;
                              executeModuleAction(cardAction, module);
                            }}
                            className="btn btn-link p-0 m-0"
                            disabled={
                              !(
                                !module.mlrStatus ||
                                ["draft", "revise and resubmit"].includes(
                                  module.mlrStatus?.toLowerCase()
                                )
                              )
                            }
                          >
                            Apply Business Rules
                          </button>
                        )}
                      </>
                    )
                  ) : (
                    <LinkButton
                      handleClick={(e) => {
                        executeModuleAction(cardActions.view, module);
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                      title={t("generalTexts.view")}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <CircularProgressBar isloading={isloading} />
      <div className="moduleCardBlock" key={module.id}>
        {renderModuleCard()}
      </div>
      <CustomModal
        displayModal={modulePreviewOpen}
        hideModal={() => {
          setModulePreviewOpen(false);
        }}
        size="xl"
        dilogClassName="alertModalSmall"
      >
        <ModuleView
          modulePreviewData={modulePreviewData}
          setModulePreviewOpen={setModulePreviewOpen}
          cloneModule={() => {
            executeModuleAction(cardActions.clone, module);
            setModulePreviewOpen(false);
          }}
        />
      </CustomModal>
    </>
  );
};

const TickIcon = styled.img`
  position: absolute;
  z-index: 999;
  right: 0;
  right: -2px;
  top: -1px;
`;

export default ModuleCard;
