import React from "react";
import styled from "styled-components";
import variables from "styles/base/_universal.scss";
import FooterButtons from "components/FooterButtons";
import PrimaryButton from "components/PrimaryButton";

const ImagePreview = ({ title, imageUrl, closePreview }) => {
  const primaryButton = (
    <PrimaryButton
      title={"Close".toUpperCase()}
      handleClick={() => {
        closePreview();
      }}
      minWidth={110}
    />
  );

  return (
    <div>
      <Header>
        <TitleText>{title}</TitleText>
      </Header>
      <div style={{ backgroundColor: variables.lightBlueColor }}>
        <Body className="customScrollBar">
          <img src={imageUrl} alt="preview" className="img-fluid" />
        </Body>
      </div>

      <FooterButtons primaryButton={primaryButton} size="md" />
    </div>
  );
};

const Header = styled.div`
  padding: 0px 15px 15px 15px;
  border-bottom: solid 1px #e6e6ec;
  text-transform: uppercase;
`;
const Body = styled.div`
  height: 490px;
  padding: 12px 20px 0px 20px;
  overflow-y: scroll;
  background-color: ${variables.lightBlueColor};
  //   width: 70%;
  //   max-width: 70%;
  margin-right: 2px;
  text-align: center;
`;
const TitleText = styled.span`
  font-size: ${variables.fontSmallV2};
  font-weight: bold;
  color: ${variables.blackColor};
`;

export default ImagePreview;
