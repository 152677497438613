import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styles from "styles/modules/importElements.module.scss";
import infIcon from "img/infoIcon.svg";
import pluseIconWhite from "img/pluseIconWhite.svg";
import PrimaryButton from "components/PrimaryButton";
import LinkButton from "components/LinkButton";
import CustomModal from "components/customModal";
import ImportReUsableText from "components/modules/importReusableTexts/ImportResusableText";
import { moduleScreens } from "pages/module/ModuleUtil";
import { ModuleContext, moduleActionTypes } from "contexts/ModuleContext";
import AddedTextElementsList from "./AddedTextElementsList";
import AddedGraphicalElementsList from "./AddedGraphicalElementsList";
import ConfirmationPopup from "components/ConfirmationPopup";
import { useTranslation } from "react-i18next";
import OverlayPopOver from "Uitls/OverlayPopOver";
import { ToolTipText } from "components/TooltipText";
import { BoldText } from "components/Texts";
import ImportElementsInfoHoverText from "components/modules/hoverTexts/ImportElementsInfoHoverText";
import { emitter } from "Uitls/Util";

function ImportElements({ ...props }) {
  const { t } = useTranslation();
  const { onImportClick, getModuleDetailsForPreview, handleCancel } = props;
  const [importReUsableTextModalOpen, setImportReUsableTextModalOpen] =
    useState(false);
  const { module, setActiveScreen, setActiveElementId, updateModule } =
    useContext(ModuleContext);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [openProceedConfirmPopup, toggleProceedConfirmPopup] = useState(false);
  const [autoSyncData, setAutoSyncData] = useState(undefined);

  useEffect(() => {
    let moduleDetails = JSON.parse(JSON.stringify(module));
    if (autoSyncData?.type === "Claim") {
      let index = moduleDetails?.claims?.findIndex(
        (elem) => elem.claimDamId === autoSyncData?.id
      );
      if (index > -1) {
        moduleDetails.claims[index].mlrStatus = autoSyncData?.mlrStatus;
      }
      if (moduleDetails.components.length > 0) {
        let components = JSON.parse(
          JSON.stringify(moduleDetails.components)
        ).map((item) => {
          let newData = [...item.relatedClaims];
          let index = newData?.findIndex(
            (elem) => elem.claimDamId === autoSyncData?.id
          );
          if (index > -1) {
            newData[index].mlrStatus = autoSyncData.mlrStatus;
            item.relatedClaims = newData;
          }
          return item;
        });
        moduleDetails.components = components;
      }
    }
    if (autoSyncData?.type === "Reusable Text") {
      let index = moduleDetails?.reusableTexts?.findIndex(
        (elem) => elem.reusableTextDamId === autoSyncData?.id
      );
      if (index > -1) {
        moduleDetails.reusableTexts[index].mlrStatus = autoSyncData?.mlrStatus;
      }
    }
    if (autoSyncData?.type === "Component") {
      let index = moduleDetails?.components?.findIndex(
        (elem) => elem.componentDamId === autoSyncData?.id
      );
      if (index > -1) {
        moduleDetails.components[index].mlrStatus = autoSyncData?.mlrStatus;
      }
    }
    updateModule(moduleActionTypes.SET_MODULE, moduleDetails);
  }, [autoSyncData]);

  useEffect(() => {
    const handleFooEvent = (e) => {
      try {
        const parsedData = JSON.parse(e);
        if (
          parsedData.type === "Claim" ||
          parsedData.type === "Reusable Text" ||
          parsedData.type === "Component"
        ) {
          setAutoSyncData(parsedData);
        }
      } catch (error) {
        console.error("Received non-JSON message:", e);
      }
    };

    emitter.on("autoSync", handleFooEvent);

    return () => {
      emitter.off("autoSync", handleFooEvent);
    };
  }, []);

  useEffect(() => {
    if (
      !module?.claims?.length &&
      !module?.reusableTexts?.length &&
      !module?.components?.length
    ) {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  }, [module]);

  const validateClaimsAndRts = () => {
    if (module?.claims?.length === 0 && module.reusableTexts?.length === 0) {
      toggleProceedConfirmPopup(true);
    } else {
      handleClickNext();
    }
  };

  const handleClickNext = () => {
    getModuleDetailsForPreview();
    // setActiveScreen(moduleScreens.modulePreview);
  };

  const getReusableTexts = () => {
    const reusableTexts = module?.reusableTexts?.map((reusableText, index) => {
      return {
        name: `Reusable text ${index + 1}`,
        id: reusableText.reusableTextName,
        text: reusableText.matchText,
        topic: reusableText.topicName || "-",
        subtopic: reusableText.subtopicName || "-",
        textType: reusableText.textType || "-",
        messageType: reusableText.messageType || "-",
        mustUse: reusableText.mustUse,
        systemId: reusableText.reusableTextId,
        mlrStatus: reusableText.mlrStatus,
      };
    });
    return reusableTexts || [];
  };

  const getClaims = () => {
    const claims = module?.claims?.map((claim, index) => {
      return {
        name: `Claim ${index + 1}`,
        id: claim.claimName,
        text: claim.matchText,
        topic: claim.topicName || "-",
        subtopic: claim.subtopicName || "-",
        messageType: claim.messageType || "-",
        systemId: claim.claimId,
        mustUse: claim.mustUse,
        mlrStatus: claim.mlrStatus,
      };
    });
    return claims || [];
  };

  const getComponents = () => {
    const components = module?.components?.map((component, index) => {
      return {
        name: `Component ${index + 1}`,
        id: component.componentDocNumber,
        text: component.matchText,
        topic: component.topicName || "-",
        subtopic: component.subtopicName || "-",
        subType: component.subType || "-",
        systemId: component.componentId,
        mustUse: component.mustUse,
        classification: component.classification,
        componentUrl: component.componentUrl,
        title: component.matchText ? component.matchText : component.title,
        componentName: component.componentName,
        mlrStatus: component.mlrStatus,
      };
    });
    return components || [];
  };

  return (
    <div className={`${styles.elementsBlockContainer}`}>
      <div className="row mt-3 mb-3">
        <div
          className={`col-md-12 font-14 heightNormal ${styles.elementSectionTitle}`}
        >
          Add Elements to Continue{" "}
          <OverlayPopOver
            element={<img src={infIcon} alt="info icon" />}
            overlayData={
              <ToolTipText>
                <ImportElementsInfoHoverText />
              </ToolTipText>
            }
            trigger={["hover", "focus"]}
            placement="bottom"
            classId="tooltip"
            inlineStyles={{ maxWidth: "790px" }}
          />
        </div>
      </div>
      <div className="row">
        <div className={`col-md-4`}>
          <AddedTextElementsList
            elementType={moduleScreens.claims}
            elementLabel="Claim"
            elements={getClaims()}
            onImportClick={onImportClick}
          />
        </div>
        <div className={`col-md-4 pb-0`}>
          <AddedTextElementsList
            elementType={moduleScreens.reusableTexts}
            elementLabel="Reusable Text"
            elements={getReusableTexts()}
            onImportClick={onImportClick}
          />
        </div>
        <div className={`col-md-4`}>
          <AddedGraphicalElementsList
            elementType={moduleScreens.components}
            elementLabel="Component"
            elements={getComponents()}
            onImportClick={onImportClick}
          />
        </div>
      </div>

      <div className={`row mt-4 align-items-center ${styles.moduleFooterRow}`}>
        <div className="col-md-6"></div>
        <div className="col-md-6 text-right">
          <LinkButton
            title="CANCEL"
            handleClick={() => {
              handleCancel();
            }}
          />
          <PrimaryButton
            title="NEXT"
            handleClick={validateClaimsAndRts}
            minWidth={110}
            marginLeft={20}
            disabled={isButtonDisabled}
          />
        </div>
      </div>
      <CustomModal
        displayModal={importReUsableTextModalOpen}
        hideModal={() => setImportReUsableTextModalOpen(false)}
        size="xl"
        dilogClassName="alertModalSmall"
      >
        <ImportReUsableText
          setImportReUsableTextModalOpen={setImportReUsableTextModalOpen}
        />
      </CustomModal>

      <CustomModal
        displayModal={openProceedConfirmPopup}
        hideModal={() => {
          toggleProceedConfirmPopup(false);
          setIsButtonDisabled(true);
        }}
        size="sm"
        dilogClassName="alertModalSmall"
      >
        <ConfirmationPopup
          title={t("generalTexts.cannotProceedWithModuleCreation")}
          text={t("generalTexts.atleastOneClaimOrRtrequiredForModule")}
          handleConfirm={() => {
            toggleProceedConfirmPopup(false);
            setIsButtonDisabled(true);
          }}
          primaryButtonTitle={t("generalTexts.okGotIt")}
          isCancelNeeded={false}
        />
      </CustomModal>
    </div>
  );
}

export default ImportElements;
