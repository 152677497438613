import React from "react";
import variables from "../styles/base/_universal.scss";
import styled from "styled-components";
import { RoundedButton } from "./Buttons";

const PrimaryButton = ({
  title,
  icon,
  handleClick,
  disabled,
  minWidth,
  marginLeft,
  showIcon,
  height,
}) => {
  return (
    <button
      onClick={handleClick}
      disabled={disabled}
      className="btn btnOutline"
      style={{ minWidth: minWidth, marginLeft: marginLeft, height: height }}
    >
      {showIcon ? (
        <>
          <img src={icon} alt="" /> {title}
        </>
      ) : (
        title
      )}
    </button>
  );
};

export default PrimaryButton;
