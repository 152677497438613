import styled from "styled-components";
import variables from "styles/base/_universal.scss";

export const NormalText = styled.span`
  font-size: ${(props) =>
    props.fontSize ? props.fontSize : variables.fontSmall};
  color: ${(props) => (props.color ? props.color : variables.subtitleColor)};
  display: ${(props) => (props.display ? props.display : "block")};
`;

export const BoldText = styled(NormalText)`
  font-weight: ${(props) =>
    props.fontWeight ? props.fontWeight : variables.fontWeight600};
  color: ${(props) =>
    props.disabled ? variables.disabledTextColor : variables.blackColor};
`;

export const MainText = styled.span`
  font-size: ${(props) =>
    props.fontSize ? props.fontSize : variables.fontSmall};
  color: ${variables.darkGrayColor};
  display: ${(props) => (props.display ? props.display : "block")};
`;
