import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.scss";

const SimpleSlider = ({ children, sliderRef }) => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: false,
    useTransform: false,
    initialSlide: null,
    draggable: false,
    fade: true,
    waitForAnimate: false,
    //adaptiveHeight: true,
  };

  return (
    <Slider {...settings} ref={sliderRef}>
      {children}
    </Slider>
  );
};

export default SimpleSlider;
