import MultiselectDropDown from "components/MultiselectListDropDown";
import React from "react";
import { useTranslation } from "react-i18next";
import { metaDataTypes } from "Uitls/Util";

export const getMarketFilterData = (data) => {
  const markets = JSON.parse(JSON.stringify(data || []));
  return markets.map((market) => {
    return {
      id: market.id,
      displayName: market.countryName,
      veevaId: market.countryDamId,
      metaDataType: metaDataTypes.country,
    };
  });
};

const MarketsFilter = ({
  data,

  handleFilter,
  selectedFilterData,
}) => {
  const { t } = useTranslation();
  return (
    <MultiselectDropDown
      title={t("metadata.market")}
      data={getMarketFilterData(data)}
      applySelection={(data) => {
        handleFilter("primary country", data);
      }}
      selectedFilterData={selectedFilterData}
    />
  );
};

export default MarketsFilter;
