import React, { useState } from "react";
import { Button, Menu, MenuItem } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import "styles/components/UserActionDropDown.scss";
import BlueDownArrow from "img/blue-down-arrow.svg";
import BlueUpArrow from "img/blue-up-arrow.svg.svg";

const UserActionsDropdown = ({
  module,
  modulePreviewData,
  handleDownloadCMSD,
  downloadCmsdDcoument,
  handleDownloadAnnotationPDF,
  setModuleIdToBeCloned,
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="user-action-button">
      <Button
        aria-controls="user-actions-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className="userActionBtn"
        disableRipple
        endIcon={
          anchorEl ? (
            <img src={BlueUpArrow} alt="Up Arrow" className="icon" />
          ) : (
            <img src={BlueDownArrow} alt="Down Arrow" className="icon" />
          )
        }
      >
        User Action(s)
      </Button>
      <Menu
        id="user-actions-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        getContentAnchorEl={null}
        classes={{ paper: "menuPaper" }}
      >
        <MenuItem
          onClick={() => {
            handleDownloadCMSD();
            handleClose();
          }}
          disabled={!module?.mlrStatus || module?.mlrStatus === null}
          className="menuItem"
        >
          Download CMSD - Clean version
        </MenuItem>
        <MenuItem
          onClick={() => {
            downloadCmsdDcoument();
            handleClose();
          }}
          disabled={
            module?.mlrStatus !== "Revise and Resubmit" ||
            module?.cmsdMajorV === null
          }
          className="menuItem"
        >
          Download CMSD - Track changes
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleDownloadAnnotationPDF();
            handleClose();
          }}
          disabled={
            !["revise and resubmit"].includes(
              modulePreviewData?.mlrStatus?.toLowerCase()
            )
          }
          className="menuItem"
        >
          Download MLR Feedback
        </MenuItem>
        <MenuItem
          onClick={() => {
            setModuleIdToBeCloned(module.id || module.moduleId);
            handleClose();
          }}
          className="menuItem"
        >
          {t("module.cloneModule")}
        </MenuItem>
      </Menu>
    </div>
  );
};

export default UserActionsDropdown;
