import styled from "styled-components";
import variables from "../styles/base/_universal.scss";
import DropdownButton from "react-bootstrap/DropdownButton";

export const RoundedButton = styled.button`
  border-radius: 20px;
  height: 32px;
  padding: 6px 10px 8px;
  text-align: center;
  font-size: ${(props) =>
    props.fontSize
      ? `${props.fontSize}`
      : `${variables.fontSmallV2}`} !important;

  box-shadow: none;
  outline: none;
  border: none;
  cursor: pointer;

  &:active {
    outline: none;
    box-shadow: none;
  }
  &:hover {
    outline: none;
    box-shadow: none;
  }
`;

export const TransparentButton = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  box-shadow: none;
  font-size: ${variables.fontSmall};
  font-weight: bold;
  color: ${(props) =>
    props.disabled
      ? `${variables.disabledTextColor}`
      : `${variables.primaryColor}`};
  padding-left: 0px;
  padding-right: 0px;
  height: ${(props) => (props.height ? props.height : "32px")};
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};

  &:active,
  &:focus {
    outline: none;
    box-shadow: none;
    border: none;
  }
`;

export const DangerButton = styled(TransparentButton)`
  color: ${variables.redColor};
  font-weight: bold;
`;

export const BorderedOutLineButton = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  box-shadow: none;
  font-size: ${variables.fontSmall};
  font-weight: bold;
  color: ${(props) =>
    props.disabled
      ? `${variables.disabledTextColor}`
      : `${variables.primaryColor}`};
  padding-left: 0px;
  padding-right: 0px;
  height: 42px;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  border-radius: 6px;
  border: ${(props) =>
    props.disabled ? `solid 1px #d4d4d4` : `solid 1px #203662`};
  background-color: #fff;
  width: 100%;

  &:active,
  &:focus {
    outline: none;
    box-shadow: none;
    border: solid 1px #203662;
    background-color: #fff;
  }
  &:hover {
    outline: none;
    box-shadow: none;
  }
`;
