import React, { useState, createRef } from "react";
import styled from "styled-components";
import styles from "styles/modules/importClaims.module.scss";
import SingleLinekeyValue from "components/SingleLineKeyValue";
import LinkButton from "components/LinkButton";
import OverlayPopOver from "Uitls/OverlayPopOver";
import { ToolTipText } from "components/TooltipText";
import { getMlrStatusIcon } from "Uitls/Util";

function DataGraphic(props) {
  const { title, data, onClick } = props;
  const popoverRef = createRef();

  return (
    <>
      <div className={`${styles.elementxBox} ${styles.elementxBoxBrdr}`}>
        <span className={`${styles.title}`}>{title}</span>
        <div ref={popoverRef} className={`${styles.fgrbox} customScrollBar`}>
          {data?.map((item, index) => {
            return (
              <CardContainer key={item.id || index}>
                <div>
                  <SingleLinekeyValue
                    label="ID"
                    value={
                      <>
                        <div
                          style={{
                            display: "inline-flex",
                            alignItems: "center",
                          }}
                        >
                          <span className="mr-1">
                            <OverlayPopOver
                              element={
                                <img
                                  src={getMlrStatusIcon(item?.mlrStatus)}
                                  alt={`${item.mlrStatus} icon`}
                                  style={{
                                    verticalAlign: "middle",
                                    marginBottom: "-2px",
                                  }}
                                />
                              }
                              overlayData={
                                <ToolTipText>
                                  {item?.mlrStatus || ""}
                                </ToolTipText>
                              }
                              trigger={["hover", "focus"]}
                              placement="bottom"
                              classId="tooltip"
                              containerRef={popoverRef}
                            />
                          </span>
                        </div>
                        <span
                          style={{
                            display: "inline-flex",
                            alignItems: "center",
                          }}
                        >
                          {item.claimName
                            ? item.claimName
                            : item.reusableTextName}
                        </span>
                      </>
                    }
                    inline={true}
                  />
                  <>
                    {item?.mlrStatus
                      ?.trim()
                      .toLowerCase()
                      .includes("expired") && (
                      <span
                        className="Expired "
                        style={{
                          verticalAlign: "middle",
                          marginBottom: "-2px",
                        }}
                      >
                        Expired
                      </span>
                    )}
                  </>
                  <span style={{ float: "right" }}>
                    <LinkButton
                      title="VIEW"
                      height="16px"
                      handleClick={() => {
                        onClick(
                          item.claimName
                            ? item.claimName
                            : item.reusableTextName
                        );
                      }}
                    />
                  </span>
                </div>
                <OverlayPopOver
                  element={<MatchText>{item.matchText}</MatchText>}
                  overlayData={
                    <ToolTipText className="scollable-tooltip customScrollBar">
                      {item.matchText}
                    </ToolTipText>
                  }
                  trigger={["hover", "focus"]}
                  placement="auto"
                  classId="tooltip"
                  containerRef={popoverRef}
                />
              </CardContainer>
            );
          })}
        </div>
      </div>
    </>
  );
}

const CardContainer = styled.div`
  background-color: #f0f5f8;
  padding: 12px;
  border-radius: 6px;
  margin-bottom: 20px;
`;

const MatchText = styled.div`
  margin-top: 12px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
`;

export default DataGraphic;
