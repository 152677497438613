import React from "react";
import styles from "styles/components/LeftRightScrollButtonSmall.module.scss";

const LeftRightScrollButtonSmall = ({
  handleClick,
  disabled = false,
  shouldRightDisabled = true,
  shouldLeftDisabled = false,
  count,
  currentValue,
}) => {
  return (
    <div className="d-flex align-items-center justify-content-center">
      <button
        className={`btn ${styles.paginationBtn} ${styles.btnPaginationLeftDisabled}`}
        disabled={shouldLeftDisabled}
        onClick={() => {
          handleClick("backward");
        }}
      ></button>
      <input
        id="pageination-input"
        type="text"
        className={`${styles.sliderInput}`}
        onBlur={(e) => {}}
        onKeyUp={(e) => {
          if (e.key === "Enter") {
          }
        }}
        disabled={disabled}
        value={currentValue}
      />
      <span className={`${styles.lableText}`}>of {count}</span>
      <button
        className={`btn ${styles.paginationBtn} ${styles.btnPaginationRightDisabled}`}
        disabled={shouldRightDisabled}
        onClick={() => {
          handleClick("forward");
        }}
      ></button>
    </div>
  );
};

export default LeftRightScrollButtonSmall;
