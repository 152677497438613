import { BoldText } from "components/Texts";
import React from "react";

const ImportElementsInfoHoverText = () => {
  return (
    <div>
      <BoldText>
        In general an element refers to either of claim, reusable text or a
        component. A component could either be an image or a graph or a chart. A
        module could be created using either of the following combination of
        elements
      </BoldText>

      <ol style={{ paddingLeft: "12px", marginBottom: "5px" }}>
        <li>Claims,</li>
        <li>Reusable Texts,</li>
        <li>Claims & Components,</li>
        <li>Reusable Texts & Components,</li>
        <li>Claims & Reusable Texts only.</li>
      </ol>
      <BoldText>Guidelines for creating a module</BoldText>
      <ol style={{ paddingLeft: "12px", marginBottom: "0px" }}>
        <li>
          A module must either have one Primary claim or one Reusable text
          element.
        </li>
        <li>
          At least one reusable text has to be added if no claim has been
          selected to create a module.
        </li>
        <li>You can create a module by selecting one or more elements.</li>
        <li>
          Use the toggle bar, “Must Use” to mark any element either as Mandatory
          or Optional. The elements are marked as “Mandatory” by default.
        </li>
        <li>
          To begin your journey, click on the Add icon displayed on the
          respective elements tab.
        </li>
        <li>
          You could remove one or more elements from the respective boxes at any
          time during the journey.
        </li>
      </ol>
    </div>
  );
};

export default ImportElementsInfoHoverText;
