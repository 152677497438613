import { moduleScreens } from "pages/module/ModuleUtil";
import { createContext, useReducer, useState } from "react";
import React from "react";

export const moduleActionTypes = {
  GET_MODULE: "get modules details",
  SET_MODULE: "set module",
  REPLACE_CLAIMS: "replace claims",
  TOGGLE_CLAIM_MUST_USE: "toggle  must use",
  REMOVE_CLAIM: "remove claim",
  REPLACE_REUSABLE_TEXTS: "replace reusable texts",
  TOGGLE_REUSABLE_TEXT_MUST_USE: "reusable text must use",
  REMOVE_REUSABLE_TEXT: "remove reusable text",
  REPLACE_COMPONENTS: "replace components",
  TOGGLE_COMPONENT_MUST_USE: "components must use",
  REMOVE_COMPONENT: "remove components",
  MODULE_PUBLISH: "update module after publish",
  BUSINESSRULES_PUBLISH: "update businessrules after publish",
  SYCH_MLR_STATUS: "update mlr status",
};

/**
 * Reducer to perform operations on module
 * @param {*} state
 * @param {*} action
 * @returns updated module data
 */
export const moduleReducer = (state, action) => {
  const newState = JSON.parse(JSON.stringify(state));
  switch (action.type) {
    case moduleActionTypes.GET_MODULE:
      return action.payload;
    case moduleActionTypes.SET_MODULE:
      return action.payload;
    case moduleActionTypes.REPLACE_CLAIMS:
      return { ...state, claims: action.payload };
    case moduleActionTypes.REMOVE_CLAIM:
      //   toggle(state, "claims", action.payload.id, action.payload.toggleValue);
      newState.claims = newState.claims.filter((claim) => {
        return claim.claimId !== action.payload.id;
      });
      return newState;
    case moduleActionTypes.TOGGLE_CLAIM_MUST_USE:
      //   toggle(state, "claims", action.payload.id, action.payload.toggleValue);

      const index = newState.claims.findIndex((elemnt) => {
        return elemnt.claimId === action.payload.id;
      });
      if (index > -1) {
        newState.claims[index].mustUse = action.payload.toggleValue;
      }
      return newState;
    case moduleActionTypes.REPLACE_REUSABLE_TEXTS:
      return { ...state, reusableTexts: action.payload };

    case moduleActionTypes.REMOVE_REUSABLE_TEXT:
      //   toggle(state, "claims", action.payload.id, action.payload.toggleValue);
      newState.reusableTexts = newState.reusableTexts.filter((reusableText) => {
        return reusableText.reusableTextId !== action.payload.id;
      });
      return newState;
    case moduleActionTypes.TOGGLE_REUSABLE_TEXT_MUST_USE:
      const reusableTextIndex = newState.reusableTexts.findIndex((elemnt) => {
        return elemnt.reusableTextId === action.payload.id;
      });
      if (reusableTextIndex > -1) {
        newState.reusableTexts[reusableTextIndex].mustUse =
          action.payload.toggleValue;
      }
      return newState;

    case moduleActionTypes.REPLACE_COMPONENTS:
      return { ...state, components: action.payload };

    case moduleActionTypes.REMOVE_COMPONENT:
      //   toggle(state, "claims", action.payload.id, action.payload.toggleValue);
      newState.components = newState.components.filter((component) => {
        return component.componentId !== action.payload.id;
      });
      return newState;
    case moduleActionTypes.TOGGLE_COMPONENT_MUST_USE:
      const componentIndex = newState.components.findIndex((elemnt) => {
        return elemnt.componentId === action.payload.id;
      });
      if (componentIndex > -1) {
        newState.components[componentIndex].mustUse =
          action.payload.toggleValue;
      }
      return newState;
    case moduleActionTypes.MODULE_PUBLISH:
      return {
        ...state,
        moduleDamId: action.payload.moduleDamId,
        mlrStatus: action.payload.mlrStatus,
      };
    case moduleActionTypes.BUSINESSRULES_PUBLISH:
      return {
        ...state,
        businessRulePublished: action.payload.businessRulePublished,
      };
    case moduleActionTypes.SYCH_MLR_STATUS:
      return {
        ...state,
        mlrStatus: action.payload.mlrStatus,
      };
    default:
      return state;
  }
};
export const ModuleContext = createContext({});

export const ModuleContextProvider = ({ children }) => {
  const [module, dispatch] = useReducer(moduleReducer, {});
  const [activeScreen, setActiveScreen] = useState(moduleScreens.home);
  const [currentActiveElementIndex, setCurrentElementActiveIndex] =
    useState(-1);
  const [isloading, setIsloading] = useState(false);
  const [activeElementId, setActiveElementId] = useState(undefined);
  const [elementToBeRemoved, setElementToBeRemoved] = useState(undefined);
  const [activeElementType, setActiveElementType] = useState(undefined);

  const updateModule = (type, payload) => {
    dispatch({ type: type, payload: payload });
  };
  //Will remove once Ranjan implements the permanent
  //solutino as we discussed
  const [messageTypes, setMessageTypes] = useState([]);
  const [textTypes, setTextTypes] = useState([]);
  const getMessageTypeLabel = (code) => {
    const msgType = messageTypes.find((el) => {
      return el.name === code;
    });
    if (msgType) {
      return msgType.label;
    }
    return "";
  };
  const getTextTypeLabel = (code) => {
    const txtType = textTypes.find((el) => {
      return el.name === code;
    });
    if (txtType) {
      return txtType.label;
    }
    return "";
  };
  return (
    <ModuleContext.Provider
      value={{
        module,
        updateModule,
        activeScreen,
        setActiveScreen,
        currentActiveElementIndex,
        setCurrentElementActiveIndex,
        isloading,
        setIsloading,
        activeElementId,
        setActiveElementId,
        elementToBeRemoved,
        setElementToBeRemoved,
        activeElementType,
        setActiveElementType,
        messageTypes,
        setMessageTypes,
        textTypes,
        setTextTypes,
        getMessageTypeLabel,
        getTextTypeLabel,
      }}
    >
      {children}
    </ModuleContext.Provider>
  );
};
