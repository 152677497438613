import { BoldText, MainText, NormalText } from "components/Texts";

import React, { useContext } from "react";
import styled from "styled-components";
import styles from "styles/modules/elementsCard.module.scss";
import variables from "styles/base/_universal.scss";
import PenIcon from "img/pen-icon.svg";
import SwicthButton from "components/SwitchButton";
import SingleLinekeyValue from "components/SingleLineKeyValue";
import { costomEllipsis, showError } from "Uitls/Util";
import { Col } from "react-bootstrap";
import LinkButton from "components/LinkButton";
import { ModuleContext, moduleActionTypes } from "contexts/ModuleContext";
import { removeElementFromModule, toggleMustUse } from "services/apis";
import {
  getElementsType,
  getToggleEventName,
  moduleScreens,
} from "pages/module/ModuleUtil";
import { getMlrStatusIcon } from "Uitls/Util";
import OverlayPopOver from "Uitls/OverlayPopOver";
import { ToolTipText } from "components/TooltipText";

const TextElementCard = (props) => {
  const { textElement, elementType, customClass } = props;

  const {
    module,
    updateModule,
    setActiveElementId,
    setActiveScreen,
    setElementToBeRemoved,
    setActiveElementType,
    getMessageTypeLabel,
    getTextTypeLabel,
  } = useContext(ModuleContext);

  const viewTextualElement = (elementId) => {
    setActiveElementId(() => {
      setActiveScreen(elementType);
      return elementId;
    });
  };

  const handleToggle = (toggleValue) => {
    toggleMustUse(
      module.moduleId,
      getElementsType(elementType),
      textElement.systemId,
      toggleValue,
      (res, err) => {
        if (res) {
          updateModule(getToggleEventName(elementType), {
            id: textElement.systemId,
            toggleValue: toggleValue,
          });
          return;
        }
        showError(err);
      }
    );
  };

  return (
    <>
      <div className={styles.elementsCard} key={textElement.id}>
        <div className={styles.elementsTitleRow}>
          <span className={`${customClass} ${styles.titleBlock}`}>
            {textElement?.name}
          </span>{" "}
          <span className={styles.idBlock}>
            ID:
            <span className="mr-1 ml-1">
              <OverlayPopOver
                element={
                  <img
                    src={getMlrStatusIcon(textElement.mlrStatus)}
                    alt={textElement.mlrStatus}
                    style={{ marginTop: "-2px" }}
                  />
                }
                overlayData={
                  <ToolTipText>
                    {textElement.mlrStatus
                      ? textElement.mlrStatus
                      : "Module not Published"}
                  </ToolTipText>
                }
                trigger={["hover", "focus"]}
                placement="bottom"
                classId="tooltip"
              />
            </span>
            <span className="rt-id"> {textElement.id}</span>
          </span>
          {textElement?.mlrStatus?.trim().toLowerCase().includes("expired") && (
            <span
              className="Expired"
              style={{
                verticalAlign: "middle",
              }}
            >
              Expired
            </span>
          )}
        </div>
        {elementType === 1 ? (
          <div className={`heightNormal ${styles.elementsContent}`}>
            <span className={styles.ellipsses}>{textElement.text}</span>
          </div>
        ) : (
          <div
            className={`heightNormal ellipssesSingleLine ${styles.elementsContent} `}
          >
            {textElement.text}
          </div>
        )}

        <div className={`${styles.elementsFooter} pt-0`}>
          <span className={styles.idBlock}>Topic: </span>{" "}
          <span className={styles.titleBlock}>{textElement.topic}</span>
          <span className={styles.idBlock}>Sub-Topic: </span>{" "}
          {textElement.subtopic}
        </div>
        {elementType === 2 && (
          <div className={`${styles.elementsFooter} pt-0`}>
            <span className={styles.idBlock}>Reusable Text Type: </span>{" "}
            <span className={styles.titleBlock}>{textElement.textType}</span>
          </div>
        )}
        {elementType === 1 && (
          <div className={`${styles.elementsFooter}`}>
            <span className={styles.idBlock}>Message Type: </span>{" "}
            <span className={styles.titleBlock}>{textElement.messageType}</span>
          </div>
        )}
        <div
          className={`d-flex align-items-center justify-content-between ${styles.switchButtonRow}`}
        >
          <MustUseToggle className="md-4">
            <SwicthButton
              label="Must Use"
              handleToggle={(toggleValue) => {
                handleToggle(toggleValue);
              }}
              checked={textElement.mustUse}
            />
          </MustUseToggle>
          <div>
            <LinkButton
              title="Remove"
              handleClick={() => {
                setElementToBeRemoved(textElement.systemId);
                setActiveElementType(elementType);
              }}
              customClass="text-uppercase"
              // disabled={!selectedData || selectedData.length === 0}
            />
            <LinkButton
              title="View"
              handleClick={() => {
                viewTextualElement(textElement.id);
              }}
              customClass="ml-12 text-uppercase"
              // disabled={!selectedData || selectedData.length === 0}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const MustUseToggle = styled("div")`
  padding-right: 0px;
`;

export default TextElementCard;
