import React, { useState, useEffect, useRef } from "react";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import { makeStyles } from "@material-ui/core/styles";

import "../styles/components/MultiselectList.scss";
import MultiSelectList from "./MultiSelectList";

import blueDownArrow from "../img/blue-down-arrow.svg";
import grayDownArrow from "../img/gray-down-arrow.svg";
import blueUpArrow from "../img/upArrow.svg";
import "../styles/components/MultiselectDropdown.scss";
import FooterButtons from "./FooterButtons";
import PrimaryButton from "./PrimaryButton";
import LinkButton from "./LinkButton";
import { ClickAwayListener } from "@material-ui/core";
import styled from "styled-components";

const useStyles = makeStyles((theme) => ({
  root: {
    // zIndex: "9999",
    display: "inline-block",
  },
  paper: {
    marginRight: theme.spacing(2),
  },
}));

const MultiselectDropDown = ({
  title,
  data,
  applySelection,
  disabled = false,
  selectedFilterData,
}) => {
  const [isOpen, toggleisOpen] = useState(false);
  const [selectedData, setSelectedData] = useState(undefined);
  const classes = useStyles();
  const anchorRef = useRef(null);
  const [initialLoad, toggleInitialLoad] = useState(true);
  const [filteredList, setFilteredList] = useState([]);
  const [searchText, setSearchText] = useState("");

  const handleToggle = () => {
    toggleisOpen((prevOpen) => !prevOpen);
  };

  useEffect(() => {
    setSelectedData(selectedFilterData);
  }, [selectedFilterData]);

  const handleClose = (event) => {
    toggleisOpen(false);
  };

  const handleSelection = (isChecked, element) => {
    toggleInitialLoad(false);
    let index = selectedData?.findIndex((el) => el.id === element.id);
    if (index === undefined) {
      index = -1;
    }
    if (isChecked && index === -1) {
      setSelectedData((oldData) => {
        if (oldData) {
          oldData = oldData.concat(element);
        } else {
          oldData = [element];
        }
        return oldData;
      });
    } else if (!isChecked && index > -1) {
      setSelectedData((oldData) => {
        oldData = oldData.filter((el) => {
          return el.id !== element.id;
        });
        if (oldData?.length === 0) {
          enableDisableApplyButton();
        }
        return oldData;
      });
    }
  };
  const clearSelection = () => {
    setSelectedData(() => {
      return undefined;
    });
    enableDisableApplyButton();
    // applySelection(undefined);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(isOpen);
  useEffect(() => {
    if (prevOpen.current === true && isOpen === false) {
      setSelectedData(selectedFilterData);
      setSearchText("");
      anchorRef.current.focus();
    }

    prevOpen.current = isOpen;
    enableDisableApplyButton();
  }, [isOpen]);
  const enableDisableApplyButton = () => {
    if (!isOpen) {
      toggleInitialLoad(true);
      return;
    }
    toggleInitialLoad(isOpen && (selectedFilterData || [])?.length === 0);
  };

  const applyButton = (
    <PrimaryButton
      title="Apply"
      handleClick={() => {
        applySelection(selectedData);
        handleClose();
      }}
      disabled={initialLoad}
    />
  );
  const clearButton = (
    <LinkButton
      title="Clear"
      handleClick={() => {
        clearSelection();
      }}
      disabled={!selectedData || selectedData.length === 0}
    />
  );

  const getArrow = () => {
    if (isOpen) {
      return blueUpArrow;
    } else if (disabled) {
      return grayDownArrow;
    }
    return blueDownArrow;
  };
  useEffect(() => {
    setFilteredList(data);
  }, [data]);
  useEffect(() => {
    searchResults(searchText);
  }, [searchText]);
  const searchResults = (searchText) => {
    if (!data) {
      return;
    }
    let filterData = [...data];

    // Filter by Search Input
    if (searchText && searchText?.trim()) {
      searchText = searchText?.toLowerCase();
      filterData = filterData.filter((item) => {
        return item.displayName.toLowerCase().includes(searchText);
      });
    }
    setFilteredList(filterData);
  };
  return (
    <div className={`filterContainer ${classes.root}`}>
      <div
        className={`btn multiselect-dropdown ${isOpen ? "active" : ""} ${
          disabled ? "disabled" : ""
        }`}
        ref={anchorRef}
        aria-controls={isOpen ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        disabled={disabled}
        onClick={() => {
          if (disabled) {
            return;
          }
          handleToggle();
        }}
      >
        {" "}
        {title}
        <img src={getArrow()} alt="" style={{ marginLeft: "4px" }} />
      </div>
      <Popper
        open={isOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        className="multi-select-dropdown-outer"
        placement="bottom-end"
      >
        {/*  */}
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <div>
                  <MultiSelectList
                    data={filteredList}
                    anchorRef={anchorRef}
                    open={isOpen}
                    handleSelection={handleSelection}
                    selected={selectedData}
                    height="200px"
                    searchResults={(value) => setSearchText(value)}
                    inputValue={searchText}
                  />
                  <FooterButtons
                    primaryButton={applyButton}
                    secondaryButton={clearButton}
                    size="sm"
                  />
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default MultiselectDropDown;
