import LinkButton from "components/LinkButton";
import SingleLinekeyValue from "components/SingleLineKeyValue";
import { ModuleContext, moduleActionTypes } from "contexts/ModuleContext";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  isEmptyObject,
  costomEllipsis,
  getMlrStatusIcon,
  emitter,
} from "Uitls/Util";

import styles from "styles/modules/moduleBrands.module.scss";
import OverlayPopOver from "Uitls/OverlayPopOver";
import { ToolTipText } from "components/TooltipText";

function ModuleBrandNavigation({
  handleEditMetaData,
  moduleHeaderData,
  showPromoMatsID = false,
}) {
  const { module, updateModule } = useContext(ModuleContext);
  const { t } = useTranslation();
  const [autoSyncData, setAutoSyncData] = useState(undefined);

  useEffect(() => {
    let moduleDetails = JSON.parse(JSON.stringify(module));
    if (
      autoSyncData?.type === "Module" &&
      moduleDetails?.moduleId === autoSyncData?.id
    ) {
      moduleDetails.mlrStatus = autoSyncData.mlrStatus;
    }
    updateModule(moduleActionTypes.SET_MODULE, moduleDetails);
  }, [autoSyncData]);

  useEffect(() => {
    const handleFooEvent = (e) => {
      try {
        const parsedData = JSON.parse(e);
        if (parsedData.type === "Module") {
          setAutoSyncData(parsedData);
        }
      } catch (error) {
        console.error("Received non-JSON message:", e);
      }
    };

    emitter.on("autoSync", handleFooEvent);

    return () => {
      emitter.off("autoSync", handleFooEvent);
    };
  }, []);

  const truncatedSegments = module?.segments?.slice(1);
  const getTruncatedSegments = () => {
    return truncatedSegments?.map((segment) => segment.segmentName);
  };

  const openVeevaVault = (url) => {
    window.open(url, "_blank");
  };

  return (
    <div className="row align-items-center">
      <div className={`col-md-12 ${styles.moduleBrandsRow}`}>
        <div className={`${styles.moduleBrandsRowBrdr}`}>
          <div>
            <SingleLinekeyValue
              label={t("metadata.brand")}
              value={
                !isEmptyObject(module)
                  ? module.productName
                  : moduleHeaderData?.product
              }
              inline={true}
            />
            <SingleLinekeyValue
              label={t("metadata.market")}
              value={
                !isEmptyObject(module)
                  ? module.countryName
                  : moduleHeaderData?.country
              }
              left="16px"
              inline={true}
            />
            {!moduleHeaderData && (
              <>
                <SingleLinekeyValue
                  label={t("metadata.segment")}
                  value={
                    !isEmptyObject(module) && module?.segments?.length > 0
                      ? costomEllipsis(module?.segments[0]?.segmentName, 15)
                      : ""
                  }
                  inline={true}
                  left="16px"
                  title={
                    !isEmptyObject(module) && module?.segments?.length > 0
                      ? module?.segments[0]?.segmentName
                      : ""
                  }
                />
                {truncatedSegments?.length > 0 && (
                  <div
                    className={`${styles.segmentCount}`}
                    title={getTruncatedSegments().join(", ")}
                  >
                    +{truncatedSegments?.length}
                  </div>
                )}
                <SingleLinekeyValue
                  label={t("metadata.communicationObj")}
                  value={
                    !isEmptyObject(module) && module?.communicationObjectives
                      ? costomEllipsis(module?.communicationObjectives, 15)
                      : ""
                  }
                  inline={true}
                  left="16px"
                  title={module?.communicationObjectives}
                />
              </>
            )}
            <span className={`d-inline-block ${styles.editCtaBrdrleft}`}>
              {!moduleHeaderData && (
                <LinkButton
                  customClass="heightNormal  p0 m0 text-uppercase"
                  title="View metadata information"
                  handleClick={() => {
                    handleEditMetaData();
                  }}
                  // disabled={
                  //   module?.claims?.length !== 0 ||
                  //   module?.components?.length !== 0 ||
                  //   module?.reusableTexts?.length !== 0
                  // }
                />
              )}
            </span>
          </div>
          {showPromoMatsID ? (
            <span
              className={` ${styles.mlrStatus} d-flex justify-content-between align-items-center`}
            >
              Content Module ID:
              <OverlayPopOver
                element={
                  <img
                    src={getMlrStatusIcon(module.mlrStatus)}
                    alt={module.mlrStatus}
                  />
                }
                overlayData={
                  <ToolTipText>
                    {module.mlrStatus
                      ? module.mlrStatus
                      : "Module not Published"}
                  </ToolTipText>
                }
                trigger={["hover", "focus"]}
                placement="bottom"
                classId="tooltip"
              />
              {module?.moduleDamName ? (
                <span
                  onClick={() => {
                    if (module?.cmsdDocUrl) {
                      openVeevaVault(module?.cmsdDocUrl);
                    }
                  }}
                  style={{
                    color: "#203662",
                    cursor: module?.cmsdDocUrl ? "pointer" : "default",
                  }}
                >
                  {module?.moduleDamName}
                </span>
              ) : (
                "-"
              )}
            </span>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default ModuleBrandNavigation;
