import React, { useState, useEffect, useRef } from "react";
import BusinessrulesBox from "./BusinessRulesBox";
import styles from "styles/components/businessRules/businesrules.module.scss";
import infoIcon from "img/infoIcon.svg";
import OverlayPopOver from "Uitls/OverlayPopOver";
import { ToolTipText } from "components/TooltipText";
import { useTranslation } from "react-i18next";
import { BoldText } from "components/Texts";
import { emitter } from "Uitls/Util";
const ApplyBusinessRules = (props) => {
  const { t } = useTranslation();
  const {
    bsRules,
    setBsRules,
    modulesList,
    guidelinesRef,
    saveBusinessRules,
    businessRulesList,
  } = props;

  const charLimit = 1500;

  // Initialize the state with usageGuidelines from businessRulesList
  const [textAreaValue, setTextAreaValue] = useState("");

  // Update the charCount based on the value of textAreaValue
  const [charCount, setCharCount] = useState(0);

  // Set textAreaValue and charCount when businessRulesList or its usageGuidelines changes
  useEffect(() => {
    if (businessRulesList?.usageGuidelines) {
      setTextAreaValue(businessRulesList.usageGuidelines);
      setCharCount(businessRulesList.usageGuidelines.length);
    }
  }, [businessRulesList]);

  // Update textarea value and character count
  const [autoSyncData, setAutoSyncData] = useState(undefined);

  useEffect(() => {
    const brules = JSON.parse(JSON.stringify(bsRules)).map((item) => {
      let newData = [...item.rules];
      let index = newData.findIndex((elem) => elem.id === autoSyncData?.id);
      if (index > -1) {
        newData[index].mlrStatus = autoSyncData.mlrStatus;
        item.rules = newData;
      }
      return item;
    });
    setBsRules(brules);
  }, [autoSyncData]);

  useEffect(() => {
    const handleFooEvent = (e) => {
      try {
        const parsedData = JSON.parse(e);
        if (parsedData.type === "Module") {
          setAutoSyncData(parsedData);
        }
      } catch (error) {
        console.error("Received non-JSON message:", e);
      }
    };

    emitter.on("autoSync", handleFooEvent);

    return () => {
      emitter.off("autoSync", handleFooEvent);
    };
  }, []);

  const handleTextAreaChange = (e) => {
    const value = e.target.value;
    if (value.length <= charLimit) {
      setTextAreaValue(value);
      setCharCount(value.length);
    }
  };

  return (
    <>
      {/* <div className="row mt-16">
        <div className="col-md-12">
          <div className={styles.businessRulesTitle}>Apply Business Rules</div>
        </div>
      </div> */}
      <div className="row mt-16">
        <div className="col-md-4">
          <div className={styles.businessRulesSubTitle}>
            {bsRules[0].title} (Compliance)
            <OverlayPopOver
              element={<img src={infoIcon} alt="Info" className="ml-1 mt2" />}
              overlayData={
                <ToolTipText>
                  <span>
                    <BoldText display="contents">
                      {bsRules[0].title} :{" "}
                    </BoldText>
                    {t("businessRules.infoMustExistWith")}
                  </span>
                </ToolTipText>
              }
              classId="tooltip"
              trigger={["hover", "focus"]}
              placement="bottom"
            />
          </div>
        </div>
        <div className="col-md-4">
          {" "}
          <div className={styles.businessRulesSubTitle}>
            {bsRules[1].title} (Compliance)
            <OverlayPopOver
              element={<img src={infoIcon} alt="Info" className="ml-1 mt2" />}
              overlayData={
                <ToolTipText>
                  <span>
                    <BoldText display="contents">
                      {bsRules[1].title} :{" "}
                    </BoldText>
                    {t("businessRules.infoCannotExistWith")}
                  </span>
                </ToolTipText>
              }
              classId="tooltip"
              trigger={["hover", "focus"]}
              placement="bottom"
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className={styles.businessRulesSubTitle}>
            Related modules (Optional){" "}
            <OverlayPopOver
              element={<img src={infoIcon} alt="Info" className="ml-1 mt2" />}
              overlayData={
                <ToolTipText>
                  <span>
                    <BoldText display="contents">
                      {t("businessRules.relatedModule")} :{" "}
                    </BoldText>
                    {t("businessRules.infoRelatedModules")}
                  </span>
                </ToolTipText>
              }
              classId="tooltip"
              trigger={["hover", "focus"]}
              placement="bottom"
            />
          </div>
        </div>
      </div>
      <div className="row mt-12">
        {bsRules.map((item) => (
          <BusinessrulesBox
            key={item.id}
            id={item.id}
            name={item.title}
            setBsRules={setBsRules}
            bsRules={bsRules}
            modulesList={modulesList}
            saveBusinessRules={saveBusinessRules}
          />
        ))}
      </div>
      <div className="row mt20">
        <div className="col-md-12">
          <div className={styles.businessRulesSubTitle}>
            <div className={styles.textAreaContainer}>
              <textarea
                placeholder="Usage Guidelines"
                className={`${styles.customTextFiled} customScrollBar`}
                rows="4"
                ref={guidelinesRef}
                value={textAreaValue}
                onChange={handleTextAreaChange}
                onDrop={(e) => e.preventDefault()}
              />
              <div className={styles.charCount}>
                {charCount}/{charLimit} chars
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApplyBusinessRules;
