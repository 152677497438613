import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import styles from "styles/modules/moduleHeader.module.scss";
import HomeIcon from "img/homeIcon.svg";
import CloudIcon from "img/cloudIcon.svg";
import HelpIcon from "img/helpIcon.svg";
import PlayIcon from "img/playIcon.svg";
import { ModuleContext } from "contexts/ModuleContext";
import { routes, getMlrStatusIcon } from "Uitls/Util";
import { BoldText } from "components/Texts";
import { ToolTipText } from "components/TooltipText";
import OverlayPopOver from "Uitls/OverlayPopOver";

function ModuleHeader(props) {
  const { moduleHeaderData, labelData, isBussinessRulesView = false } = props;
  const { module } = useContext(ModuleContext);
  const history = useHistory();

  return (
    <div className="row align-items-center pt-1 pb-1">
      <div className="col-md-6">
        <div className={`d-flex align-items-center homeHeaderSection`}>
          <div
            className={`d-flex align-items-center pt-1 plr15 pb-1 ${styles.homeHeaderSection}`}
          >
            <div>
              <img
                src={HomeIcon}
                alt="Home"
                onClick={() => {
                  const selectTab = isBussinessRulesView ? 1 : 0;
                  history.push({
                    pathname: routes.dashboard,
                    state: {
                      selectedTab: selectTab,
                    },
                  });
                }}
                className="cursor-pointer"
              />
            </div>
            <div className={styles.homeIconDivider}>
              {/* <span className={`d-block ${styles.minHeight8}`} /> */}

              <span className="d-block">
                {labelData?.pageTitle ? labelData.pageTitle : "Create Module"}
              </span>
              <OverlayPopOver
                element={
                  <BoldText
                    className={`d-block bold600 ${styles.minHeight16} ellipssesSingleLine`}
                  >
                    {module?.moduleName}
                  </BoldText>
                }
                overlayData={
                  <ToolTipText className="scollable-tooltip customScrollBar">
                    {module?.moduleName || "-"}
                  </ToolTipText>
                }
                trigger={["hover", "focus"]}
                placement="bottom"
                classId="tooltip"
              />

              {/* <span className={`d-block ${styles.minHeight16}`} /> */}
            </div>
          </div>
          {/* <div
            className={`ml-3  ${styles.secondryLinksDivider} ${styles.secondryLinks}`}
          >
            <img src={CloudIcon} alt="Save Changes" />
            Save changes
          </div> */}
          <div
            className={`ml-3 ${styles.secondryLinks} ${styles.secondryLinksDivider}`}
          >
            <img src={HelpIcon} alt="Help" />
            Help
          </div>
          <div className={`${styles.secondryLinks}`}>
            <img src={PlayIcon} alt="Tutorials" />
            Tutorial
          </div>
        </div>
      </div>
      <div className="col-md-6 text-right">
        {module?.sourceModuleDamName && (
          <div className={`d-flex justify-content-end text-left`}>
            <div className={`${styles.headerDamsSection}`}>
              <span className="d-block">Source Content Module ID</span>
              <span
                className={` ${styles.mlrStatus} d-flex justify-content-end align-items-center font-600`}
              >
                <OverlayPopOver
                  element={
                    <img
                      src={getMlrStatusIcon(module?.sourceModuleMlrStatus)}
                      alt="Mlr Status"
                    />
                  }
                  overlayData={
                    <ToolTipText>
                      {module?.sourceModuleMlrStatus
                        ? module.sourceModuleMlrStatus
                        : "Module not Published"}
                    </ToolTipText>
                  }
                  trigger={["hover", "focus"]}
                  placement="bottom"
                  classId="tooltip"
                />
                <span
                  onClick={() => {
                    if (module?.sourceModuleDamUrl) {
                      window.open(module?.sourceModuleDamUrl, "_blank");
                    }
                  }}
                  style={{
                    color: "#203662",
                    cursor: module?.sourceModuleDamUrl ? "pointer" : "default",
                  }}
                >
                  {module?.sourceModuleDamName}
                </span>
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ModuleHeader;
