import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import FooterButtons from "components/FooterButtons";
import LinkButton from "components/LinkButton";
import PrimaryButton from "components/PrimaryButton";
import SingleLinekeyValue from "components/SingleLineKeyValue";
import variables from "styles/base/_universal.scss";
import { useTranslation } from "react-i18next";
import Table from "components/TableComponent";
import {
  fetchTextualElementsTypes,
  fetchTextualElements,
  addElementsToModule,
  fetchTopicsAndSubtopics,
  getSubTopics,
} from "services/apis";
import { showError, getDisplayNames, removeDuplicates } from "Uitls/Util";
import CircularProgressBar from "Uitls/CircularProgressBar";
import { ModuleContext } from "contexts/ModuleContext";
import FilterComponent from "components/modules/importclaims/FilterComponent";
import SwicthButton from "components/SwitchButton";
import { BoldText, NormalText } from "components/Texts";
import NoDataFound from "components/NoDataFound";

const ImportClaims = ({
  closeModalAndRefresh,
  closeModalAndGoBack,
  addedClaimIds,
  addedClaimsData,
}) => {
  const { t } = useTranslation();
  const [isloading, setIsloading] = useState(false);
  const [messageTypes, setMessageTypes] = useState([]);
  const [claims, setClaims] = useState([]);
  const { module } = useContext(ModuleContext);
  const [filteredClaims, setFilteredClaims] = useState([]);
  const [selectedClaimIds, setSelectedClaimIds] = useState(undefined);
  const [toggle, setToggle] = useState(false);
  const [searchTriggered, setSearchTrgiggered] = useState(false);
  const [dropDownData, setDropDownData] = useState(undefined);
  const [topicsData, setTopicsData] = useState([]);
  const [subTopicsData, setSubTopicsData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [addedClaims, setAddedClaims] = useState([]);
  const [totalElements, setTotalElements] = useState(0);
  const [shouldRefresh, setShouldRefresh] = useState(true);

  //filters
  const [searchInput, setSearchInput] = useState("");
  const [isResetClicked, setIsResetClicked] = useState(false);
  const resetAll = () => {
    setIsResetClicked(true);
    setDropDownData({});
    //setHasMore(true);
    setSearchInput("");
    setSearchTrgiggered(false);
    setFilteredClaims([]);
    setToggle(false);
    setCurrentPage(1);
    setTimeout(() => {
      setIsResetClicked(false);
    }, [0]);
  };

  // useEffect(() => {
  //   if (
  //     !dropDownData?.messageType &&
  //     !dropDownData?.searchText &&
  //     !dropDownData?.topic &&
  //     !dropDownData?.subtopics
  //   ) {
  //     setSearchTrgiggered(false);
  //     setCurrentPage(1);
  //     setHasMore(true);
  //   } else {
  //     setSearchTrgiggered(true);
  //     setToggle(false);
  //   }
  // }, [dropDownData]);
  // useEffect(() => {
  //   if (
  //     dropDownData?.messageType ||
  //     dropDownData?.searchText ||
  //     dropDownData?.topic ||
  //     dropDownData?.subtopics
  //   ) {
  //     getData("search");
  //   } else {
  //     getData();
  //   }
  //   return () => {
  //     setCurrentPage(1);
  //   };
  // }, [dropDownData]);

  useEffect(() => {
    if (shouldRefresh) {
      getData();
    }
  }, [shouldRefresh]);
  // useEffect(() => {
  //   if (!toggle) {
  //     resetAll();
  //   }
  // }, [toggle]);

  useEffect(() => {
    // setHasMore(true);
    checkIfFiltersPresent();
    setCurrentPage(1);
    setShouldRefresh(true);
  }, [dropDownData]);

  useEffect(() => {
    setShouldRefresh(true);
  }, [currentPage]);

  const checkIfFiltersPresent = () => {
    let filtersPresent = false;
    if (
      dropDownData &&
      (Object.keys(dropDownData?.topic || {})?.length > 0 ||
        (dropDownData?.subtopics || [])?.length > 0 ||
        Object.keys(dropDownData?.messageType || {})?.length > 0 ||
        (dropDownData?.searchText || "")?.trim()?.length > 0)
    ) {
      filtersPresent = true;
    }
    // if (!filtersPresent) {
    //   setIsResetClicked(!filtersPresent);
    // }
    setSearchTrgiggered(filtersPresent);
  };

  useEffect(() => {
    fetchTextualElementsTypes("claims/messageType", (res, err) => {
      if (res) {
        const msgTypes = res.data.map((el) => {
          return { id: el.name, displayName: el.label };
        });

        setMessageTypes(msgTypes);
      } else {
        showError(err);
      }
    });
    fetchTopicsAndSubtopics((res, err, errCode) => {
      if (res) {
        setTopicsData(res.data);
        return;
      }
    });
  }, []);

  useEffect(() => {
    if (dropDownData?.topic?.veevaId) {
      let productDamId = module.productDamId;
      let topicDamId = dropDownData?.topic.veevaId;
      const payload = {
        productDamIds: [productDamId],
        topicDamIds: [topicDamId],
      };
      setIsloading(true);
      getSubTopics(payload, (res, err) => {
        setIsloading(false);
        if (res) {
          setSubTopicsData(res.data);
          return;
        }
        showError(err);
      });
    }
  }, [dropDownData?.topic]);

  const getData = (type) => {
    if (!toggle) {
      setIsloading(true);
    }
    let filterData = {
      topicName: dropDownData?.topic?.displayName,
      subtopicNames: getDisplayNames(dropDownData?.subtopics),
      messageType: dropDownData?.messageType?.id,
      searchText: dropDownData?.searchText,
    };
    const payload = {
      product: module?.productName,
      country: module?.countryName,
      pageNo: (currentPage - 1) * 10,
      pageSize: 10,
      filterData: filterData,
    };
    // setIsloading(true);
    fetchTextualElements(payload, "claims", async (res, err) => {
      if (res) {
        let data = res.data.data;
        let claimsData = await updatedClaims(data);
        if (currentPage === 1) {
          const totalElements = res?.data?.responseDetails?.total || 0;
          // const totalPages = Math.ceil(total / 10);
          setTotalElements(totalElements);
          setHasMore(currentPage < Math.ceil(totalElements / 10));
        } else {
          setHasMore(currentPage < Math.ceil(totalElements / 10));
        }

        //setTotalPages(totalPages);

        if (currentPage === 1) {
          setFilteredClaims(claimsData);
        } else {
          setFilteredClaims(filteredClaims.concat(claimsData));
        }
      } else {
        showError(err);
      }
      setShouldRefresh(false);
      setIsloading(false);
    });
  };

  const updatedClaims = (claimsData) => {
    const updatedClaims = claimsData.map((claim) => {
      return {
        ...claim,
        description: claim.description.replace(/\n/g, ""),
      };
    });
    return updatedClaims;
  };

  const getRecentlyImported = () => {
    let importedIds = selectedClaimIds;
    if ((addedClaimIds || 0)?.length > 0) {
      importedIds = selectedClaimIds.filter((element) => {
        return !addedClaimIds.includes(element);
      });
    }

    return importedIds[0];
  };

  /**
   * Import and Add claims to the module
   */
  const importClaims = () => {
    const payload = {
      moduleId: module.moduleId,
      claimNames: selectedClaimIds,
    };
    setIsloading(true);
    addElementsToModule("claims", payload, (res, err) => {
      setIsloading(false);
      if (res) {
        closeModalAndRefresh(getRecentlyImported());
        return;
      }
      showError(err);
    });
  };

  const primaryButton = (
    <PrimaryButton
      title={"Add".toUpperCase()}
      handleClick={() => {
        importClaims();
      }}
      minWidth={110}
      disabled={
        !selectedClaimIds ||
        selectedClaimIds?.length === 0 ||
        addedClaimsData.length === selectedClaimIds?.length
      }
    />
  );
  const CancelButton = (
    <LinkButton
      title={"Cancel".toUpperCase()}
      handleClick={() => closeModalAndGoBack()}
    />
  );

  let columnHeaders = [
    {
      lable: "Claims ID",
      field: "claimId",
      width: "6rem",
    },
    {
      lable: "Match Text",
      field: "description",
    },
    {
      lable: "Topic",
      field: "topic",
      width: "8.5rem",
    },
    {
      lable: "Sub-Topic",
      field: "subtopic",
      width: "8.5rem",
    },
    {
      lable: "Message Type",
      field: "messageType",
      width: "8.5rem",
    },
  ];

  /**
   * store selected claim ids in a variable
   * @param {*} claimId claim id for the claim user selects or deselects
   * @param {*} isSelected boolean true is claim selected or false if deselected
   */
  const handleClaimSelection = (claim, isSelected) => {
    let newClaimIds = selectedClaimIds ? [...selectedClaimIds] : [];
    let addedCLaimsTempData = JSON.parse(JSON.stringify(addedClaims));
    let claimIndex = selectedClaimIds?.findIndex((addedClaimId) => {
      return addedClaimId === claim?.claimId;
    });
    if (claimIndex === undefined) {
      claimIndex = -1;
    }
    if (isSelected && claimIndex === -1) {
      newClaimIds.push(claim?.claimId);
      addedCLaimsTempData.push(claim);
    } else if (!isSelected && claimIndex > -1) {
      newClaimIds = newClaimIds.filter((addedClaimId) => {
        return addedClaimId !== claim?.claimId;
      });
      addedCLaimsTempData = addedCLaimsTempData.filter((addedClaimId) => {
        return addedClaimId.claimId !== claim?.claimId;
      });
    }
    addedCLaimsTempData = removeDuplicates(addedCLaimsTempData, "claimId");
    // setSelectedClaimIds(newClaimIds);
    setSelectedClaimIds(addedCLaimsTempData?.map((claim) => claim.claimId));
    setAddedClaims(addedCLaimsTempData);
  };
  const countMatchingItems = () => {
    const selectedCount = selectedClaimIds
      ? selectedClaimIds.filter((id) =>
          filteredClaims.some((item) => item.claimId === id)
        ).length
      : 0;

    const addedCount = addedClaimIds
      ? addedClaimIds.filter((id) =>
          filteredClaims.some((item) => item.claimId === id)
        ).length
      : 0;
    return selectedCount;
    //return parseInt(selectedCount + addedCount);
  };

  const secondaryButton = (
    <div className="d-flex">
      <NormalText>Total Selected :</NormalText>
      <BoldText fontWeight="bold" style={{ marginLeft: "2px" }}>
        {/* {parseInt(
          (addedClaimIds?.length || 0) + (selectedClaimIds?.length || 0)
        )} */}
        {addedClaims?.length || 0}
      </BoldText>
    </div>
  );

  const handleSelectAll = () => {
    if (toggle && (selectedClaimIds || [])?.length === 0) {
      return;
    }
    // if (toggle && selectedClaimIds?.length === 0) {
    //   return;
    // }
    const nonDisbaledClaims = filteredClaims.filter(
      (item) => !addedClaimIds?.includes(item.claimId)
    );
    // const allNonDisabledIds = filteredClaims
    //   .filter((item) => !addedClaimIds?.includes(item.claimId))
    //   .map((ele) => ele.claimId);
    // setSelectedClaimIds(allNonDisabledIds);
    // const allNonDisabledClaims = filteredClaims.filter(
    //   (item) => !addedClaimIds?.includes(item.claimId)
    // );
    let selectedClaims = JSON.parse(JSON.stringify(addedClaims || [])).concat(
      nonDisbaledClaims || []
    );
    selectedClaims = removeDuplicates(selectedClaims, "claimId");
    const claimIds = selectedClaims?.map((claim) => claim.claimId);
    setSelectedClaimIds(claimIds);
    setAddedClaims(selectedClaims);
  };

  useEffect(() => {
    setAddedClaims(addedClaimsData);
  }, [addedClaimsData]);

  const handlePageChange = () => {
    if (toggle) {
      return;
    }
    setCurrentPage((prev) => {
      // setShouldRefresh(true);
      return prev + 1;
    });
  };

  const getDataForTable = () => {
    return toggle ? addedClaims : filteredClaims;
  };

  const getSelectedIds = () => {
    const selectedClaims = selectedClaimIds
      ? selectedClaimIds.filter((id) =>
          filteredClaims.some((item) => item.claimId === id)
        )
      : [];

    return selectedClaims;
  };
  const getAddedClaimIds = () => {
    const addedClaims = addedClaimIds
      ? addedClaimIds.filter((id) =>
          filteredClaims.some((item) => item.claimId === id)
        )
      : [];

    return addedClaims;
  };

  const handleShowSelected = (isChecked) => {
    setToggle(isChecked);

    if (isChecked) {
      setIsResetClicked(true);
      setDropDownData({});
      setSearchInput("");
      setSearchTrgiggered(false);
      setFilteredClaims([]);
      setCurrentPage(1);
      setTimeout(() => {
        setIsResetClicked(false);
      }, [0]);
    } else {
      resetAll();
    }

    // setIsResetClicked(true);
    // setDropDownData({});
    // setSearchInput("");
    // setSearchTrgiggered(false);
    // setFilteredClaims([]);
    // setCurrentPage(1);
    // setTimeout(() => {
    //   setIsResetClicked(false);
    // }, [0]);
  };

  return (
    <>
      <Container>
        <CircularProgressBar isloading={isloading} />
        <div className="row" style={{ marginBottom: "6px" }}>
          <div className="col-md-6">
            <TitleText>{t("importClaims.title")}</TitleText>
            <SubTitle>{t("importClaims.subTitle")}</SubTitle>
          </div>
          <div className="col-md-6 d-flex justify-content-end">
            <MetaInfoContainer>
              <SingleLinekeyValue
                label="Primary Product"
                value={module?.productName}
              />
            </MetaInfoContainer>
            <MetaInfoContainer>
              <SingleLinekeyValue
                label="Primary Country"
                value={module?.countryName}
              />
            </MetaInfoContainer>
          </div>
        </div>
        <FilterComponent
          messageTypes={messageTypes}
          setSearchTrgiggered={setSearchTrgiggered}
          searchTriggered={searchTriggered}
          toggle={toggle}
          setFilteredClaims={setFilteredClaims}
          claims={claims}
          setToggle={setToggle}
          setDropDownData={setDropDownData}
          dropDownData={dropDownData}
          topicsData={topicsData}
          subTopicsData={subTopicsData}
          setCurrentPage={setCurrentPage}
          setHasMore={setHasMore}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          isResetClicked={isResetClicked}
          setIsResetClicked={setIsResetClicked}
          resetAll={resetAll}
        />
      </Container>
      <ContainerBody>
        <>
          <div className="row" style={{ marginRight: "16px" }}>
            <div className="col-md-6 d-flex">
              <BoldText fontWeight="bold" style={{ marginRight: "2px" }}>
                {toggle
                  ? "Total Selected"
                  : searchTriggered
                  ? "Search Result"
                  : "All"}
              </BoldText>
              <NormalText>
                (
                {
                  toggle
                    ? addedClaims?.length || 0
                    : // parseInt(
                    //     (addedClaimIds?.length || 0) +
                    //       (selectedClaimIds?.length || 0)
                    //   )
                    searchTriggered
                    ? countMatchingItems()
                    : addedClaims?.length || 0
                  // parseInt(
                  //     (addedClaimIds?.length || 0) +
                  //       (selectedClaimIds?.length || 0)
                  //   )
                }
                {toggle
                  ? ""
                  : searchTriggered
                  ? `/${totalElements} selected`
                  : `/${totalElements} selected`}
                )
              </NormalText>
            </div>
            <div className="col-md-6 d-flex justify-content-end pr-0">
              <SwicthButton
                label="Show All Selected"
                checked={toggle}
                handleToggle={(isChecked) => {
                  // setToggle(!toggle);
                  handleShowSelected(isChecked);
                }}
              />
            </div>
          </div>
          <div key={toggle ? 1 : 0}>
            <Table
              rows={getDataForTable()}
              headers={columnHeaders}
              onCheckboxChange={(claim, isSelected) => {
                handleClaimSelection(claim, isSelected);
              }}
              unselectAll={(checked) => {
                if (checked) {
                  handleSelectAll();
                } else {
                  setSelectedClaimIds(undefined);
                  setAddedClaims(addedClaimsData);
                }
              }}
              selectedRows={toggle ? selectedClaimIds : getSelectedIds()}
              disabledIds={toggle ? addedClaimIds : getAddedClaimIds()}
              hasMore={hasMore}
              handlePageChange={handlePageChange}
              isloading={isloading}
              toggleState={toggle}
            />
          </div>
          {filteredClaims.length === 0 && !toggle && !shouldRefresh ? (
            <NoDataFound
              customClass={true}
              customClassNoResult={true}
              title={"No Result Found"}
              Description={"Try searching using different parameter(s)"}
            />
          ) : null}
        </>
      </ContainerBody>
      <FooterButtons
        primaryButton={primaryButton}
        intermediateButtons={[CancelButton]}
        secondaryButton={secondaryButton}
      />
    </>
  );
};
const Container = styled.div`
  padding: 0px 20px 0px 20px;
  border-bottom: solid 1px #e6e6ec;
`;
const TitleText = styled.span`
  font-size: ${variables.fontSmallV2};
  font-weight: bold;
  color: ${variables.blackColor};
`;
const MetaInfoContainer = styled.div`
  margin-left: 16px;
`;
const ContainerBody = styled.div`
  padding: 12px 4px 0px 20px;
  margin-right: 4px;
`;
const SubTitle = styled.span`
  font-size: ${variables.fontSmall};
  font-weight: normal;
  color: ${variables.subtitleColor};
  display: block;
`;
export default ImportClaims;
