import React, {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";
import variables from "styles/base/_universal.scss";
import FooterButtons from "components/FooterButtons";
import PrimaryButton from "components/PrimaryButton";
import { useTranslation } from "react-i18next";
import LinkButton from "components/LinkButton";

import OutsideClickHandler from "react-outside-click-handler";
import { costomEllipsis } from "Uitls/Util";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import RoundedSearchTextField from "components/RoundedSearchTextField";
import styled from "styled-components";
import MultiSelectList from "./MultiSelectList";
//import { OutsideClickHandler } from "Uitls/Util";
const MultiSelectDropDownWithInlineSearch = (props) => {
  const [optionsToDisplay, setOptionsToDisplay] = useState(props.options.items);
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    getfilteredOptions(props.options.items, searchText);
  }, [searchText, props.options.items]);

  useEffect(() => {
    if (!open && searchText?.length > 0) {
      setSearchText("");
    }
  }, [open]);

  const useStyles = makeStyles((theme) => ({
    popupIndicator: {
      "& span": {
        "& svg": {
          "& path": {
            d: "path('M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z')",
          },
        },
      },
    },
    truncatedChip: {
      display: "inline-flex",
      alignItems: "center",
      width: "auto",
      height: "20px",
      padding: "2px !important",
      margin: "0 1px 4px 0",
      borderRadius: "10px",
      backgroundColor: "transparent", //variables.paleGrayColor,
      // color: variables.searchTextColor,
      fontSize: "12px",
      fontWeight: variables.fontWeight600,
    },
    selectedChip: {
      backgroundColor: "#FFFFFF",
      margin: "0",
      fontSize: "13px",

      //color: variables.searchTextColor,
    },
    paper: {
      // border: "5px solid black",
      overflowY: "hidden",
      fontSize: variables.fontSmall,

      "& MuiPaper-root": {
        overflowY: "hidden",
      },
    },
    autocomplete: {
      //overflowX: "hidden",

      "& .MuiAutocomplete-inputRoot": {
        height: "42px",
        marginBottom: "16px",
        fontSize: variables.fontSmall,
      },
      "& .MuiChip-deleteIcon": {
        display: "none",
      },
      "& .MuiFilledInput-root": {
        backgroundColor: `${variables.whiteColor}`,
        height: "42px",
        fontSize: `${variables.fontSmall}`,
        border: `solid 1px ${variables.borderColor}`,
        paddingRight: "0px !important",
        borderRadius: "6px",
        paddingBottom: "0px",
        paddingTop: "4px",
      },
      "& .MuiFormControl-root": {
        // marginTop: "16px",
      },

      "& .MuiFilledInput-root:hover": {
        backgroundColor: `${variables.whiteColor}`,
      },

      "& .MuiFilledInput-input": {
        //padding: 0;
        lineHeight: "4.5",
        height: "42px",
        transition: "none",
        paddingRight: "5px",
        padding: "0px !important",
        color: variables.searchTextColor,
        //display: "flex",
      },
      "& .MuiInputLabel-formControl": {
        top: "-4px",
      },
      "& .MuiFilledInput-underline :focus": {
        borderBottom: "none",
      },
      "& .MuiFilledInput-underline:before": {
        borderBottom: "none",
      },
      "& .MuiFilledInput-underline:after": {
        borderBottom: "none",
      },
      "& .MuiFilledInput-underline:hover:before": {
        borderBottom: "none",
      },
      "& .MuiSelect-select:focus": {
        borderRadius: "6px",
      },
      "& .MuiInputLabel-filled.MuiInputLabel-shrink": {
        transform: " translate(9px, 15px) scale(0.75)",
      },
      "& .MuiInputBase-root.Mui-disabled": {
        border: "none !important",
      },

      "& .MuiChip-label": {
        paddingLeft: "0px",
        paddingTop: "10px",
        paddingRight: "0px",
        fontWeight: variables.fontWeight600,
        color: `${
          props.options.disabled
            ? variables.disabledTextColor
            : variables.searchTextColor
        } !important`,
      },
      "& .MuiAutocomplete-clearIndicator": {
        display: "none",
      },
      "& .MuiAutocomplete-inputFocused": {
        //display: open ? "flex" : "none",
        display: "flex",
      },
      "& .MuiAutocomplete-tag": {
        backgroundColor: "transparent",
      },
      "& .MuiFormLabel-asterisk": {
        color: "#e32b35",
      },
      "& .MuiChip-root .MuiChip-label": {
        color: `${
          props.options.disabled
            ? variables.disabledTextColor
            : variables.searchTextColor
        } !important`,
      },
    },
  }));

  const classes = useStyles();

  const getfilteredOptions = useCallback((data, searchText) => {
    data = data.filter((el) => {
      return el?.displayName?.toLowerCase().includes(searchText?.toLowerCase());
    });

    setOptionsToDisplay(() => {
      return data;
    });

    // return data;
  }, []);

  const updateOpenState = useCallback((isopen) => {
    setOpen(() => {
      return isopen;
    });
  }, []);

  return (
    <div>
      <OutsideClickHandler
        onOutsideClick={() => {
          const element = document.getElementsByClassName(
            "MuiAutocomplete-noOptions"
          );
          if (
            element?.length > 0 &&
            element[0]?.className === "MuiAutocomplete-noOptions"
          ) {
            updateOpenState(false);
          }

          // setSelected(props.options.value || []);
        }}
      >
        <Autocomplete
          key="multi-select-dropdown"
          disabled={props.options.disabled}
          limitTags={1}
          id="search-and-select"
          style={{ width: "100%" }}
          open={open}
          onOpen={() => {
            updateOpenState(true);
          }}
          options={optionsToDisplay}
          value={props.options.value || []}
          className={`${classes.autocomplete} ${classes.paper} ${classes.dropdownMenu}  ${classes.popupIndicator}`}
          getOptionLabel={(option) => option.displayName}
          getOptionSelected={(option, value) => {
            return option.id === value.id;
          }}
          getOptionDisabled={(option) => option.isNotSelectable}
          renderInput={(params) => (
            <TextField
              id="tf-mui-search-select"
              {...params}
              label={props.options.label}
              variant="filled"
              placeholder={
                props.options.value?.length > 0
                  ? ""
                  : t("generalTexts.searchAndSelect")
              }
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              required={props.options.required}
              inputProps={{
                ...params.inputProps,
                autoComplete: "off",
                value: searchText,
                // style: { display: !open ? "none" : "block" },
              }}
            />
          )} //
          renderTags={(value, getTagProps) => {
            if (!open && value?.length > 0) {
              const selectedOptions = value?.slice(0, 1);
              const truncatedOptions = value?.slice(1);
              const label = `${
                selectedOptions[0].displayName?.length > 15
                  ? costomEllipsis(selectedOptions[0].displayName, 15)
                  : selectedOptions[0].displayName
              }
            ${truncatedOptions.length ? " +" + truncatedOptions.length : ""}`;

              return [
                ...selectedOptions.map((option, index) => (
                  <>
                    <span
                      style={{
                        color: props.options.disabled
                          ? variables.disabledTextColor
                          : variables.searchTextColor,
                      }}
                    >
                      {selectedOptions[0].displayName?.length > 15
                        ? costomEllipsis(selectedOptions[0].displayName, 15)
                        : selectedOptions[0].displayName}
                    </span>
                    <Chip
                      key={option.displayName}
                      label={
                        truncatedOptions.length
                          ? " +" + truncatedOptions.length
                          : ""
                      }
                      // classes={{ root: classes.selectedChip }}
                      {...getTagProps({ index })}
                    />
                  </>
                )),
              ];
            }

            return null;
          }}
          multiple
          disableCloseOnSelect={true}
          ListboxComponent={(listboxProps) => (
            <ListboxComponentCustom
              {...listboxProps}
              updateOpenState={updateOpenState}
              alreadySelected={props?.options?.value}
              optionsToDisplay={optionsToDisplay || []}
              open={open}
              applySelection={(selectedValues) => {
                return props?.options?.handleChange(selectedValues);
              }}
            />
          )}
        />
      </OutsideClickHandler>
    </div>
  );
};

export default MultiSelectDropDownWithInlineSearch;

const ListboxComponentCustom = ({
  updateOpenState,
  alreadySelected,
  optionsToDisplay,
  open,
  applySelection,
}) => {
  const ref = useRef(null);
  const { t } = useTranslation();
  const [selected, setSelected] = useState(alreadySelected || []);

  useEffect(() => {
    setSelected(() => {
      return alreadySelected || [];
    });
  }, [alreadySelected]);

  const shouldApplyBeDisbaled = () => {
    return (
      (!alreadySelected || alreadySelected?.length === 0) &&
      selected?.length === 0
    );
  };

  const primaryButton = (
    <PrimaryButton
      title={t("generalTexts.apply")}
      handleClick={() => {
        applySelection(selected);
        updateOpenState(false);
      }}
      disabled={shouldApplyBeDisbaled()}
    />
  );

  const secondaryButton = (
    <LinkButton
      title={t("generalTexts.clear")}
      handleClick={() => {
        setSelected([]);
        // props.options.handleChange([]);
      }}
      disabled={selected?.length === 0}
    />
  );

  const handleChange = useCallback((isChecked, option) => {
    return setSelected((value) => {
      const index = value?.findIndex((el) => {
        return el.id === option.id;
      });
      if (isChecked) {
        if (index === -1) {
          value = (value || []).concat(option);
        }
      } else if (index > -1) {
        value = value?.filter((item) => item.id !== option.id);
      }
      return value;
    });
  }, []);

  return (
    <OutsideClickHandler
      ref={ref}
      onOutsideClick={() => {
        updateOpenState(false);
        setSelected(alreadySelected || []);
      }}
      key="multi-select-list-drp-dwn"
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <MultiSelectList
          data={optionsToDisplay}
          open={open}
          enableSearch={false}
          handleSelection={(isChecked, option) => {
            handleChange(isChecked, option);
          }}
          selected={selected}
          height="200px"
        />
        <FooterButtons
          primaryButton={primaryButton}
          secondaryButton={secondaryButton}
          size="sm"
        />
      </div>
    </OutsideClickHandler>
  );
};
