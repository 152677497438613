import React from "react";
import PrimaryButton from "components/PrimaryButton";
import LinkButton from "components/LinkButton";
import FooterButtons from "components/FooterButtons";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { BoldText } from "./Texts";
import variables from "styles/base/_universal.scss";

const ConfirmationPopup = ({
  title,
  text,
  closeModal,
  handleConfirm,
  primaryButtonTitle,
  isCancelNeeded = true,
}) => {
  const { t } = useTranslation();
  const ConfirmButton = (
    <PrimaryButton
      title={primaryButtonTitle || t("generalTexts.yes").toUpperCase()}
      handleClick={handleConfirm}
      minWidth="110"
    />
  );
  const CancelButton = (
    <LinkButton
      title={t("generalTexts.no").toUpperCase()}
      handleClick={closeModal}
    />
  );

  return (
    <div>
      <TitleDiv>{title}</TitleDiv>
      <Divider />
      <ContentDiv>{text}</ContentDiv>
      <FooterButtons
        primaryButton={ConfirmButton}
        secondaryButton={isCancelNeeded ? CancelButton : undefined}
      />
    </div>
  );
};

const TitleDiv = styled(BoldText)`
  font-size: ${variables.fontSmallV2};
  font-weight: bold;
  text-transform: uppercase;
  margin: 5px 20px 20px 20px;
`;
const Divider = styled.div`
  height: 1px;
  background-color: ${variables.borderColor};
`;
const ContentDiv = styled(BoldText)`
  font-size: ${variables.fontSmallV2};
  font-weight: ${variables.fontWeight600};

  margin: 12px 20px 40px 20px;
`;

export default ConfirmationPopup;
