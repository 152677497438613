import React, { useState, useEffect } from "react";
import "../styles/components/DashboardFilter.scss";
import NotifyIcon from "img/group-1230.svg";
import NoNotifyIcon from "img/group-179854.svg";
import OverlayPopOverToolTip from "Uitls/OverlayPopOverToolTip";
import { ToolTipText } from "components/TooltipText";
import nextIcon from "img/gileadLogo.svg";
import dotsIcon from "img/dotsIcon.svg";
import dropdownEmailIcon from "img/dropdownEmailIcon.svg";
import dropdownEdetailIcon from "img/dropdownEdetailIcon.svg";
import dropdownBannerIcon from "img/dropdownBannerIcon.svg";

import { useKeycloak } from "@react-keycloak/web";
import { Dropdown } from "react-bootstrap";
import LogoutDropdownCard from "components/LogoutDropdownCard";
import NotificationsSidebar from "components/dashboard/ModuleDashbaord/NotificationsSidebar";
import { getExpiryNotificationData } from "services/apis";
import { showError, emitter } from "Uitls/Util";
import OverlayPopOver from "Uitls/OverlayPopOver";
const DashboardHeader = () => {
  const { keycloak } = useKeycloak();
  const [value, setValue] = useState("");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [unreadNotificationsCount, setUnreadNotificationsCount] =
    useState(undefined);
  const [expiryNotificationData, setExpiryNotificationData] = useState([]);
  useEffect(() => {
    getExpiryDataOfMCRT("");
  }, []);
  const getExpiryDataOfMCRT = (type) => {
    getExpiryNotificationData(type, (res, err) => {
      if (res) {
        let unreadCount = res?.data?.unReadCount;
        let expiryData = res?.data;
        setUnreadNotificationsCount(unreadCount);
        setExpiryNotificationData(expiryData);
        return;
      }
      showError(err);
    });
  };

  useEffect(() => {
    const handleFooEvent = (e) => {
      try {
        const parsedData = JSON.parse(e);
        if (parsedData.mlrStatus?.trim().toLowerCase().includes("expired")) {
          getExpiryDataOfMCRT("");
        }
      } catch (error) {
        console.error("Received non-JSON message:", e);
      }
    };

    emitter.on("autoSync", handleFooEvent);

    return () => {
      emitter.off("autoSync", handleFooEvent);
    };
  }, []);

  const getFirstLetters = () => {
    const userName = !!keycloak.authenticated
      ? keycloak.tokenParsed.name
      : null;
    if (!userName) return "";
    const nameArray = userName.split(" ");
    const firstLetters = nameArray.map((name) => name.charAt(0));
    return firstLetters.join("");
  };

  const getUserNameEmail = () => {
    const userName = !!keycloak.authenticated ? keycloak : null;
    return userName;
  };
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      className="dashBoardIcon"
      title="NCCA Apps"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    ></div>
  ));

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen); // Toggle sidebar open/close state
  };

  const logoutButton = React.forwardRef(({ children, onClick }, ref) => (
    <div
      title="Logout"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <div className="userName"> {getFirstLetters()}</div>
    </div>
  ));

  // Dropdown needs access to the DOM of the Menu to measure it
  const logoutMenu = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      return (
        <div
          ref={ref}
          style={style}
          className={`pt15 pb15 ${className}`}
          aria-labelledby={labeledBy}
        >
          <ul className="list-unstyled appDropdown">
            {React.Children.toArray(children).filter(
              (child) =>
                !value || child.props.children.toLowerCase().startsWith(value)
            )}
          </ul>
        </div>
      );
    }
  );
  // forwardRef again here!
  // Dropdown needs access to the DOM of the Menu to measure it
  const CustomMenu = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <ul className="list-unstyled appDropdown">
            {React.Children.toArray(children).filter(
              (child) =>
                !value || child.props.children.toLowerCase().startsWith(value)
            )}
          </ul>
        </div>
      );
    }
  );

  return (
    <div>
      <div className="row dashboard-header align-items-center">
        <div className="col-md-6">
          <div className="d-flex">
            <a href="https://contentstudio.gilead.com">
              <div className="dashBoardIcon" title="NCCA Apps"></div>
            </a>
            <img src={nextIcon} alt="" className="img-fluid nextIcon" />
          </div>
        </div>
        <div className="col-md-6">
          <div className="d-flex justify-content-end align-items-center">
            <div>
              <span className="sourceText ">Source</span>
              <div className="promotext ">PromoMats</div>
            </div>
            <div className="ml16">
              <OverlayPopOverToolTip
                element={
                  <img
                    src={
                      unreadNotificationsCount > 0 ? NotifyIcon : NoNotifyIcon
                    }
                    alt=""
                    className="img-fluid bell-icon"
                    onClick={toggleSidebar}
                  />
                }
                overlayData={
                  <ToolTipText className="tooltip-text-Bell-icon">
                    Click to view notifications for expired Elements, Modules
                    and Materials in the last 30 days.
                  </ToolTipText>
                }
                trigger={["hover", "focus"]}
                placement="bottom"
                classId="tooltip"
              />
            </div>
            {/* <div>
              <Dropdown>
                <Dropdown.Toggle
                  className="userLogin"
                  id="userLogout"
                  drop="left"
                >
                  <div className="userName"> {getFirstLetters()}</div>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <ul className="list-unstyled appDropdown">
                    <LogoutDropdownCard
                      getFirstLetters={getFirstLetters}
                      getUserNameEmail={getUserNameEmail()}
                    />
                  </ul>
                </Dropdown.Menu>
              </Dropdown>
            </div> */}
            <div>
              <Dropdown className="logOutButton">
                <Dropdown.Toggle
                  as={logoutButton}
                  drop="left"
                  id="logoutButton"
                />
                <Dropdown.Menu as={logoutMenu}>
                  <LogoutDropdownCard
                    getFirstLetters={getFirstLetters}
                    getUserNameEmail={getUserNameEmail()}
                  />
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
      {/* Notifications Sidebar */}
      {isSidebarOpen && (
        <NotificationsSidebar
          isOpen={isSidebarOpen}
          toggleSidebar={toggleSidebar}
          expiryNotificationData={expiryNotificationData}
          getExpiryDataOfMCRT={getExpiryDataOfMCRT}
        />
      )}
    </div>
  );
};

export default DashboardHeader;
