import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { logoutUser } from "../keycloak/keyCloakConfig";

function UnAuthorizeModal(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const onLogout = () => {
    logoutUser();
    history.push("/");
  };
  useEffect(() => {
    setTimeout(() => {
      logoutUser();
      history.push("/");
    }, 6000);
  });
  return (
    <>
      <p className="notificationHeading">IMC</p>
      <p className="notificationText">{t("apiErrors.err403")}</p>
      <button onClick={onLogout} className="btn btn-link pl-0">
        {t("generalTexts.loginAgain")}
      </button>
    </>
  );
}

export default UnAuthorizeModal;
