import React from "react";
import styles from "styles/components/OutlineButton.module.scss";

const OutlineButton = ({
  btnLabel,
  icon,
  handleClick,
  disabled,
  minWidth,
  marginLeft,
  iconSize,
}) => {
  return (
    <button
      onClick={handleClick}
      disabled={disabled}
      className={`btn btn-block ${styles.btnOutline}`}
      type="button"
    >
      {icon && <img src={icon} alt={btnLabel} width={iconSize} />} {btnLabel}
    </button>
  );
};

export default OutlineButton;
