import React, { useEffect, useState } from "react";
import IconAdvanceFilter from "img/advance-filter.svg";
import FooterButtons from "components/FooterButtons";
import variables from "styles/base/_universal.scss";
import styled from "styled-components";
import CustomModal from "components/customModal";
import LinkButton from "components/LinkButton";
import PrimaryButton from "components/PrimaryButton";
import MultiSelectList from "components/MultiSelectList";
import TransRoundedButtonWithArrow from "components/TransRoundedButtonWithArrow";
import { getSubTopics } from "services/apis";
import { capitalizeFirstLetterOfEachWord, showError } from "Uitls/Util";
import { fecthSubTopicsFilter } from "dashboard/filters/SubTopicsFilter";
import CircularProgressBar from "Uitls/CircularProgressBar";

const AdvanceFilter = ({
  title = "Filters",
  data,
  addedFilters,
  applySelection,
}) => {
  const [isOpen, toggleIsOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(undefined);
  const [filtersData, setFiltersData] = useState({});
  const [filteredList, setFilteredList] = useState({});
  const [isloading, setIsloading] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const [searchText, setSearchText] = useState(undefined);

  useEffect(() => {
    setFiltersData(addedFilters);
    let count = getFilterCount(addedFilters);
    setNotificationCount(count);
  }, [addedFilters]);

  const getFilterCount = (dataObject) => {
    let result = [];
    if (dataObject["primary product"]?.length > 0) {
      result = result.concat(...dataObject["primary product"]);
    }
    if (dataObject["primary country"]?.length > 0) {
      result = result.concat(...dataObject["primary country"]);
    }
    if (dataObject?.topic?.length > 0) {
      result = result.concat(...dataObject?.topic);
    }
    if (dataObject["sub-topic"]?.length > 0) {
      if (dataObject?.topic?.length === 0 || dataObject?.topic === undefined) {
        dataObject["sub-topic"] = [];
        result = result.concat(...dataObject["sub-topic"]);
      } else {
        result = result.concat(...dataObject["sub-topic"]);
      }
    }
    if (dataObject?.indication?.length > 0) {
      result = result.concat(...dataObject?.indication);
    }
    if (dataObject?.language?.length > 0) {
      result = result.concat(...dataObject?.language);
    }
    if (dataObject["therapeutic area"]?.length > 0) {
      result = result.concat(...dataObject["therapeutic area"]);
    }
    if (dataObject?.segment?.length > 0) {
      result = result.concat(...dataObject?.segment);
    }
    if (dataObject["strategic objective"]?.length > 0) {
      result = result.concat(...dataObject["strategic objective"]);
    }
    if (dataObject["mlr status"]?.length > 0) {
      result = result.concat(...dataObject["mlr status"]);
    }
    return result.length;
  };

  const getFilterCounts = (dataObject, filterType) => {
    let count = 0;
    switch (filterType) {
      case "Primary Product":
        count = dataObject["primary product"]?.length || 0;
        break;
      case "Primary Country":
        count = dataObject["primary country"]?.length || 0;
        break;
      case "Topic":
        count = dataObject?.topic?.length || 0;
        break;
      case "Sub-topic":
        count = dataObject["sub-topic"]?.length || 0;
        break;
      case "Indication":
        count = dataObject?.indication?.length || 0;
        break;
      case "Language":
        count = dataObject?.language?.length || 0;
        break;
      case "Therapeutic Area":
        count = dataObject["therapeutic area"]?.length || 0;
        break;
      case "Segment":
        count = dataObject?.segment?.length || 0;
        break;
      case "Strategic Objective":
        count = dataObject["strategic objective"]?.length || 0;
        break;
      case "MLR Status":
        count = dataObject["mlr status"]?.length || 0;
        break;
      default:
        break;
    }
    return count;
  };

  useEffect(() => {
    if (data) {
      const keys = Object.keys(data);
      if (keys?.length > 0) {
        setSelectedFilter(keys[0].toUpperCase());
        setFilteredList(data);
      }
    }
  }, [data]);

  const toggleOpenState = () => {
    toggleIsOpen((prevState) => {
      return !prevState;
    });
  };

  const primaryButton = (
    <PrimaryButton
      title="APPLY"
      handleClick={() => {
        setSearchText(undefined);
        applySelection(filtersData);
        toggleOpenState();
      }}
      disabled={
        getFilterCount(filtersData) > 0 || getFilterCount(addedFilters) > 0
          ? false
          : true
      }
    />
  );
  const cancelButton = (
    <LinkButton
      title="CANCEL"
      handleClick={() => {
        handleCancel();
      }}
    />
  );

  const getFilterTypes = () => {
    return Object.keys(data).map((key) => {
      return key === "mlr status"
        ? "MLR Status"
        : capitalizeFirstLetterOfEachWord(key);
    });
  };
  const handleSelection = (isChecked, element, key) => {
    let index = filtersData[key]?.findIndex((el) => el.id === element.id);
    if (index === undefined) {
      index = -1;
    }
    if (isChecked && index === -1) {
      let oldData = { ...filtersData };
      if (oldData[key]) {
        oldData[key] = oldData[key].concat(element);
      } else {
        oldData[key] = [element];
      }
      setFiltersData(oldData);
    } else if (!isChecked && index > -1) {
      setFiltersData((oldData) => {
        return {
          ...oldData,
          [key]: oldData[key].filter((el) => {
            return el.id !== element.id;
          }),
        };
      });
    }
  };

  const searchResults = (searchText, key) => {
    let newData = { ...data };

    // Filter by Search Input
    searchText = searchText?.toLowerCase();
    if (searchText && searchText.trim()) {
      newData[key] = newData[key].filter((item) => {
        return item.displayName.toLowerCase().includes(searchText);
      });
    }
    setFilteredList(newData);
  };

  useEffect(() => {
    if (
      filtersData["primary product"]?.length > 0 &&
      filtersData?.topic?.length > 0
    ) {
      let productDamId = filtersData["primary product"].map((item) => {
        return item.veevaId;
      });
      let topicDamId = filtersData?.topic.map((item) => {
        return item.veevaId;
      });
      getSubTopicsData(productDamId, topicDamId);
    }
  }, [filtersData["primary product"], filtersData?.topic]);
  useEffect(() => {
    if (filtersData?.topic?.length === 0) {
      let newFilterData = { ...filtersData };
      newFilterData["sub-topic"] = [];
      data["sub-topic"] = [];
      setFiltersData(newFilterData);
    }
  }, [filtersData?.topic]);

  const getSubTopicsData = (productDamIds, topicDamIds) => {
    setIsloading(true);
    const payload = {
      productDamIds: productDamIds,
      topicDamIds: topicDamIds,
    };
    getSubTopics(payload, (res, err) => {
      setIsloading(false);
      if (res) {
        data["sub-topic"] = fecthSubTopicsFilter(res.data, filtersData?.topic);
        return;
      } else {
        showError(err);
      }
    });
  };

  const handleSearch = (type) => {
    switch (type) {
      case "search":
        searchResults(searchText, selectedFilter?.toLowerCase());
        break;
      case "searchBarEmpty":
        searchResults("", selectedFilter?.toLowerCase());
        setSearchText(undefined);
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    searchResults(searchText, selectedFilter?.toLowerCase());
  }, [selectedFilter]);
  const shouldEnableSubTopic = () => {
    return filtersData?.topic?.length > 0;
  };
  const handleCancel = () => {
    setSearchText(undefined);
    setFiltersData(addedFilters);
    if (data) {
      const keys = Object.keys(data);
      if (keys?.length > 0) {
        setSelectedFilter(keys[0].toUpperCase());
        setFilteredList(data);
      }
    }
    toggleOpenState();
  };
  return (
    <div>
      <div className={`filterContainer`}>
        <FilterButtonDiv
          onClick={() => {
            toggleOpenState();
          }}
        >
          {" "}
          {title}
          <img src={IconAdvanceFilter} alt="" style={{ marginLeft: "6px" }} />
          {notificationCount > 0 && (
            <NotificationCount>{notificationCount}</NotificationCount>
          )}
        </FilterButtonDiv>
        <CustomModal
          displayModal={isOpen}
          hideModal={toggleOpenState}
          title=""
          size="md"
          dilogClassName="alertModalSmall"
        >
          <HeaderDiv className="row ml-0 mr-0">
            <div className="col-md-6 d-flex align-items-center p-0">
              <span>
                <img src={IconAdvanceFilter} alt="" />
              </span>
              <span>Filters</span>
              <span> ({getFilterCount(filtersData)})</span>
            </div>
            <div className="col-md-6 d-flex justify-content-end p-0">
              <LinkButton
                title="Clear All"
                className="zeroHeight"
                handleClick={() => {
                  setFiltersData({ createdByMe: filtersData.createdByMe });
                  setSearchText(undefined);
                  handleSearch("searchBarEmpty");
                  //applySelection({ createdByMe: addedFilters.createdByMe });
                }}
                disabled={getFilterCount(filtersData) > 0 ? false : true}
              />
            </div>
          </HeaderDiv>
          <div className="row ml-0 mr-0">
            <CircularProgressBar isloading={isloading} />
            <FilterTypesCol className="col-md-5 custom-scroll-bar">
              {getFilterTypes().map((filterType, index) => {
                return (
                  <TransRoundedButtonWithArrow
                    key={title + index}
                    title={
                      filterType +
                      (filtersData[filterType.toLocaleLowerCase()]?.length
                        ? " (" +
                          filtersData[filterType.toLocaleLowerCase()]?.length +
                          ")"
                        : "")
                    }
                    width="100%"
                    selected={
                      filterType?.toLowerCase() ===
                      selectedFilter?.toLowerCase()
                    }
                    handleClick={() => {
                      setSearchText(undefined);
                      setSelectedFilter(filterType);
                    }}
                    disabled={
                      filterType.toLocaleLowerCase() === "sub-topic" &&
                      shouldEnableSubTopic()
                        ? false
                        : filterType.toLocaleLowerCase() !== "sub-topic"
                        ? false
                        : true
                    }
                  />
                );
              })}
            </FilterTypesCol>
            <FilterList className="col-md-7">
              <MultiSelectList
                searchResults={(value) => {
                  if (value.trim() === 0 || value.trim() === "") {
                    setSearchText(undefined);
                    handleSearch("searchBarEmpty");
                  } else {
                    setSearchText(value);
                  }
                }}
                key={selectedFilter}
                data={filteredList[selectedFilter?.toLowerCase()] || []}
                open={true}
                handleSelection={(checked, value) =>
                  handleSelection(checked, value, selectedFilter?.toLowerCase())
                }
                selected={filtersData[selectedFilter?.toLowerCase()] || []}
                searchOptions={{
                  placeholder: "Type here",
                  searchButtonTitle: "Search",
                }}
                height="300px"
                inlineButton={true}
                handleSearch={handleSearch}
                inputValue={searchText}
                placeholder="Type here"
              />
            </FilterList>
          </div>
          <FooterButtons
            primaryButton={primaryButton}
            intermediateButtons={[cancelButton]}
          />
        </CustomModal>
      </div>
    </div>
  );
};

const FilterButtonDiv = styled.div`
  position: relative;
  padding: 6px 15px 8px;
  font-size: ${variables.fontSmall};
  border-radius: 16px;
  font-weight: ${variables.fontWeight600};
  text-wrap: nowrap;
  cursor: pointer;
  height: 32px;
  border: solid 1px ${variables.borderColor};
  margin: 0px 0px 0px 20px;
`;

const NotificationCount = styled.span`
  content: "";
  position: absolute;
  top: -5px;
  right: -1px;
  width: 0.9rem;
  height: 0.9rem;
  background-color: #024ea2;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 10px;
`;

const HeaderDiv = styled.div`
  background-color: ${variables.whiteColor};
  border-bottom: solid 1px ${variables.borderColor};
  font-size: ${variables.fontSmallV2};
  font-weight: bold;
  // padding-bottom: 16px;
  // padding-top: 5px;
  padding: 5px 20px 16px 20px;
  color: ${variables.blackColor};
  & > div > span:not(:last-child) {
    margin-right: 6px;
  }
  .zeroHeight {
    height: 0;
  }
`;

const FilterList = styled.div`
  border-left: solid 1px ${variables.borderColor};
  padding: 0px 4px !important;
`;

const FilterTypesCol = styled.div`
  // border-right: solid 1px ${variables.borderColor};
  height: 332px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 12px 12px 0px 20px;
`;

export default AdvanceFilter;
