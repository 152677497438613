import { sortObjects } from "Uitls/Util";
import MultiselectDropDown from "components/MultiselectListDropDown";
import React from "react";
import { metaDataTypes } from "Uitls/Util";
export const getSubTopicsFilterData = (data, selectedTopics) => {
  let topics = JSON.parse(JSON.stringify(selectedTopics || []));
  if (topics?.length > 0) {
    const topicIds = topics?.map((topic) => {
      return topic.id;
    });
    topics = JSON.parse(JSON.stringify(data))?.filter((el) => {
      return topicIds.includes(el.id);
    });
    if (topics?.length > 0) {
      let subTopics = [];
      topics.forEach((topic) => {
        subTopics = subTopics.concat(topic.subtopics);
      });
      if (subTopics?.length > 0) {
        subTopics = subTopics.map((subTopic) => {
          return {
            id: subTopic.id,
            displayName: subTopic.subtopicName || subTopic.name,
          };
        });
        return sortObjects(subTopics, "displayName");
      }
    }
  }

  return [];
};

export const fecthSubTopicsFilter = (data, selectedTopics) => {
  if (data.length > 0) {
    return data.map((subtopic, index) => {
      return {
        id: index,
        displayName: subtopic.subtopicName,
        veevaId: subtopic.subtopicDamId,
        metaDataType: metaDataTypes.subTopic,
      };
    });
  }
  return [];
};

const SubTopicsFilter = ({
  data,
  selectedTopics,
  handleFilter,
  selectedFilterData,
  selectedProducts,
}) => {
  return (
    <MultiselectDropDown
      title="Sub Topic"
      data={fecthSubTopicsFilter(data, selectedTopics)}
      applySelection={(data) => {
        handleFilter("sub-topic", data);
      }}
      disabled={
        !selectedTopics ||
        selectedTopics?.length === 0 ||
        !selectedProducts ||
        selectedProducts?.length === 0
      }
      selectedFilterData={selectedFilterData}
    />
  );
};

export default SubTopicsFilter;
