import MultiselectDropDown from "components/MultiselectListDropDown";
import React from "react";
import { useTranslation } from "react-i18next";
import { metaDataTypes } from "Uitls/Util";

export const getBrandsFilterData = (data) => {
  return data?.map((brand) => {
    return {
      id: brand.id,
      displayName: brand.productName,
      veevaId: brand.productDamId,
      metaDataType: metaDataTypes.product,
    };
  });
};
const BrandsFilter = ({ data, handleFilter, selectedFilterData }) => {
  const { t } = useTranslation();
  return (
    <MultiselectDropDown
      title={t("metadata.brand")}
      data={getBrandsFilterData(data)}
      applySelection={(data) => {
        handleFilter("primary product", data);
      }}
      selectedFilterData={selectedFilterData}
    />
  );
};

export default BrandsFilter;
