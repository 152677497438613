import React from "react";
import styled from "styled-components";
import { BoldText, NormalText } from "./Texts";

const DoubleLinekeyValue = ({ label, value = "-" }) => {
  return (
    <Container>
      <NormalText>{label}</NormalText>
      <BoldText>{value}</BoldText>
    </Container>
  );
};

const Container = styled.div`
  margin: 12px 0px;
`;

export default DoubleLinekeyValue;
