import MultiselectDropDown from "components/MultiselectListDropDown";
import React from "react";
import { metaDataTypes } from "Uitls/Util";

export const getTopicsFilterData = (data) => {
  const topics = JSON.parse(JSON.stringify(data || []));
  return topics
    .filter((topic) => topic?.topicName !== "" && topic?.name !== "")
    .map((topic) => {
      return {
        id: topic.id,
        displayName: topic.topicName || topic.name,
        veevaId: topic.topicDamId,
        metaDataType: metaDataTypes.topic,
      };
    });
};
const TopicsFilter = ({ data, handleFilter, selectedFilterData }) => {
  return (
    <MultiselectDropDown
      title="Topic"
      data={getTopicsFilterData(data)}
      applySelection={(data) => {
        handleFilter("topic", data);
      }}
      selectedFilterData={selectedFilterData}
    />
  );
};

export default TopicsFilter;
