import axios from "axios";
const moduleApiEndPoint = "https://contentstudiomodule-api-gilead.indegene.com";

export const instance = axios.create({
  baseURL: moduleApiEndPoint,
  timeout: 35000,
  // headers: { "X-Custom-Header": "foobar" },
});

// Add a request interceptor
instance.interceptors.request.use(
  function (config) {
    const token = window.localStorage.getItem("userToken");

    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export const instanceUpload = axios.create({
  baseURL: moduleApiEndPoint,
  timeout: 460000,
});

// Add a request interceptor
instanceUpload.interceptors.request.use(
  function (config) {
    const token = window.localStorage.getItem("userToken");
    //  const token = "12345678dkdklsdf";
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default instance;
