/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import variables from "styles/base/_universal.scss";
import { Paper } from "@material-ui/core";
import ExpandMore from "@material-ui/icons/ExpandMore";
//import "../styles/components/DropDownWithSearch.scss";

const DropDownWithSearch = (props) => {
  const { options } = props;
  setTimeout(async () => {
    const close = await document.getElementsByClassName(
      "MuiAutocomplete-clearIndicator"
    )[0];
    if (close) {
      close.addEventListener("click", () => {
        const handleChnage = props?.options?.handleChange;
        if (handleChnage) {
          handleChnage(undefined);
        }
      });
    }
  }, 0);

  const getValue = () => {
    if (options && options.items && options.items.length > 0 && options.value) {
      if (options.isDataTypeObject) {
        const selectedObject = options.items.find(
          (item) => item.id === options.value?.id
        );
        return selectedObject;
      } else {
        return options.items.includes(options.value) ? options.value : "";
      }
    }
    return "";
  };

  const handleSelection = (selectedValue) => {
    //const selectedValue = e.target.value;
    // const selectedObject = options.items?.find((item) => {
    //   return item.displayName === selectedValue;
    // });
    options.handleChange(selectedValue);
  };

  const useStyles = makeStyles((theme) => ({
    popupIndicator: {
      "& span": {
        "& svg": {
          "& path": {
            d: "path('M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z')",
          },
        },
      },
    },
    paper: {
      // border: "5px solid black",
      overflowY: "hidden",
      fontSize: variables.fontSmall,

      "& MuiPaper-root": {
        overflowY: "hidden",
      },
    },
  }));
  const classes = useStyles();

  const CustomPaper = (props) => {
    return <Paper elevation={8} {...props} className="customScrollBar" />;
  };
  const iconComponent = (props) => {
    return !options.disabled && <ExpandMore className={props.className} />;
  };

  const Component = props.options.disabled
    ? DisabledAutoComplete
    : FilledAutoComplete;

  return (
    <Component
      //disabled={props.options.disabled}
      blurOnSelect={false}
      id="search-and-select"
      style={{ width: "100%" }}
      options={props?.options?.items || []}
      value={getValue()}
      onChange={(e, value) => {
        handleSelection(value);
      }}
      getOptionLabel={(option) => {
        return option?.displayName || "";
      }}
      disabled={props.options.disabled}
      closeIcon={""}
      classes={{ paper: classes.paper, popupIndicator: classes.popupIndicator }}
      autoHighlight
      getOptionSelected={(option, value) => {
        if (value) {
          return option?.displayName === value?.displayName;
        }
        return false;
      }}
      PaperComponent={CustomPaper}
      getOptionDisabled={(option) => {
        return option?.isNotSelectable;
      }}
      renderOption={(option) => {
        return (
          <React.Fragment>
            <span className="display-name">{option.displayName}</span>
          </React.Fragment>
        );
      }}
      IconComponent={iconComponent}
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.options.label}
          placeholder={props.options.placeholder}
          variant="filled"
          required={props.options.required || false}
          inputProps={{
            ...params.inputProps,
            autoComplete: "off", // "new-password" disable autocomplete and autofill
          }}
        />
      )}
      ListboxProps={{
        className: "mui-popper customScrollBar",
      }}
    />
  );
};

const AutoCmplte = styled(Autocomplete)`
  margin-bottom: 16px !important;
`;

const FilledAutoComplete = styled(AutoCmplte)({
  "& .MuiFilledInput-root": {
    backgroundColor: `${variables.whiteColor}`,
    height: "42px",
    fontSize: `${variables.fontSmall}`,
    border: `solid 1px ${variables.borderColor}`,
    paddingRight: "0px !important",
    borderRadius: "6px",
    paddingBottom: "0px",
    paddingTop: "4px",
  },
  "& .MuiFormControl-root": {
    // marginTop: "16px",
  },

  "& .MuiFilledInput-root:hover": {
    backgroundColor: `${variables.whiteColor}`,
  },

  "& .MuiFilledInput-input": {
    //padding: 0;
    lineHeight: "4.5",
    height: "42px",
    transition: "none",
    paddingRight: "5px",
    padding: "0px !important",
    color: variables.searchTextColor,
  },
  "& .MuiInputLabel-formControl": {
    top: "-4px",
  },
  "& .MuiFilledInput-underline :focus": {
    borderBottom: "none",
  },
  "& .MuiFilledInput-underline:before": {
    borderBottom: "none",
  },
  "& .MuiFilledInput-underline:after": {
    borderBottom: "none",
  },
  "& .MuiFilledInput-underline:hover:before": {
    borderBottom: "none",
  },
  "& .MuiSelect-select:focus": {
    borderRadius: "6px",
  },
  "& .MuiInputLabel-filled.MuiInputLabel-shrink": {
    transform: " translate(9px, 15px) scale(0.75)",
  },
  "& .MuiInputBase-root.Mui-disabled": {
    border: "none !important",
  },
  "& .MuiFormLabel-asterisk": {
    color: "#e32b35",
  },
});

const DisabledAutoComplete = styled(FilledAutoComplete)({
  "& .MuiAutocomplete-endAdornment": {
    display: "none",
  },
  "& .MuiFilledInput-input": {
    color: variables.subtitleColor,
  },
});

export default DropDownWithSearch;
