import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer as MUITableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import InfiniteScroll from "react-infinite-scroll-component";

import styled from "styled-components";
import variables from "styles/base/_universal.scss";
import { useTranslation } from "react-i18next";

const TableContainer = styled(MUITableContainer).attrs(() => ({
  className: "customScrollBar",
}))`
  overflow: auto;
  height: 20rem;
  margin-top: 12px;
  overflow-y: scroll;
  padding-right: 16px;
`;

const Th = styled(TableCell)`
  padding: 8px 10px 8px 10px;
  text-align: left;
  background-color: #dae5f2;
  font-size: ${variables.fontSmall};
  color: ${variables.darkGrayColor};
  font-weight: bold;
  white-space: nowrap;
  width: ${(props) => (props.width ? props.width : "")};
`;

const Td = styled(TableCell)`
  padding: 8px 10px 8px 10px;
  text-align: left;
  background-color: ${(props) => (props.isOdd ? "#f3f6fa" : "transparent")};
  font-size: ${variables.fontSmall};
  color: ${variables.searchTextColor};
  font-weight: normal;
  border: none;
  word-break: break-word;
`;

const CheckboxTh = styled(Th)`
  padding: 8px 10px 8px 20px;
  text-align: center;
  width: 1rem;
  input {
    border-radius: 1px;
    border: solid 1px #878888;
    display: inline-block;
    vertical-align: middle;
  }
`;

const CheckboxTd = styled(Td)`
  text-align: center;
  padding: 8px 10px 8px 20px;
  input {
    border-radius: 1px;
    border: solid 1px #878888;
    display: inline-block;
    vertical-align: middle;
    cursor: ${(props) =>
      props.isDisabled
        ? "not-allowed"
        : "pointer"}; // Set cursor based on isDisabled prop
    pointer-events: ${(props) =>
      props.isDisabled
        ? "none"
        : "auto"}; // Disable pointer events based on isDisabled prop
  }
  &:hover {
    input:disabled {
      border-color: #878888; /* Optional: change border color on hover */
      /* Show tooltip on hover */
      &:hover {
        &:before {
          content: attr(title);
          position: absolute;
          background-color: #333;
          color: #fff;
          padding: 4px 8px;
          border-radius: 4px;
          z-index: 1;
          white-space: nowrap;
        }
      }
    }
  }
`;

const StylizedTable = ({
  headers,
  rows,
  onCheckboxChange,
  unselectAll,
  selectedRows = [],
  disabledIds = [],
  handlePageChange,
  hasMore,
  isloading,
}) => {
  const [selectAll, setSelectAll] = useState(false);
  const { t } = useTranslation();
  // useEffect(() => {
  //   if (
  //     selectedRows?.length + disabledIds?.length === rows?.length &&
  //     rows?.length > 0
  //   ) {
  //     setSelectAll(true);
  //   } else {
  //     setSelectAll(false);
  //   }
  // }, [selectedRows, rows, disabledIds]);

  const handleCheckboxChange = (checked) => {
    setSelectAll(!selectAll);
    unselectAll(checked);
  };

  const isTableRowSelected = (rowId) => {
    return (
      selectedRows?.findIndex((elId) => rowId === elId) > -1 ||
      disabledIds?.findIndex((elId) => rowId === elId) > -1
    );
  };

  return (
    <InfiniteScroll
      dataLength={rows.length}
      next={handlePageChange}
      hasMore={hasMore}
      scrollableTarget="scrollableDiv"
      scrollThreshold={0.9}
    >
      <TableContainer id="scrollableDiv">
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <CheckboxTh>
                <input
                  type="checkbox"
                  checked={
                    disabledIds?.length > 0 &&
                    disabledIds?.length === rows?.length
                      ? true
                      : selectedRows?.length > 0 &&
                        rows?.length > 0 &&
                        selectedRows?.length === rows?.length
                  }
                  onChange={(e) => handleCheckboxChange(e.target.checked)}
                  disabled={
                    disabledIds?.length > 0 &&
                    disabledIds?.length === rows?.length
                  }
                />
              </CheckboxTh>
              {headers.map((header, index) => (
                <Th key={index} width={header.width}>
                  {header.lable}
                </Th>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length > 0 &&
              rows.map((rowData, rowIndex) => (
                <TableRow key={rowIndex}>
                  <CheckboxTd
                    isOdd={rowIndex % 2 !== 0}
                    isDisabled={disabledIds?.includes(
                      rowData[headers[0]?.field]
                    )}
                    title={
                      disabledIds?.includes(rowData[headers[0]?.field])
                        ? t("module.alreadyAdded")
                        : ""
                    }
                  >
                    <input
                      type="checkbox"
                      checked={isTableRowSelected(rowData[headers[0]?.field])}
                      onChange={(e) =>
                        onCheckboxChange(rowData, e.target.checked)
                      }
                      disabled={disabledIds?.includes(
                        rowData[headers[0]?.field]
                      )}
                    />
                  </CheckboxTd>
                  {headers.map(({ field }, cellIndex) => (
                    <Td key={cellIndex} isOdd={rowIndex % 2 !== 0}>
                      {rowData[field]}
                    </Td>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </InfiniteScroll>
  );
};

export default StylizedTable;
