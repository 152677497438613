import React from "react";
import styled from "styled-components";
import variables from "styles/base/_universal.scss";
import PreviewIcon from "img/preview_eye_icon.svg";

const Preview = ({
  message,
  mesagePosition = "up",
  onPreviewClick,
  shouldHavePreviewCTA = true,
}) => {
  return (
    <div className="text-align-center">
      {message ? (
        <div
          style={{
            color: variables.whiteColor,
            fontSize: variables.fontSmall,
            marginBottom: "12px",
            textAlign: "center",
            margin: "30px 30px 6px 30px",
          }}
        >
          {message}
        </div>
      ) : null}

      {shouldHavePreviewCTA ? (
        <div
          className="preview"
          style={{
            zIndex: "2",
            cursor: "pointer",
            backdropFilter: "blur(2px)",
            display: "flex",
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            borderRadius: "10px",
            padding: "3px 7px",
            color: variables.whiteColor,
            fontSize: variables.fontXssmall,
            maxWidth: "80px",
            position: message ? "absolute" : "",
            left: message ? "calc(50% - 26px)" : "",
          }}
          onClick={onPreviewClick}
        >
          <img
            alt=""
            src={PreviewIcon}
            style={{
              float: "left",
              marginRight: "6px",
            }}
          />
          Preview
        </div>
      ) : null}
    </div>
  );
};

export default Preview;
