import React, { useState } from "react";
import "styles/modules/modulePreview.scss";
import { fetchModuleDetails } from "services/apis";
import { showError } from "Uitls/Util";
import CustomModal from "components/customModal";
import ModuleView from "./ModuleView";
import CircularProgressBar from "Uitls/CircularProgressBar";

const BusinessRulesPreview = ({
  businessRules,
  usageGuidelines,
  linksNotClickable = false,
}) => {
  const [modulePreviewOpen, setModulePreviewOpen] = useState(false);
  const [modulePreviewData, setModulePreviewData] = useState([]);
  const [isloading, setIsloading] = useState(false);
  function filterRuleCondition() {
    const optionalRules = [];
    const cannotExistWithRules = [];
    const mustExistWithRules = [];

    businessRules.forEach((rule) => {
      switch (rule.ruleCondition) {
        case "Optional":
          optionalRules.push(rule);
          break;
        case "CannotExistWith":
          cannotExistWithRules.push(rule);
          break;
        case "MustExistWith":
          mustExistWithRules.push(rule);
          break;
        default:
          break;
      }
    });

    return {
      optionalRules,
      cannotExistWithRules,
      mustExistWithRules,
    };
  }

  const openVeevaVault = (url) => {
    window.open(url, "_blank");
  };

  const getModuleDetailsForPreview = (moduleId) => {
    setIsloading(true);
    fetchModuleDetails(moduleId, (res, err) => {
      if (res) {
        setIsloading(false);
        setModulePreviewData(res.data);
        setModulePreviewOpen(true);
        console.log(res, "response");
      } else {
        setIsloading(false);
        showError(err);
      }
    });
  };

  const renderAnchorTag = (item) => {
    return (
      <a
        href={item?.cmsdDocUrl}
        onClick={(e) => {
          e.preventDefault();
          if (linksNotClickable) {
            return;
          }

          getModuleDetailsForPreview(item.targetModuleId);
        }}
        target="_blank"
        rel="noopener noreferrer"
        style={{
          cursor: linksNotClickable ? "default" : "pointer",
          textDecoration: "none",
          color: "inherit",
        }}
        onContextMenu={(e) => {
          e.preventDefault();
        }}
      >
        {item.targetModuleDamName}
      </a>
    );
  };

  const renderRulesTable = (rules) => {
    return rules.length > 0 ? (
      rules.map((item) => {
        const el = (
          <tr key={item.targetModuleDamName}>
            <td className="tdValue tdCommon" style={{ borderTop: "none" }}>
              {item.targetModuleDamName ? renderAnchorTag(item) : "-"}
            </td>
          </tr>
        );

        return el;
      })
    ) : (
      <tr>
        <td className="tdValue tdCommon" style={{ borderTop: "none" }}>
          -
        </td>
      </tr>
    );
  };

  return (
    <div>
      <CircularProgressBar isloading={isloading} />
      <table className={`table brulesTable`}>
        <tr className="brulesTableHeading">
          <th colSpan={2} className="tdHeading tdCommon">
            Business Rules
          </th>
        </tr>
        <tr>
          <td style={{ width: "414px" }} className="tdlable tdCommon">
            This module must exist with
          </td>
          <td className="p-0 tdForValueTable">
            <table className="brulesTable brulesTableNoBrdr">
              {renderRulesTable(
                filterRuleCondition().mustExistWithRules[0]?.targetModules || []
              )}
            </table>
          </td>
        </tr>
        <tr>
          <td className="tdlable tdCommon">This module cannot exist with</td>
          <td className="p-0 tdForValueTable">
            <table className="brulesTable brulesTableNoBrdr">
              {renderRulesTable(
                filterRuleCondition().cannotExistWithRules[0]?.targetModules ||
                  []
              )}
            </table>
          </td>
        </tr>
        <tr>
          <td className="tdlable tdCommon">Related modules (Optional)</td>
          <td className="p-0 tdForValueTable">
            <table className="brulesTable brulesTableNoBrdr">
              {renderRulesTable(
                filterRuleCondition().optionalRules[0]?.targetModules || []
              )}
            </table>
          </td>
        </tr>
        <tr>
          <td className="tdlable tdCommon">Usage Guidelines</td>
          <td className="tdValue2 tdCommon" style={{ borderTop: "none" }}>
            {usageGuidelines ? usageGuidelines : "-"}
          </td>
        </tr>
      </table>
      <CustomModal
        displayModal={modulePreviewOpen}
        hideModal={() => {
          setModulePreviewOpen(false);
        }}
        size="xl"
        dilogClassName="alertModalSmall"
      >
        <ModuleView
          modulePreviewData={modulePreviewData}
          setModulePreviewOpen={setModulePreviewOpen}
        />
      </CustomModal>
    </div>
  );
};
export default BusinessRulesPreview;
