import React from "react";
import { Dropdown } from "react-bootstrap";
import dots from "img/3dot.svg";

export const ThreeDotMenu = ({ menuOptions }) => {
  const moduleDropdDown = React.forwardRef(({ children, onClick }, ref) => (
    <div
      title=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <button className="btn btn-link p-0 m-0 pl5">
        <img src={dots} alt="3dotIcon" />
      </button>{" "}
    </div>
  ));

  // Dropdown needs access to the DOM of the Menu to measure it
  const moduleMenu = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      return (
        <div
          ref={ref}
          style={style}
          className={`p-0 ${className}`}
          aria-labelledby={labeledBy}
        >
          <ul>
            {/* {React.Children.toArray(children).filter(
              (child) =>
                !value || child.props.children?.toLowerCase().startsWith(value)
            )} */}
            {React.Children.toArray(children).filter(
              (child) => child.props.children !== null
            )}
          </ul>
        </div>
      );
    }
  );

  return (
    <Dropdown onSelect={() => {}}>
      <Dropdown.Toggle as={moduleDropdDown} id="three-dot-menu" />
      <Dropdown.Menu as={moduleMenu} className="three-dot-menu">
        {menuOptions?.map((menuOption) => {
          return (
            <Dropdown.Item
              disabled={menuOption?.props?.children?.props?.disabled}
            >
              {menuOption}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};
