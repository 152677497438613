import React, { useEffect, useState } from "react";
import { emitter } from "Uitls/Util";
import EventSource from "eventsource";
import { useKeycloak } from "@react-keycloak/web";

const GlobaleEventListener = () => {
  const { keycloak } = useKeycloak();
  useEffect(() => {
    if (!keycloak.token) {
      return;
    }
    const resoureUrl = `https://contentstudiomodule-api-gilead.indegene.com/api/modulems/v1/mlrStatusPush?token=${encodeURIComponent(
      keycloak.token
    )}`;
    const eventSourceInitDict = {
      https: { rejectUnauthorized: false },
      headers: { withCredentials: true },
    };
    const es = new EventSource(resoureUrl, eventSourceInitDict);

    es.onmessage = (e) => {
      emitter.emit("autoSync", e.data);
    };

    es.onerror = function (err) {
      if (err) {
        if (err.status === 401 || err.status === 403) {
          console.log("not authorized");
        }
      }
    };

    return () => {
      es.close();
      emitter.off("autoSync");
    };
  }, []);
  return null;
};
export default GlobaleEventListener;
