import { BoldText } from "components/Texts";
import React from "react";

const ImportClaimsInfoHoverText = () => {
  return (
    <div>
      <BoldText>Add Claim :</BoldText>

      <ol style={{ paddingLeft: "12px", marginBottom: "5px" }}>
        <li>To add a claim from VVPM, please click the add CTA.</li>
        <li>
          Use the search screen to apply filters or directly search for a claim.
        </li>
        <li>
          Topic & Sub-Topic are the additional filter types provided for the
          search.
        </li>
      </ol>
      <BoldText>Note :</BoldText>
      <ol style={{ paddingLeft: "12px", marginBottom: "0px" }}>
        <li>The first claim would be referred to as a primary claim.</li>
        <li>
          All the succeeding claims would be referred to as secondary claims.
        </li>
        <li>
          You can add more than one supporting claim from the search and
          selection screen. You can delete one or more supporting claims from
          the Claim Box.
        </li>
      </ol>
    </div>
  );
};

export default ImportClaimsInfoHoverText;
