import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import FooterButtons from "components/FooterButtons";
import LinkButton from "components/LinkButton";
import PrimaryButton from "components/PrimaryButton";
import SingleLinekeyValue from "components/SingleLineKeyValue";
import variables from "styles/base/_universal.scss";
import { useTranslation } from "react-i18next";
import Table from "components/TableComponent";
import {
  fetchTextualElements,
  addElementsToModule,
  fetchTextualElementsTypes,
  fetchTopicsAndSubtopics,
  getSubTopics,
} from "services/apis";
import { showError, getDisplayNames, removeDuplicates } from "Uitls/Util";
import CircularProgressBar from "Uitls/CircularProgressBar";
import { ModuleContext } from "contexts/ModuleContext";
import FilterComponent from "components/modules/importReusableTexts/FilterComponent";
import SwicthButton from "components/SwitchButton";
import { BoldText, NormalText } from "components/Texts";
import NoDataFound from "components/NoDataFound";

const ImportReusableText = ({
  closeModalAndRefresh,
  closeModalAndGoBack,
  addedReusableTextIds,
  addedReusableTextData,
}) => {
  const { t } = useTranslation();
  const [isloading, setIsloading] = useState(false);
  const [messageTypes, setMessageTypes] = useState([]);
  const [resusableTexts, setResusableTexts] = useState([]);
  const [topicsSubtopics, setTopicsSubtopics] = useState(undefined);
  const [resUsableTextTypes, setResUsableTextTypes] = useState([]);
  const { module } = useContext(ModuleContext);
  const [dropDownData, setDropDownData] = useState(undefined);
  const [selectedReusableTextIds, setSelectedReusableTextIds] =
    useState(undefined);
  const [filteredReusableTexts, setFilteredReusableTexts] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [searchTriggered, setSearchTrgiggered] = useState(false);
  const [subTopicsData, setSubTopicsData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [addedReusableTexts, setAddedReusableTexts] = useState([]);
  const [totalElements, setTotalElements] = useState(0);
  const [refreshScreen, setRefreshScreen] = useState(true);
  useEffect(() => {
    if (
      !dropDownData?.reusableTexts &&
      !dropDownData?.searchText &&
      !dropDownData?.topic &&
      !dropDownData?.subtopics
    ) {
      setSearchTrgiggered(false);
    } else {
      setSearchTrgiggered(true);
      setToggle(false);
    }
    //setHasMore(true);
    setCurrentPage(1);
    setRefreshScreen(true);
  }, [dropDownData]);

  useEffect(() => {
    setRefreshScreen(true);
  }, [currentPage]);

  useEffect(() => {
    // if (
    //   dropDownData &&
    //   Object.keys(dropDownData).length > 0 &&
    //   currentPage === 1
    // ) {
    //   getData("search");
    // } else {
    //   getData();
    // }
    if (refreshScreen) {
      getData();
    }
  }, [refreshScreen]);

  const getRecentlyImported = () => {
    let importedIds = selectedReusableTextIds;
    if ((addedReusableTextIds || 0)?.length > 0) {
      importedIds = selectedReusableTextIds.filter((element) => {
        return !addedReusableTextIds.includes(element);
      });
    }

    return importedIds[0];
  };

  /**
   * Import and Add reusable texts to the module
   */
  const importReusableTexts = () => {
    const payload = {
      moduleId: module.moduleId,
      reusableTextNames: selectedReusableTextIds,
    };
    setIsloading(true);
    addElementsToModule("reusableTexts", payload, (res, err) => {
      setIsloading(false);
      if (res) {
        closeModalAndRefresh(getRecentlyImported());
        return;
      }
      showError(err);
    });
  };

  const getData = (type) => {
    if (!toggle) {
      setIsloading(true);
    }

    let filterData = {
      topicName: dropDownData?.topic?.displayName,
      subtopicNames: getDisplayNames(dropDownData?.subtopics),
      reusableTextType: dropDownData?.reusableTexts?.id,
      searchText: dropDownData?.searchText,
    };
    const payload = {
      product: module?.productName,
      country: module?.countryName,
      pageNo: (currentPage - 1) * 10,
      pageSize: 10,
      filterData: filterData,
    };

    fetchTextualElements(payload, "reusableTexts", (res, err) => {
      setIsloading(false);
      if (res) {
        let data = res?.data?.data;

        let claimsData = updatedClaims(data);
        // const total = res?.data?.responseDetails?.total || 0;
        // const totalPages = Math.ceil(total / 10);
        // setTotalElements(total);
        // // setResusableTexts(claimsData);
        // if (currentPage >= totalPages) {
        //   setHasMore(false);
        // }

        if (currentPage === 1) {
          const totalElements = res?.data?.responseDetails?.total || 0;
          // const totalPages = Math.ceil(total / 10);
          setTotalElements(totalElements);
          setHasMore(currentPage < Math.ceil(totalElements / 10));
        } else {
          setHasMore(currentPage < Math.ceil(totalElements / 10));
        }

        if (currentPage === 1) {
          setFilteredReusableTexts(claimsData);
        } else {
          setFilteredReusableTexts(filteredReusableTexts.concat(claimsData));
        }
      } else {
        showError(err);
      }
      setRefreshScreen(false);
    });
  };

  useEffect(() => {
    fetchTextualElementsTypes("reusableTexts/reusableTextType", (res, err) => {
      if (res) {
        const textTypes = res.data.map((el) => {
          return { id: el.name, displayName: el.label };
        });

        setResUsableTextTypes(textTypes);
      } else {
        showError(err);
      }
    });
    fetchTopicsAndSubtopics((res, err, errCode) => {
      if (res) {
        setTopicsSubtopics(res.data);
        return;
      }
    });
  }, []);

  useEffect(() => {
    if (dropDownData?.topic?.veevaId) {
      let productDamId = module.productDamId;
      let topicDamId = dropDownData?.topic.veevaId;
      const payload = {
        productDamIds: [productDamId],
        topicDamIds: [topicDamId],
      };
      setIsloading(true);
      getSubTopics(payload, (res, err) => {
        setIsloading(false);
        if (res) {
          setSubTopicsData(res.data);
          return;
        }
        showError(err);
      });
    }
  }, [dropDownData?.topic]);

  const updatedClaims = (claimsData) => {
    const updatedClaims = claimsData.map((claim) => {
      return {
        ...claim,
        description: claim.description.replace(/\n/g, ""),
      };
    });
    return updatedClaims;
  };

  const primaryButton = (
    <PrimaryButton
      title={"Add".toUpperCase()}
      handleClick={() => {
        importReusableTexts();
      }}
      disabled={
        !selectedReusableTextIds ||
        selectedReusableTextIds.length === 0 ||
        addedReusableTextData.length === selectedReusableTextIds.length
      }
      minWidth={110}
    />
  );
  const CancelButton = (
    <LinkButton
      title={"Cancel".toUpperCase()}
      handleClick={() => closeModalAndGoBack()}
    />
  );
  let columnHeaders = [
    {
      lable: "Reusable Text ID",
      field: "reusableTextId",
      width: "6rem",
    },
    {
      lable: "Match Text",
      field: "description",
    },
    {
      lable: "Reusable Text Type",
      field: "reusableTextType",
      width: "8.5rem",
    },
    {
      lable: "Topic",
      field: "topic",
      width: "8.5rem",
    },
    {
      lable: "Sub-Topic",
      field: "subtopic",
      width: "8.5rem",
    },
    // {
    //   lable: "Message Type",
    //   field: "messageType",
    //   width: "8.5rem",
    // },
  ];

  const handleDropdownSelection = (value, type) => {
    switch (type) {
      case "messageType":
        setDropDownData((prevState) => {
          return { ...prevState, messageType: value };
        });
        break;
      case "topic":
        setDropDownData((prevState) => {
          return { ...prevState, topic: value, subtopics: null };
        });
        break;
      case "subtopics":
        setDropDownData((prevState) => {
          return { ...prevState, subtopics: value };
        });
        break;
      case "reusableTexts":
        setDropDownData((prevState) => {
          return { ...prevState, reusableTexts: value };
        });
        break;
      default:
        break;
    }
    setSearchTrgiggered(true);
    setCurrentPage(1);
  };

  /**
   * store selected reusable text ids in a variable
   * @param {*} reusableTextId reusable text id for the reusable text user selects or deselects
   * @param {*} isSelected boolean true is reusable text selected or false if deselected
   */
  const handleClaimSelection = (reusableTextObject, isSelected) => {
    let newIds = selectedReusableTextIds ? [...selectedReusableTextIds] : [];
    let addedReusableTextTempData = JSON.parse(
      JSON.stringify(addedReusableTexts)
    );
    let claimIndex = selectedReusableTextIds?.findIndex((addedClaimId) => {
      return addedClaimId === reusableTextObject.reusableTextId;
    });
    if (claimIndex === undefined) {
      claimIndex = -1;
    }
    if (isSelected && claimIndex === -1) {
      newIds.push(reusableTextObject.reusableTextId);
      addedReusableTextTempData.push(reusableTextObject);
    } else if (!isSelected && claimIndex > -1) {
      newIds = newIds.filter((addedClaimId) => {
        return addedClaimId !== reusableTextObject.reusableTextId;
      });
      addedReusableTextTempData = addedReusableTextTempData.filter(
        (reusableText) => {
          return (
            reusableText.reusableTextId !== reusableTextObject.reusableTextId
          );
        }
      );
    }
    // setSelectedReusableTextIds(newIds);
    addedReusableTextData = removeDuplicates(
      addedReusableTextTempData,
      "reusableTextId"
    );
    setSelectedReusableTextIds(
      addedReusableTextData?.map((el) => el.reusableTextId)
    );
    setAddedReusableTexts(addedReusableTextData);
  };
  const handleSelectAll = () => {
    // const allNonDisabledClaims = filteredReusableTexts
    //   .filter((item) => !addedReusableTextIds?.includes(item.reusableTextId))
    //   // .map((ele) => ele.reusableTextId);
    // setSelectedReusableTextIds(allNonDisabledIds);
    if (toggle && (selectedReusableTextIds || [])?.length === 0) {
      return;
    }

    const allNonDisabledReusableTexts = filteredReusableTexts.filter(
      (item) => !addedReusableTextIds?.includes(item.reusableTextId)
    );
    let newTexts = JSON.parse(JSON.stringify(addedReusableTexts));
    const data = removeDuplicates(
      newTexts.concat(allNonDisabledReusableTexts),
      "reusableTextId"
    );
    setSelectedReusableTextIds(data?.map((el) => el.reusableTextId));
    setAddedReusableTexts(data);
  };
  useEffect(() => {
    if (toggle) {
      // Show items that are either selected or added
      let filteredData;
      filteredData = resusableTexts?.filter(
        (item) =>
          selectedReusableTextIds?.includes(item.reusableTextId) ||
          addedReusableTextIds?.includes(item.reusableTextId)
      );
      setFilteredReusableTexts(filteredData);
    }
  }, [toggle]);

  const countMatchingItems = () => {
    const selectedCount = selectedReusableTextIds
      ? selectedReusableTextIds.filter((id) =>
          filteredReusableTexts.some((item) => item.reusableTextId === id)
        ).length
      : 0;

    const addedCount = addedReusableTextIds
      ? addedReusableTextIds.filter((id) =>
          filteredReusableTexts.some((item) => item.reusableTextId === id)
        ).length
      : 0;
    return selectedCount;
    //return parseInt(selectedCount + addedCount);
  };

  useEffect(() => {
    setAddedReusableTexts(addedReusableTextData);
    // setSelectedReusableTextIds(
    //   addedReusableTextData?.map((reusableText) => reusableText.reusableTextId)
    // );
  }, [addedReusableTextData]);

  const handlePageChange = () => {
    if (toggle) {
      return;
    }
    setCurrentPage((prev) => prev + 1);
  };

  const getDataForTable = () => {
    return toggle ? addedReusableTexts : filteredReusableTexts;
  };

  const secondaryButton = (
    <div className="d-flex">
      <NormalText>Total Selected :</NormalText>
      <BoldText fontWeight="bold" style={{ marginLeft: "2px" }}>
        {addedReusableTexts?.length || 0}
      </BoldText>
    </div>
  );
  const getSelectedIds = () => {
    const selectedReusableText = selectedReusableTextIds
      ? selectedReusableTextIds.filter((id) =>
          filteredReusableTexts.some((item) => item.reusableTextId === id)
        )
      : [];

    return selectedReusableText;
  };
  const getAddedReusableIds = () => {
    const addedReusableIds = addedReusableTextIds
      ? addedReusableTextIds.filter((id) =>
          filteredReusableTexts.some((item) => item.reusableTextId === id)
        )
      : [];

    return addedReusableIds;
  };
  return (
    <>
      <Container>
        <CircularProgressBar isloading={isloading} />

        <div className="row" style={{ marginBottom: "6px" }}>
          <div className="col-md-6">
            <TitleText>{t("importReusableTexts.title")}</TitleText>
            <SubTitle>{t("importReusableTexts.subTitle")}</SubTitle>
          </div>
          <div className="col-md-6 d-flex justify-content-end">
            <MetaInfoContainer>
              <SingleLinekeyValue
                label="Primary Product"
                value={module?.productName}
                top="0px"
                bottom="0px"
              />
            </MetaInfoContainer>
            <MetaInfoContainer>
              <SingleLinekeyValue
                label="Primary Country"
                value={module?.countryName}
                top="0px"
                bottom="0px"
              />
            </MetaInfoContainer>
          </div>
        </div>
        <FilterComponent
          // messageTypes={messageTypes}
          resUsableTextTypes={resUsableTextTypes}
          handleDropdownSelection={handleDropdownSelection}
          topicsSubtopics={topicsSubtopics}
          dropDownData={dropDownData}
          setFilteredReusableTexts={setFilteredReusableTexts}
          resusableTexts={resusableTexts}
          setSearchTrgiggered={setSearchTrgiggered}
          searchTriggered={searchTriggered}
          setDropDownData={setDropDownData}
          toggle={toggle}
          subTopicsData={subTopicsData}
          setCurrentPage={setCurrentPage}
          setHasMore={setHasMore}
        />
      </Container>
      <ContainerBody>
        <>
          <div className="row" style={{ marginRight: "16px" }}>
            <div className="col-md-6 d-flex">
              <BoldText fontWeight="bold" style={{ marginRight: "2px" }}>
                {toggle
                  ? "Total Selected"
                  : searchTriggered
                  ? "Search Result"
                  : "All"}
              </BoldText>
              <NormalText>
                (
                {toggle
                  ? addedReusableTexts?.length || 0
                  : searchTriggered
                  ? countMatchingItems()
                  : addedReusableTexts?.length || 0}
                {toggle
                  ? ""
                  : searchTriggered
                  ? `/${totalElements} selected`
                  : `/${totalElements} selected`}
                )
              </NormalText>
            </div>
            <div className="col-md-6 d-flex justify-content-end pr-0">
              <SwicthButton
                label="Show All Selected"
                checked={toggle}
                handleToggle={() => {
                  setToggle(!toggle);
                }}
              />
            </div>
          </div>

          <div key={toggle ? 1 : 0}>
            <Table
              rows={getDataForTable()}
              headers={columnHeaders}
              onCheckboxChange={(reusableText, isSelected) => {
                handleClaimSelection(reusableText, isSelected);
              }}
              unselectAll={(checked) => {
                if (checked) {
                  handleSelectAll();
                } else {
                  setAddedReusableTexts(addedReusableTextData);
                  setSelectedReusableTextIds(
                    addedReusableTextData?.map(
                      (reusableText) => reusableText.reusableTextId
                    )
                  );
                }
              }}
              selectedRows={toggle ? selectedReusableTextIds : getSelectedIds()}
              disabledIds={
                toggle ? addedReusableTextIds : getAddedReusableIds()
              }
              hasMore={hasMore}
              handlePageChange={handlePageChange}
              isloading={isloading}
            />
          </div>
          {filteredReusableTexts?.length === 0 && !toggle && !isloading ? (
            <NoDataFound
              customClass={true}
              customClassNoResult={true}
              title={"No Result Found"}
              Description={"Try searching using different parameter(s)"}
            />
          ) : null}
        </>
      </ContainerBody>
      <FooterButtons
        primaryButton={primaryButton}
        intermediateButtons={[CancelButton]}
        secondaryButton={secondaryButton}
      />
    </>
  );
};
const Container = styled.div`
  padding: 0px 20px 0px 20px;
  border-bottom: solid 1px #e6e6ec;
`;
const TitleText = styled.span`
  font-size: ${variables.fontSmallV2};
  font-weight: bold;
  color: ${variables.blackColor};
  //margin-top: 12px;
`;
const MetaInfoContainer = styled.div`
  margin-left: 16px;
`;
const ContainerBody = styled.div`
  padding: 12px 4px 0px 20px;
`;
const SubTitle = styled.span`
  font-size: ${variables.fontSmall};
  font-weight: normal;
  color: ${variables.subtitleColor};
  display: block;
`;
export default ImportReusableText;
