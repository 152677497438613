import React from "react";
import variables from "../styles/base/_universal.scss";
import styled from "styled-components";
import { BorderedOutLineButton } from "./Buttons";

const LinkButton = ({
  title,
  handleClick,
  customClass,
  disabled = false,
  btnLable,
}) => {
  return (
    <BorderedOutLineButton
      title={title}
      className={`${customClass} link-button`}
      variant="light"
      disabled={disabled}
      onClick={handleClick}
    >
      {btnLable}
    </BorderedOutLineButton>
  );
};

export default LinkButton;
