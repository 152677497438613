import React from "react";
import variables from "../styles/base/_universal.scss";
import styled from "styled-components";
import { RoundedButton } from "./Buttons";

export const TransparentButton = styled(RoundedButton)`
  color: ${(props) =>
    props.selected ? `${variables.tabGrayColor}` : `${variables.tabGrayColor}`};
  background-color: ${(props) =>
    props.selected ? `${variables.paleGreenColor}` : "transparent"};
  font-weight: ${(props) =>
    props.selected ? `${variables.fontWeight600}` : ``};
  &:focus {
    outline: none;
  }
  &:disabled {
    color: rgba(0, 0, 0, 0.5);
  }
`;

const RoundedTransparentButton = ({
  title,
  clicked,
  handleClick,
  btnStatus,
}) => {
  return (
    <TransparentButton
      title={title}
      variant="light"
      selected={clicked}
      onClick={handleClick}
      disabled={btnStatus}
    >
      {" "}
      {title}
    </TransparentButton>
  );
};

export default RoundedTransparentButton;
