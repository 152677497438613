import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";

const OverlayPopOver = ({
  element,
  overlayData,
  trigger,
  classId = "no-arrow",
  placement,
  needArrow = true,
  inlineStyles,
  containerRef,
}) => {
  return (
    <OverlayTrigger
      trigger={trigger}
      placement={placement ? placement : "auto"}
      rootClose={true}
      boundary="viewport"
      container={containerRef}
      overlay={
        <Popover id={classId} style={inlineStyles}>
          {overlayData}
        </Popover>
      }
    >
      {element}
    </OverlayTrigger>
  );
};

export default OverlayPopOver;
