import React, { useEffect, useState } from "react";

function UserInfo(props) {
  const [userName, setUserName] = useState("");

  useEffect(() => {
    if (localStorage.getItem("currentUserName")) {
      setUserName(localStorage.getItem("currentUserName"));
    } else {
      setUserName(localStorage.getItem("currentUserName"));
    }
  }, []);

  return <>{userName && userName}</>;
}

export default UserInfo;
