import React from "react";
import styled from "styled-components";
import approvedIcon from "img/FinalStatus.svg";

const MLRStatusContainer = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
`;

const StatusText = styled.span`
  margin-right: 12px;
  color: #66676b;
  font-size: 12px;
`;
const StatusValue = styled.span`
  font-size: 12px;
  font-weight: 600;
  line-height: 1.33;
  letter-spacing: normal;
  color: #1e1e1e;
`;

const RightTickIcon = styled.img`
  width: 12px;
  height: 12px;
  margin-right: 6px;
`;

const MLRStatus = ({ status }) => {
  return (
    <MLRStatusContainer>
      <StatusText>MLR status</StatusText>
      <RightTickIcon src={approvedIcon} alt="Approved Icon" />
      <StatusValue>{status}</StatusValue>
    </MLRStatusContainer>
  );
};

export default MLRStatus;
