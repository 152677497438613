import React from "react";
import variables from "../styles/base/_universal.scss";
import styled from "styled-components";
import { RoundedButton } from "./Buttons";

const Button = styled(RoundedButton)`
  background-color: ${variables.themeColor};
  color: white;
  text-wrap: nowrap;
  font-weight: bold;
  padding: 0px 13px;
  &:disabled {
    background-color: #e6e7e8;
    color: ${variables.disabledColor};
    cursor: default;
  }
  &:focus {
    outline: none;
  }
  min-width: ${(props) => props.minWidth}px;
  margin-left: ${(props) => props.marginLeft}px;
`;

const Icon = styled.img`
  padding-bottom: 2px;
`;
const PrimaryButton = ({
  title,
  icon,
  handleClick,
  disabled,
  minWidth,
  marginLeft,
  customClass,
  fontSize,
}) => {
  return (
    <Button
      onClick={handleClick}
      disabled={disabled}
      minWidth={minWidth}
      marginLeft={marginLeft}
      className={customClass}
      fontSize={fontSize}
    >
      {icon ? <Icon src={icon} alt="" /> : null} {title}
    </Button>
  );
};

export default PrimaryButton;
