import { Switch } from "@material-ui/core";
import React, { useState } from "react";
import variables from "styles/base/_universal.scss";
import "styles/components/SwitchButton.scss";
import { BoldText } from "components/Texts";

const SwicthButton = ({ label, handleToggle, id = 1, checked = false }) => {
  //const [isChecked, toggleIsChecked] = useState(checked);
  return (
    <div className="align-items-center">
      <BoldText display="inline" style={{ marginRight: "-8px" }}>
        {label}
      </BoldText>
      <Switch
        className={checked ? "checked" : "unchecked"}
        checked={checked}
        onChange={(e) => {
          //toggleIsChecked(!isChecked);
          handleToggle(e.target.checked);
        }}
      />
    </div>
  );
};

export default SwicthButton;
