import React, { useEffect, useState, useRef } from "react";
import variables from "styles/base/_universal.scss";
import PaginationButton from "components/PaginationButton";
import MLRStatus from "components/MLRStatus";
import ModulesList from "components/dashboard/ModuleDashbaord/ModulesList";
import {
  getAllModulesList,
  syncMLRStatusOfModules,
  getModuleBusinessRules,
  fetchModuleDetails,
  deleteModule,
} from "services/apis";
import CircularProgressBar from "Uitls/CircularProgressBar";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { routes, showError, emitter } from "Uitls/Util";
import ScrollableChipComponent from "components/ScrollableChipComponent";
import FirstTimeLoginBox from "components/FirstTimeLoginBox";
import CustomModal from "components/customModal";
import BussinesRulesView from "components/dashboard/BussinessRulesView";
import InfiniteScroll from "react-infinite-scroll-component";
import LoadingBar from "react-top-loading-bar";


const ComponentsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ModulesDashboard = ({
  selectedTab,
  setSelectedTab,
  filtersData,
  deleteFilterOption,
  resetAll,
  handleCreateModule,
  setTotalElements,
  isFiltersApplied,
  getModuleCounts,
}) => {
  const history = useHistory();
  const scrollableDivRef = useRef(null);
  const [modulesList, setModulesList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredModules, setFilteredModules] = useState(undefined);
  const [businessRulesList, setBusinessRulesList] = useState([]);
  const [businessRulesViewModal, setBusinessRulesViewModal] = useState(false);
  const [modulePreviewData, setModulePreviewData] = useState({});
  const [hasMore, setHasMore] = useState(true);
  const progressBarRef = useRef(null);
  const [autoSyncData, setAutoSyncData] = useState(undefined);

  useEffect(() => {
    let newData = [...modulesList];
    let index = newData.findIndex((item) => item.id === autoSyncData?.id);

    if (index > -1) {
      newData[index].mlrStatus = autoSyncData.mlrStatus;
      setModulesList(newData);
    }
  }, [autoSyncData]);

  useEffect(() => {
    const handleFooEvent = (e) => {
      try {
        const parsedData = JSON.parse(e);
        if (parsedData.type === "Module") {
          setAutoSyncData(parsedData);
        }
      } catch (error) {
        console.error("Received non-JSON message:", e);
      }
    };

    emitter.on("autoSync", handleFooEvent);

    return () => {
      emitter.off("autoSync", handleFooEvent);
    };
  }, []);

  useEffect(() => {
    getAllModulesForDashBoard(currentPage);
  }, [currentPage]);

  useEffect(() => {
    handlePageChange(1);
    getAllModulesForDashBoard(1);
    sendFiltersAppliedOrNot();
  }, [filtersData]);

  const sendFiltersAppliedOrNot = () => {
    let data = getFilterDataToDisplay();
    // data = [...data].filter((item) => {
    //   return item.metaDataType !== "searchText";
    // });
    isFiltersApplied(data.length);
  };

  useEffect(() => {
    if (scrollableDivRef.current) {
      scrollableDivRef.current.scrollTop = 0;
    }
  }, [filtersData, selectedTab]);

  const getAllModulesForDashBoard = (page) => {
    setIsLoading(true);
    if (progressBarRef && progressBarRef.current) {
      progressBarRef.current.continuousStart();
    }
    let filterPayLoad = {
      productNames: getDisplayNames(filtersData["primary product"]),
      countryNames: getDisplayNames(filtersData["primary country"]),
      topicNames: getDisplayNames(filtersData?.topic),
      subtopicNames: getDisplayNames(filtersData["sub-topic"]),
      therapeuticAreaDamNames: getDisplayNames(filtersData["therapeutic area"]),
      languageDamNames: getDisplayNames(filtersData?.language),
      mlrStatuses: getDisplayNames(filtersData["mlr status"]),
      indicationNames: getDisplayNames(filtersData?.indication),
      strategicObjNames: getDisplayNames(filtersData["strategic objective"]),
      searchText: filtersData?.searchText || "",
      segmentNames: getDisplayNames(filtersData?.segment),
    };
    getAllModulesList(
      {
        pageNo: page - 1,
        pageSize: 20,
        createdByMe:
          filtersData.createdByMe === undefined
            ? true
            : filtersData.createdByMe,
        filterPayLoad: filterPayLoad,
      },
      (res, err) => {
        if (progressBarRef && progressBarRef.current) {
          progressBarRef.current.complete();
        }
        setIsLoading(false);
        if (res) {
          const newModules = res.data.data || [];
          setTotalElements(res?.data?.totalElements);
          setModulesList((prevModules) =>
            page === 1 ? newModules : [...prevModules, ...newModules]
          );
          setHasMore(!res?.data?.last);
        }
      }
    );
  };

  const syncMLRStatus = (moduleId) => {
    setIsLoading(true);
    let payload = [
      {
        moduleId: moduleId,
      },
    ];
    syncMLRStatusOfModules(payload, (res, err) => {
      setIsLoading(false);
      if (err) {
        showError(err);
      } else {
        let result = [...modulesList];
        result = result.map((item) => {
          const newMlrStatus = res.data.find(
            (xItem) => xItem.moduleId === item.id
          );
          if (newMlrStatus) {
            return {
              ...item,
              mlrStatus: newMlrStatus.mlrStatus,
              moduleDamName: newMlrStatus.moduleDamName,
            };
          }
          return item;
        });
        setModulesList(result);
      }
    });
  };

  const handlePageChange = (page) => {
    setCurrentPage((old) => {
      return page;
    });
  };

  const goToBusinessRules = (stateData) => {
    history.push({
      pathname: routes.businessRules,
    });

    localStorage.setItem("bsrulesPageData", JSON.stringify(stateData));
  };

  const goToBusinessRulesView = (stateData) => {
    setIsLoading(true);
    let payload = stateData.id;
    getModuleBusinessRules(payload, (res, err) => {
      if (res) {
        fetchModuleDetails(stateData.id, (res, err) => {
          setIsLoading(false);
          if (res) {
            setModulePreviewData(res.data);
            setBusinessRulesViewModal(true);
          } else {
            showError(err);
          }
        });
        setBusinessRulesList(res.data);
      } else {
        showError(err);
      }
    });
  };

  const checkIfFiltersApplied = () => {
    if (getFilterDataToDisplay().length > 0) {
      return true;
    }
    return false;
  };

  const editModule = (module) => {
    history.push({
      pathname: routes.module,
      state: { moduleId: module },
    });
  };
  //Don't delete, we need it
  const getDisplayNames = (data) => {
    let result = [];
    if (data && data) {
      data.forEach((item) => {
        return result.push(item.displayName);
      });
    }
    return result;
  };
  const getFilterDataToDisplay = () => {
    let result = [];

    if (filtersData["primary product"]?.length > 0) {
      result = result.concat(...filtersData["primary product"]);
    }
    if (filtersData["primary country"]?.length > 0) {
      result = result.concat(...filtersData["primary country"]);
    }
    if (filtersData?.topic?.length > 0) {
      result = result.concat(...filtersData?.topic);
    }
    if (filtersData["sub-topic"]?.length > 0) {
      if (
        filtersData?.topic?.length === 0 ||
        filtersData?.topic === undefined
      ) {
        filtersData["sub-topic"] = [];
        result = result.concat(...filtersData["sub-topic"]);
      } else {
        result = result.concat(...filtersData["sub-topic"]);
      }
    }
    if (filtersData?.indication?.length > 0) {
      result = result.concat(...filtersData?.indication);
    }
    if (filtersData?.language?.length > 0) {
      result = result.concat(...filtersData?.language);
    }
    if (filtersData["therapeutic area"]?.length > 0) {
      result = result.concat(...filtersData["therapeutic area"]);
    }
    if (filtersData?.segment?.length > 0) {
      result = result.concat(...filtersData?.segment);
    }
    if (filtersData["strategic objective"]?.length > 0) {
      result = result.concat(...filtersData["strategic objective"]);
    }
    if (filtersData["mlr status"]?.length > 0) {
      result = result.concat(...filtersData["mlr status"]);
    }

    if (filtersData?.searchText) {
      result = result.concat(
        ...[
          {
            id: 1,
            displayName: filtersData?.searchText,
            veevaId: 1,
            metaDataType: "searchText",
          },
        ]
      );
    }

    return result;
  };

  const removeModule = (moduleId) => {
    setIsLoading(true);
    deleteModule(moduleId, (res, err) => {
      setIsLoading(false);
      if (err) {
        showError(err);
      } else {
        setModulesList((prevModulesList) => {
          return prevModulesList.filter((module) => module.id !== moduleId);
        });
        getModuleCounts();
      }
    });
  };

  return (
    <>
      <div
        className="row"
        style={{ backgroundColor: variables.lightBlueColor }}
      >
        <LoadingBar
          color={variables.redPinkColor}
          ref={progressBarRef}
          shadow={false}
        />
        <CircularProgressBar isloading={isLoading} />
        
        <div className="col-12 plr20">
          <ComponentsContainer>
            <ScrollableChipComponent
              filterDataToDisplay={getFilterDataToDisplay()}
              deleteFilterOption={deleteFilterOption}
              resetAll={resetAll}
            />
          </ComponentsContainer>
        </div>
        <div className="col-12 plr20">
          {isLoading && modulesList.length === 0 ? null : (
            <InfiniteScroll
              dataLength={modulesList?.length}
              next={() => {
                handlePageChange(currentPage + 1);
              }}
              hasMore={hasMore}
              loader={<CircularProgressBar isloading={isLoading} />}
              scrollableTarget="scrollableDiv"
              scrollThreshold={0.9}
            >
              <div
                className={`dasbhoardSearchResult customScrollBar ${
                  getFilterDataToDisplay().length > 0
                    ? "dasbhoardSearchResultWithFilter"
                    : ""
                }`}
                // key={(selectedTab && currentPage) || currentPage}
                id="scrollableDiv"
                ref={scrollableDivRef}
              >
                <div className="d-flex flex-wrap">
                  <ModulesList
                    modules={modulesList}
                    selectedTab={selectedTab}
                    handleCreateModule={handleCreateModule}
                    goToBusinessRules={goToBusinessRules}
                    goToBusinessRulesView={goToBusinessRulesView}
                    editModule={editModule}
                    emptyPageTitle={
                      checkIfFiltersApplied()
                        ? "No Module(s) Found"
                        : "Create your first module"
                    }
                    filtersApplied={checkIfFiltersApplied()}
                    syncMLRStatus={syncMLRStatus}
                    removeModule={removeModule}
                    isloading={isLoading}
                  />
                  <FirstTimeLoginBox selectedTab={selectedTab} />
                </div>
              </div>
            </InfiniteScroll>
          )}
        </div>
      </div>
      <CustomModal
        displayModal={businessRulesViewModal}
        hideModal={() => {
          setBusinessRulesViewModal(false);
        }}
        size="xl"
        dilogClassName="alertModalSmall"
      >
        <BussinesRulesView
          businessRulesList={businessRulesList}
          modulePreviewData={modulePreviewData}
          closePreviewModal={() => {
            setBusinessRulesViewModal(false);
          }}
        />
      </CustomModal>
    </>
  );
};

export default ModulesDashboard;
