import React, { useEffect, useRef, useState, useContext } from "react";
import ModuleFilter from "components/businessrules/ModuleFilter";
import { routes, showError, emitter } from "Uitls/Util";
import {
  fetchModuleDetails,
  getAssociatedModules,
  getModulesListForBusinessRules,
  saveAssociatedModules,
} from "services/apis";
import CircularProgressBar from "Uitls/CircularProgressBar";
import ModuleCard from "components/modules/ModuleCard";
import styled from "styled-components";
import FooterButtons from "components/FooterButtons";
import PrimaryButton from "components/PrimaryButton";
import LinkButton from "components/LinkButton";
import { useTranslation } from "react-i18next";
import ImageSlectTick from "img/ImageSelectTickIcon.svg";
import TickIconPurple from "img/Tick_icon_purple.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import CustomModal from "components/customModal";
import ModuleView from "components/businessrules/ModuleView";
import { ModuleContext, moduleActionTypes } from "contexts/ModuleContext";

const AssociatedModuleSelection = ({
  sourceModule,
  selectedModules,
  setSelectedModules,
  goToNext,
  alreadyAddedModules,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [modulesList, setModulesList] = useState(undefined);
  const [modulesTobeDisplayed, setModulesTobeDisplayed] = useState([]);
  const [isloading, setIsloading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalModules, setTotalModules] = useState(undefined);
  const [shouldRefreshScreeen, toggleShouldRefreshScreeen] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [modulePreviewOpen, setModulePreviewOpen] = useState(false);
  const [modulePreviewData, setModulePreviewData] = useState([]);
  const showAllRef = useRef(false);
  const { updateModule, module } = useContext(ModuleContext);

  const [appliedFilters, setAppliedFilters] = useState({
    product: {
      id: sourceModule?.productDamId || "",
      displayName: sourceModule?.productName || "",
    },
    country: {
      id: sourceModule?.countryName || "",
      displayName: sourceModule?.countryName || "",
    },
  });

  const [autoSyncData, setAutoSyncData] = useState(undefined);

  useEffect(() => {
    let newData = [...modulesTobeDisplayed];
    let index = newData.findIndex((item) => item.id === autoSyncData?.id);

    if (index > -1) {
      newData[index].mlrStatus = autoSyncData.mlrStatus;
      setModulesTobeDisplayed(newData);
    }
    if (sourceModule && sourceModule.id === autoSyncData?.id) {
      let moduleDetails = JSON.parse(JSON.stringify(sourceModule));
      moduleDetails.mlrStatus = autoSyncData?.mlrStatus;
      updateModule(moduleActionTypes.SET_MODULE, moduleDetails);
      localStorage.setItem("bsrulesPageData", JSON.stringify(moduleDetails));
    }
  }, [autoSyncData]);

  useEffect(() => {
    const handleFooEvent = (e) => {
      try {
        const parsedData = JSON.parse(e);
        if (parsedData.type === "Module") {
          setAutoSyncData(parsedData);
        }
      } catch (error) {
        console.error("Received non-JSON message:", e);
      }
    };

    emitter.on("autoSync", handleFooEvent);

    return () => {
      emitter.off("autoSync", handleFooEvent);
    };
  }, []);

  useEffect(() => {
    getSelectedModules();
  }, []);
  useEffect(() => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    }
    toggleShouldRefreshScreeen(true);
  }, [appliedFilters]);

  useEffect(() => {
    if (shouldRefreshScreeen) {
      getAllModules();
    }
  }, [currentPage, shouldRefreshScreeen]);

  useEffect(() => {
    if (showAllRef?.current === true) {
      resetFilters();
    }
  }, [showAllRef?.current]);

  const getAllModules = () => {
    setIsloading(true);
    const payload = {
      exculdeModuleId: sourceModule.id,
      productNames: [appliedFilters.product.displayName],
      countryNames: [appliedFilters.country.displayName],
      topicNames: appliedFilters?.topic
        ? [appliedFilters.topic?.displayName]
        : undefined,
      subtopicNames: appliedFilters.subtopic?.map((topic) => {
        return topic.displayName;
      }),
      searchText: appliedFilters.searchText || "",
      page: currentPage - 1,
    };
    getModulesListForBusinessRules(payload, (res, err) => {
      setIsloading(false);
      if (res) {
        const data = res.data.data;
        let moduleList = undefined;
        if (currentPage === 1) {
          moduleList = [sourceModule].concat(data);
          // setModulesList([sourceModule].concat(data));
          // setModulesTobeDisplayed([sourceModule].concat(data));
          setTotalModules(res.data.totalElements);
        } else {
          moduleList = modulesList.concat(data);
          // setModulesList(modulesList.concat(data));
          // setModulesTobeDisplayed(modulesTobeDisplayed.concat(data));
        }
        setModulesList(moduleList);
        if (showAllRef?.current !== true) {
          setModulesTobeDisplayed(moduleList);
        }
        toggleShouldRefreshScreeen(false);
        setHasMore(!res.data.last);
      } else {
        showError(err);
      }
    });
  };
  const executeModuleAction = (type, module) => {
    switch (type) {
      case cardActions.select:
        const addedAsbrule = alreadyAddedModules.find((addedModule) => {
          return addedModule.id === module.id;
        });
        if (addedAsbrule) {
          return;
        }
        let modules = JSON.parse(JSON.stringify(selectedModules));
        if (isModuleSelected(module.id)) {
          modules = modules.filter((el) => {
            return el.id !== module.id;
          });
        } else {
          modules.push(module);
        }
        setSelectedModules(modules);
        if (showAllRef && showAllRef?.current === true) {
          setModulesTobeDisplayed([sourceModule].concat(modules));
        }
        break;
      case cardActions.view:
        getModuleDetailsForPreview(module.id);
        break;
      default:
        break;
    }
  };
  const cardActions = {
    select: "select",
    view: "view",
  };

  const handleFilter = (filter, type) => {
    const filters = JSON.parse(JSON.stringify(appliedFilters));
    filters[type] = filter;
    if (type === "topic") {
      delete filters.subtopic;
    }
    setAppliedFilters(filters);
    if (showAllRef) {
      showAllRef.current = false;
    }
  };

  const resetFilters = () => {
    const filters = JSON.parse(JSON.stringify(appliedFilters));
    let isAlreadyReset = false;
    if (
      !filters.topic &&
      !filters.subtopic &&
      filters.searchText?.trim()?.length === 0
    ) {
      isAlreadyReset = true;
    }
    delete filters.topic;
    delete filters.subtopic;
    delete filters.searchText;
    if (!isAlreadyReset) {
      setAppliedFilters(filters);
    }
  };
  const isModuleSelected = (moduleId) => {
    if (moduleId === sourceModule.id) {
      return true;
    }
    let foundObj = selectedModules?.find((module) => {
      return module.id === moduleId || module?.moduleId === moduleId;
    });
    if (!foundObj) {
      foundObj = alreadyAddedModules?.find((module) => {
        return module.id === moduleId;
      });
    }
    return foundObj;
  };
  const showAllSelected = (isToggleOn) => {
    // setAppliedFilters({ ...appliedFilters, showAllSelected: isToggleOn });
    const modules = JSON.parse(JSON.stringify(modulesList || [])) || [];
    if (isToggleOn) {
      setModulesTobeDisplayed(
        // modules?.filter((module) => {
        //   return isModuleSelected(module.id);
        // })
        [sourceModule].concat(selectedModules)
      );
    } else {
      setModulesTobeDisplayed(modules);
    }
  };

  /**
   * get associated modules saved against the source module
   */
  const getSelectedModules = () => {
    getAssociatedModules(sourceModule?.id, (res, err) => {
      if (res) {
        const data = res?.data?.map((el) => {
          el.id = el?.moduleId;
          delete el?.moduleId;
          return el;
        });

        setSelectedModules(data);
      } else {
        showError(err);
      }
    });
  };

  /**
   * store these selected modules in Db agaainst the spurce module
   */
  const saveSelectedModules = () => {
    setIsloading(true);
    const payload = selectedModules?.map((selectedModule) => {
      return {
        moduleId: selectedModule?.id || selectedModule?.moduleId,
        moduleName: selectedModule?.moduleName,
        nextUniqueModuleCode: selectedModule?.nextUniqueModuleCode,
        moduleDamId: selectedModule?.moduleDamId,
        moduleDamName: selectedModule?.moduleDamName,
      };
    });

    saveAssociatedModules(sourceModule?.id, payload, (res, err) => {
      setIsloading(false);
      if (res) {
        goToNext();
      } else {
        showError(err);
      }
    });
  };

  const nextButton = (
    <PrimaryButton
      title={t("generalTexts.next").toUpperCase()}
      handleClick={() => {
        saveSelectedModules();
      }}
      disabled={selectedModules?.length === 0}
      minWidth={110}
    />
  );

  const cancelButton = (
    <LinkButton
      title={t("generalTexts.cancel").toUpperCase()}
      handleClick={() => {
        history.push({
          pathname: routes.dashboard,
          state: {
            selectedTab: 1,
          },
        });
      }}
    />
  );
  const legends = (
    <Legends>
      <LegendImg src={TickIconPurple} alt="" />{" "}
      <span style={{ marginRight: "20px" }}>
        {t("businessRules.selectedSourceModule")}
      </span>
      <LegendImg src={ImageSlectTick} alt="" />{" "}
      {t("businessRules.selectedAssociatedModules")}
    </Legends>
  );

  const getModuleDetailsForPreview = (moduleId) => {
    setIsloading(true);
    fetchModuleDetails(moduleId, (res, err) => {
      if (res) {
        setIsloading(false);
        setModulePreviewData(res.data);
        setModulePreviewOpen(true);
      } else {
        setIsloading(false);
        showError(err);
      }
    });
  };

  return (
    <div>
      <CircularProgressBar isloading={isloading} />
      <ModuleFilter
        filterData={appliedFilters}
        handleFilter={handleFilter}
        resetFilters={resetFilters}
        showAllSelected={showAllSelected}
        showAllRef={showAllRef}
      />
      <InfiniteScroll
        dataLength={modulesTobeDisplayed?.length || 0}
        next={() => {
          if (showAllRef?.current === true) {
            return;
          }
          setCurrentPage((prev) => {
            toggleShouldRefreshScreeen(true);
            return prev + 1;
          });
        }}
        hasMore={hasMore}
        scrollableTarget="module-cards"
        scrollThreshold={0.9}
        style={{ display: "contents" }}
      >
        <ModuleCards
          className="row customScrollBar ml-0"
          id="module-cards"
          key={showAllRef?.current}
        >
          <div
            className="d-flex flex-wrap"
            style={{ width: "100%", marginTop: "16px" }}
          >
            {(modulesTobeDisplayed || [])?.map((module, index) => {
              return (
                <ModuleCard
                  module={module}
                  // selectedTab={1}
                  executeModuleAction={executeModuleAction}
                  cardActions={cardActions}
                  isSelected={isModuleSelected(module.id) ? true : false}
                  selectedTickIcon={
                    module.id === sourceModule.id ? TickIconPurple : undefined
                  }
                  isSelectable={
                    alreadyAddedModules.findIndex((m) => {
                      return m.id === module.id;
                    }) === -1
                  }
                />
              );
            })}
          </div>
        </ModuleCards>
      </InfiniteScroll>
      <div className="row">
        <FooterButtons
          primaryButton={nextButton}
          intermediateButtons={[cancelButton]}
          secondaryButton={legends}
        />
      </div>
      <CustomModal
        displayModal={modulePreviewOpen}
        hideModal={() => {
          setModulePreviewOpen(false);
        }}
        size="xl"
        dilogClassName="alertModalSmall"
      >
        <ModuleView
          modulePreviewData={modulePreviewData}
          setModulePreviewOpen={setModulePreviewOpen}
        />
      </CustomModal>
    </div>
  );
};

const ModuleCards = styled.div`
  height: calc(100vh - 260px);
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 0 !important;
  margin-right: -11px;
`;
const LegendImg = styled.img`
  margin-right: 6px;
  height: 20px;
  width: 20px;
`;
const Legends = styled.div`
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default AssociatedModuleSelection;
