import React from "react";

import LogoutCTA from "pages/LogoutCTA";

const NotFound = (props) => {
  return (
    <>
      <div
        className="trasnlateHeaderBg"
        style={{ backgroundColor: "#ccc", height: "200px", width: "300px" }}
      >
        <h1>Page Not Found</h1>
      </div>
    </>
  );
};

export default NotFound;
