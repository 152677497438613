import React from "react";
import styles from "styles/modules/moduleSteps.module.scss";

function ModuleSteps(props) {
  const { activeScreen, moduleHeaderData } = props;
  return (
    <div className={`row ${styles.moduleStepsRow}`}>
      <div className="col-12">
        <div className={`d-flex justify-content-center `}>
          {moduleHeaderData ? (
            <div
              className={`${styles.step01Width}  ${
                activeScreen === 1
                  ? `${styles.borderDefault}`
                  : activeScreen === 4 && moduleHeaderData ? 
                  styles.borderActiveBussinessRules 
                    : styles.borderActive
              }`}
            >
              <div
                className={
                  activeScreen === 1
                    ? `${styles.one} ${styles.circleCurrent}`
                    : `${styles.one} ${styles.circleActive}`
                }
              >
                <span>{moduleHeaderData.stepOne}</span>
              </div>
            </div>
          ) : null}

          <div
            className={`${styles.step02Width} ${styles.borderDefault} ${
              activeScreen === 2 || activeScreen === 0 || activeScreen === 1
                ? styles.borderDefault
                : styles.borderActive
            }`}
          >
            <div
              className={
                activeScreen === 2 || activeScreen === 0
                  ? `${styles.two} ${styles.circleCurrent}`
                  : activeScreen === 1
                  ? `${styles.two} ${styles.circleDefault}`
                  : `${styles.two} ${styles.circleActive}`
              }
            >
              <span>
                {moduleHeaderData ? "Apply Business Rules" : "Assemble Module"}
              </span>
            </div>
          </div>
          <div className={`${styles.step03Width} ${styles.borderDefault}`}>
            <div
              className={
                activeScreen === 4
                  ? `${styles.three} ${styles.circleCurrent} `
                  : `${styles.three} ${styles.circleDefault}`
              }
            >
              <span>Preview</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModuleSteps;
