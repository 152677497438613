import SimpleSlider from "components/SimpleSlider";
import { ModuleContext } from "contexts/ModuleContext";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "styles/modules/importClaims.module.scss";
import ComponentsTitleRow from "components/modules/ComponentsTitleRow";
import { moduleScreens } from "pages/module/ModuleUtil";
import ClaimSummaryBox from "components/modules/claims/ClaimSummaryBox";
import ReusableTextSummaryBox from "components/modules/reusableTexts/ReusableTextSummaryBox";
import TextComponent from "components/modules/TextComponent";
/**
 * To display claims and reusable texts
 * @param {*} param0
 * @returns
 */
const ModuleTextAttributes = ({ elementLabel, elementType }) => {
  const { t } = useTranslation();
  const { module, activeScreen, currentActiveElementIndex } =
    useContext(ModuleContext);
  const sliderRef = useRef();

  const columnSize = activeScreen === moduleScreens.reusableTexts ? 12 : 4;

  useEffect(() => {
    goToSlide(currentActiveElementIndex);
  }, [currentActiveElementIndex]);

  const goToSlide = (slideIndex) => {
    if (sliderRef && sliderRef.current) {
      sliderRef.current.slickGoTo(slideIndex);
    }
  };

  const renderTextualComponents = () => {
    if (!module) {
      return;
    }
    return module[elementType]?.map((el, index) => {
      return (
        <div key={index} style={{ width: "100%" }}>
          <div className="row">
            <div className={`col-md-${columnSize}`}>
              {activeScreen === moduleScreens.claims && (
                <ClaimSummaryBox claim={el} />
              )}
              {activeScreen === moduleScreens.reusableTexts && (
                <ReusableTextSummaryBox reusableText={el} />
              )}
            </div>
            {activeScreen !== moduleScreens.reusableTexts && (
              <div className={`col-md-${columnSize}`}>
                <TextComponent
                  title="Footnotes"
                  data={[
                    {
                      content: el.footnotes,
                    },
                  ]}
                  componentType={1}
                />
              </div>
            )}

            {activeScreen !== moduleScreens.reusableTexts && (
              <div className={`col-md-${columnSize}`}>
                <TextComponent
                  title="References"
                  data={
                    el.references
                      ? el.references?.map((ref, index) => {
                          return {
                            content: `${index + 1}. ${ref.documentName}`,
                          };
                        })
                      : []
                  }
                  componentType={3}
                />
              </div>
            )}
          </div>
        </div>
      );
    });
  };

  return (
    <>
      <div className="row mt-3 mb-3 align-items-center">
        <ComponentsTitleRow
          elementLabel={`${elementLabel.toUpperCase()} (${
            currentActiveElementIndex + 1
          })`}
        />
      </div>

      {
        <div className={`${styles.sliderheight}`}>
          <SimpleSlider
            children={renderTextualComponents()}
            sliderRef={sliderRef}
          />
        </div>
      }
    </>
  );
};

export default ModuleTextAttributes;
