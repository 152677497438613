import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import "styles/components/DropDownButtons.scss"; // Import the styles

const DropDownButtons = ({
  handleClick,
  totalElements,
  createdByMeFlag,
  moduleCountSummary,
  isFiltersApplied,
}) => {
  const [activeButton, setActiveButton] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleButtonClick = (buttonIndex) => {
    setActiveButton(buttonIndex);
    handleClick(buttonIndex);
    setDropdownOpen(false); // Close the dropdown
  };

  const toggleDropdown = (isOpen) => {
    setDropdownOpen(isOpen);
  };

  useEffect(() => {
    if (createdByMeFlag) {
      setActiveButton(1);
    } else if (createdByMeFlag === false) {
      setActiveButton(2);
    } else {
      setActiveButton(3);
    }
  }, [createdByMeFlag]);

  const getSelectedName = () => {
    let selectedOption = "";
    if (activeButton === 1) {
      let count = !isFiltersApplied
        ? moduleCountSummary?.createdByMe
          ? moduleCountSummary?.createdByMe
          : "0"
        : totalElements
        ? totalElements
        : "0";
      selectedOption = `Created by Me (${count})`;
    } else if (activeButton === 2) {
      let othersCount = !isFiltersApplied
        ? moduleCountSummary?.createdByOthers
          ? moduleCountSummary?.createdByOthers
          : "0"
        : totalElements
        ? totalElements
        : "0";
      selectedOption = `Created by Others (${othersCount})`;
    } else {
      let allCount = !isFiltersApplied
        ? moduleCountSummary?.totalCount
          ? moduleCountSummary?.totalCount
          : "0"
        : totalElements
        ? totalElements
        : "0";
      selectedOption = `All (${allCount})`;
    }
    return selectedOption;
  };
  return (
    <Dropdown
      show={dropdownOpen}
      onToggle={toggleDropdown}
      className="dropdown-button"
    >
      <Dropdown.Toggle onClick={() => setDropdownOpen(!dropdownOpen)}>
        {getSelectedName()}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item
          onClick={() => handleButtonClick(1)}
          as="button"
          className={`btnLink ${activeButton === 1 ? "activeButton" : ""}`}
          style={{
            borderTopLeftRadius: "12px",
            borderTopRightRadius: "12px",
          }}
        >
          <span>
            Created by Me{" "}
            {activeButton === 1 || !isFiltersApplied
              ? ` (${
                  !isFiltersApplied
                    ? moduleCountSummary?.createdByMe
                      ? moduleCountSummary?.createdByMe
                      : "0"
                    : totalElements
                    ? totalElements
                    : "0"
                })`
              : ""}
          </span>
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => handleButtonClick(2)}
          as="button"
          className={`btnLink ${activeButton === 2 ? "activeButton" : ""}`}
        >
          <span>
            Created by Others{" "}
            {activeButton === 2 || !isFiltersApplied
              ? ` (${
                  !isFiltersApplied
                    ? moduleCountSummary?.createdByOthers
                      ? moduleCountSummary?.createdByOthers
                      : "0"
                    : totalElements
                    ? totalElements
                    : "0"
                })`
              : ""}
          </span>
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => handleButtonClick(3)}
          as="button"
          className={`btnLink ${activeButton === 3 ? "activeButton" : ""}`}
          style={{
            borderBottomLeftRadius: "12px",
            borderBottomRightRadius: "12px",
          }}
        >
          <span>
            All{" "}
            {activeButton === 3 || !isFiltersApplied
              ? `(${
                  !isFiltersApplied
                    ? moduleCountSummary?.totalCount
                      ? moduleCountSummary?.totalCount
                      : "0"
                    : totalElements
                    ? totalElements
                    : "0"
                })`
              : ""}
          </span>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DropDownButtons;
