import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import FooterButtons from "components/FooterButtons";
import LinkButton from "components/LinkButton";
import PrimaryButton from "components/PrimaryButton";
import SingleLinekeyValue from "components/SingleLineKeyValue";
import variables from "styles/base/_universal.scss";
import { useTranslation } from "react-i18next";
import {
  fetchTextualElementsTypes,
  fetchTextualElements,
  fetchWithPromiseTextualElementsTypes,
  addElementsToModule,
  fetchallThumbnailImages,
  fetchTopicsAndSubtopics,
  getSubTopics,
  fetchSubTypes,
  fetchClassifications,
} from "services/apis";
import { showError, getDisplayNames } from "Uitls/Util";
import CircularProgressBar from "Uitls/CircularProgressBar";
import { ModuleContext } from "contexts/ModuleContext";
import FilterComponent from "components/modules/importComponents/FilterComponent";
import { BoldText, NormalText } from "components/Texts";
import ComponentCard from "components/modules/importComponents/ComponentCard";
import PaginationButton from "components/PaginationButton";
import InfiniteScroll from "react-infinite-scroll-component";
import NoDataFound from "components/NoDataFound";

const ImportComponents = ({
  closeModalAndRefresh,
  closeModal,
  addedComponentIds,
}) => {
  const { t } = useTranslation();
  const [isloading, setIsloading] = useState(false);
  const [topicsSubtopics, setTopicsSubtopics] = useState(undefined);
  const { module } = useContext(ModuleContext);
  const [searchTriggered, setSearchTrgiggered] = useState(false);
  const [componentList, setComponentList] = useState([]);
  const [filteredComponentList, setFilteredComponentList] = useState([]);
  const [selectedComponentId, setSelectedComponentId] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  // const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [dropDownData, setDropDownData] = useState(undefined);
  const [subTopicsData, setSubTopicsData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [subType, setSubType] = useState([]);
  const [classification, setClassification] = useState([]);

  useEffect(() => {
    if (
      (dropDownData?.classification ||
        dropDownData?.searchText ||
        dropDownData?.topic ||
        dropDownData?.subtopics ||
        dropDownData?.subType) &&
      currentPage === 1
    ) {
      getData("search");
    } else {
      getData();
    }
  }, [dropDownData, currentPage]);

  useEffect(() => {
    fetchTopicsAndSubtopics((res, err, errCode) => {
      if (res) {
        setTopicsSubtopics(res.data);
        return;
      } else {
        showError(err);
      }
    });
    fetchSubTypes((res, err, errCode) => {
      if (res) {
        const removeVideoAndAudio = res?.data?.filter(
          (item) => item.value !== "audio__c" && item.value !== "video__c"
        );
        setSubType(removeVideoAndAudio);
        return;
      } else {
        showError(err);
      }
    });
  }, []);

  useEffect(() => {
    fetchClassifications(dropDownData?.subType?.id, (res, err, errCode) => {
      if (res) {
        setClassification(res.data);
        return;
      } else {
        showError(err);
      }
    });
  }, [dropDownData?.subType]);

  useEffect(() => {
    if (dropDownData?.topic?.veevaId) {
      let productDamId = module.productDamId;
      let topicDamId = dropDownData?.topic.veevaId;
      const payload = {
        productDamIds: [productDamId],
        topicDamIds: [topicDamId],
      };
      setIsloading(true);
      getSubTopics(payload, (res, err) => {
        setIsloading(false);
        if (res) {
          setSubTopicsData(res.data);
          return;
        } else {
          showError(err);
        }
      });
    }
  }, [dropDownData?.topic]);

  const isAlreadyAdded = (compId) => {
    return addedComponentIds.includes(compId);
  };

  /**
   * Import and Add components to the module
   */
  const importComponents = () => {
    const payload = {
      moduleId: module.moduleId,
      componentDocNumbers: selectedComponentId,
    };
    setIsloading(true);
    addElementsToModule("components", payload, (res, err) => {
      setIsloading(false);
      if (res) {
        closeModalAndRefresh(selectedComponentId[0]);
      } else {
        showError(err);
      }
    });
  };

  const primaryButton = (
    <PrimaryButton
      title={"Add".toUpperCase()}
      handleClick={() => {
        importComponents();
      }}
      minWidth={110}
      disabled={!selectedComponentId || selectedComponentId.length === 0}
    />
  );
  const CancelButton = (
    <LinkButton title={"Cancel".toUpperCase()} handleClick={closeModal} />
  );

  const getData = (type) => {
    setIsloading(true);
    let filterData = {
      topicName: dropDownData?.topic?.displayName,
      subtopicNames: getDisplayNames(dropDownData?.subtopics),
      subType: dropDownData?.subType?.displayName,
      classification: dropDownData?.classification?.displayName,
      searchText: dropDownData?.searchText,
    };
    const pageSize = 10;
    const startIndex = (currentPage - 1) * pageSize;
    const payload = {
      product: module?.productName,
      country: module?.countryName,
      pageNo: startIndex,
      pageSize: pageSize,
      filterData: filterData,
    };

    fetchTextualElements(payload, "components", (res, err) => {
      setIsloading(false);
      if (res) {
        let data = res.data.data;
        // const total = res.data.responseDetails.total;
        // const totalPages = Math.ceil(total / pageSize);
        // setTotalElements(data?.length > 0 ? total : 0);
        // setTotalPages(totalPages);

        if (currentPage === 1) {
          const totalElements = res?.data?.responseDetails?.total || 0;
          //const totalPages = Math.ceil(total / 10);
          setTotalElements(totalElements);
          setHasMore(currentPage < Math.ceil(totalElements / 10));
        } else {
          setHasMore(currentPage < Math.ceil(totalElements / 10));
        }

        // if (currentPage >= totalPages) {
        //   setHasMore(false);
        // }
        getImageIds(data, type);
      } else {
        showError(err);
      }
    });
  };

  const getImageIds = (data, type) => {
    setIsloading(true);
    const componentsData = data;
    const imageIds = data.map((item) => item.componentDocId);
    fetchallThumbnailImages(imageIds, "components", (res, err) => {
      setIsloading(false);
      if (res) {
        const images = res
          .filter((item) => item.status === "fulfilled")
          .map((item) => item.value.data);
        images.forEach((filteredItem) => {
          const foundItem = componentsData.find(
            (fullItem) => fullItem.componentDocId === filteredItem.componentid
          );
          if (foundItem) {
            foundItem.componentUrl = `data:image/png;base64,${filteredItem.file}`;
          }
        });
        if (currentPage === 1) {
          setFilteredComponentList(() => [...componentsData]);
        } else {
          setFilteredComponentList((prev) => [...prev, ...componentsData]);
        }
      } else {
        showError(err);
      }
    });
  };

  const onSelectComponent = (componentId) => {
    let newIds = selectedComponentId ? [...selectedComponentId] : [];
    const index = newIds.findIndex((item) => {
      return item === componentId;
    });

    if (index > -1) {
      newIds = newIds.filter((item) => {
        return item !== componentId;
      });
    } else {
      newIds = [componentId];
    }
    setSelectedComponentId(newIds);
  };

  const handlePageChange = () => {
    setIsloading(true);
    setCurrentPage((prev) => prev + 1);
  };

  return (
    <>
      <Container>
        <CircularProgressBar isloading={isloading} />
        <div className="row ml-0 mr-0" style={{ marginBottom: "6px" }}>
          <div className="col-md-6 pl-0">
            <TitleText>{t("importComponents.title")}</TitleText>
            <SubTitle>{t("importComponents.subTitle")}</SubTitle>
          </div>
          <div className="col-md-6 d-flex justify-content-end pr-0">
            <MetaInfoContainer>
              <SingleLinekeyValue
                label="Primary Product"
                value={module?.productName}
                top="0px"
                bottom="0px"
              />
            </MetaInfoContainer>
            <MetaInfoContainer>
              <SingleLinekeyValue
                label="Primary Country"
                value={module?.countryName}
                top="0px"
                bottom="0px"
              />
            </MetaInfoContainer>
          </div>
        </div>
        <FilterComponent
          topicsSubtopics={topicsSubtopics}
          setSearchTrgiggered={setSearchTrgiggered}
          searchTriggered={searchTriggered}
          componentList={componentList}
          setFilteredComponentList={setFilteredComponentList}
          setDropDownData={setDropDownData}
          dropDownData={dropDownData}
          subTopicsData={subTopicsData}
          setSearchInput={setSearchInput}
          searchInput={searchInput}
          subType={subType}
          classification={classification}
          setCurrentPage={setCurrentPage}
          setHasMore={setHasMore}
        />
      </Container>
      <ContainerBody>
        <>
          <div className="row d-flex justify-content-between align-items-center  mr-2">
            <div className="col-md-6 d-flex">
              <BoldText fontWeight="bold" style={{ marginRight: "2px" }}>
                {searchTriggered ? "Search Result" : "All"}
              </BoldText>
              <NormalText>({totalElements})</NormalText>
            </div>
          </div>
          <InfiniteScroll
            dataLength={filteredComponentList.length}
            next={handlePageChange}
            hasMore={hasMore}
            loader={<CircularProgressBar isloading={isloading} />}
            scrollableTarget="scrollableDiv"
            scrollThreshold={0.9}
          >
            <div
              className="col-12 pl-0 pr-0 customScrollBar"
              id="scrollableDiv"
              style={{ height: "285px", overflowY: "scroll" }}
            >
              <div className="d-flex flex-wrap">
                {filteredComponentList?.map((item) => (
                  <ComponentCard
                    componentObjet={item}
                    key={item.componentDocNumber}
                    onSelectComponent={onSelectComponent}
                    selectedComponentId={selectedComponentId}
                    isAlreadyAdded={isAlreadyAdded}
                  />
                ))}
              </div>
            </div>
          </InfiniteScroll>
          {!isloading &&
          filteredComponentList.length === 0 &&
          totalElements === 0 ? (
            <NoDataFound
              customClass={true}
              customClassNoResult={true}
              title={"No Result Found"}
              Description={"Try searching using different parameter(s)"}
            />
          ) : null}
        </>
      </ContainerBody>
      <FooterButtons
        primaryButton={primaryButton}
        intermediateButtons={[CancelButton]}
      />
    </>
  );
};

const Container = styled.div`
  padding: 0px 20px 0px 20px;
`;
const TitleText = styled.span`
  font-size: ${variables.fontSmallV2};
  font-weight: bold;
  color: ${variables.blackColor};
  //margin-top: 12px;
`;
const MetaInfoContainer = styled.div`
  margin-left: 16px;
`;
const ContainerBody = styled.div`
  padding: 12px 4px 0px 20px;

  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const SubTitle = styled.span`
  font-size: ${variables.fontSmall};
  font-weight: normal;
  color: ${variables.subtitleColor};
  display: block;
`;
export default ImportComponents;
