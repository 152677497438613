import React from "react";
import styles from "styles/components/LeftRightScrollButton.module.scss";

const LeftRightScrollButton = ({
  btnLabel,
  handleClick,
  shouldDisableForward,
  shouldDisableBackward,
  count,
  currentIndex = 0,
  goToSlide,
  customMargin = false,
  leadingZeros = false,
}) => {
  const parseTextAndNavigate = (value) => {
    try {
      value = parseInt(value);
      // goToSlide(value);
    } catch (e) {
      console.debug("ERROR", e?.message);
    }
  };

  // Function to optionally pad numbers with leading zeros
  const formatNumber = (number, totalDigits) => {
    return leadingZeros ? number.toString().padStart(totalDigits, "0") : number;
  };

  return (
    <div
      className={`d-flex justify-content-end ${
        customMargin ? styles.customMargin : ""
      }`}
    >
      <button
        className={`btn ${styles.paginationBtn} ${styles.btnPaginationLeft}`}
        disabled={shouldDisableBackward}
        onClick={() => {
          handleClick("backward");
        }}
      ></button>
      {btnLabel && <span className={`${styles.lableText}`}>{btnLabel}</span>}

      <input
        id="pageination-input"
        type="text"
        className={`${styles.sliderInput}`}
        onBlur={(e) => {
          parseTextAndNavigate(e.target.value);
        }}
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            parseTextAndNavigate(e.target.value);
          }
        }}
        value={formatNumber(currentIndex, 2)}
      />
      <span className={`${styles.lableText}`}>of {formatNumber(count, 2)}</span>
      <button
        className={`btn ${styles.paginationBtn} ${styles.btnPaginationRight}`}
        disabled={shouldDisableForward}
        onClick={() => {
          handleClick("forward");
        }}
      ></button>
    </div>
  );
};

export default LeftRightScrollButton;
