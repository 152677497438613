import React from "react";
import styled from "styled-components";
import placeholderImage from "images/Emptymodule.svg";
import CreateModuleIcon from "img/cerateBlue.svg";
import PrimaryButtonOutline from "components/PrimaryButtonOutline";

const Container = styled.div`
  text-align: center;
  overflow: hidden;
  ${({ customClass }) =>
    customClass &&
    `
  position: absolute;
  top: 62%; 
  left: 50%;
  transform: translate(-50%, -50%);
`}
`;

const PlaceholderImage = styled.img`
  position: relative;
  width: 600px;
  height: 321px;
  ${({ customClass }) =>
    customClass &&
    `
    position: relative;
    width: 374px;
    height: 200px;
`}
  ${({ customClassforNotifications }) =>
    customClassforNotifications &&
    `
    position: relative;
    width: 300px;
    height: 170px;
`}
`;

const OverlayContent = styled.div`
  position: relative;
  z-index: 99;
  margin-top: -95px;
  ${({ customClassNoResult }) =>
    customClassNoResult &&
    `
  position: relative;
  z-index: 99;
  margin-top: -60px;
`}
`;

const Title = styled.p`
  font-weight: 600;
  margin-bottom: 6px;
`;

const NoDataFound = ({
  handleCreateModule,
  selectedTab,
  title,
  Description,
  filtersApplied,
  customClass,
  customClassforNotifications,
  customClassNoResult,
}) => {
  return (
    <Container
      customClass={customClass}
      customClassforNotifications={customClassforNotifications}
      className="btnVhCenter"
    >
      <PlaceholderImage
        customClass={customClass}
        customClassforNotifications={customClassforNotifications}
        src={placeholderImage}
        alt="Placeholder"
      />
      <OverlayContent customClassNoResult={customClassNoResult}>
        <Title
          hasDescription={!!Description}
          hasButton={
            (selectedTab === 0 || selectedTab === 1) &&
            handleCreateModule &&
            !filtersApplied
          }
        >
          {title}
        </Title>
        {Description && (
          <span style={{ marginBottom: "6px" }}>{Description}</span>
        )}

        {(selectedTab === 0 || selectedTab === 1) &&
          handleCreateModule &&
          !filtersApplied && (
            <>
              <span style={{ marginBottom: "20px", display: "block" }}>
                Click here
              </span>
              <PrimaryButtonOutline
                title="Create Module"
                icon={CreateModuleIcon}
                showIcon={true}
                handleClick={handleCreateModule}
              />
            </>
          )}
      </OverlayContent>
    </Container>
  );
};

export default NoDataFound;
