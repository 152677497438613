import React, { useState, useEffect } from "react";
import styled from "styled-components";
import FooterButtons from "components/FooterButtons";
import PrimaryButton from "components/PrimaryButton";
import SingleLinekeyValue from "components/SingleLineKeyValue";
import variables from "styles/base/_universal.scss";
import ModulePreviewData from "components/modules/ModulePreviewData";
import statusDraft from "img/statusDraft.svg";
import infoIcon from "img/infoIcon.svg";
import { costomEllipsis, emitter } from "Uitls/Util";
import OverlayPopOver from "Uitls/OverlayPopOver";
import { ToolTipText } from "components/TooltipText";
import { getMlrStatusIcon } from "Uitls/Util";
import { NormalText } from "components/Texts";
import LinkButton from "components/LinkButton";

const ModuleView = ({
  modulePreviewData,
  setModulePreviewOpen,
  cloneModule,
}) => {
  const [mlrStatus, setMlrStatus] = useState("");
  useEffect(() => {
    if (modulePreviewData?.mlrStatus) {
      setMlrStatus(modulePreviewData?.mlrStatus);
    }
  }, [modulePreviewData]);
  useEffect(() => {
    const handleFooEvent = (e) => {
      try {
        const parsedData = JSON.parse(e);
        if (
          parsedData.type === "Module" &&
          parsedData.id === modulePreviewData.moduleId
        ) {
          setMlrStatus(parsedData.mlrStatus);
        }
      } catch (error) {
        console.error("Received non-JSON message:", e);
      }
    };

    emitter.on("autoSync", handleFooEvent);

    return () => {
      emitter.off("autoSync", handleFooEvent);
    };
  }, []);
  const CancelButton = (
    <PrimaryButton
      title={"Close".toUpperCase()}
      handleClick={() => setModulePreviewOpen(false)}
    />
  );

  const Status = () => {
    let status = mlrStatus ? (
      <>
        <img src={getMlrStatusIcon(mlrStatus)} alt="" className="mt2 mr4" />
        {mlrStatus}
      </>
    ) : (
      <>
        <img src={infoIcon} alt="" className="mt2 mr4" />
        Not published
      </>
    );
    return status;
  };

  const textCloneModule = cloneModule ? (
    <div
      style={{
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
        gap: "4px",
      }}
    >
      <NormalText display="flex">To make a copy of this module</NormalText>
      <LinkButton
        title="click here"
        handleClick={() => {
          cloneModule();
        }}
      />
    </div>
  ) : undefined;

  return (
    <>
      <Container>
        <div className="row" style={{ marginBottom: "20px" }}>
          <div className="col-md-4">
            <TitleText>CMSD Preview</TitleText>
          </div>
          <div className="col-md-8 d-flex justify-content-end">
            <OverlayPopOver
              element={
                <MetaInfoContainer>
                  <SingleLinekeyValue
                    label="Module Name"
                    value={costomEllipsis(
                      modulePreviewData?.moduleName || "-",
                      40
                    )}
                    top="0px"
                    bottom="0px"
                  />
                </MetaInfoContainer>
              }
              overlayData={
                <ToolTipText>{modulePreviewData?.moduleName}</ToolTipText>
              }
              trigger={["hover", "focus"]}
              placement="bottom"
              classId="tooltip"
            />

            <MetaInfoContainer>
              <SingleLinekeyValue
                label="Content Module ID"
                value={
                  modulePreviewData?.moduleDamName
                    ? modulePreviewData?.moduleDamName
                    : "-"
                }
                top="0px"
                bottom="0px"
              />
            </MetaInfoContainer>
            <MetaInfoContainer>
              <SingleLinekeyValue
                label="MLR Status"
                value={Status()}
                top="0px"
                bottom="0px"
              />
            </MetaInfoContainer>
          </div>
        </div>
      </Container>
      <ContainerBody>
        <ModulePreviewData
          modulePreviewData={modulePreviewData}
          openedFromModal={true}
        />
      </ContainerBody>
      <FooterButtons
        primaryButton={CancelButton}
        secondaryButton={textCloneModule}
      />
    </>
  );
};

// const ToolTipText = styled.div`
//   margin: 12px 6px 6px 6px;
//   font-size: ${variables.fontSmall};
//   color: ${variables.searchTextColor};
//   border: none;
// `;
const Container = styled.div`
  padding: 0px 15px 0px 15px;
  border-bottom: solid 1px #e6e6ec;
`;
const TitleText = styled.span`
  font-size: ${variables.fontSmallV2};
  font-weight: bold;
  color: ${variables.blackColor};
  //margin-top: 12px;
`;
const MetaInfoContainer = styled.div`
  margin-left: 16px;
`;
const ContainerBody = styled.div.attrs(() => ({
  className: "customScrollBar",
}))`
  overflow: auto;
  padding: 12px 15px 15px 15px;
  margin-right: 4px;
  height: calc(100vh - 200px);
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #f9f9fa;
`;
export default ModuleView;
