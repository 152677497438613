import React from "react";
import styled, { css } from "styled-components";
import rightIcon from "img/right 1.svg";
import leftIcon from "img/left 1.svg";
import rightDisabledIcon from "img/RightDisbaled.svg";
import leftDisabledIcon from "img/LeftDisbaled.svg";

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  height: 31px;
  margin: 11px 0px;
  margin-left: 16px !important;
  padding: 8px 6px;
  border-radius: 16px;
  background-color: #fff;
  cursor: pointer;
  outline: none !important;
`;

const Icon = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 4px;
  margin-left: 4px;
  padding: 4px;
  border-radius: 50%;
  &:hover {
    background-color: #f2f6fa;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      content: url(${(props) =>
        props.src === rightIcon ? rightDisabledIcon : leftDisabledIcon});
    `}
`;

const PaginationButton = ({
  onPageChange,
  currentPage,
  totalPages,
  totalElements,
}) => {
  const goToPreviousPage = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  const getPageRange = () => {
    if (totalElements === 0) {
      return "0-0 of 0";
    } else {
      const startIndex = (currentPage - 1) * 10 + 1;
      const endIndex = Math.min(currentPage * 10, totalElements);
      return `${startIndex}-${endIndex} of ${totalElements}`;
    }
  };

  return (
    <ButtonContainer>
      <div onClick={goToPreviousPage}>
        <Icon
          src={leftIcon}
          alt="Left Icon"
          disabled={currentPage === 1 || totalPages === 0}
        />
      </div>

      <span style={{ minWidth: "90px", textAlign: "center" }}>
        {getPageRange()}
      </span>

      <div onClick={goToNextPage}>
        <Icon
          src={rightIcon}
          alt="Right Icon"
          disabled={currentPage === totalPages || totalPages === 0}
        />
      </div>
    </ButtonContainer>
  );
};

export default PaginationButton;
