import React from "react";
import styled from "styled-components";
import SingleLineKeyValue from "components/SingleLineKeyValue";
import LinkButton from "components/LinkButton";
import { BoldText } from "./Texts";
import failureImg from "img/failed.svg";

const UnsuccessfulToastMessage = ({ message, handleClose }) => {
  return (
    <div style={{ color: "#000" }}>
      <div className="d-flex">
        <img
          src={failureImg}
          alt=""
          height={16}
          style={{ marginRight: "6px" }}
        />
        <BoldText>{message}</BoldText>
      </div>
      <div style={{ float: "right", marginTop: "10px", marginBottom: "0px" }}>
        <LinkButton title="OK, GOT IT" handleClick={handleClose} />
      </div>
    </div>
  );
};

export default UnsuccessfulToastMessage;
