import React, { useState } from "react";
import styles from "styles/components/businessRules/businesrules.module.scss";
import dragIcon from "img/dragIcon.svg";
import statusDraft from "img/statusDraft.svg";
import statusApproved from "img/statusApproved.svg";
import deleteCardIcon from "img/deleteCardIcon.svg";
import { costomEllipsis, showError } from "Uitls/Util";
import ModuleListCard from "./ModuleListCard";
import { fetchModuleDetails } from "services/apis";
import CustomModal from "components/customModal";
import ModuleView from "./ModuleView";
const BusinessrulesBox = (props) => {
  const {
    id,
    name,
    setBsRules,
    bsRules,
    modulesList,
    actionType,
    viewAssociatedModule,
    saveBusinessRules,
  } = props;
  const [modulePreviewOpen, setModulePreviewOpen] = useState(false);
  const [modulePreviewData, setModulePreviewData] = useState([]);
  const [isloading, setIsloading] = useState(false);

  const dropModuleHandler = (ev, id) => {
    ev.preventDefault();
    const droppedContent = ev.dataTransfer.getData("text");
    try {
      JSON.parse(droppedContent);
    } catch (e) {
      return;
    }

    const data = JSON.parse(ev.dataTransfer.getData("text"));

    setBsRules((prev) => {
      return prev.map((item) =>
        item.id === id ? { ...item, rules: [...item.rules, data] } : item
      );
    });
  };

  const onDragOver = (event) => {
    event.stopPropagation();
    event.preventDefault();
  };

  const removeIdFromBsrulesList = (moduleId) => {
    const brules = JSON.parse(JSON.stringify(bsRules)).map((item) =>
      item.rules.some((module) => {
        return moduleId === module.id;
      })
        ? { ...item, rules: item.rules.filter((i) => i.id !== moduleId) }
        : item
    );
    setBsRules(() => {
      saveBusinessRules(false, brules);
    });
    setBsRules(brules);
    // saveBusinessRules(false, brules, (deleted) => {
    //   if (deleted) {
    //     setBsRules((prev) => {
    //       return brules;
    //     });
    //   }
    // });
  };

  const getModuleDetailsForPreview = (moduleId) => {
    setIsloading(true);
    fetchModuleDetails(moduleId, (res, err) => {
      if (res) {
        setIsloading(false);
        setModulePreviewData(res.data);
        if (actionType === "view") {
          viewAssociatedModule(res.data);
        } else {
          setModulePreviewOpen(true);
        }
      } else {
        setIsloading(false);
        showError(err);
      }
    });
  };

  return (
    <div
      id={id}
      onDragOver={(e) => onDragOver(e)}
      onDrop={(e) => dropModuleHandler(e, id)}
      className={`col-md-4`}
    >
      <div className={`${styles.businessrulesBox}`}>
        {actionType !== "view" ? (
          <>
            {/* <p className={`${styles.rulesTitle}`}>{name}</p> */}
            {!bsRules[id - 1]?.rules?.length > 0 && (
              <div className={`btnVhCenter ${styles.dropPlaceHolder}`}>
                Drag & Drop here
              </div>
            )}
          </>
        ) : (
          ""
        )}
        <div className={`customScrollBar ${styles.bsrulesList}`}>
          {bsRules[id - 1]?.rules?.map((item, index) => {
            return (
              <ModuleListCard
                draggable={false}
                key={item.id}
                cardData={item}
                handleView={(moduleId) => {
                  getModuleDetailsForPreview(moduleId);
                }}
                readOnly={actionType === "view" ? true : false}
                handleRemove={removeIdFromBsrulesList}
              />
              //  <div className={` ${styles.addedClaimBox}`} key={index}>
              //   {actionType !== "view" ? (
              //     <img
              //       src={deleteCardIcon}
              //       alt="Remove"
              //       className={styles.deleteCardIcon}
              //       onClick={() => removeIdFromBsrulesList(item.id)}
              //     />
              //   ) : (
              //     ""
              //   )}
              //   <div className="d-flex align-items-center">
              //     <div className="w-100">
              //       <div className="mb6 d-flex align-items-center justify-content-between">
              //         <span className={styles.cardBrandDetails}>
              //           <img src={statusDraft} alt="" className="mt2" />{" "}
              //           {item.moduleDamName}
              //         </span>

              //       </div>
              //       <div
              //         className={`${styles.cardBrandDetails} heightNormal ellipssesSingleLine`}
              //         title={item?.moduleName}
              //       >
              //         <span>Name :</span> {item?.moduleName || "-"}
              //       </div>
              //     </div>
              //   </div>

              // </div>
            );
          })}
        </div>
      </div>

      <CustomModal
        displayModal={modulePreviewOpen}
        hideModal={() => {
          setModulePreviewOpen(false);
        }}
        size="xl"
        dilogClassName="alertModalSmall"
      >
        <ModuleView
          modulePreviewData={modulePreviewData}
          setModulePreviewOpen={setModulePreviewOpen}
        />
      </CustomModal>
    </div>
  );
};

export default BusinessrulesBox;
