import React from "react";
import { useTranslation } from "react-i18next";

function InvalidDataErrModal(props) {
  const { t } = useTranslation();
  return (
    <p className="notificationText">
      {(props && props.errorMessage) || t("apiErrors.errDefault")}
    </p>
  );
}

export default InvalidDataErrModal;
