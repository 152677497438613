import React, { useState, useEffect } from "react";
import "styles/components/FirstTimeLoginBox.scss";
import { showError } from "Uitls/Util";
import {
  updateNotificationSettings,
  getNotificationSettings,
} from "services/apis";

const FirstTimeLoginBox = ({ selectedTab }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dontShowAgain, setDontShowAgain] = useState(false);

  useEffect(() => {
    const isNotificationDismissed = localStorage.getItem(
      `notificationDismissed_${selectedTab}`
    );

    if (isNotificationDismissed === "true") {
      setIsOpen(false);
    } else {
      getNotificationSettings((data, error) => {
        if (!error && data) {
          if (selectedTab === 0 && data.isModuleDashboardTicked) {
            setIsOpen(false);
          } else if (selectedTab !== 0 && data.isRuleDashboardTicked) {
            setIsOpen(false);
          } else {
            setIsOpen(true);
          }
        } else {
          setIsOpen(true);
        }
      });
    }
  }, [selectedTab]);

  useEffect(() => {
    // Reset the dontShowAgain state when the selectedTab changes
    setDontShowAgain(false);
  }, [selectedTab]);

  const handleClose = () => {
    setIsOpen(false);
    localStorage.setItem(`notificationDismissed_${selectedTab}`, "true");

    // Update notification settings based on selectedTab and dontShowAgain
    let notificationSettings = {};
    if (selectedTab === 0) {
      notificationSettings.isModuleDashboardTicked = dontShowAgain;
    } else if (selectedTab === 1) {
      notificationSettings.isRuleDashboardTicked = dontShowAgain;
    }
    updateNotificationSettings(notificationSettings, (response, error) => {
      if (error) {
        showError(error);
      }
    });
  };

  const handleCheckboxChange = (event) => {
    setDontShowAgain(event.target.checked);
  };

  return (
    <>
      {isOpen && (
        <div className="box-container">
          <div className="card">
            {selectedTab === 0 ? (
              <>
                <p className="styled-paragraph-12">
                  Create a New Module in 2 simple steps :
                </p>
                <span className="step-text">Step 1 :</span>
                <p className="styled-paragraph">Import Element(s)</p>
                <span className="step-text">Step 2 :</span>
                <p className="styled-paragraph-40">Preview & Publish Module</p>
              </>
            ) : (
              <>
                <p className="styled-paragraph-12">
                  Apply Business Rules in 3 simple steps :
                </p>
                <span className="step-text">Step 1 :</span>
                <p className="styled-paragraph">
                  Select a Content Module to “Apply Business Rules”
                </p>
                <span className="step-text">Step 2 :</span>
                <p className="styled-paragraph">
                  Select required Modules to Apply Business Rules
                </p>
                <span className="step-text">Step 3 :</span>
                <p className="styled-paragraph-40">
                  Start Applying Business Rules
                </p>
              </>
            )}
            <div className="button-container">
              <label className="checkbox-text d-flex">
                <input
                  type="checkbox"
                  className="styled-checkbox"
                  checked={dontShowAgain}
                  onChange={handleCheckboxChange}
                />
                <span className="checkbox-container"></span> Don't show me again
              </label>
              <span
                className="close-button"
                onClick={() => {
                  handleClose();
                }}
              >
                OK, GOT IT
              </span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FirstTimeLoginBox;
