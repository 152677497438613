import React, { useState } from "react";
import Popover from "@material-ui/core/Popover";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";

// Custom styles for the tooltip
const useStyles = makeStyles((theme) => ({
  customTooltip: {
    backgroundColor: "#ffffff",
    color: "#4d4d4d",
    padding: theme.spacing(1),
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
    fontSize: "0.875rem",
  },
  arrow: {
    color: "#ffffff",
  },
}));

const OverlayPopOverToolTip = ({
  element,
  overlayData,
  trigger,
  classId = "no-arrow",
  placement,
  needArrow = true,
  inlineStyles,
  containerRef,
}) => {
  const classes = useStyles(); // Hook into custom styles

  return (
    <>
      <div
        style={{ display: "inline-block" }} // Ensures correct inline display for element
      >
        <Tooltip
          title={overlayData}
          classes={{
            tooltip: classes.customTooltip, // Apply custom styles
            arrow: classes.arrow, // Apply custom arrow color
          }}
          arrow={needArrow}
          placement={placement}
        >
          {element}
        </Tooltip>
      </div>

      <Popover
        id={classId}
        container={containerRef}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        style={inlineStyles}
      >
        {overlayData}
      </Popover>
    </>
  );
};

export default OverlayPopOverToolTip;
