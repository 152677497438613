import React, { useState, useEffect } from "react";

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MenuItem from "@material-ui/core/MenuItem";
import styled from "styled-components";
import variables from "styles/base/_universal.scss";

const DropDownWithLabel = ({ items, options }) => {
  const getValue = () => {
    if (options && options.items && options.items.length > 0 && options.value) {
      if (options.isDataTypeObject) {
        const selectedObject = options.items.find(
          (item) => item.id === options.value?.id
        );
        return selectedObject ? selectedObject.displayName : "";
      } else {
        return options.items.includes(options.value) ? options.value : "";
      }
    }
    return "";
  };
  const handleSelection = (e) => {
    const selectedValue = e.target.value;
    const selectedObject = options.items?.find((item) => {
      return item.displayName === selectedValue;
    });
    options.handleChange(selectedObject);
  };
  const iconComponent = (props) => {
    return !options.disabled && <ExpandMoreIcon className={props.className} />;
  };
  const Component = options.disabled ? DisabledDropDown : FilledDropDown;

  return (
    <Component
      disabled={options.disabled || false}
      className="drop-down-with-label"
      variant="filled"
      fullWidth={true}
      style={{ border: options.disabled ? "none" : "" }}
    >
      <InputLabel htmlFor={options.name}>{options.name}</InputLabel>
      <Select
        value={getValue()}
        onChange={handleSelection}
        // label={options.label}
        labelId={options.label}
        // name={options.name}
        id={options.name}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
            pointerEvents: "none",
          },
          getContentAnchorEl: null,
        }}
        IconComponent={iconComponent}
      >
        {options.items &&
          options.items.length > 0 &&
          options.items.map((item) => {
            return (
              <MenuItem
                value={options.isDataTypeObject ? item.displayName : item}
                // id={options.isDataTypeObject ? item.displayName : item}
                key={options.isDataTypeObject ? item.displayName : item}
                // disabled={options.isDataTypeObject && item.isSelected}
              >
                {options.isDataTypeObject ? item.displayName : item}
              </MenuItem>
            );
          })}
      </Select>
    </Component>
  );
};

const DropDown = styled(FormControl)`
  margin-bottom: 16px !important;
`;

const FilledDropDown = styled(DropDown)({
  "& .MuiFilledInput-root": {
    backgroundColor: `${variables.whiteColor}`,

    fontSize: `${variables.fontSmall}`,
    border: `solid 1px ${variables.borderColor}`,
    paddingRight: "0px",
    borderRadius: "6px",
    paddingBottom: "0px",
  },
  "& .MuiFormControl-root": {
    marginTop: "16px",
  },

  "& .MuiFilledInput-root:hover": {
    backgroundColor: `${variables.whiteColor}`,
  },

  "& .MuiFilledInput-input": {
    //padding: 0;
    lineHeight: "4.5",
    height: "42px",
    transition: "none",
    paddingRight: "5px",
    paddingTop: "0px",
    color: variables.searchTextColor,
  },
  "& .MuiInputLabel-formControl": {
    top: "-4px",
  },
  "& .MuiFilledInput-underline :focus": {
    borderBottom: "none",
  },
  "& .MuiFilledInput-underline:before": {
    borderBottom: "none",
  },
  "& .MuiFilledInput-underline:after": {
    borderBottom: "none",
  },
  "& .MuiFilledInput-underline:hover:before": {
    borderBottom: "none",
  },
  "& .MuiSelect-select:focus": {
    borderRadius: "6px",
  },
  "& .MuiInputLabel-filled.MuiInputLabel-shrink": {
    transform: " translate(12px, 15px) scale(0.75)",
  },
});

const DisabledDropDown = styled(FilledDropDown)({
  "& .MuiFilledInput-root": {
    border: `none`,
    height: "44px",
  },
  "& .MuiFilledInput-input": {
    color: variables.subtitleColor,
    fontWeight: variables.fontWeight600,
  },
});

export default DropDownWithLabel;
