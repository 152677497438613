import React, { useEffect, useMemo, useState } from "react";
import "styles/components/DashboardFilter.scss";
import RoundedTransparentButton from "components/RoundedTransparentButton";
import RoundedSearchTextField from "components/RoundedSearchTextField";
import MultiselectDropDown from "components/MultiselectListDropDown";
import PrimaryButton from "components/PrimaryButton";

import CreateModuleIcon from "img/icon-create.svg";
import AdvanceFilter from "dashboard/filters/AdvanceFilter";
import BrandsFilter, {
  getBrandsFilterData,
} from "dashboard/filters/BrandsFilter";
import MarketsFilter, {
  getMarketFilterData,
} from "dashboard/filters/MarketsFilter";
import TopicsFilter, {
  getTopicsFilterData,
} from "dashboard/filters/TopicsFilter";
import SubTopicsFilter, {
  getSubTopicsFilterData,
  fecthSubTopicsFilter,
} from "dashboard/filters/SubTopicsFilter";
import DropDownButtons from "components/DropDownButton";
import {
  fetchAllMlrStatuses,
  fetchBrands,
  fetchMarkets,
  fetchTopicsAndSubtopics,
  getSubTopics,
} from "services/apis";
import {
  showError,
  getIndicationData,
  getLanguageData,
  getTheurapiticData,
  getSegmentData,
  getStrategiData,
  mlrStatusArray,
  metaDataTypes,
} from "Uitls/Util";
import CircularProgressBar from "Uitls/CircularProgressBar";

const DashboardFilter = ({
  applyFilters,
  handleCreateModule,
  selectedTab,
  //setFiltersData,
  filtersData,
  metaDataDropDownList,
  totalElements,
  moduleCountSummary,
  isFiltersApplied,
}) => {
  const [createdByMe, toggleCreatedByMe] = useState(true);
  //const [filtersData, setFiltersData] = useState({ createdByMe: createdByMe });
  const [brands, setBrands] = useState(undefined);
  const [markets, setMarkets] = useState(undefined);
  const [topicsSubtopics, setTopicsSubtopics] = useState(undefined);
  const [subTopicsData, setSubTopicsData] = useState([]);
  const [isloading, setIsloading] = useState(false);
  const [searchText, setSearchText] = useState(undefined);
  const [mlrStatuses, setMlrStatuses] = useState(undefined);
  useEffect(() => {
    getFiltersData();
    //applyFilters({ createdByMe: createdByMe });
  }, []);

  const getFiltersData = () => {
    fetchBrands((res, err, errCode) => {
      if (res) {
        setBrands(res.data);
        return;
      }
    });
    fetchMarkets((res, err, errCode) => {
      if (res) {
        setMarkets(res.data);
        return;
      }
    });
    fetchTopicsAndSubtopics((res, err, errCode) => {
      if (res) {
        setTopicsSubtopics(res.data);
        return;
      }
    });
    fetchAllMlrStatuses((res, err) => {
      if (res) {
        const data = res?.data?.map((mlrStatus) => {
          return {
            id: mlrStatus.name,
            displayName: mlrStatus.label,
            veevaId: mlrStatus.name,
            metaDataType: metaDataTypes.mlr,
          };
        });
        setMlrStatuses(data);
      }
    });
  };

  useEffect(() => {
    toggleCreatedByMe(() => {
      return true;
    });
  }, [selectedTab]);

  useEffect(() => {
    const filters = JSON.parse(JSON.stringify(filtersData));
    filters.createdByMe = createdByMe;
    applyFilters(filters);
  }, [createdByMe]);

  const handleFilter = (key, data) => {
    const prevState = JSON.parse(JSON.stringify(filtersData));
    prevState[key] = data;
    //Reset if product/topic combination is changed
    if (key === "primary product" || key === "topic") {
      prevState["sub-topic"] = [];
    }
    applyFilters(prevState);
  };

  const prepareDataForAdvanceFilter = () => {
    const data = {
      "primary product": getBrandsFilterData(brands),
      "primary country": getMarketFilterData(markets),
      // campaigns: getCampaignsFilterData(brands, filtersData.brands),
      indication: getIndicationData(metaDataDropDownList?.indicationNode),
      language: getLanguageData(metaDataDropDownList?.languageNode),
      topic: getTopicsFilterData(topicsSubtopics),
      "sub-topic": fecthSubTopicsFilter(subTopicsData, filtersData?.topic),
      "therapeutic area": getTheurapiticData(
        metaDataDropDownList?.theraputicAreaNode
      ),
      segment: getSegmentData(metaDataDropDownList?.segmentNode),
      "strategic objective": getStrategiData(
        metaDataDropDownList?.strategicObjectiveNode
      ),
      "mlr status": mlrStatuses || [],
    };
    return data;
  };

  useEffect(() => {
    if (
      filtersData["primary product"]?.length > 0 &&
      filtersData?.topic?.length > 0
    ) {
      let productDamId = filtersData["primary product"].map((item) => {
        return item.veevaId;
      });
      let topicDamId = filtersData?.topic.map((item) => {
        return item.veevaId;
      });
      getSubTopicsData(productDamId, topicDamId);
    }
  }, [filtersData["primary product"], filtersData?.topic]);

  // useEffect(() => {
  //   if (filtersData?.topic?.length === 0) {
  //     let newFilterData = { ...filtersData };
  //     newFilterData["sub-topic"] = [];
  //     applyFilters(newFilterData);
  //   }
  // }, [filtersData?.topic]);

  const getSubTopicsData = (productDamId, topicDamId) => {
    setIsloading(true);
    const payload = {
      productDamIds: productDamId,
      topicDamIds: topicDamId,
    };
    getSubTopics(payload, (res, err) => {
      setIsloading(false);
      if (res) {
        // filtersData["sub-topic"] = [];
        setSubTopicsData(res.data);
        return;
      } else {
        showError(err);
      }
    });
  };
  const handleSearch = (type) => {
    switch (type) {
      case "search":
        handleFilter("searchText", searchText);
        break;
      case "searchBarEmpty":
        handleFilter("searchText", "");
        setSearchText(undefined);
        break;
      default:
        break;
    }
  };
  const handleChangeSelection = (buttonIndex) => {
    const filters = JSON.parse(JSON.stringify(filtersData));
    switch (buttonIndex) {
      case 1:
        filters.createdByMe = true;
        applyFilters(filters);
        break;
      case 2:
        filters.createdByMe = false;
        applyFilters(filters);
        break;
      case 3:
        filters.createdByMe = "";
        applyFilters(filters);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <CircularProgressBar isloading={isloading} />
      <div
        className="d-flex filter-container shadow"
        style={{
          marginLeft: "-15px",
          marginRight: "-15px",
          paddingLeft: "15px",
          paddingRight: "15px",
        }}
      >
        <div className="col-md-33" style={{ flex: "0 0 250px" }}>
          <DropDownButtons
            handleClick={handleChangeSelection}
            totalElements={totalElements}
            createdByMeFlag={filtersData?.createdByMe}
            moduleCountSummary={moduleCountSummary}
            isFiltersApplied={isFiltersApplied}
          />
          {/* <div className="btn-grp-createdby">
            <RoundedTransparentButton
              title="Created by Me"
              clicked={createdByMe}
              handleClick={() => {
                toggleCreatedByMe(true);
              }}
            />
            <RoundedTransparentButton
              title="Created by Others"
              clicked={!createdByMe}
              handleClick={() => {
                toggleCreatedByMe(false);
              }}
            />
          </div> */}
        </div>
        <div className="col-md-99" style={{ flex: 1 }}>
          <div className="d-flex justify-content-end">
            <BrandsFilter
              data={brands}
              handleFilter={handleFilter}
              selectedFilterData={filtersData["primary product"]}
            />
            {/* {useMemo(() => {
              return (
                <BrandsFilter
                  data={brands}
                  handleFilter={handleFilter}
                  selectedFilterData={filtersData["primary product"]}
                />
              );
            }, [brands, filtersData["primary product"]])} */}
            {/* <CampaignsFilter
            data={brands}
            handleFilter={handleFilter}
            selectedBrands={filtersData?.brands}
          /> */}
            <MarketsFilter
              data={markets}
              handleFilter={handleFilter}
              selectedFilterData={filtersData["primary country"]}
            />
            <TopicsFilter
              data={topicsSubtopics}
              handleFilter={handleFilter}
              selectedFilterData={filtersData?.topic}
            />
            <SubTopicsFilter
              data={subTopicsData}
              handleFilter={handleFilter}
              selectedTopics={filtersData?.topic}
              selectedFilterData={filtersData["sub-topic"]}
              selectedProducts={filtersData["primary product"]}
            />
            <AdvanceFilter
              data={prepareDataForAdvanceFilter()}
              addedFilters={filtersData}
              applySelection={(updatedFilters) => {
                applyFilters(updatedFilters);
              }}
            />
            <div style={{ margin: "0px 16px " }}>
              <RoundedSearchTextField
                inlineButton={true}
                placeholder="Type Module Name / ID"
                onTextChange={(value) => {
                  if (value.trim() === 0 || value.trim() === "") {
                    setSearchText(undefined);
                    handleSearch("searchBarEmpty");
                  } else {
                    setSearchText(value);
                  }
                }}
                handleSearch={(type) => handleSearch(type)}
                isResetClicked={
                  filtersData?.searchText === undefined ? true : false
                }
                expandable={true}
                inputValue={searchText}
              />
            </div>
            {selectedTab !== 1 && selectedTab !== undefined && (
              <PrimaryButton
                title="Create Module"
                icon={CreateModuleIcon}
                handleClick={handleCreateModule}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardFilter;
