import React, { useState } from "react";

import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import NoDataFound from "components/NoDataFound";
import { routes, showError } from "Uitls/Util";
import { moduleScreens } from "pages/module/ModuleUtil";
import { fetchModuleDetails } from "services/apis";
import ModuleCard from "components/modules/ModuleCard";
import ConfirmationPopup from "components/ConfirmationPopup";
import CustomModal from "components/customModal";
import { CloneModule } from "components/modules/clone/CloneModule";

const ModulesList = ({
  modules,
  selectedTab,
  emptyPageTitle,
  goToBusinessRules,
  filterModules,
  filtersApplied,
  editModule,
  handleCreateModule,
  getFilterDataToDisplay,
  syncMLRStatus,
  goToBusinessRulesView,
  removeModule,
  isloading,
}) => {
  const [moduleIdToBeDeleted, setModuleIdToBeDeleted] = useState(undefined);
  const [moduleIdToBeCloned, setModuleIdToBeCloned] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();

  const openVeevaVault = (url) => {
    window.open(url, "_blank");
  };

  const handlePublishClick = (moduleId) => {
    setIsLoading(true);
    fetchModuleDetails(moduleId, (response, err) => {
      setIsLoading(false);
      if (err) {
        showError(err);
        return;
      }
      const moduleData = response.data;
      history.push({
        pathname: routes.module,
        state: {
          defaultScreen: moduleScreens.modulePreview,
          modulePreviewData: moduleData,
        },
      });
    });
  };

  if (!modules || (modules.length === 0 && !isloading)) {
    return (
      <NoDataFound
        selectedTab={selectedTab}
        handleCreateModule={handleCreateModule}
        title={emptyPageTitle}
        filtersApplied={filtersApplied}
      />
    );
  }

  const executeModuleAction = (type, module) => {
    switch (type) {
      case cardActions.applyBRule:
        goToBusinessRules(module);
        break;
      case cardActions.view:
        goToBusinessRulesView(module);
        break;
      case cardActions.syncMlr:
        syncMLRStatus(module.id);
        break;
      case cardActions.edit:
        editModule(module.id);
        break;
      case cardActions.publish:
        handlePublishClick(module.id);
        break;
      case cardActions.open:
        if (module?.cmsdDocUrl) {
          openVeevaVault(module?.cmsdDocUrl);
        }
        break;
      case cardActions.delete:
        setModuleIdToBeDeleted(module.id);
        break;
      case cardActions.clone:
        setModuleIdToBeCloned(module.id);
        break;
      default:
        break;
    }
  };
  const cardActions = {
    publish: "publish",
    applyBRule: "applybrules",
    syncMlr: "syncmlr",
    edit: "edit",
    open: "open",
    delete: "delete",
    view: "view",
    clone: "Make a Copy",
  };

  return (
    <>
      {(modules || [])?.map((module, index) => {
        return (
          <ModuleCard
            module={module}
            selectedTab={selectedTab}
            cardActions={cardActions}
            executeModuleAction={executeModuleAction}
          />
        );
      })}
      <CustomModal
        displayModal={moduleIdToBeDeleted}
        hideModal={() => {
          setModuleIdToBeDeleted(undefined);
        }}
        size="sm"
        dilogClassName="alertModalSmall"
      >
        <ConfirmationPopup
          title={"Deleting Module"}
          text="Are you sure you want to delete the Module?"
          closeModal={() => {
            setModuleIdToBeDeleted(undefined);
          }}
          handleConfirm={() => {
            removeModule(moduleIdToBeDeleted);
            setModuleIdToBeDeleted(undefined);
          }}
        />
      </CustomModal>

      {moduleIdToBeCloned ? (
        <CloneModule
          sourceModuleId={moduleIdToBeCloned}
          onClose={() => {
            setModuleIdToBeCloned(undefined);
          }}
        />
      ) : null}
    </>
  );
};

export default ModulesList;
