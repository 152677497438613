import React, { useContext, useState } from "react";
import styles from "styles/modules/importClaims.module.scss";
import SwicthButton from "components/SwitchButton";
import { DangerButton } from "components/Buttons";
import { removeElementFromModule, toggleMustUse } from "services/apis";
import { showError } from "Uitls/Util";
import { ModuleContext, moduleActionTypes } from "contexts/ModuleContext";
import {
  getElementName,
  getElementNameKey,
  getElementsType,
  getSystemIdKey,
  getToggleEventName,
  moduleScreens,
} from "pages/module/ModuleUtil";
import CustomModal from "components/customModal";
import ConfirmationPopup from "components/ConfirmationPopup";

function ComponentsTitleRow(prpos) {
  const { elementLabel } = prpos;
  const {
    module,
    updateModule,
    currentActiveElementIndex,
    activeScreen,

    setElementToBeRemoved,
    setActiveElementType,
    getMessageTypeLabel,
    getTextTypeLabel,
  } = useContext(ModuleContext);
  //const [elementIndx, setElementIndex] = useState(currentActiveElementIndex);
  const getCurrentElement = () => {
    const elements = module[getElementsType(activeScreen)];
    if (elements && elements.length > 0) {
      return elements[currentActiveElementIndex];
    }
  };
  const getElementsMetaData = () => {
    let metadata = {};
    const currentElement = getCurrentElement();
    if (currentElement) {
      metadata[`${getElementName(activeScreen)} ID`] =
        currentElement[getElementNameKey(activeScreen)];
      if (getElementsType(activeScreen) === "reusableTexts") {
        metadata["Reusable Text Type"] = currentElement.textType
          ? currentElement.textType
          : "-";
      }
      if (currentElement.subType) {
        metadata["Sub-Type"] = currentElement.subType;
      }
      if (currentElement.classification) {
        metadata["Classification"] = currentElement.classification;
      }
      metadata["Topic"] = currentElement.topicName
        ? currentElement.topicName
        : "-";
      let subTopicName =
        currentElement?.subTopicName || currentElement?.subtopicName;
      metadata["Sub-Topic"] = subTopicName ? subTopicName : "-";

      if (getElementsType(activeScreen) == "claims") {
        metadata["Message Type"] = currentElement.messageType
          ? currentElement.messageType
          : "-";
      }
    }

    return metadata;
  };

  const handleToggle = (toggleValue) => {
    const currentElement = getCurrentElement();

    toggleMustUse(
      module.moduleId,
      getElementsType(activeScreen),
      currentElement[getSystemIdKey(activeScreen)],
      toggleValue,
      (res, err) => {
        if (res) {
          updateModule(getToggleEventName(activeScreen), {
            id: currentElement[getSystemIdKey(activeScreen)],
            toggleValue: toggleValue,
          });

          return;
        }
        showError(err);
      }
    );
  };

  const removeElement = (element) => {
    setElementToBeRemoved(element[getSystemIdKey(activeScreen)]);
    setActiveElementType(activeScreen);
  };

  return (
    <>
      <div className="col-md-10">
        <ul className={`d-flex heightNormal ${styles.claimsMetaList}`}>
          <li className="text-uppercase">{elementLabel}</li>
          {Object.keys(getElementsMetaData())?.map((key) => {
            return (
              <li key={key}>
                <span>{key}:</span> {getElementsMetaData()[key]}
              </li>
            );
          })}
        </ul>
      </div>
      <div className="col-md-2 d-flex align-items-center justify-content-end">
        <SwicthButton
          label="Must Use"
          checked={getCurrentElement()?.mustUse}
          handleToggle={(toggleValue) => {
            handleToggle(toggleValue);
          }}
        />{" "}
        <div style={{ marginLeft: "12px", marginRight: "12px" }}>|</div>
        <DangerButton
          className="heightNormal"
          onClick={() => {
            removeElement(getCurrentElement());
          }}
        >
          REMOVE
        </DangerButton>
      </div>
      {/* <CustomModal>
        <ConfirmationPopup
          title="Remove"
          text="Are you sure, you want to Remive this claim"
        />
      </CustomModal> */}
    </>
  );
}

export default ComponentsTitleRow;
