import React, { useState, useEffect } from "react";
import LinkButton from "components/LinkButton";
//Import Icons
import SearchIcon from "img/blue_lens.svg";
import clearTextIcon from "img/clear_text.svg";

//Import styles
import "styles/components/RectangularSearchTextField.scss";

const RectangularSearchTextField = ({
  inlineButton = true,
  placeholder = "Search...",
  onSearchInputChange,
  handleSearch,
  isResetClicked,
  charLimit,
}) => {
  const [isSearchBarEmpty, setIsSearchBarEmpty] = useState(true);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (isResetClicked) {
      clearTextField();
    }
  }, [isResetClicked]);
  const clearTextField = () => {
    const element = document.getElementById("rgl-search-field");
    if (element) {
      element.value = "";
      setIsSearchBarEmpty(true);
      onSearchInputChange("");
      setInputValue("");
    }
  };
  const handleSearchClick = (type) => {
    handleSearch(type);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch("search");
      event.preventDefault();
      event.target.blur();
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (!charLimit || value.length <= charLimit) {
      setInputValue(value);
      setIsSearchBarEmpty(value.trim().length === 0);
      onSearchInputChange(value);
    }
  };

  return (
    <div className="d-flex">
      <div className="tf-rectangular-search">
        <div
          className="d-flex align-items-center"
          style={{ paddingTop: "5px" }}
        >
          <img src={SearchIcon} alt="" className="search-icon" />
          <input
            id="rgl-search-field"
            type="text"
            placeholder={placeholder}
            className="search-input"
            autoComplete="off"
            value={inputValue}
            onKeyPress={handleKeyPress}
            onChange={handleInputChange}
          />

          {inlineButton && (
            <LinkButton
              className=""
              handleClick={(event) => {
                handleSearchClick("search");
              }}
              disabled={isSearchBarEmpty}
              title="SEARCH"
            />
          )}

          {!isSearchBarEmpty && (
            <img
              src={clearTextIcon}
              alt=""
              className="cross-icon"
              onClick={(event) => {
                clearTextField();
                handleSearchClick("searchBarEmpty");
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default RectangularSearchTextField;
