import React from "react";

import UnAuthorizeModal from "components/unAuthorizeModal";
import SomeServerError from "components/someServerErrorModal";
import NotFoundError from "components/notFoundErrorModal";
import DefaultApiErrorModal from "components/defaultApiErrorModal";
import AuthenticateErr from "components/authenticateErrModal";
import InvalidDataErrModal from "components/invalidDataErrModal";

const errComponentToRender = (errorCode, errorMessage) => {
  switch (errorCode) {
    case 400:
      return <InvalidDataErrModal errorMessage={errorMessage} />;
    case 401:
      return <AuthenticateErr />;
    case 500:
      return <SomeServerError />;
    case 404:
      return <NotFoundError />;
    case 403:
      return <UnAuthorizeModal />;
    default:
      return <DefaultApiErrorModal />;
  }
};

export default errComponentToRender;
