import React from "react";
import styled from "styled-components";
import variables from "styles/base/_universal.scss";
import { BoldText } from "./Texts";
import { useKeycloak } from "@react-keycloak/web";
import DoubleLinekeyValue from "./DoubleLineKeyValue";

import moment from "moment";

const AuthorInfo = ({ title, source, userName, module }) => {
  title = title.toUpperCase();
  // const { keycloak } = useKeycloak();
  // const userName = !!keycloak.authenticated ? keycloak.tokenParsed.name : null;
  return (
    <Container>
      <BoldText fontSize={variables.fontSmallV2}>{title}</BoldText>
      <SourceDiv>
        <DoubleLinekeyValue label="Source" value={source} />
      </SourceDiv>
      <DoubleLinekeyValue
        label="Created by"
        value={module?.createdByName || userName}
      />
      <DoubleLinekeyValue
        label="Created on"
        value={
          module?.createdDateTime
            ? moment(module?.createdDateTime).format("DD/MM/yyyy")
            : moment(new Date().toDateString()).format("DD/MM/yyyy")
        }
      />
      {module?.editedByName && (
        <DoubleLinekeyValue
          label="Last edited by"
          value={module?.editedByName ? module?.editedByName : "-"}
        />
      )}
      {module?.editedByName && module?.editedDateTime && (
        <DoubleLinekeyValue
          label="Last edited on"
          value={
            module?.editedDateTime
              ? moment(module?.editedDateTime).format("DD/MM/yyyy")
              : "-"
          }
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  background-color: ${variables.paleGrayColor};
  height: 100%;
  padding: 17px 20px 0px 20px;
  border-top-left-radius: 8px;
`;

const SourceDiv = styled.div`
  border-bottom: solid 1px ${variables.borderColor};
`;

export default AuthorInfo;
