import React, { useEffect, useState, useRef } from "react";
import "styles/components/ScrollableChipComponent.scss";
import CloseIcon from "img/closeIcon.svg";
import LeftArrow from "img/scrollLeftIcon.svg";
import RightArrow from "img/scrollRightIcon.svg";
import PrimaryButtonOutline from "components/PrimaryButtonOutline";

const ScrollableChipComponent = ({
  filterDataToDisplay,
  deleteFilterOption,
  resetAll,
}) => {
  const [showRightArrowButton, setShowRightArrowButton] = useState(false);
  const [showLeftArrowButton, setShowLeftArrowButton] = useState(false);
  const scrollContainerRef = useRef(null);
  useEffect(() => {
    const scrollContainer = document.querySelector(".scroll-container");

    const handleScroll = () => {
      const { scrollLeft, scrollWidth, clientWidth } = scrollContainer;
      setShowLeftArrowButton(scrollLeft > 0);
      setShowRightArrowButton(scrollWidth - clientWidth > scrollLeft);
    };

    scrollContainer.addEventListener("scroll", handleScroll);
    handleScroll(); // Initial check
    return () => {
      scrollContainer.removeEventListener("scroll", handleScroll);
    };
  }, [filterDataToDisplay]);
  const leftScroll = () => {
    const container = scrollContainerRef.current;
    container.scrollBy({
      left: -100,
      behavior: "smooth",
    });
  };

  const rightScroll = () => {
    const container = scrollContainerRef.current;
    container.scrollBy({
      left: 100,
      behavior: "smooth",
    });
  };
  return (
    <>
      <div className={`${showLeftArrowButton ? "arrow-navigation" : ""}`}>
        {showLeftArrowButton && (
          <img src={LeftArrow} alt="" onClick={leftScroll} />
        )}
      </div>
      <div
        className="scroll-container"
        id="scroll-container"
        ref={scrollContainerRef}
      >
        {filterDataToDisplay?.map((item, index) => (
          <div key={index} className="custom-chip">
            <span>{item.displayName}</span>
            <img
              src={CloseIcon}
              alt=""
              className="close-icon"
              onClick={(e) => deleteFilterOption(item)}
            />
          </div>
        ))}
        {filterDataToDisplay.length > 0 &&
          !showRightArrowButton &&
          !showLeftArrowButton && (
            <PrimaryButtonOutline
              title={`Reset All`}
              handleClick={() => {
                resetAll();
              }}
              btnType="outline"
              showIcon={false}
              height={32}
            />
          )}
      </div>
      <div
        className={`${showRightArrowButton ? "arrow-navigation-right" : ""}`}
      >
        {showRightArrowButton && (
          <img src={RightArrow} alt="" onClick={rightScroll} />
        )}
      </div>
      {(showRightArrowButton || showLeftArrowButton) && (
        <div className="resetl-all">
          <PrimaryButtonOutline
            title={`Reset All`}
            handleClick={() => {
              resetAll();
            }}
            btnType="outline"
            showIcon={false}
            height={32}
          />
        </div>
      )}
    </>
  );
};
export default ScrollableChipComponent;
