import React from "react";
import styled from "styled-components";
const Footer = styled.div`
  width: 100%;
  padding: 14px 20px;
  box-shadow: 1px -3px 10px 0 rgba(0, 0, 0, 0.07);
  display: block;
  z-index: 1;
`;
const Left = styled.div`
  float: left !important;
`;

const Intermediate = styled.div`
  margin-right: 44px;
`;
const footerSize = {
  md: 14,
  sm: 8,
};

const SmallFooter = styled(Footer)`
  padding: 8px 16px;
`;
const FooterButtons = ({
  primaryButton,
  secondaryButton,
  intermediateButtons,
  size = "md",
}) => {
  const Component = size === "sm" ? SmallFooter : Footer;
  return (
    <Component $verticalPadding>
      <Left>{secondaryButton}</Left>
      <div className="d-flex justify-content-end">
        {intermediateButtons &&
          intermediateButtons.map((intermediateButton, index) => {
            return (
              <Intermediate key={index}>{intermediateButton}</Intermediate>
            );
          })}
        {primaryButton}
      </div>
    </Component>
  );
};
export default FooterButtons;
