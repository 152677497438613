import React from "react";
import styled from "styled-components";
import { NormalText, BoldText } from "components/Texts";

const SingleLinekeyValue = ({
  label,
  value = "-",
  top = "",
  bottom = "",
  left = "0px",
  right = "0px",
  inline = false,
  title = "",
}) => {
  return (
    <Container
      top={top}
      bottom={bottom}
      left={left}
      right={right}
      inline={inline}
      title={title}
    >
      <NormalText display="contents">{label}</NormalText>:{" "}
      <BoldText display="contents">{value}</BoldText>
    </Container>
  );
};

const Container = styled.div`
  margin-top: ${(props) => props.top};
  margin-right: ${(props) => props.right};
  margin-bottom: ${(props) => props.bottom};
  margin-left: ${(props) => props.left};
  display: ${(props) => (props.inline ? "inline" : "block")};
`;

export default SingleLinekeyValue;
