import React, { useState } from "react";
import styles from "styles/modules/ComponentCard.module.scss";

import { BoldText, NormalText } from "components/Texts";
import ImagePlaceHolder from "img/imagePlaceholder.svg";
import ImageSlectTick from "img/ImageSelectTickIcon.svg";
import ImageSelectTickDisabled from "img/ImageSelectTickDisabled.svg";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const ComponentCard = ({
  selectedComponent,
  componentObjet,
  onSelectComponent,
  selectedComponentId,
  isAlreadyAdded,
}) => {
  const { t } = useTranslation();
  const isSelected = (componentId) => {
    const index = selectedComponentId.findIndex((item) => {
      return item === componentId;
    });
    return index > -1 ? styles.componentCardSelected : "";
  };
  const isComponentSelected = (componentId) => {
    const index = selectedComponentId.findIndex((item) => {
      return item === componentId;
    });
    return index > -1 ? true : false;
  };

  return (
    <div className={`${styles.componentCardParent} ellipssesSingleLine`}>
      <div
        title={`${
          isAlreadyAdded(componentObjet.componentDocNumber)
            ? t("module.alreadyAdded")
            : ""
        }`}
        className={`${styles.componentCard} ellipssesSingleLine ${isSelected(
          componentObjet.componentDocNumber
        )} ${
          isAlreadyAdded(componentObjet.componentDocNumber)
            ? styles.componentCardDisabled
            : ""
        }`}
        onClick={() => {
          if (isAlreadyAdded(componentObjet.componentDocNumber)) {
            return;
          }
          onSelectComponent(componentObjet.componentDocNumber);
        }}
      >
        {isComponentSelected(componentObjet.componentDocNumber) && (
          <img
            src={ImageSlectTick}
            alt=""
            className={`${styles.imageSelectionTick}`}
          ></img>
        )}
        {isAlreadyAdded(componentObjet.componentDocNumber) && (
          <img
            src={ImageSelectTickDisabled}
            alt=""
            className={`${styles.imageSelectionTick}`}
          ></img>
        )}

        <div
          className={`${styles.imageBlock}`}
          style={{
            backgroundImage: `url(${componentObjet.componentUrl})`,
            backgroundColor: componentObjet.componentUrl ? "transparent" : "",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "contain",
          }}
        >
          {componentObjet?.componentUrl ? (
            ""
          ) : (
            <img
              src={ImagePlaceHolder}
              alt="Placeholder"
              className="btnVhCenter"
            />
          )}
        </div>

        <div className={`d-block `} style={{ margin: "12px" }}>
          <div className="d-flex">
            <NormalText>ID:</NormalText>{" "}
            <BoldText
              fontWeight="bold"
              style={{ marginLeft: "2px" }}
              disabled={isAlreadyAdded(componentObjet.componentDocNumber)}
            >
              {componentObjet.componentDocNumber}
            </BoldText>{" "}
          </div>
          <div className="d-flex">
            <NormalText>Name:</NormalText>{" "}
            <BoldText
              fontWeight="bold"
              style={{
                marginLeft: "2px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
              disabled={isAlreadyAdded(componentObjet.componentDocNumber)}
            >
              {componentObjet.componentName
                ? componentObjet.componentName
                : "-"}
            </BoldText>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComponentCard;
