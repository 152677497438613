import { showError } from "Uitls/Util";
import { BorderedOutLineButton } from "components/Buttons";
import DropDownWithSearch from "components/DropDownWithSearch";
import MultiSelectDropDownWithInlineSearch from "components/MultiSelectDropDownWithInlineSearch";
import RectangularSearchTextField from "components/RectangularSearchTextField";
import SwicthButton from "components/SwitchButton";
import { BoldText, NormalText } from "components/Texts";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { fetchTopicsAndSubtopics, getSubTopics } from "services/apis";
import styled from "styled-components";
import variables from "styles/base/_universal.scss";

const ModuleFilter = ({
  filterData,
  handleFilter,
  resetFilters,
  showAllSelected,
  showAllRef,
}) => {
  const { t } = useTranslation();
  const [topics, setTopics] = useState([]);
  const [subtopics, setSubtopics] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [resetClicked, setResetClicked] = useState(true);
  const [showSelected, toggleShowSeelected] = useState(false);
  useEffect(() => {
    getTopics();
  }, []);

  useEffect(() => {
    fetchSubtopics();
  }, [filterData.topic]);

  const shouldResetBeDisbaled = () => {
    return (
      filterData?.topic ||
      filterData?.subtopic?.length > 0 ||
      filterData?.searchText?.trim()?.length > 0
    );
  };
  useEffect(() => {
    setResetClicked(!shouldResetBeDisbaled());
  }, [filterData]);

  useEffect(() => {
    if (showAllRef && typeof showAllRef.current === "boolean") {
      toggleShowSeelected(showAllRef.current);
    }
  }, [showAllRef?.current]);

  const getTopics = () => {
    fetchTopicsAndSubtopics((res, err) => {
      if (res) {
        setTopics(
          res.data.map((topic) => {
            return { id: topic.topicDamId, displayName: topic.topicName };
          })
        );
        return;
      }
      showError(err);
    });
  };

  const fetchSubtopics = () => {
    if (filterData?.product?.id && filterData?.topic?.id) {
      const payload = {
        productDamIds: [filterData?.product?.id],
        topicDamIds: [filterData?.topic?.id],
      };
      getSubTopics(payload, (res, err) => {
        if (res) {
          setSubtopics(
            res.data.map((subtopic) => {
              return {
                id: subtopic.subtopicDamId,
                displayName: subtopic.subtopicName,
              };
            })
          );
          return;
        }
        showError(err);
      });
    }
  };

  const handleToggleOn = (isToggleOn) => {
    showAllSelected(isToggleOn);
    toggleShowSeelected(isToggleOn);
    // if (showAllRef) {
    //   showAllRef.current = isToggleOn;
    // }
    showAllRef.current = isToggleOn;
    if (isToggleOn) {
      setResetClicked(true);
    }
    // setResetClicked(true);
  };

  return (
    <div>
      <div style={{ height: "26px" }}>
        <TitleDiv>
          <BoldText display="contents">
            {t("businessRules.selectOneorMoreModules")}
          </BoldText>
        </TitleDiv>
        <ShowAllSelected ref={showAllRef}>
          <SwicthButton
            label={t("generalTexts.showAllSelected")}
            handleToggle={(isToggleOn) => {
              handleToggleOn(isToggleOn);
            }}
            checked={showAllRef?.current}
          />
        </ShowAllSelected>
      </div>

      <Filters>
        <div style={{ flex: "0 0 15%", paddingRight: "16px" }}>
          <DropDownWithSearch
            options={{
              value: filterData.product, //dropDownData?.topic,
              items: [filterData.product], //getTopicsFilterData(topicsData),
              name: t("metadata.brand"),
              label: t("metadata.brand"),
              isDataTypeObject: true,
              placeholder: "Search and Select",
              handleChange: (selectedValue) => {},
              disabled: true,
            }}
          />
        </div>
        <div style={{ flex: "0 0 15%", paddingRight: "16px" }}>
          <DropDownWithSearch
            options={{
              value: filterData.country, //dropDownData?.topic,
              items: [filterData.country], //getTopicsFilterData(topicsData),
              name: t("metadata.market"),
              label: t("metadata.market"),
              isDataTypeObject: true,
              placeholder: "Search and Select",
              handleChange: (selectedValue) => {},
              disabled: true,
            }}
          />
        </div>

        <div style={{ flex: "0 0 15%", paddingRight: "16px" }}>
          <DropDownWithSearch
            options={{
              value: filterData.topic, //dropDownData?.topic,
              items: topics, //getTopicsFilterData(topicsData),
              name: t("metadata.topic"),
              label: t("metadata.topic"),
              isDataTypeObject: true,
              placeholder: "Search and Select",
              handleChange: (selectedValue) => {
                handleFilter(selectedValue, "topic");
              },
            }}
          />
        </div>
        <div style={{ flex: "0 0 15%", paddingRight: "16px" }}>
          <MultiSelectDropDownWithInlineSearch
            options={{
              value: filterData.subtopic,
              items: subtopics,
              name: t("metadata.subtopic"),
              label: t("metadata.subtopic"),
              isDataTypeObject: true,
              placeholder: "Search and Select",
              handleChange: (selectedValue) => {
                handleFilter(selectedValue, "subtopic");
              },
              disabled: !filterData?.topic,
            }}
          />
        </div>
        <div style={{ flex: "0 0 30%", paddingRight: "16px" }}>
          <RectangularSearchTextField
            placeholder="Search by ‘Module Name / ID’"
            onSearchInputChange={(value) => {
              setSearchText(value);
              if (value?.trim()?.length === 0) {
                if (!showSelected) {
                  handleFilter("", "searchText");
                }
                //handleFilter("", "searchText");
              }
            }}
            handleSearch={(type) => {
              if (type === "searchBarEmpty") {
                handleFilter("", "searchText");
                return;
              }
              handleFilter(searchText, "searchText");
            }}
            isResetClicked={resetClicked}
          />
        </div>

        <div style={{ flex: "0 0 10%" }}>
          <BorderedOutLineButton
            btnLable="Reset All"
            //   disabled={!searchTriggered}
            onClick={() => {
              handleToggleOn(false);
              setResetClicked(true);
              resetFilters();
            }}
            //disabled={resetClicked}
            disabled={resetClicked}
          >
            {t("generalTexts.resetAll").toUpperCase()}
          </BorderedOutLineButton>
        </div>
      </Filters>
      <Divider />
    </div>
  );
};

const TitleDiv = styled.div`
  display: flex;
  float: left;
  margin-top: 12px;
`;
const ShowAllSelected = styled.div`
  float: right;
  margin-top: 10px;
`;
const Filters = styled.div`
  display: flex;
  margin: 12px 0px 0px 0px;
`;
const Divider = styled.div`
  border-bottom: 1px solid ${variables.borderColor};
  margin: 0px -15px 0px -15px;
`;

export default ModuleFilter;
