import React, { useContext, useEffect, useReducer, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import ModuleHeader from "components/modules/ModuleHeader";
import ModuleSteps from "components/modules/ModuleSteps";
import { ModuleContext, moduleActionTypes } from "contexts/ModuleContext";
import { fetchModuleDetails } from "services/apis";
import { useTranslation } from "react-i18next";
import { routes, showError } from "Uitls/Util";
import CircularProgressBar from "Uitls/CircularProgressBar";
import { moduleScreens } from "pages/module/ModuleUtil";
import ModulePreview from "components/modules/ModulePreview";

function Page(props) {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const [isloading, setIsloading] = useState(false);
  const [modulePreviewData, setModulePreviewData] = useState([]);
  const [isDataReady, setIsDataReady] = useState(false);

  useEffect(() => {
    const queryUrl = location.search;
    const urlParams = new URLSearchParams(queryUrl);
    const moduleId = urlParams.get("moduleId");
    if (location?.state || moduleId) {
      sessionStorage.setItem("moduleId", location?.state?.moduleId || moduleId);
      getModuleDetailsForPreview();
    } else {
      history.replace(routes.dashboard);
    }
  }, []);
  const {
    module,
    activeScreen,
    updateModule,
    elementToBeRemoved,
    setElementToBeRemoved,
    activeElementType,
    setActiveElementType,
    setCurrentElementActiveIndex,
    setActiveElementId,
    setActiveScreen,
    setMessageTypes,
    setTextTypes,
  } = useContext(ModuleContext);
  const getModuleDetailsForPreview = (moduleIds) => {
    const moduleId = sessionStorage.getItem("moduleId");
    if (!moduleId) {
      return;
    }
    setIsloading(true);
    fetchModuleDetails(moduleId, (res, err) => {
      if (res) {
        setIsloading(false);
        setModulePreviewData(res.data);
        updateModule(moduleActionTypes.SET_MODULE, res.data);
        setIsDataReady(true);
      } else {
        setIsloading(false);
        if (err.response.status === 500) {
          history.replace(routes.dashboard);
        } else {
          showError(err);
        }
      }
    });
  };

  return (
    <>
      <CircularProgressBar isloading={isloading} />
      <ModuleHeader />
      <ModuleSteps activeScreen={4} />
      {isDataReady && (
        <ModulePreview
          moduleScreens={moduleScreens}
          modulePreviewData={modulePreviewData}
          preview={true}
          setModulePreviewData={setModulePreviewData}
        />
      )}
    </>
  );
}

export default Page;
