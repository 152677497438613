import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import RectangularSearchTextField from "components/RectangularSearchTextField";
import { getTopicsFilterData } from "dashboard/filters/TopicsFilter";
import BorderedOutLineButton from "components/BorderOutLinedButton";
import DropDownWithSearch from "components/DropDownWithSearch";
import MultiSelectDropDownWithInlineSearch from "components/MultiSelectDropDownWithInlineSearch";
import { fecthSubTopicsFilter } from "dashboard/filters/SubTopicsFilter";

const FilterComponent = ({
  messageTypes,
  setSearchTrgiggered,
  searchTriggered,
  toggle,
  setFilteredClaims,
  setToggle,
  dropDownData,
  setDropDownData,
  topicsData,
  subTopicsData,
  setCurrentPage,
  setHasMore,
  searchInput,
  setSearchInput,
  isResetClicked,
  setIsResetClicked,
  resetAll,
}) => {
  const { t } = useTranslation();
  const charLimit = 250;
  // const [searchInput, setSearchInput] = useState("");
  // const [isResetClicked, setIsResetClicked] = useState(false);

  // useEffect(() => {
  //   if (toggle) {
  //     setIsResetClicked(true);
  //     setDropDownData({});
  //     setSearchInput("");
  //     setSearchTrgiggered(false);
  //     setFilteredClaims([]);
  //     setCurrentPage(1);
  //     setTimeout(() => {
  //       setIsResetClicked(false);
  //     }, [0]);
  //   } else {
  //     // setCurrentPage(1);
  //     // setHasMore(true);
  //     // setFilteredClaims([]);
  //     resetAll();
  //   }
  //   // if (toggle) {
  //   //   //resetAll();
  //   //   setDropDownData({});
  //   // }
  // }, [toggle]);

  const handleSearch = (type) => {
    setToggle(false);
    if (type === "search") {
      setDropDownData((prevState) => {
        return { ...prevState, searchText: searchInput };
      });
      setSearchTrgiggered(true);
    } else {
      setDropDownData((prevState) => {
        return { ...prevState, searchText: undefined };
      });
    }
    setCurrentPage(1);
  };

  // const resetAll = () => {
  //   setIsResetClicked(true);
  //   setDropDownData({});
  //   setHasMore(true);
  //   setSearchInput("");
  //   setSearchTrgiggered(false);
  //   setFilteredClaims([]);
  //   setToggle(false);
  //   setCurrentPage(1);
  //   setTimeout(() => {
  //     setIsResetClicked(false);
  //   }, [0]);
  // };
  const handleDropdownSelection = (value, type) => {
    setToggle(false);
    switch (type) {
      case "messageType":
        setDropDownData((prevState) => {
          return { ...prevState, messageType: value };
        });
        break;
      case "topic":
        setDropDownData((prevState) => {
          return { ...prevState, topic: value, subtopics: undefined };
        });
        break;
      case "subtopic":
        setDropDownData((prevState) => {
          return { ...prevState, subtopics: value };
        });
        break;
      default:
        break;
    }
    setSearchTrgiggered(true);
    setCurrentPage(1);
  };
  return (
    <div className="d-flex">
      <div style={{ flex: "0 0 30%", paddingRight: "16px" }}>
        <RectangularSearchTextField
          placeholder="Search by ‘ID / Match Text’"
          onSearchInputChange={(value) => {
            if (value.trim() === 0 || value.trim() === "") {
              setSearchInput("");
              if (!toggle) {
                handleSearch("searchBarEmpty");
              }
            } else {
              setSearchInput(value);
            }
          }}
          handleSearch={(type) => {
            if (type === "searchBarEmpty") {
              handleSearch("", "searchText");
              return;
            }
            handleSearch("search");
          }}
          isResetClicked={isResetClicked}
          charLimit={charLimit}
        />
      </div>
      <div style={{ flex: "0 0 20%", paddingRight: "16px" }}>
        <DropDownWithSearch
          options={{
            value: dropDownData?.topic,
            items: getTopicsFilterData(topicsData),
            name: t("metadata.topic"),
            label: t("metadata.topic"),
            isDataTypeObject: true,
            placeholder: "Search and Select",
            handleChange: (selectedValue) => {
              handleDropdownSelection(selectedValue, "topic");
            },
          }}
        />
      </div>
      <div style={{ flex: "0 0 20%", paddingRight: "16px" }}>
        <MultiSelectDropDownWithInlineSearch
          options={{
            value: dropDownData?.subtopics,
            items: fecthSubTopicsFilter(subTopicsData),
            name: t("metadata.subtopic"),
            label: t("metadata.subtopic"),
            isDataTypeObject: true,
            placeholder: "Search and Select",
            handleChange: (selectedValue) => {
              handleDropdownSelection(selectedValue, "subtopic");
            },
            disabled: !dropDownData?.topic,
          }}
        />
      </div>
      <div style={{ flex: "0 0 20%", paddingRight: "16px" }}>
        <DropDownWithSearch
          options={{
            value: dropDownData?.messageType,
            items: messageTypes,
            name: t("importClaims.messageType"),
            label: t("importClaims.messageType"),
            isDataTypeObject: true,
            placeholder: "Search and Select",
            handleChange: (selectedValue) => {
              handleDropdownSelection(selectedValue, "messageType");
            },
          }}
        />
      </div>
      <div style={{ flex: "0 0 10%" }}>
        <BorderedOutLineButton
          btnLable="Reset All"
          disabled={!searchTriggered}
          handleClick={resetAll}
        />
      </div>
    </div>
  );
};
export default FilterComponent;
