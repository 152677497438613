import React, { useState, useEffect, createRef } from "react";
import styled from "styled-components";
import FooterButtons from "components/FooterButtons";
import PrimaryButton from "components/PrimaryButton";
import BusinessrulesBox from "components/businessrules/BusinessRulesBox";
import variables from "styles/base/_universal.scss";
import { useHistory } from "react-router-dom";
import { routes } from "Uitls/Util";
import styles from "styles/components/businessRules/businesrules.module.scss";
import OverlayPopOver from "Uitls/OverlayPopOver";
import { ToolTipText } from "components/TooltipText";
import { useTranslation } from "react-i18next";
import { BoldText } from "components/Texts";
import infoIcon from "img/infoIcon.svg";
import ModulePreviewData from "components/modules/ModulePreviewData";
import SwicthButton from "components/SwitchButton";
import { getMlrStatusIcon } from "Uitls/Util";
import LinkButton from "components/LinkButton";
import { costomEllipsis } from "Uitls/Util";
import SingleLinekeyValue from "components/SingleLineKeyValue";
import { emitter } from "Uitls/Util";

const BussinesRulesView = ({
  businessRulesList,
  closePreviewModal,
  modulePreviewData,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [toglePreview, setToglePreview] = useState(false);
  const [bsRules, setBsRules] = useState([
    {
      id: 1,
      ruleCondition: "MustExistWith",
      title: "Must exist with",
      rules: [],
    },
    {
      id: 2,
      ruleCondition: "CannotExistWith",
      title: "Cannot exist with",
      rules: [],
    },
    {
      id: 3,
      ruleCondition: "Optional",
      title: "",
      rules: [],
    },
  ]);
  const [associatedModuleData, setAssociatedModuleData] = useState({});
  const [isViewAssociatedModule, setIsViewAssociatedModule] = useState(false);
  const popoverRef = createRef();
  const overLayRef = createRef();
  const [autoSyncData, setAutoSyncData] = useState(undefined);
  const [mlrStatus, setMlrStatus] = useState(undefined);
  useEffect(() => {
    if (modulePreviewData?.mlrStatus) {
      setMlrStatus(modulePreviewData?.mlrStatus);
    }
  }, [modulePreviewData]);
  useEffect(() => {
    const brules = JSON.parse(JSON.stringify(bsRules)).map((item) => {
      let newData = [...item.rules];
      let index = newData.findIndex((elem) => elem.id === autoSyncData?.id);
      if (index > -1) {
        newData[index].mlrStatus = autoSyncData.mlrStatus;
        item.rules = newData;
      }
      return item;
    });
    setBsRules(brules);
    if (
      autoSyncData?.type === "Module" &&
      autoSyncData?.id === modulePreviewData.moduleId
    ) {
      setMlrStatus(autoSyncData.mlrStatus);
    }
    if (
      autoSyncData?.type === "Module" &&
      autoSyncData?.id === associatedModuleData.moduleId
    ) {
      associatedModuleData.mlrStatus = autoSyncData.mlrStatus;
      setAssociatedModuleData(associatedModuleData);
    }
  }, [autoSyncData]);

  useEffect(() => {
    const handleFooEvent = (e) => {
      try {
        const parsedData = JSON.parse(e);
        if (parsedData.type === "Module") {
          setAutoSyncData(parsedData);
        }
      } catch (error) {
        console.error("Received non-JSON message:", e);
      }
    };

    emitter.on("autoSync", handleFooEvent);

    return () => {
      emitter.off("autoSync", handleFooEvent);
    };
  }, []);
  useEffect(() => {
    bsRules.forEach((rule) => {
      businessRulesList.businessRules.forEach((businessRule) => {
        if (rule.ruleCondition === businessRule.ruleCondition) {
          const rulesObj = [];
          businessRule.targetModules.map((item) => {
            rulesObj.push({
              id: item.targetModuleId,
              nextUniqueModuleCode: item.targetNextUniqueModuleCode,
              moduleDamId: item.targetModuleDamId,
              moduleDamName: item.targetModuleDamName,
              productName: item.productName,
              moduleName: item.targetModuleName,
              componentThumbnailUrl: item.targetModuleComponentThumbnailUrl,
              mlrStatus: item.targetModuleMlrStatus,
            });
          });
          rule.rules = [...rulesObj];
        }
      });
    });
    setBsRules([...bsRules]);
  }, [businessRulesList]);
  const CancelButton = (
    <PrimaryButton
      title={"Close".toUpperCase()}
      handleClick={() => closePreviewModal()}
      fontSize="12px"
    />
  );

  const EditButton = (
    <LinkButton
      title={"EDIT Business Rules".toUpperCase()}
      handleClick={() => goToBusinessRules()}
      disabled={
        mlrStatus?.toLowerCase() !== "draft" &&
        mlrStatus?.toLowerCase() !== "revise and resubmit"
      }
    />
  );

  const goToBusinessRules = () => {
    history.push({
      pathname: routes.businessRules,
    });
    modulePreviewData["id"] = modulePreviewData.moduleId;
    localStorage.setItem("bsrulesPageData", JSON.stringify(modulePreviewData));
  };

  const viewAssociatedModule = (moduleData) => {
    setAssociatedModuleData(moduleData);
    setIsViewAssociatedModule(true);
  };

  const Status = () => {
    return (
      <>
        <img
          src={getMlrStatusIcon(associatedModuleData?.mlrStatus)}
          alt=""
          className="mt2 mr4"
        />
        {associatedModuleData?.mlrStatus}
      </>
    );
  };

  const BackToBussinessRulesViewButton = (
    <PrimaryButton
      title={"Back to View business rules".toUpperCase()}
      handleClick={() => {
        setIsViewAssociatedModule(false);
        setAssociatedModuleData({});
      }}
      fontSize="12px"
    />
  );

  return (
    <>
      <Container>
        <div ref={overLayRef} className="row" style={{ marginBottom: "16px" }}>
          {!isViewAssociatedModule ? (
            <>
              <div className="col-md-8 d-flex">
                <TitleText>View Business Rules</TitleText>
                <MetaInfoContainer>
                  |{" "}
                  <OverlayPopOver
                    element={
                      <img
                        src={getMlrStatusIcon(mlrStatus)}
                        alt={mlrStatus}
                        className="mt2"
                        style={{ marginLeft: "12px", marginRight: "6px" }}
                      />
                    }
                    overlayData={
                      <ToolTipText>
                        {mlrStatus ? mlrStatus : "Not Published"}
                      </ToolTipText>
                    }
                    trigger={["hover", "focus"]}
                    placement="bottom"
                    classId="tooltip"
                    containerRef={overLayRef}
                  />
                  <TitleText>{modulePreviewData?.moduleDamName}</TitleText>
                </MetaInfoContainer>
                <MetaInfoContainer>
                  |
                  <OverlayPopOver
                    element={
                      <TitleText style={{ marginLeft: "12px" }}>
                        {costomEllipsis(
                          modulePreviewData?.moduleName || "-",
                          20
                        )}
                      </TitleText>
                    }
                    overlayData={
                      <ToolTipText>{modulePreviewData?.moduleName}</ToolTipText>
                    }
                    trigger={["hover", "focus"]}
                    placement="bottom"
                    classId="tooltip"
                    containerRef={overLayRef}
                  />
                </MetaInfoContainer>
              </div>
              <div className="col-md-4 d-flex justify-content-end">
                <SwicthButton
                  label="Show CMSD Preview"
                  checked={toglePreview}
                  handleToggle={() => {
                    setToglePreview(!toglePreview);
                  }}
                />
              </div>
            </>
          ) : (
            <>
              <div className="col-md-4" style={{ marginBottom: "7px" }}>
                <TitleText>CMSD Preview</TitleText>
              </div>
              <div
                className="col-md-8 d-flex justify-content-end"
                style={{ marginBottom: "7px" }}
              >
                <OverlayPopOver
                  element={
                    <MetaInfoContainer>
                      <SingleLinekeyValue
                        label="Module Name"
                        value={costomEllipsis(
                          associatedModuleData?.moduleName || "-",
                          40
                        )}
                        top="0px"
                        bottom="0px"
                      />
                    </MetaInfoContainer>
                  }
                  overlayData={
                    <ToolTipText>
                      {associatedModuleData?.moduleName}
                    </ToolTipText>
                  }
                  trigger={["hover", "focus"]}
                  placement="bottom"
                  classId="tooltip"
                  containerRef={overLayRef}
                />

                <MetaInfoContainer>
                  <SingleLinekeyValue
                    label="Module ID"
                    value={
                      associatedModuleData?.moduleDamName
                        ? associatedModuleData?.moduleDamName
                        : "-"
                    }
                    top="0px"
                    bottom="0px"
                  />
                </MetaInfoContainer>
                <MetaInfoContainer>
                  <SingleLinekeyValue
                    label="MLR Status"
                    value={Status()}
                    top="0px"
                    bottom="0px"
                  />
                </MetaInfoContainer>
              </div>
            </>
          )}
        </div>
      </Container>

      <ContainerBody
        backGroundColorChange={toglePreview || isViewAssociatedModule}
      >
        {toglePreview || isViewAssociatedModule ? (
          <ModulePreviewData
            modulePreviewData={
              isViewAssociatedModule ? associatedModuleData : modulePreviewData
            }
            openedFromModal={true}
          />
        ) : (
          <>
            <div ref={popoverRef} className="row mt-16">
              <div className="col-md-4">
                <div
                  className={styles.businessRulesSubTitle}
                  style={{ textTransform: "none" }}
                >
                  Must exist with (Compliance)
                  <OverlayPopOver
                    element={
                      <img src={infoIcon} alt="Info" className="ml-1 mt2" />
                    }
                    overlayData={
                      <ToolTipText>
                        <span>
                          <BoldText display="contents">
                            {bsRules[0].title} :{" "}
                          </BoldText>
                          {t("businessRules.infoMustExistWith")}
                        </span>
                      </ToolTipText>
                    }
                    classId="tooltip"
                    trigger={["hover", "focus"]}
                    placement="bottom"
                    containerRef={popoverRef}
                  />
                </div>
              </div>
              <div className="col-md-4">
                {" "}
                <div
                  className={styles.businessRulesSubTitle}
                  style={{ textTransform: "none" }}
                >
                  Cannot exist with (Compliance)
                  <OverlayPopOver
                    element={
                      <img src={infoIcon} alt="Info" className="ml-1 mt2" />
                    }
                    overlayData={
                      <ToolTipText>
                        <span>
                          <BoldText display="contents">
                            {bsRules[1].title} :{" "}
                          </BoldText>
                          {t("businessRules.infoCannotExistWith")}
                        </span>
                      </ToolTipText>
                    }
                    classId="tooltip"
                    trigger={["hover", "focus"]}
                    placement="bottom"
                    containerRef={popoverRef}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div
                  className={styles.businessRulesSubTitle}
                  style={{ textTransform: "none" }}
                >
                  Related modules (Optional){" "}
                  <OverlayPopOver
                    element={
                      <img src={infoIcon} alt="Info" className="ml-1 mt2" />
                    }
                    overlayData={
                      <ToolTipText>
                        <span>
                          <BoldText display="contents">
                            {t("businessRules.relatedModule")} :{" "}
                          </BoldText>
                          {t("businessRules.infoRelatedModules")}
                        </span>
                      </ToolTipText>
                    }
                    classId="tooltip"
                    trigger={["hover", "focus"]}
                    placement="bottom"
                    containerRef={popoverRef}
                  />
                </div>
              </div>
            </div>
            <div className="row mt-12">
              {bsRules.map((item) => (
                <BusinessrulesBox
                  key={item.id}
                  id={item.id}
                  name={item.title}
                  setBsRules={setBsRules}
                  bsRules={bsRules}
                  actionType="view"
                  viewAssociatedModule={viewAssociatedModule}
                />
              ))}
            </div>
            <div className="row mt20">
              <div className="col-md-12">
                <GuidelinesContainer>
                  <GuidelinesTitle>Usage Guidelines</GuidelinesTitle>
                  <GuidelinesValue>
                    {businessRulesList?.usageGuidelines}
                  </GuidelinesValue>
                </GuidelinesContainer>
              </div>
            </div>
          </>
        )}
      </ContainerBody>
      <FooterButtons
        primaryButton={
          isViewAssociatedModule ? BackToBussinessRulesViewButton : CancelButton
        }
        intermediateButtons={
          isViewAssociatedModule || toglePreview ? [] : [EditButton]
        }
      />
    </>
  );
};
const Container = styled.div`
  padding: 0px 15px 0px 15px;
  border-bottom: solid 1px #e6e6ec;
`;
const TitleText = styled.span`
  font-size: ${variables.fontSmallV2};
  font-weight: bold;
  color: ${variables.blackColor};
  //margin-top: 12px;
`;
const MetaInfoContainer = styled.div`
  margin-left: 12px;
`;
const ContainerBody = styled.div.attrs(() => ({
  className: "customScrollBar",
}))`
  overflow: auto;
  padding: 12px 15px 15px 15px;
  margin-right: 4px;
  height: calc(100vh - 200px);
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: ${(props) =>
    props.backGroundColorChange ? "#f9f9fa" : "#ffffff"};
`;
const GuidelinesContainer = styled.div.attrs(() => ({
  className: "customScrollBar",
}))`
  height: 115px;
  margin: 16px 0 0;
  padding: 16px;
  border-radius: 6px;
  border: solid 1px #d4d4d4;
  background-color: #fff;
  overflow-y: scroll;
`;
const GuidelinesTitle = styled.span`
  font-size: 12px;
  color: #1e1e1e;
  display: block;
  margin-bottom: 6px;
`;
const GuidelinesValue = styled.span`
  font-size: 12px;
  font-weight: 600;
  word-break: break-all;
  color: #1e1e1e;
`;
export default BussinesRulesView;
