import { moduleActionTypes } from "contexts/ModuleContext";

export const moduleScreens = {
  home: 0,
  claims: 1,
  reusableTexts: 2,
  components: 3,
  modulePreview: 4,
};
export const getSystemIdKey = (activeScreen) => {
  switch (activeScreen) {
    case moduleScreens.claims:
      return "claimId";
    case moduleScreens.reusableTexts:
      return "reusableTextId";
    case moduleScreens.components:
      return "componentId";
    default:
      return;
  }
};

export const getElementsType = (activeScreen) => {
  switch (activeScreen) {
    case moduleScreens.claims:
      return "claims";
    case moduleScreens.reusableTexts:
      return "reusableTexts";
    case moduleScreens.components:
      return "components";
    default:
      return;
  }
};

export const getElementNameKey = (activeScreen) => {
  switch (activeScreen) {
    case moduleScreens.claims:
      return "claimName";
    case moduleScreens.reusableTexts:
      return "reusableTextName";
    case moduleScreens.components:
      return "componentDocNumber";
    default:
      return;
  }
};

export const getElementName = (activeScreen) => {
  switch (activeScreen) {
    case moduleScreens.claims:
      return "Claim";
    case moduleScreens.reusableTexts:
      return "Reusable Text";
    case moduleScreens.components:
      return "Component";
    default:
      return;
  }
};

export const getToggleEventName = (activeScreen) => {
  switch (activeScreen) {
    case moduleScreens.claims:
      return moduleActionTypes.TOGGLE_CLAIM_MUST_USE;
    case moduleScreens.reusableTexts:
      return moduleActionTypes.TOGGLE_REUSABLE_TEXT_MUST_USE;
    case moduleScreens.components:
      return moduleActionTypes.TOGGLE_COMPONENT_MUST_USE;
    default:
      return;
  }
};

export const getRemoveEventName = (activeScreen) => {
  switch (activeScreen) {
    case moduleScreens.claims:
      return moduleActionTypes.REMOVE_CLAIM;
    case moduleScreens.reusableTexts:
      return moduleActionTypes.REMOVE_REUSABLE_TEXT;
    case moduleScreens.components:
      return moduleActionTypes.REMOVE_COMPONENT;
    default:
      return;
  }
};
