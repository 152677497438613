import React, {
  useContext,
  useEffect,
  useReducer,
  useState,
  useRef,
  useMemo,
} from "react";
import { useTranslation } from "react-i18next";
import { useHistory, Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import styles from "styles/modules/modulePreview.module.scss";
import CircularProgressBar from "Uitls/CircularProgressBar";
import ModuleBrandNavigation from "components/modules/ModuleBrandNavigation";
import ModuleHeader from "components/modules/ModuleHeader";
import ModuleSteps from "components/modules/ModuleSteps";
import Businessrules from "components/businessrules/Businessrules";
import BusinessrulesFooter from "components/businessrules/BusinessrulesFooter";
import {
  getModulesListForBusinessRules,
  getModuleBusinessRules,
  fetchModuleDetails,
  saveBusinessRules,
} from "services/apis";
import { ModuleContext, moduleActionTypes } from "contexts/ModuleContext";
import { isEmptyObject, removeDuplicates, routes, showError } from "Uitls/Util";
import ModulePreview from "components/modules/ModulePreview";
import ModuleFilter from "components/businessrules/ModuleFilter";
import AssociatedModuleSelection from "components/businessrules/AssociatedModulesSelection";
import FooterButtons from "components/FooterButtons";
import LinkButton from "components/LinkButton";
import PrimaryButton from "components/PrimaryButton";
export const brulesScreens = {
  modulesSelection: 1,
  applyRules: 2,
  bsrulesPreview: 4,
};
function Page(props) {
  const { t } = useTranslation();
  let history = useHistory();

  const [activeScreen, setActiveScreen] = useState(1);
  const [isloading, setIsloading] = useState(false);
  const [selectedModules, setSelectedModules] = useState([]);

  const [alreadyAddedModules, setAlreadyaddedModules] = useState([]); //Already added modules as b rules
  const [modulePreviewData, setModulePreviewData] = useState([]);
  const brulesPageData = JSON.parse(localStorage.getItem("bsrulesPageData"));
  const { updateModule } = useContext(ModuleContext);
  const [defaultScreen, setDefaultScreen] = useState(
    brulesScreens.modulesSelection
  );
  useEffect(() => {
    if (!brulesPageData) {
      history.push(routes.dashboard);
    } else {
      //getAllBusinessRules(data.moduleId);
      fetchModuleDetails(brulesPageData.id, (res, err) => {
        if (res) {
          updateModule(moduleActionTypes.SET_MODULE, res.data);
        } else {
          showError(err);
        }
      });
      if (brulesPageData?.businessRules?.length > 0) {
        setActiveScreen(brulesScreens.applyRules);
        setDefaultScreen(brulesScreens.applyRules);
      } else {
        setDefaultScreen(brulesScreens.modulesSelection);
      }
    }
    return () => {};
  }, []);

  const labelData = {
    pageTitle: " Apply Business Rules",
    stepOne: "Choose Associated Modules",
    stepTwo: "Apply Business Rules",
    stepThree: "Preview",
  };

  const moduleHeaderData = {
    type: "businessRules",
    nextUniqueModuleCode: brulesPageData?.nextUniqueModuleCode,
    product: brulesPageData?.productName,
    country: brulesPageData?.countryName,
    stepOne: "Choose Associated Modules",
    stepTwo: "Apply Business Rules",
    stepThree: "Preview",
  };

  const getModuleDetailsForPreview = (moduleId = brulesPageData.id) => {
    if (!moduleId) {
      return;
    }
    setIsloading(true);
    fetchModuleDetails(moduleId, (res, err) => {
      if (res) {
        let data = res.data;
        getModuleBusinessRules(moduleId, (res, err) => {
          if (res) {
            let busRules = res.data.businessRules;
            setIsloading(false);
            data.businessRules = busRules;
            setModulePreviewData(data);
            setActiveScreen(brulesScreens.bsrulesPreview);
          } else {
            setIsloading(false);
            showError(err);
          }
        });
      } else {
        setIsloading(false);
        showError(err);
      }
    });
  };

  const goToNext = () => {
    switch (activeScreen) {
      case brulesScreens.modulesSelection:
        setActiveScreen(brulesScreens.applyRules);
        break;
      case brulesScreens.applyRules:
        getModuleDetailsForPreview();
        break;
      default:
        break;
    }
  };

  const goBack = () => {
    switch (activeScreen) {
      case brulesScreens.applyRules:
        setActiveScreen(brulesScreens.modulesSelection);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <CircularProgressBar isloading={isloading} />
      <ModuleHeader
        labelData={labelData}
        moduleHeaderData={moduleHeaderData}
        isBussinessRulesView={true}
      />
      <ModuleSteps
        activeScreen={activeScreen}
        labelData={labelData}
        moduleHeaderData={moduleHeaderData}
      />
      {activeScreen === brulesScreens.bsrulesPreview ? (
        <ModuleBrandNavigation moduleHeaderData={moduleHeaderData} />
      ) : null}
      {activeScreen === brulesScreens.modulesSelection && (
        <AssociatedModuleSelection
          sourceModule={brulesPageData}
          selectedModules={selectedModules}
          setSelectedModules={setSelectedModules}
          goToNext={goToNext}
          alreadyAddedModules={alreadyAddedModules}
        />
      )}
      {activeScreen === brulesScreens.applyRules && (
        <>
          <Businessrules
            sourceModule={brulesPageData}
            modulesList={selectedModules}
            goToNext={goToNext}
            goBack={goBack}
            setAlreadyaddedModules={(modules) => {
              setAlreadyaddedModules(modules);
              //removeDuplicates();
              // setSelectedModules(
              //   removeDuplicates(selectedModules?.concat(modules), "id")
              // );
            }}
            defaultScreen={defaultScreen}
            setSelectedModules={setSelectedModules}
          />
        </>
      )}

      {activeScreen === brulesScreens.bsrulesPreview && (
        <div className={styles.bsrulesPreview}>
          <ModulePreview
            modulePreviewData={modulePreviewData}
            setActiveScreen={() => {
              setActiveScreen(brulesScreens.applyRules);
              //backPageLoadBusinessRules();
            }}
            isBussinessRulesView={true}
            setModulePreviewData={setModulePreviewData}
          />
        </div>
      )}
    </>
  );
}

export default Page;
