import React, { useState } from "react";
import styled from "styled-components";
import styles from "styles/modules/importClaims.module.scss";

function TextComponent(props) {
  const { title, data, componentType, customClass } = props;
  return (
    <>
      <div
        className={`${styles.elementxBox} ${styles.elementxBoxBrdr} ${customClass}`}
      >
        <span className={`${styles.title}`}>{title}</span>
        <div className={`${styles.fgrbox} customScrollBar`}>
          {data.map((item, index) => {
            return <TextAttribute>{item.content}</TextAttribute>;
          })}
        </div>
      </div>
    </>
  );
}

const TextAttribute = styled.div`
  margin-bottom: 20px;
`;

export default TextComponent;
