import React, { useState, useEffect } from "react";
import ModuleListCard from "components/businessrules/ModuleListCard";
import styles from "styles/components/businessRules/businesrules.module.scss";
import RoundedSearchTextField from "components/RoundedSearchTextField";
import CustomModal from "components/customModal";
import { fetchModuleDetails } from "services/apis";
import { showError, emitter } from "Uitls/Util";
import CircularProgressBar from "Uitls/CircularProgressBar";
import ModuleView from "components/businessrules/ModuleView";
import LinkButton from "components/LinkButton";
import doneIcon from "img/done.svg";
import { NormalText } from "components/Texts";
import { useTranslation } from "react-i18next";

const BrowseModules = (props) => {
  const { bsRules, modulesList, sourceModule, handleEditSelection } = props;
  const [searchText, setSearchText] = useState(undefined);
  const [filteredModuleList, setFilteredModuleList] = useState([]);
  const [modulePreviewOpen, setModulePreviewOpen] = useState(false);
  const [modulePreviewData, setModulePreviewData] = useState([]);
  const [isloading, setIsloading] = useState(false);
  const { t } = useTranslation();
  const [autoSyncData, setAutoSyncData] = useState(undefined);

  useEffect(() => {
    let newData = [...filteredModuleList];
    let index = newData.findIndex((item) => item.id === autoSyncData?.id);
    if (index > -1) {
      newData[index].mlrStatus = autoSyncData.mlrStatus;
      setFilteredModuleList(newData);
    }
  }, [autoSyncData]);

  useEffect(() => {
    const handleFooEvent = (e) => {
      try {
        const parsedData = JSON.parse(e);
        if (parsedData.type === "Module") {
          setAutoSyncData(parsedData);
        }
      } catch (error) {
        console.error("Received non-JSON message:", e);
      }
    };

    emitter.on("autoSync", handleFooEvent);

    return () => {
      emitter.off("autoSync", handleFooEvent);
    };
  }, []);

  const draggableStatus = (desiredId) => {
    let status = false;
    bsRules.map((item) => {
      item.rules.map((single) => {
        if (single.id === desiredId) {
          status = true;
        }
      });
    });
    return !status;
  };

  useEffect(() => {
    setFilteredModuleList(modulesList);
  }, [modulesList]);

  const searchModulesToBrowse = () => {
    if (searchText !== "" && searchText !== undefined) {
      let filterData = [...modulesList];
      filterData = filterData?.filter((item) => {
        let checkWith = item?.moduleName
          ? item?.moduleName
          : item?.nextUniqueModuleCode;
        return (
          checkWith?.toLowerCase().includes(searchText.toLowerCase()) ||
          item?.moduleDamName?.toLowerCase().includes(searchText.toLowerCase())
        );
      });
      setFilteredModuleList(filterData);
    } else {
      setFilteredModuleList(modulesList);
    }
  };

  useEffect(() => {
    searchModulesToBrowse();
  }, [searchText]);

  const getModuleDetailsForPreview = (moduleId) => {
    setIsloading(true);
    fetchModuleDetails(moduleId, (res, err) => {
      if (res) {
        setIsloading(false);
        setModulePreviewData(res.data);
        setModulePreviewOpen(true);
      } else {
        setIsloading(false);
        showError(err);
      }
    });
  };

  const checkIfAllAdded = () => {
    let allAdded = true;
    modulesList.forEach((module) => {
      if (!allAdded) {
        return;
      }
      if (draggableStatus(module.id)) {
        allAdded = false;
      }
    });
    return allAdded;
  };

  const renderEmptyList = () => {
    return (
      <div className={styles.emptylist}>
        <div className={styles.emptyMessage}>
          <div className={styles.doneIcon}>
            <img alt="" src={doneIcon} />
          </div>
          <NormalText> {t("businessRules.assignedBRules")}</NormalText>
        </div>
      </div>
    );
  };
  return (
    <div style={{ width: "300px" }}>
      <CircularProgressBar isloading={isloading} />

      <div>
        <div
          className={`mt-16 ${styles.businessRulesTitle} d-flex`}
          style={{ alignItems: "center" }}
        >
          Source Module
        </div>

        <div className={styles.cntnrbruleSourceModuleCard}>
          <ModuleListCard
            draggable={false}
            key={sourceModule.id}
            cardData={sourceModule}
            handleView={(moduleId) => {
              getModuleDetailsForPreview(moduleId);
            }}
            isSourceModule={true}
          />
        </div>
      </div>

      <div className="d-flex" style={{ justifyContent: "space-between" }}>
        <div
          className={`${styles.businessRulesTitle} d-flex`}
          style={{ alignItems: "center" }}
        >
          Associated Modules
        </div>
        <div>
          <LinkButton
            title="EDIT SELECTION"
            handleClick={() => {
              handleEditSelection();
            }}
          />
        </div>
      </div>
      <div className={styles.searchSubTitle}>
        <p>Drag & drop the desired modules into categories</p>
        <RoundedSearchTextField
          placeholder="Search by 'Module Name / ID'"
          onTextChange={(value) => setSearchText(value)}
          expandable={false}
          inputValue={searchText}
          handleSearch={() => {
            //searchModulesToBrowse();
          }}
        />
      </div>
      <div className={`customScrollBar ${styles.moduleSearchResult}`}>
        {!checkIfAllAdded() &&
          filteredModuleList.map((item, index) => {
            if (!draggableStatus(item.id)) {
              return null;
            }
            return (
              <ModuleListCard
                draggable={
                  item.moduleDamId !== null && draggableStatus(item.id)
                }
                key={item.id}
                cardData={item}
                handleView={(moduleId) => {
                  getModuleDetailsForPreview(moduleId);
                }}
              />
            );
          })}
        {checkIfAllAdded() ? renderEmptyList() : null}
      </div>
      <CustomModal
        displayModal={modulePreviewOpen}
        hideModal={() => {
          setModulePreviewOpen(false);
        }}
        size="xl"
        dilogClassName="alertModalSmall"
      >
        <ModuleView
          modulePreviewData={modulePreviewData}
          setModulePreviewOpen={setModulePreviewOpen}
        />
      </CustomModal>
    </div>
  );
};

export default BrowseModules;
