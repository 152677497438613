import React, { useContext, useEffect, useState, useRef } from "react";
import styles from "styles/modules/importClaims.module.scss";
import PrimaryButton from "components/PrimaryButton";
import PrimaryButtonOutline from "components/PrimaryButtonOutline";
import LinkButton from "components/LinkButton";
import ComponentsTitleRow from "components/modules/ComponentsTitleRow";
import LeftRightScrollButton from "components/LeftRightScrollButton";
import addIcon from "img/addIconSm.svg";
import TextComponent from "components/modules/TextComponent";
import { ModuleContext, moduleActionTypes } from "contexts/ModuleContext";
import { showError, emitter } from "Uitls/Util";
import { fetchAddedModuleElements, fetchModuleComponents } from "services/apis";
import { moduleScreens } from "pages/module/ModuleUtil";
import SimpleSlider from "components/SimpleSlider";
import CustomModal from "components/customModal";
import ImportComponents from "components/modules/importComponents/ImportComponents";
import ImagePreview from "components/ImagePreview";
import DataGraphicCard from "components/modules/components/DataGraphicCard";
import ViewTextElement from "components/modules/ViewTextElement";

function Components({ onImportClick, elementTypes }) {
  // const [importComponentModalOpen, setImportComponentModalOpen] =
  //   useState(false);
  const {
    setIsloading,
    updateModule,
    module,
    currentActiveElementIndex,
    setCurrentElementActiveIndex,
    activeElementId,
    setActiveScreen,
    setActiveElementId,
  } = useContext(ModuleContext);
  //const [isFirstLoad, toggleIsFirstLoad] = useState(true);
  const [openImportModal, toggleOpenImportModal] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [componentPreviewUrl, setComponentPreviewurl] = useState(undefined);
  const [componentsData, setComponents] = useState([]);
  const [componentView, setComponentView] = useState(false);
  const [elementType, setElementType] = useState(undefined);
  const [selectectedtextElement, setSelectectedtextElement] =
    useState(undefined);
  const [autoSyncData, setAutoSyncData] = useState(undefined);

  useEffect(() => {
    let moduleDetails = JSON.parse(JSON.stringify(module));
    if (autoSyncData?.type === "Claim") {
      let index = moduleDetails?.claims?.findIndex(
        (elem) => elem.claimDamId === autoSyncData?.id
      );
      if (index > -1) {
        moduleDetails.claims[index].mlrStatus = autoSyncData?.mlrStatus;
      }
      if (moduleDetails.components.length > 0) {
        let components = JSON.parse(
          JSON.stringify(moduleDetails.components)
        ).map((item) => {
          let newData = [...item.relatedClaims];
          let index = newData?.findIndex(
            (elem) => elem.claimDamId === autoSyncData?.id
          );
          if (index > -1) {
            newData[index].mlrStatus = autoSyncData.mlrStatus;
            item.relatedClaims = newData;
          }
          return item;
        });
        moduleDetails.components = components;
      }
    }
    if (autoSyncData?.type === "Reusable Text") {
      let index = moduleDetails?.reusableTexts?.findIndex(
        (elem) => elem.reusableTextDamId === autoSyncData?.id
      );
      if (index > -1) {
        moduleDetails.reusableTexts[index].mlrStatus = autoSyncData?.mlrStatus;
      }
      if (moduleDetails.components.length > 0) {
        let components = JSON.parse(
          JSON.stringify(moduleDetails.components)
        ).map((item) => {
          let newData = [...item.relatedReusableTexts];
          let index = newData?.findIndex(
            (elem) => elem.reusableTextDamId === autoSyncData?.id
          );
          if (index > -1) {
            newData[index].mlrStatus = autoSyncData.mlrStatus;
            item.relatedReusableTexts = newData;
          }
          return item;
        });
        moduleDetails.components = components;
      }
    }
    if (autoSyncData?.type === "Component") {
      let index = moduleDetails?.components?.findIndex(
        (elem) => elem.componentDamId === autoSyncData?.id
      );
      if (index > -1) {
        moduleDetails.components[index].mlrStatus = autoSyncData?.mlrStatus;
      }
    }
    updateModule(moduleActionTypes.SET_MODULE, moduleDetails);
  }, [autoSyncData]);

  useEffect(() => {
    const handleFooEvent = (e) => {
      try {
        const parsedData = JSON.parse(e);
        if (
          parsedData.type === "Claim" ||
          parsedData.type === "Reusable Text" ||
          parsedData.type === "Component"
        ) {
          setAutoSyncData(parsedData);
        }
      } catch (error) {
        console.error("Received non-JSON message:", e);
      }
    };

    emitter.on("autoSync", handleFooEvent);

    return () => {
      emitter.off("autoSync", handleFooEvent);
    };
  }, []);

  const sliderRef = useRef();
  let subTypeGraphic = "Data Graphic";

  // Function to fetch module components
  const loadModuleComponents = () => {
    const moduleId = module.moduleId;
    fetchModuleComponents(moduleId, (response, err) => {
      if (response) {
        setComponents(response.data.components);
      } else {
        console.error("Error fetching module components:", err);
      }
    });
  };

  useEffect(() => {
    // Load components when module or activeElementId changes
    loadModuleComponents();
  }, [module, activeElementId]);

  useEffect(() => {
    if (activeElementId) {
      getAddedComponents(true);
    }
  }, [activeElementId]);

  useEffect(() => {
    goToSlide(currentActiveElementIndex);
  }, [currentActiveElementIndex]);

  const goToSlide = (slideIndex) => {
    if (sliderRef && sliderRef.current) {
      sliderRef.current.slickGoTo(slideIndex);
    }
  };

  useEffect(() => {
    if (isDataLoaded) {
      goToDefaultSlide();
    }
  }, [isDataLoaded]);

  const goToDefaultSlide = () => {
    const data = module?.components;
    const index = data?.findIndex((el) => {
      return el.componentDocNumber === activeElementId;
    });
    if (index > -1) {
      setCurrentElementActiveIndex(index);
    } else if (data?.length > 0) {
      setCurrentElementActiveIndex(0);
    }
  };

  const getAddedComponents = (toView, recentlyImportedId) => {
    const moduleId = module.moduleId;
    setIsloading(true);
    fetchAddedModuleElements(moduleId, "components", (res, err) => {
      setIsloading(false);

      if (res) {
        updateModule(moduleActionTypes.REPLACE_COMPONENTS, res.data.components);
        setIsDataLoaded(true);
      } else {
        showError(err);
      }
    });
  };

  const renderComponents = () => {
    if (!module) {
      return;
    }
    return module.components?.map((component, index) => {
      return (
        <div key={index} style={{ width: "100%" }}>
          {/* <div className={`d-flex justify-content-around ${styles.claimsRow}`}> */}
          <div className="row">
            <div
              className={`${styles.claimBoxType1} ${styles.claimboxPadding}`}
            >
              <div className={`${styles.elementxBox}`}>
                <div className={`${styles.title}`}>
                  <span>Component</span>
                  <span style={{ float: "right" }}>
                    <LinkButton
                      title="Preview"
                      height="16px"
                      handleClick={() => {
                        setComponentPreviewurl(component?.componentUrl);
                      }}
                    />
                  </span>
                  <div
                    className={`mt10 customScrollBar ${styles.componetImgContainer}`}
                  >
                    <div>
                      <img
                        src={component.componentUrl}
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {component.subType.toLowerCase() ===
            subTypeGraphic.toLowerCase() ? (
              <>
                <div
                  className={`${styles.flexWidth30} ${styles.elementxBoxBrdr} ${styles.claimboxPadding}`}
                >
                  <DataGraphicCard
                    title={`Related Claims (${component.relatedClaims.length})`}
                    data={component.relatedClaims}
                    setIsloading={setIsloading}
                    onClick={(id) => {
                      setSelectectedtextElement(id);
                      setComponentView(true);
                      setElementType(1);
                    }}
                  />
                </div>
                <div
                  className={`${styles.flexWidth30} ${styles.elementxBoxBrdr} ${styles.claimboxPadding}`}
                >
                  <DataGraphicCard
                    title={`Related Reusable Texts (${component.relatedReusableTexts.length})`}
                    data={component.relatedReusableTexts}
                    onClick={(id) => {
                      setSelectectedtextElement(id);
                      setComponentView(true);
                      setElementType(2);
                    }}
                  />
                </div>
              </>
            ) : (
              <>
                <div
                  className={`${styles.flexWidth20} ${styles.elementxBoxBrdr} ${styles.claimboxPadding}`}
                >
                  <TextComponent
                    title="Title"
                    data={[
                      {
                        content: component.matchText
                          ? component.matchText
                          : component.title,
                        Title: "Title",
                      },
                    ]}
                    componentType={1}
                  />
                </div>
                <div
                  className={`${styles.flexWidth20} ${styles.elementxBoxBrdr} ${styles.claimboxPadding}`}
                >
                  <TextComponent
                    title="Footnote(s)"
                    data={[
                      {
                        content: component.footnotes,
                      },
                    ]}
                    componentType={2}
                  />
                </div>
                <div
                  className={`${styles.flexWidth20} ${styles.elementxBoxBrdr} ${styles.claimboxPadding}`}
                >
                  <TextComponent
                    title="Reference(s)"
                    data={
                      component.references
                        ? component.references?.map((ref, index) => {
                            return {
                              content: `${index + 1}. ${ref.documentName}`,
                            };
                          })
                        : []
                    }
                    componentType={3}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      );
    });
  };

  const goBack = () => {
    setCurrentElementActiveIndex(-1);
    setActiveScreen(moduleScreens.home);
    setActiveElementId(undefined);
  };

  // const handlePreviewComponent = (flag) => {
  //   setComponentPreviewurl(flag);
  // };

  return (
    <div className={`${styles.elementsBlockContainer}`}>
      <div className="row mt-3 mb-3 align-items-center">
        <ComponentsTitleRow
          elementLabel={`${"Component".toUpperCase()} (${
            currentActiveElementIndex + 1
          })`}
        />
      </div>
      {
        <div className={`${styles.sliderheight}`}>
          <SimpleSlider children={renderComponents()} sliderRef={sliderRef} />
        </div>
      }

      <div
        className={`row mt-20 align-items-center ${styles.moduleFooterRow1}`}
      >
        <div className="col-md-6">
          <PrimaryButtonOutline
            title="Add Component"
            handleClick={() => {
              //onImportClick();
              toggleOpenImportModal(true);
            }}
            minWidth={168}
            icon={addIcon}
            btnType="outline"
            showIcon={true}
          />
        </div>
        <div className="col-md-6 text-right">
          <LeftRightScrollButton
            btnLabel="Component"
            handleClick={(direction) => {
              setCurrentElementActiveIndex((prevIndex) => {
                if (direction === "forward") {
                  prevIndex += 1;
                } else {
                  prevIndex -= 1;
                }
                return prevIndex;
              });
            }}
            shouldDisableForward={
              !module ||
              module.components?.length === 0 ||
              currentActiveElementIndex === module.components?.length - 1
            }
            shouldDisableBackward={
              !module ||
              module.components?.length === 0 ||
              currentActiveElementIndex === 0
            }
            currentIndex={currentActiveElementIndex + 1}
            count={module ? module.components?.length : 0}
            goToSlide={(value) => {
              setCurrentElementActiveIndex(value);
            }}
          />
        </div>
      </div>

      <div className={`row align-items-center ${styles.moduleFooterRow}`}>
        <div className="col-md-6">
          <LinkButton
            title="BACK"
            handleClick={() => {
              goBack();
            }}
            // disabled={!selectedData || selectedData.length === 0}
          />
        </div>
        <div className="col-md-6 text-right">
          <PrimaryButton
            title="DONE"
            handleClick={() => {
              goBack();
            }}
            minWidth={110}
            marginLeft={20}
            disabled={module?.components?.length === 0}
          />
        </div>
      </div>

      <CustomModal
        displayModal={openImportModal}
        hideModal={() => {
          toggleOpenImportModal(false);
          //closeImportModalAndGoBack();
        }}
        size="xl"
        dilogClassName="alertModalSmall"
      >
        <ImportComponents
          closeModalAndRefresh={(recentlyImportedId) => {
            toggleOpenImportModal(false);

            setIsDataLoaded(() => {
              //getAddedComponents(false, recentlyImportedId);
              setActiveElementId(recentlyImportedId);
              return false;
            });
          }}
          closeModal={() => {
            toggleOpenImportModal(false);
          }}
          addedComponentIds={module.components?.map((component) => {
            return component.componentDocNumber;
          })}
        />
      </CustomModal>
      <CustomModal
        displayModal={componentPreviewUrl}
        hideModal={() => {
          setComponentPreviewurl(undefined);
        }}
        size="lg"
        dilogClassName="alertModalSmall"
      >
        <ImagePreview
          title="Component Preview"
          imageUrl={componentPreviewUrl}
          closePreview={() => {
            setComponentPreviewurl(undefined);
          }}
        />
      </CustomModal>
      <CustomModal
        displayModal={componentView}
        hideModal={() => {
          setComponentView(false);
          setElementType(undefined);
        }}
        size={elementType === 1 ? "lg" : "datagraphic"}
        dilogClassName="alertModalSmall"
      >
        <ViewTextElement
          viewTextualElementType={elementType}
          selectectedtextElement={selectectedtextElement}
          toggleComponentView={setComponentView}
          data={
            componentsData?.length > 0
              ? elementType === 1
                ? componentsData[currentActiveElementIndex]?.relatedClaims
                : componentsData[currentActiveElementIndex]
                    ?.relatedReusableTexts
              : []
          }
        />
      </CustomModal>
    </div>
  );
}

export default Components;
