import LeftRightScrollButtonSmall from "components/LeftRightScrollButtonSmall";
import SimpleSlider from "components/SimpleSlider";
import React, { useEffect, useRef, useState } from "react";
import styles from "styles/modules/importClaims.module.scss";

const ClaimSummaryBox = ({ claim }) => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  let textVariants = [claim.claimMatchText || claim.matchText];
  textVariants = textVariants.concat(
    claim.matchTextVariants?.map((variant) => {
      return variant.matchText;
    }) || []
  );

  const slides = textVariants.map((variant) => {
    return <div key={variant.id}>{variant}</div>;
  });
  const sliderRef = useRef();
  useEffect(() => {
    goToSlide(currentSlideIndex);
  }, [currentSlideIndex]);

  const goToSlide = (slideIndex) => {
    if (sliderRef && sliderRef.current) {
      sliderRef.current.slickGoTo(slideIndex);
    }
  };

  return (
    <div className={`${styles.elementxBox}`}>
      <div className={`${styles.title}`}>
        <span>Match Text</span>
        <div className={`${styles.descriptionbox} customScrollBar`}>
          <SimpleSlider children={slides} sliderRef={sliderRef} />
        </div>
      </div>

      <div className={`${styles.moduleFooterRowInsideCard}`}>
        <LeftRightScrollButtonSmall
          count={slides.length}
          currentValue={currentSlideIndex + 1}
          handleClick={(direction) => {
            setCurrentSlideIndex((prevState) => {
              if (direction === "forward") {
                prevState += 1;
              } else {
                prevState -= 1;
              }
              return prevState;
            });
          }}
          shouldRightDisabled={currentSlideIndex === slides.length - 1}
          shouldLeftDisabled={currentSlideIndex === 0}
        />
      </div>
    </div>
  );
};

export default ClaimSummaryBox;
