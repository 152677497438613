import Keycloak from "keycloak-js";

const KEY_CLOAK_URL = "https://gilead-auth.indegene.com/auth";
const CLIENT_ID = "NCCA";
const REALM = "NCCA-GILEAD-PROD";

let initOptions = {
  url: KEY_CLOAK_URL,
  realm: REALM,
  clientId: CLIENT_ID,
};

export const keycloak = new Keycloak(initOptions);

// keycloak
//   .init({
//     onLoad: initOptions.onLoad,
//     KeycloakResponseType: "code",
//     silentCheckSsoRedirectUri: window.location.origin,
//     checkLoginIframe: false,
//     pkceMethod: "S256",
//   })
//   .then(
//     async (auth) => {
//       if (!auth) {
//         window.location.reload();
//       } else {
//         if (keycloak.token) {
//           window.localStorage.setItem("userToken", keycloak.token);

//           window.localStorage.setItem(
//             "userInfo",
//             JSON.stringify(await getUserInfo())
//           );
//         }
//         keycloak.onTokenExpired = () => {
//           console.log("token expired");
//         };
//       }
//     },
//     () => {
//       console.error("Authenticated Failed");
//     }
//   );

/******************* Key cloak loggers and functions******************* */

const getUserInfo = async () => {
  try {
    const userData = await keycloak.loadUserInfo();
    return userData;
    // setUserSub(userData.sub);
  } catch (error) {
    console.log(error);
    return undefined;
  }
};

export const tokenLogger = async (tokenData) => {
  if (tokenData.token) {
    window.localStorage.setItem("userToken", tokenData.token);
    const data = await keycloak.loadUserInfo();
    window.localStorage.setItem("userRole", keycloak.realmAccess.roles);
    window.localStorage.setItem("userEmail", data.email);
    window.localStorage.setItem("userId", data.sub);
    window.localStorage.setItem("currentUserName", data.name);
  }
};

export const eventLogger = async (event) => {
  if (event === "onAuthLogout") {
    window.location.href = `https://contentstudio.gilead.com`;
  }
};

export const logoutUser = async () => {
  await keycloak.logout({
    redirectUri: "https://contentstudio.gilead.com",
  });
};

// onAuthSuccess = function () {
//   // Check token validity every 10 seconds (10 000 ms) and, if necessary, update the token.
//   // Refresh token if it's valid for less then 60 seconds
//   const updateTokenInterval = setInterval(() => keycloak.updateToken(60).error(() => {
//     keycloak.clearToken()
//   }), 10000)
