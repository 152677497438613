import CircularProgressBar from "Uitls/CircularProgressBar";
import { routes, showError } from "Uitls/Util";
import CustomModal from "components/customModal";
import MetaDataInformation from "components/modules/metadata/MetaDataInformation";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { fetchMetaDataDetails, fetchModuleDetails } from "services/apis";

export const CloneModule = ({ sourceModuleId, onClose }) => {
  const { t } = useTranslation();
  const [isloading, setIsloading] = useState(false);
  const [sourceModule, setSourceModule] = useState(undefined);
  const [metaDataDropDownList, setMetaDataDropDownList] = useState(undefined);
  const history = useHistory();

  useEffect(() => {
    getModuleDetails();
    getDropDownDataForMetaDataScreen();
  }, []);

  useEffect(() => {
    if (metaDataDropDownList && sourceModule) {
      setIsloading(false);
    }
  }, [sourceModule, metaDataDropDownList]);

  const getModuleDetails = () => {
    setIsloading(true);
    fetchModuleDetails(sourceModuleId, (res, err) => {
      if (res && res?.data) {
        setSourceModule(res.data);
      } else {
        showError(err);
      }
    });
  };

  const getDropDownDataForMetaDataScreen = () => {
    setIsloading(true);
    fetchMetaDataDetails((res, err) => {
      if (res) {
        try {
          setMetaDataDropDownList(res.data);
        } catch (e) {
          console.log(e);
        }
        return;
      }
      showError(err);
    });
  };

  const goToAssembleModulePage = (module) => {
    const lastLocation = history.location;
    const params = {
      pathname: routes.module,
      state: module,
    };
    if (lastLocation?.pathname === routes.module) {
      history.replace(params);
    } else {
      history.push(params);
    }

    onClose();
  };

  return (
    <div>
      <CircularProgressBar isloading={isloading} />
      <CustomModal
        displayModal={sourceModule && metaDataDropDownList}
        hideModal={() => {
          onClose();
        }}
        size="lg"
        dilogClassName="alertModalSmall"
      >
        <MetaDataInformation
          closeMetaDataScreen={() => {
            onClose();
          }}
          gotoNextPage={goToAssembleModulePage}
          metaDataDropDownList={metaDataDropDownList || []}
          buttonLable={t("module.cloneModule")}
          editMetaDataFlag={true}
          module={sourceModule}
          moduleType="cloned"
        />
      </CustomModal>
    </div>
  );
};
