import React, { useState, useEffect } from "react";
import LinkButton from "components/LinkButton";
//Import Icons
import SearchIcon from "img/blue_lens.svg";
import clearTextIcon from "img/clear_text.svg";

//Import styles
import "styles/components/RoundedSearchTextField.scss";

const RoundedSearchTextField = ({
  inlineButton = false,
  placeholder = "Search...",
  onTextChange,
  handleSearch,
  isResetClicked,
  expandable = false, // New prop to control expansion behavior
  inputValue = "",
}) => {
  const [isSearchBarEmpty, setIsSearchBarEmpty] = useState(true);
  const [isFocused, setIsFocused] = useState(false);
  useEffect(() => {
    if (isResetClicked) {
      clearTextField();
    }
  }, [isResetClicked]);
  useEffect(() => {
    if (inputValue && inputValue.trim()) {
      setIsSearchBarEmpty(false);
    } else {
      setIsSearchBarEmpty(true);
    }
  }, [inputValue]);
  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const clearTextField = () => {
    const element = document.getElementById("tf-search-field");
    if (element) {
      element.value = "";
      setIsSearchBarEmpty(true);
      onTextChange(element.value);
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch("search");
    }
  };
  const handleSearchClick = (type) => {
    handleSearch(type);
  };
  return (
    <div className={`tf-rounded-search ${expandable ? "expandable" : ""}`}>
      <div className="d-flex align-items-center">
        <img src={SearchIcon} alt="" className="search-icon" />
        <input
          id="tf-search-field"
          type="text"
          value={inputValue || ""}
          placeholder={placeholder}
          className="search-input"
          autoComplete="off"
          onKeyPress={handleKeyPress}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onDrop={(e) => e.preventDefault()} // Prevents default behavior of dropping files
          onChange={(e) => {
            if (e.target.value?.trim().length > 0) {
              setIsSearchBarEmpty(false);
            } else {
              setIsSearchBarEmpty(true);
            }
            onTextChange(e.target.value);
          }}
        />

        {inlineButton && (
          <LinkButton
            className=""
            handleClick={(event) => {
              handleSearchClick("search");
            }}
            disabled={isSearchBarEmpty}
            title="Search"
          />
        )}

        {!isSearchBarEmpty && (
          <img
            src={clearTextIcon}
            alt=""
            className=""
            style={{ cursor: "pointer" }}
            onClick={(event) => {
              clearTextField();
              handleSearchClick("searchBarEmpty");
            }}
          />
        )}
      </div>
    </div>
  );
};

export default RoundedSearchTextField;
