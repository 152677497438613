import React, { useContext } from "react";
import styles from "styles/modules/importElements.module.scss";
import infIcon from "img/infoIcon.svg";
import PrimaryButton from "components/PrimaryButton";
import pluseIconWhite from "img/pluseIconWhite.svg";
import BluePlusIcon from "img/pluseIcon.svg";
import OutlineButton from "components/OutlineButton";
import TextElementCard from "components/modules/TextElementCard";
import { ModuleContext } from "contexts/ModuleContext";
import styled from "styled-components";
import ComponentCard from "components/modules/components/ComponentCard";
import { moduleScreens } from "pages/module/ModuleUtil";
import OverlayPopOver from "Uitls/OverlayPopOver";
import { ToolTipText } from "components/TooltipText";
import ImportComponentsInfoHoverText from "components/modules/hoverTexts/ImportComponentsInfoHoverText";

const AddedGraphicalElementsList = ({
  elementType,
  elementLabel,
  elements,
  onImportClick,
}) => {
  const { setActiveScreen, module } = useContext(ModuleContext);
  const handleClick = () => {
    onImportClick(moduleScreens.components);
    //setActiveScreen(elementType);
  };
  return (
    <div className={`${styles.elementxBox}`}>
      <div className="d-flex align-items-center justify-content-between mb-12">
        <div className={`${styles.title}`}>
          <div className="d-flex align-items-center">
            <span>{elementLabel}(s)</span>{" "}
            <OverlayPopOver
              element={<img src={infIcon} alt="Information" />}
              overlayData={
                <ToolTipText>
                  <ImportComponentsInfoHoverText />
                </ToolTipText>
              }
              trigger={["hover", "focus"]}
              placement="bottom"
              classId="tooltip"
              inlineStyles={{ maxWidth: "354px" }}
            />
            {elements?.length > 0 ? (
              <span className={`${styles.countRounded}`}>
                {elements?.length}
              </span>
            ) : null}
          </div>
        </div>
        {elements?.length > 0 ? (
          <div>
            <PrimaryButton
              title="Add"
              icon={pluseIconWhite}
              minWidth={100}
              marginLeft={20}
              customClass="text-uppercase"
              handleClick={handleClick}
            />
          </div>
        ) : null}
      </div>
      {elements?.length === 0 ? (
        <OutlineButton
          icon={BluePlusIcon}
          btnLabel={`Add ${elementLabel}`}
          handleClick={handleClick}
          disabled={!module?.claims?.length && !module?.reusableTexts?.length}
        />
      ) : null}
      <Cards className="customScrollBar">
        {elements?.map((element) => {
          return <ComponentCard element={element} elementType={elementType} />;
        })}
      </Cards>
    </div>
  );
};

const Cards = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100% - 45px);
  margin-right: -13px;
  padding-right: 10px;
`;

export default AddedGraphicalElementsList;
