import React, { createRef } from "react";
import styles from "styles/components/businessRules/businesrules.module.scss";
import dragIcon from "img/dragIcon.svg";
import statusDraft from "img/statusDraft.svg";
import statusApproved from "img/statusApproved.svg";
import infoIcon from "img/infoIcon.svg";
import OverlayPopOver from "Uitls/OverlayPopOver";
import styled from "styled-components";
import variables from "styles/base/_universal.scss";
import { costomEllipsis, getMlrStatusIcon } from "Uitls/Util";
import { useTranslation } from "react-i18next";
import { ToolTipText } from "components/TooltipText";
import placeholder from "img/Placeholder.svg";
import deleteCardIcon from "img/deleteCardIcon.svg";

const ModuleListCard = (props) => {
  const { t } = useTranslation();
  const {
    draggable,
    disabledBox,
    keyIndex,
    cardData,
    handleView,
    isSourceModule = false,
    readOnly = true,
    handleRemove,
  } = props;
  const data = JSON.stringify(cardData);
  const popoverRef = createRef();
  function dragstartHandler(ev) {
    if (!draggable) {
      return;
    }
    ev.dataTransfer.setData("text/plain", data);
  }
  //old style
  // ${
  //   cardData?.moduleDamId === null
  //     ? styles.disabledClaimbox
  //     : !draggable
  //     ? styles.disabled
  //     : ""
  // }

  return (
    <div
      className={`${styles.claimBox} 
      ${isSourceModule ? styles.bruleSourceModuleCard : ""}
      `}
      draggable={draggable}
      onDragStart={(event) => dragstartHandler(event)}
    >
      {!readOnly ? (
        <img
          src={deleteCardIcon}
          alt="Remove"
          className={styles.deleteCardIcon}
          onClick={() => {
            if (!readOnly && handleRemove) {
              handleRemove(cardData.id);
            }
          }}
        />
      ) : null}

      <div className="d-flex align-items-center">
        {draggable ? (
          <div className="flex-shrink-1">
            <img src={dragIcon} alt="" className="mr12" draggable={false} />
          </div>
        ) : (
          ""
        )}

        <div
          className={`${styles.componentimage} ${
            cardData.componentThumbnailUrl
              ? styles.thumbImg
              : styles.placeholderImg
          }`}
          style={{
            backgroundImage: `url(${cardData.componentThumbnailUrl})`,
            backgroundColor: cardData.componentThumbnailUrl
              ? "transparent"
              : isSourceModule
              ? "#fff"
              : "#fff7ed",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "contain",
          }}
          draggable={false}
        >
          {cardData?.componentThumbnailUrl ? null : (
            <img
              src={placeholder}
              alt="Placeholder"
              // className="btnVhCenter"
              width={14}
              draggable={false}
            />
          )}
        </div>

        <div ref={popoverRef} className="w-100 d-inline-grid">
          <div className="mb6 d-flex align-items-center justify-content-between">
            <span
              className={`${styles.cardBrandDetails} ellipssesSingleLine`}
              draggable={false}
            >
              <OverlayPopOver
                element={
                  <img
                    src={getMlrStatusIcon(cardData?.mlrStatus)}
                    alt=""
                    className="mt2"
                    draggable={false}
                    style={{ marginRight: "6px" }}
                  />
                }
                overlayData={<ToolTipText>{cardData?.mlrStatus}</ToolTipText>}
                trigger={["hover", "focus"]}
                placement="bottom"
                classId="tooltip"
                containerRef={popoverRef}
              />
              {/* <img
                src={getMlrStatusIcon(cardData?.mlrStatus)}
                alt=""
                className="mt2"
                draggable={false}
              /> */}
              {/* {" "} */}
              {cardData?.moduleDamName}
            </span>
            <span>
              <button
                className="btn btn-link m-0 p-0"
                onClick={() => handleView(cardData?.id)}
                draggable={false}
              >
                VIEW
              </button>
            </span>
          </div>

          <div
            className={`${styles.cardBrandDetails} ellipssesSingleLine `}
            title={cardData?.moduleName}
            draggable={false}
          >
            {/* <span>Name:</span> {costomEllipsis(cardData?.moduleName || "-", 25)} */}
            <span>Name:</span> {cardData?.moduleName || "-"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModuleListCard;
