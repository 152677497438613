import { BoldText, MainText, NormalText } from "components/Texts";

import React, { useContext, useState } from "react";
import styled from "styled-components";
import styles from "styles/modules/elementsCard.module.scss";
import variables from "styles/base/_universal.scss";
import PenIcon from "img/pen-icon.svg";
import SwicthButton from "components/SwitchButton";
import SingleLinekeyValue from "components/SingleLineKeyValue";
import { costomEllipsis, showError } from "Uitls/Util";
import { Col } from "react-bootstrap";
import LinkButton from "components/LinkButton";
import { ModuleContext, moduleActionTypes } from "contexts/ModuleContext";
import { removeElementFromModule, toggleMustUse } from "services/apis";
import {
  getElementsType,
  getToggleEventName,
  moduleScreens,
} from "pages/module/ModuleUtil";
import Preview from "components/PreviewButton";
import CustomModal from "components/customModal";
import ImagePreview from "components/ImagePreview";
import OverlayPopOver from "Uitls/OverlayPopOver";
import { ToolTipText } from "components/TooltipText";
import { getMlrStatusIcon } from "Uitls/Util";

const ComponentCard = (props) => {
  const { element, elementType, customClass } = props;
  const [componentPreview, toggleComponentPreview] = useState(false);

  const {
    module,
    updateModule,
    setActiveElementId,
    setActiveScreen,
    setElementToBeRemoved,
    setActiveElementType,
  } = useContext(ModuleContext);

  const viewTextualElement = (elementId) => {
    setActiveElementId(() => {
      setActiveScreen(elementType);
      return elementId;
    });
  };

  const handlePreviewComponent = (flag) => {
    toggleComponentPreview(flag);
  };

  const handleToggle = (toggleValue) => {
    toggleMustUse(
      module.moduleId,
      getElementsType(elementType),
      element.systemId,
      toggleValue,
      (res, err) => {
        if (res) {
          updateModule(getToggleEventName(elementType), {
            id: element.systemId,
            toggleValue: toggleValue,
          });
          return;
        }
        showError(err);
      }
    );
  };

  return (
    <>
      <div className={styles.elementsCard} key={element.id}>
        <div className={styles.elementsTitleRow}>
          <span className={`${customClass} ${styles.titleBlock}`}>
            {element.name}
          </span>{" "}
          <span className={styles.idBlock}>ID: </span>{" "}
          <span className="mr-1 ">
            <OverlayPopOver
              element={
                <img
                  src={getMlrStatusIcon(element?.mlrStatus)}
                  alt={element.mlrStatus}
                  style={{ marginTop: "-2px" }}
                />
              }
              overlayData={
                <ToolTipText>
                  {element?.mlrStatus
                    ? element?.mlrStatus
                    : "Module not Published"}
                </ToolTipText>
              }
              trigger={["hover", "focus"]}
              placement="bottom"
              classId="tooltip"
            />
          </span>
          {element.id}
          {element?.mlrStatus?.trim().toLowerCase().includes("expired") && (
            <span
              className="Expired"
              style={{
                verticalAlign: "middle",
              }}
            >
              Expired
            </span>
          )}
        </div>
        <div className="d-flex">
          <div style={{ margin: "12px 0px 12px 12px" }}>
            <div
              className={`${styles.thumbnailcontainer} pt-0 pb-0`}
              style={{
                backgroundImage: `url(${element.componentUrl})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "contain",
              }}
            >
              {/* <img
                src={element.componentUrl}
                alt=""
                className={`${styles.thumbnail} pt-0 pb-0`}
              /> */}
              <Preview
                onPreviewClick={() => {
                  handlePreviewComponent(true);
                }}
              />
            </div>
          </div>
          <div style={{ display: "grid" }}>
            <div
              className={`heightNormal ellipssesSingleLine ${styles.elementsContent} `}
            >
              <span className={styles.idBlock}>Name: </span>{" "}
              {element.componentName}
            </div>

            <div className={`${styles.elementsFooter} pt-0`}>
              <span className={styles.idBlock}>Topic: </span>{" "}
              <OverlayPopOver
                element={
                  <span className={styles.titleBlock}>
                    {costomEllipsis(element.topic, 10)}
                  </span>
                }
                overlayData={<ToolTipText>{element.topic}</ToolTipText>}
                trigger={["hover", "focus"]}
                placement="bottom"
                classId="tooltip"
              />
              <OverlayPopOver
                element={
                  <span className={styles.idBlock}>
                    Sub-Topic: {costomEllipsis(element.subtopic, 10)}
                  </span>
                }
                overlayData={<ToolTipText>{element.subtopic}</ToolTipText>}
                trigger={["hover", "focus"]}
                placement="bottom"
                classId="tooltip"
              />
            </div>
            <div className={`${styles.elementsFooter} pt-0`}>
              <span className={styles.idBlock}>Sub-Type: </span>{" "}
              <span className={styles.titleBlock}>{element.subType}</span>
            </div>
            <div className={`${styles.elementsFooter}`}>
              <span className={styles.idBlock}>Classification: </span>{" "}
              <span className={styles.titleBlock}>
                {element.classification}
              </span>
            </div>
          </div>
        </div>
        <div
          className={`d-flex align-items-center justify-content-between ${styles.switchButtonRow}`}
        >
          <MustUseToggle className="md-4">
            <SwicthButton
              label="Must Use"
              handleToggle={(toggleValue) => {
                handleToggle(toggleValue);
              }}
              checked={element.mustUse}
            />
          </MustUseToggle>
          <div>
            <LinkButton
              title="Remove"
              handleClick={() => {
                setElementToBeRemoved(element.systemId);
                setActiveElementType(elementType);
              }}
              customClass="text-uppercase"
              // disabled={!selectedData || selectedData.length === 0}
            />
            <LinkButton
              title="View"
              handleClick={() => {
                viewTextualElement(element.id);
              }}
              customClass="ml-12 text-uppercase"
              // disabled={!selectedData || selectedData.length === 0}
            />
          </div>
        </div>
      </div>
      <CustomModal
        displayModal={componentPreview}
        hideModal={() => {
          handlePreviewComponent(false);
        }}
        size="lg"
        dilogClassName="alertModalSmall"
      >
        <ImagePreview
          title="Component Preview"
          imageUrl={element.componentUrl}
          closePreview={() => {
            handlePreviewComponent(false);
          }}
        />
      </CustomModal>
    </>
  );
};

const MustUseToggle = styled("div")`
  padding-right: 0px;
`;

export default ComponentCard;
