import { useKeycloak } from "@react-keycloak/web";
import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import GlobaleEventListener from "Uitls/GlobaleEventListener";

// import { Route } from "react-router-dom/cjs/react-router-dom.min";

const KeyCloakPrivateRoute = ({
  component: Component,
  canAccessedBy,
  role,
  ...rest
}) => {
  const { initialized, keycloak } = useKeycloak();
  const currentLocation = useLocation();
  let init = false;
  if (!initialized && !init) {
    init = true;
    return <div>Loading...</div>;
  }

  const hasAccess = () => {
    // not having canAccessedBy as props, will be treated as a whitelisted route for all
    if (!canAccessedBy) {
      return true;
    }
    const userRoles = keycloak.realmAccess.roles; //window.localStorage.getItem("userRole") || "";

    for (let i = 0; i < userRoles.length; i++) {
      if (canAccessedBy.includes(userRoles[i])) {
        return true;
      }
    }
    return false;
  };
  const isLoggedIn = keycloak.authenticated && hasAccess();

  // return isLoggedIn ? (
  //   <Route
  //     {...rest}
  //     render={(props) => {
  //       return <Component {...props} />;
  //     }}
  //   />
  // ) : (
  //   <Redirect to={{ pathname: "/", state: currentLocation || "" }} />
  // );

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isLoggedIn) {
          return (
            <>
              <GlobaleEventListener />
              <Component {...props} />
            </>
          );
        } else {
          return (
            <Redirect to={{ pathname: "/", state: currentLocation || "" }} />
          );
        }
      }}
    />
  );
};

export default KeyCloakPrivateRoute;
