import React from "react";
import styled from "styled-components";
import styles from "styles/modules/importClaims.module.scss";

const ReusableTextSummaryBox = ({ reusableText }) => {
  return (
    <div className={`${styles.elementxBox}`}>
      <div className={`${styles.title}`}>
        <span>Match Text</span>
      </div>
      <ContentDiv className={`${styles.fgrbox} customScrollBar`}>
        {reusableText.matchText}
      </ContentDiv>
    </div>
  );
};

const ContentDiv = styled.div`
  margin-top: 12px;
`;

export default ReusableTextSummaryBox;
