import { TextField } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import variables from "styles/base/_universal.scss";
import "styles/components/CustomTextField.scss";

const CustomTextField = ({
  label,
  rows = 4,
  rowsMax = 4,
  multiline,
  link,
  handleChange,
  normalinput,
  value = "",
  disabled = false,
  required = false,
  charLimit,
}) => {
  const [inputValue, setInputValue] = useState(value);
  const [isFocused, setIsFocused] = useState(false);
  const Component = link || normalinput ? UrlInput : Multiline;
  useEffect(() => {
    setInputValue(value);
  }, [value]);
  const handleInputChange = (e) => {
    const newValue = e.target.value;
    if (!charLimit || newValue.length <= charLimit) {
      setInputValue(newValue);
      handleChange(newValue);
    }
  };

  return (
    <div
      className={`textfield-container ${isFocused ? "focused" : ""}`}
      style={{ marginBottom: "16px" }}
    >
      <Component
        id={`textfield`}
        label={label}
        variant="filled"
        fullWidth={true}
        rows={rows}
        rowsMax={rowsMax}
        multiline={multiline}
        onChange={handleInputChange}
        normalinput={normalinput}
        autoComplete="off"
        value={value}
        disabled={disabled}
        required={required}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
      {charLimit && (
        <div className="char-count">
          {inputValue.length}/{charLimit} chars
        </div>
      )}
    </div>
  );
};

const FilledTextField = styled(TextField)({
  "& .MuiFilledInput-root": {
    backgroundColor: `${variables.whiteColor}`,
    lineHeight: 1.5,
    fontSize: `${variables.fontSmall}`,
    border: `solid 1px ${variables.borderColor}`,
    paddingRight: "0px",
    borderRadius: "6px",
    paddingBottom: "0px",
    height: "216px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  "& .MuiFilledInput-multiline": {
    paddingTop: "15px",
    paddingRight: "4px",
    whiteSpace: "normal",
    overflow: "hidden",
  },

  "& .MuiFilledInput-root:hover": {
    backgroundColor: `${variables.whiteColor}`,
  },

  "& .MuiFilledInput-input": {
    lineHeight: "1.5",
    transition: "none",
    paddingRight: "5px",
    fontWeight: variables.fontWeight600,
    color: variables.searchTextColor,
    textOverflow: "ellipsis",
    whiteSpace: "wrap",
    marginLeft: "-5px",
  },
  "& .MuiFilledInput-underline :focus": {
    borderBottom: "none",
  },
  "& .MuiFilledInput-underline:before": {
    borderBottom: "none",
  },
  "& .MuiFilledInput-underline:after": {
    borderBottom: "none",
  },
  "& .MuiFilledInput-underline:hover:before": {
    borderBottom: "none",
  },
  "& .MuiFormLabel-asterisk": {
    color: "#e32b35",
  },
});

const Multiline = styled(FilledTextField)({
  "& .MuiInputLabel-filled.MuiInputLabel-shrink": {
    transform: " translate(12px, 15px) scale(0.75)",
  },
  "& .MuiFormLabel-root": {
    marginTop: "-5px",
  },
});

const UrlInput = styled(FilledTextField)(({ normalinput }) => ({
  "& .MuiFilledInput-root": {
    height: "44px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "wrap",
  },
  "& .MuiFilledInput-input": {
    paddingTop: "14px",
    color: normalinput ? variables.searchTextColor : variables.themeColor,
    fontWeight: variables.fontWeight600,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "wrap",
  },
  "& .MuiInputLabel-filled.MuiInputLabel-shrink": {
    transform: " translate(12px, 10px) scale(0.75)",
  },
  "& .MuiInputBase-input": {
    height: "42px ",
  },
  "& .MuiInputLabel-filled": {
    transform: "translate(12px, 17px) scale(1)",
  },
}));

export default CustomTextField;
