import { BoldText } from "components/Texts";
import React from "react";

const ImportRtsInfoHoverText = () => {
  return (
    <div>
      <BoldText>Add Reusable Text :</BoldText>

      <ol style={{ paddingLeft: "12px", marginBottom: "5px" }}>
        <li>To add a reusable text from VVPM, please click the add CTA.</li>
        <li>
          Use the search screen to apply filters or directly search for a
          reusable text.
        </li>
        <li>
          Reusable text type, Topic & Sub-Topic are the additional filter types
          provided for the search.
        </li>
      </ol>
      <BoldText>Note :</BoldText>
      <ol style={{ paddingLeft: "12px", marginBottom: "0px" }}>
        <li>
          You can add more than one Reusable text from the search and selection
          screen.{" "}
        </li>
        <li>
          You can delete one or more Reusable texts from the Re- usable Text
          Box.
        </li>
      </ol>
    </div>
  );
};

export default ImportRtsInfoHoverText;
