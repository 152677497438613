import React from "react";
import styled from "styled-components";
import variables from "../styles/base/_universal.scss";
import BlueRightArrow from "../img/blue-right-arrow.svg";
import { RoundedButton } from "./Buttons";
//url(${BlueRightArrow})
const ButtonWithArrow = styled(RoundedButton)`
  text-align: left;
  padding-right: 48px;
  padding: 8px 12px;
  background-image: ${(props) =>
    props.selected ? `url(${BlueRightArrow})` : ``};
  background-repeat: no-repeat;
  background-position: right 10px center;
  width: ${(props) => (props.width ? `${props.width}` : ``)};
  color: ${(props) =>
    props.selected
      ? `${variables.themeColor}`
      : props.disabled
      ? "#878888"
      : `${variables.blackColor}`};
  background-color: ${(props) =>
    props.selected ? `${variables.appBgColor}` : "transparent"};
  font-weight: ${(props) => (props.selected ? "600" : "normal")};
  &:focus {
    outline: none;
    box-shadow: none;
    border: none;
  }
`;

const TransRoundedButtonWithArrow = ({
  title,
  handleClick,
  selected,
  width,
  disabled = false,
}) => {
  return (
    <div>
      <ButtonWithArrow
        onClick={handleClick}
        selected={selected}
        width={width}
        disabled={disabled}
      >
        {title}
      </ButtonWithArrow>
    </div>
  );
};

export default TransRoundedButtonWithArrow;
